/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 * @author Viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    Button,
    FormControl,
    TextareaAutosize,
} from '@material-ui/core';
import Pageview from '@material-ui/icons/Pageview';

import Container from '../../components/atoms/Container/container';
import { GET, POST } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { statutAbsenceCouleur, boutonAbsenceCouleur, tableHead, tableHeadExport } from './consts';
import { Modification_statut, Export_excel } from '../../fonctionsRoles/absences';

const ListesAbsences = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataAbsence, setDataAbsence] = useState([]);
    const [singleAbsence, setSingleAbsence] = useState([]);
    const reducerFiltreMultiple = useSelector((state) => state.SelectMultiple);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [openAdminAbs, setOpenAdminAbs] = useState(false);
    const [tableauFiltre, setTableauFiltre] = useState({
        status: [],
        anneeScolaire: [],
    });
    const [avisDirection, setAvisDirection] = useState('');
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const css = useStylesMaterielUIGlobal();
    function StatusAbsenceCell(props) {
        return (
            <FormControl className={[css.directionRow, css.alignCenter]}>
                <p className="mr5 p5 radius10 textCenter" style={{ backgroundColor: statutAbsenceCouleur[props.status] }}>
                    {props.libelleAbsenceStatut}
                </p>
                <Pageview
                    onClick={() => {
                        setOpenAdminAbs(true);
                        recupSingleAbsence(props.absenceId);
                    }}
                />
            </FormControl>
        );
    }
    function ButtonModal(props) {
        if (props.verifStatut === 3) {
            return <></>;
        } else {
            return (
                <Button
                    variant="outlined"
                    className={[css.mr5, css.xs_mb2]}
                    style={{
                        color: boutonAbsenceCouleur[props.fk_absencesStatut],
                        borderColor: boutonAbsenceCouleur[props.fk_absencesStatut],
                    }}
                    onClick={() => {
                        changeStatus(props.idAbsence, props.fk_absencesStatut);
                    }}
                >
                    {props.lib}
                </Button>
            );
        }
    }
    function ViewModal(props) {
        return (
            <>
                <span className="flex1-2 ">{props.label}</span>
                <span className="flex1-2 ">{props.data}</span>
            </>
        );
    }
    async function recupSingleAbsence(idOne) {
        const response = await GET(`/api/absences/${idOne}`);
        setSingleAbsence(response.data);
        setAvisDirection(response.data.avisDirection);
    }
    async function recupDataAbsence() {
        const data = {
            anneeScolaire: reducerFiltre.AnneeScolaire,
            listStatuts: reducerFiltreMultiple.StatusAbsence.length === 0 ? null : reducerFiltreMultiple.StatusAbsence,
        };
        const response = await POST('/api/absences/listAbsences/', data);
        setDataAbsence(response.data.filter((e) => e.fk_absencesStatut !== 1));
    }
    function updatefiltre(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    async function recupFiltre() {
        const statusFiltre = await GET('/api/absences/absencesStatuts');
        updatefiltre(
            'status',
            statusFiltre.data.filter((e) => e.idAbsenceStatut !== 1),
        );
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        updatefiltre('anneeScolaire', anneeScolaireFiltre.data);
    }
    async function changeStatus(idAbs, idStat) {
        const data = {
            idAbsence: idAbs,
            fk_anneeScolaire: singleAbsence.fk_anneeScolaire,
            fk_absencesStatut: idStat === 2 ? 3 : idStat,
            dateDemande: singleAbsence.dateDemande,
            fk_user: singleAbsence.fk_user,
            fk_absencesObjet: singleAbsence.fk_absencesObjet,
            dateDebut: singleAbsence.dateDebut,
            dateFin: singleAbsence.dateFin,
            heuresAnnulees: singleAbsence.heuresAnnulees,
            reportDemande: singleAbsence.reportDemande,
            reportHeures: singleAbsence.reportHeures,
            reportDetail: singleAbsence.reportDetail,
            motif: singleAbsence.motif,
            avisDirection: avisDirection,
        };
        if (idStat) {
            await POST('/api/absences/' + idAbs, data);
        }
        setOpenAdminAbs(false);
        recupDataAbsence();
    }
    useEffect(() => {
        props.addNomPage('Gestion des absences');
        recupFiltre();
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        props.filtreMultiple([{ StatusAbsence: [2, 3] }]);
    }, []);
    useEffect(() => {
        recupDataAbsence();
    }, [refreshSimple, refreshMultiple]);
    return (
        <div className="listeContainer">
            <Dialog open={openAdminAbs} onClose={() => setOpenAdminAbs(false)} maxWidth={'md'} fullWidth={true}>
                <DialogContent>
                    <DialogContentText>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                    <TableRow className={css.trbody}>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_p0]} scope="row">
                                            <h2>Demande d'autorisation absence</h2>
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Nom" data={singleAbsence.nomPrenomUtilisateur} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal
                                                label="Dates demandées"
                                                data={
                                                    <>
                                                        <DateHeureFormat date={singleAbsence.dateDebut} lien={'au'} />
                                                        <DateHeureFormat date={singleAbsence.dateFin} />
                                                    </>
                                                }
                                            />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Motif de la demande" data={singleAbsence.libelleAbsenceObjet} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Argumentaire" data={singleAbsence.motif} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Heure Reportée" data={singleAbsence.reportHeures} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Heure Annulée" data={singleAbsence.heuresAnnulees} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            <ViewModal label="Detail du report" data={singleAbsence.reportDetail} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.mb2, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            {ViewModal({
                                                label: 'Avis direction',
                                                data: (
                                                    <TextareaAutosize
                                                        className="w100"
                                                        rowsMin={3}
                                                        value={avisDirection}
                                                        onChange={(event) => setAvisDirection(event.target.value)}
                                                    />
                                                ),
                                            })}
                                        </TableCell>
                                        <TableCell className={[css.td, css.flex, css.xs_directionColumn, css.xs_p0]} scope="row">
                                            {testDroit(user[usurpation].roleFonction, Modification_statut) ? (
                                                <>
                                                    <ButtonModal idAbsence={singleAbsence.idAbsence} lib={'Demande de modification'} fk_absencesStatut={1} />
                                                    <ButtonModal
                                                        idAbsence={singleAbsence.idAbsence}
                                                        lib={'Instruction en cours'}
                                                        fk_absencesStatut={2}
                                                        verifStatut={singleAbsence.fk_absencesStatut}
                                                    />
                                                    <ButtonModal idAbsence={singleAbsence.idAbsence} lib={'Accepter'} fk_absencesStatut={4} />
                                                    {!singleAbsence.refusPossible ? null : (
                                                        <ButtonModal idAbsence={singleAbsence.idAbsence} lib={'Refuser'} fk_absencesStatut={5} />
                                                    )}
                                                </>
                                            ) : null}
                                            <ButtonModal idAbsence={singleAbsence.idAbsence} lib={'Annuler'} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Container>
                <SelectSimple
                    tableau={tableauFiltre.anneeScolaire}
                    inputLabel={'Année Scolaire'}
                    indice={'AnneeScolaire'}
                    classe={[css.mr2, css.w25, css.xs_w100]}
                ></SelectSimple>
                <SelectMultiple
                    tableau={tableauFiltre.status}
                    inputLabel={'Statuts'}
                    indice={'StatusAbsence'}
                    classe={[css.mr2, css.w25, css.xs_w100]}
                ></SelectMultiple>
                <div className="flex alignICenter">
                    <TableRecherche tableau={dataAbsence} className={[css.w50, css.xs_w100]} indice={['nomPrenomUtilisateur']}></TableRecherche>
                    {testDroit(user[usurpation].roleFonction, Export_excel) ? (
                        <div className="ml5">
                            <ExportExcel data={dataAbsence} head={tableHeadExport} nomFichier="Liste Absences" />
                        </div>
                    ) : null}
                </div>
                <PaginationTable data={props.recherche.fn(dataAbsence)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataAbsence, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    return (
                                        <TableRow key={index} className={css.trbody}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Date Demande</div>
                                                <DateHeureFormat date={row.dateDemande} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Agent</div>
                                                {row.nomPrenomUtilisateur}
                                            </TableCell>
                                            <TableCell className={[css.td, css.w30, css.xs_w100]} component="th" scope="row">
                                                <div>Objet</div>
                                                <td>
                                                    <p className="cGris">{`${row.libelleAbsenceObjet} :`}</p>
                                                    {row.motif}
                                                </td>
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Avis direction</div>
                                                {row.avisDirection}
                                            </TableCell>
                                            <TableCell className={[css.td, css.w10, css.xs_w100]} component="th" scope="row">
                                                <div>Date de début</div>
                                                <DateHeureFormat date={row.dateDebut} />
                                            </TableCell>
                                            <TableCell className={[css.td, css.w10, css.xs_w100]} component="th" scope="row">
                                                <div>Date de fin</div>
                                                <DateHeureFormat date={row.dateFin} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Statut</div>
                                                <StatusAbsenceCell
                                                    libelleAbsenceStatut={row.libelleAbsenceStatut}
                                                    status={row.fk_absencesStatut}
                                                    absenceId={row.idAbsence}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataAbsence)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListesAbsences);
