/* eslint-disable react-hooks/exhaustive-deps */
/**
 * TopBar
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';

const Usurpation = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const verifUsurpation = useSelector((state) => state.Utilisateur.usurpation);
    const usurpation = user.userC;
    const history = useHistory();
    const [buttonU, setBouttonU] = useState(<></>);
    const [infoUsurpation, setInfoUsurpation] = useState();
    let nomUser = () => {
        if (window.matchMedia('(min-width: 576px)').matches) {
            return infoUsurpation === undefined ? user.user.nomPrenomUtilisateur : '';
        }
    };

    useEffect(() => {
        if (user.usurpation === true) {
            if (window.matchMedia('(min-width: 576px)').matches) {
                setInfoUsurpation(`Usurpation info: ${user[usurpation].role}, ${user[usurpation].nomPrenomUtilisateur}`);
            }
            setBouttonU(
                <>
                    <CustomTooltips title="Ne plus usurper cette personne.">
                        <IconButton
                            onClick={() => {
                                props.removeUsuration();
                                setInfoUsurpation();
                                history.push('/accueil');
                            }}
                            color="secondary"
                        >
                            <VisibilityOffIcon />
                        </IconButton>
                    </CustomTooltips>
                </>,
            );
        } else {
            setBouttonU(<></>);
        }
    }, [verifUsurpation]);
    return (
        <div>
            <span>{nomUser()}</span>
            <span style={{ color: 'white' }}>{infoUsurpation}</span>
            {buttonU}
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        removeUsuration() {
            dispatch({ type: 'REMOVE_USURPATION' });
        },
    };
}
export default connect(null, mapDispatchToProps)(Usurpation);
