/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { CardContent, Button } from '@material-ui/core';

import CardComponent from '../../components/organism/card/card';
import valuesQuery from '../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ChoixParcours = () => {
    const css = useStylesMaterielUIGlobal();
    const numeroEleve = useSelector((state) => state.DataInscriptionTarn.eleves);
    const history = useHistory();
    useEffect(() => {
        if (!numeroEleve) {
            history.push('/inscription/accueil');
        }
    }, []);
    if (!numeroEleve) {
        return <></>;
    }
    return (
        <div className=" bgcfff h100 flex">
            <div className="w40 ml10 mt9 p1">
                <h1>Vous allez maintenant procéder à votre préinscription</h1>
                <p>
                    Quel que soit votre âge, que vous soyez débutant ou que vous ayez déjà une pratique artistique, notre
                    établissement essaiera de vous proposer la formation qui vous convient. Si vous souhaitez mieux connaître
                    nos propositions avant de procéder à l’inscription, rendez-vous sur&nbsp;
                    <a href="https://www.cmdtarn.fr/" target="_blank" rel="noreferrer">
                        www.cmdtarn.fr
                    </a>
                    , ou vous pourrez trouver notre « règlement des études ».
                </p>
            </div>
            <div className="w50 flex mt15 pb10 spaceAround mr5 textCenter">
                <CardComponent className={[css.Bcfce5cd, css.w40]}>
                    <CardContent className={[css.flex, css.directionColumn, css.alignCenter, css.mt25, css.h60]}>
                        <p>Je consulte toutes les offres disponibles</p>
                    </CardContent>
                    <Link
                        to={
                            `/inscription/ChoixOffres?numeroEleve=${numeroEleve.length}` +
                            `${valuesQuery('reinscription') ? `&reinscription=true` : ''}`
                        }
                    >
                        <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.w60]}>
                            C'est parti
                        </Button>
                    </Link>
                </CardComponent>
                <div className="positionAbsolute bgcGrisClair h5 w7 mt10 radius40 spaceAround flex ">
                    <h2 className="nomargin">ou</h2>
                </div>
                <CardComponent className={[css.Bcf9cb9c, css.w40]}>
                    <CardContent className={[css.flex, css.directionColumn, css.alignCenter, css.mt25, css.h60]}>
                        <p>Je me laisse guider en fonction de mon âge et du site d’enseignement souhaité</p>
                    </CardContent>
                    <Link>
                        <Link
                            to={
                                `/inscription/informationGeneral?numeroEleve=${numeroEleve.length}` +
                                `${valuesQuery('reinscription') ? `&reinscription=true` : ''}`
                            }
                        >
                            <Button size="small" variant="contained" className={[css.Bcfce5cd, css.radius10, css.w60]}>
                                C'est parti
                            </Button>
                        </Link>
                    </Link>
                </CardComponent>
            </div>
        </div>
    );
};

export default connect(null, null)(ChoixParcours);
