import React from 'react';
import ListeButton from '../../atoms/ButtonCustom';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = (props) => {
    if (window.matchMedia('(max-width: 576px)').matches) {
        return <></>;
    }
    return (
        <ExcelFile
            element={
                props.label ? (
                    <span>{props.label}</span>
                ) : (
                    <ListeButton variant="outlined" size="small" color="primary">
                        Excel
                    </ListeButton>
                )
            }
        >
            <ExcelSheet data={props.data} name={props.nomFichier}>
                {props.head.map((row, i) => (
                    <ExcelColumn label={row.label} value={row.value} key={i} />
                ))}
            </ExcelSheet>
        </ExcelFile>
    );
};
export default ExportExcel;
