export default function extractModeReglement(data) {
    const modeReglement = [];
    const splitData = data.split('|-|');
    if (data) {
        for (let index = 0; index < splitData.length; index++) {
            if (modeReglement.length === 0) {
                modeReglement.push(splitData[index].split('|')[5]);
            }
            const noDoublonMode = modeReglement.filter((e) => e === splitData[index].split('|')[5]);
            if (noDoublonMode.length === 0) {
                modeReglement.push(splitData[index].split('|')[5]);
            }
        }
    }
    return modeReglement.join(',');
}
