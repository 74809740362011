import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import format from 'date-fns/format';
import moment from 'moment';

const DatePickerLocal = (props) => {
    class LocalizedUtils extends DateFnsUtils {
        getDatePickerHeaderText(date) {
            return format(date, 'd MMM yyyy', { locale: this.locale });
        }
    }

    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
            {props.children}
        </MuiPickersUtilsProvider>
    );
};
export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format('DD-MM-YYY HH:MM');
    return buildDate;
};

export default DatePickerLocal;
