/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import {
    Button,
    Select,
    TextareaAutosize,
    Switch,
    IconButton,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Drawer,
    Tooltip,
} from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { GET, POST, DELETE } from '../../components/Utils/requete';
import Input from '../../components/atoms/TextField';
import DateInput from '../../components/organism/Input/date';
import setData from '../../components/organism/Table/setDataTableau';
import DialogCustom from '../../components/organism/Dialog/dialog';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import UserInactif from '../../components/organism/UserInactif/UserInactif';
import TriToggle from '../../components/organism/Tri_Toggle/triToggle';
import ColorPicker from '../../components/organism/Input/colorPicker';
import Wait from '../../components/atoms/Wait';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ViewTableData = (props) => {
    const [openDraw, setOpenDraw] = useState({ open: false, value: undefined });
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const [dataRequete, setDataRequete] = useState([]);
    const structure = useSelector((state) => state.StructureTable);
    const [recherche, setRecherche] = useState([]);
    const [champsNouvelLigne, setChampsNouvelLigne] = useState([]);
    const [dataChamps, setDataChamps] = useState([]);
    const [newChamps, setNewChamps] = useState({});
    const [disabledSave, setDisabledSave] = useState();
    const [headTableau, setHeadTableau] = useState([]);
    const [filtre, setFiltre] = useState();
    const [booleanFiltre, setBooleanFiltre] = useState();
    const [openModal, setOpenModal] = useState({ open: false, value: '', content: '' });
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [indexColorPicker, setIndexColorPicker] = useState();
    const cookiesBDD = Cookies.get('GestionBDD') ? JSON.parse(Cookies.get('GestionBDD')) : { status: false };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [selected, setSelected] = useState([]);
    const [wait, setWait] = useState(false);
    const url = props.location.search.split('');
    url.shift();
    const urlGET = url.join('').split('&')[0];
    function updateChamps(keys, valueI, index) {
        if (index === true) {
            setNewChamps((prevState) => ({
                ...prevState,
                [keys]: valueI,
            }));
        } else {
            if (disabledSave === undefined) {
                setDisabledSave(index);
                setSelected([index]);
            } else if (disabledSave !== index) {
                return props.notification({ message: "Vous devez finir l'operation en cours" });
            }
            const creationNouvelleReference = [];
            for (let elem = 0; elem < dataChamps.length; elem++) {
                creationNouvelleReference.push({});
                Object.assign(creationNouvelleReference[elem], dataChamps[elem]);
                if (elem === +index) {
                    creationNouvelleReference[elem][keys] = valueI;
                }
            }
            setDataChamps(creationNouvelleReference);
        }
    }
    function Modal() {
        return (
            <DialogCustom
                open={openModal.open}
                children={
                    <div>
                        <h3>Attention vous allez supprimer une ligne dans la base de donnée</h3>
                        <span>Voulez-vous continuer ?</span>
                        <Bouton
                            color="secondary"
                            label={'OUI'}
                            onClick={() => {
                                deleteBDD();
                                setOpenModal({ open: false });
                            }}
                        />
                        <Bouton label={'NON'} onClick={() => setOpenModal({ open: false })} />
                    </div>
                }
            />
        );
    }
    function valuesChamps(index, value, champType, newChamp) {
        if (index === true) {
            if (champType === 'date') {
                return newChamps[value] ? new Date(newChamps[value]) : null;
            }
            return newChamps[value];
        } else {
            if (champType === 'date') {
                return !dataChamps[index][value] ? null : new Date(dataChamps[index][value]);
            }
            if (champType === 'number') {
                return !dataChamps[index][value] ? 0 : dataChamps[index][value];
            }
            if (champType === 'triToggle') {
                return dataChamps[index][value];
            }
            return !dataChamps[index][value] ? '' : dataChamps[index][value];
        }
    }

    function selectChamp(structureChamp, variableChamps, index, cssNew = []) {
        if (dataChamps.length === structure.nombreElem) {
            switch (structureChamp[0].type) {
                case 'inputToolip':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <Tooltip
                                title={
                                    <TextareaAutosize
                                        rowsMin={10}
                                        value={valuesChamps(index, variableChamps)}
                                        onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                                    />
                                }
                                interactive
                            >
                                <Input value={valuesChamps(index, variableChamps)} className={[css.nomargin, css.ml2, css.w90]} />
                            </Tooltip>
                        </TableCell>
                    );
                case 'input':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <Input
                                value={valuesChamps(index, variableChamps)}
                                className={[css.nomargin, css.ml2, css.w90]}
                                onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                            />
                        </TableCell>
                    );
                case 'number':
                    return (
                        <TableCell className={[css.td, css.w2].concat(cssNew)} component="th" scope="row">
                            <input
                                type="number"
                                value={valuesChamps(index, variableChamps, 'number')}
                                className={[css.nomargin, css.ml2, css.textRight]}
                                onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                                step={structureChamp[0].increment}
                                min={0}
                            />
                        </TableCell>
                    );
                case 'date':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <DateInput
                                value={valuesChamps(index, variableChamps, 'date')}
                                format="dd/MM/yyyy"
                                classeDiv="nomargin flex "
                                invalidDateMessage="La date n'est pas valide"
                                onChange={(event) => updateChamps(variableChamps, DateHeureFormat({ BDD: 'date', date: event }), index)}
                                children={
                                    valuesChamps(index, variableChamps, 'date') ? (
                                        <IconButton
                                            color="secondary"
                                            onClick={(event) => {
                                                updateChamps(variableChamps, null, index);
                                            }}
                                        >
                                            <EventBusyIcon />
                                        </IconButton>
                                    ) : null
                                }
                            />
                        </TableCell>
                    );
                case 'select':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <Select
                                className={css.w70}
                                value={valuesChamps(index, variableChamps)}
                                onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                            >
                                <option value={''}></option>
                                {structureChamp[0].values.map((elem, index) => {
                                    return (
                                        <option key={index} value={elem.id}>
                                            {elem.libelle}
                                        </option>
                                    );
                                })}
                            </Select>
                        </TableCell>
                    );
                case 'textarea':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <TextareaAutosize
                                rowsMin={index === true ? 5 : 3}
                                value={valuesChamps(index, variableChamps)}
                                className={[css.w90]}
                                onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                            />
                        </TableCell>
                    );
                case 'boolean':
                    return (
                        <TableCell className={[css.td, css.w2]} component="th" scope="row">
                            <Switch
                                checked={index === true ? newChamps[variableChamps] : !dataChamps[index][variableChamps] ? false : true}
                                onChange={(event) =>
                                    updateChamps(
                                        variableChamps,
                                        index === true ? !newChamps[variableChamps] : !dataChamps[index][variableChamps] ? 1 : 0,
                                        index,
                                    )
                                }
                            />
                        </TableCell>
                    );
                case 'set':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <div className="flex ml5 block w100">
                                {structureChamp[0].values.map((row, i) => (
                                    <div className="block">
                                        <span>{row}:</span>
                                        <Checkbox
                                            key={i}
                                            checked={checkedValeur(variableChamps, index, row, structureChamp)}
                                            onChange={() => updateChamps(variableChamps, updateSet(row, variableChamps, index), index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </TableCell>
                    );
                case 'colorPicker':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <div
                                className="h30px w80 block border1darkgrey cursorPointer"
                                style={{ backgroundColor: valuesChamps(index, variableChamps) }}
                                onClick={() => {
                                    setOpenColorPicker(true);
                                    setIndexColorPicker(index);
                                }}
                            />
                            {openColorPicker && indexColorPicker === index ? (
                                <div className="colorPickerTableau " style={{ position: 'absolute', zIndex: '2' }}>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px',
                                        }}
                                        onClick={() => {
                                            setOpenColorPicker(true);
                                            setIndexColorPicker();
                                        }}
                                    />
                                    <ColorPicker onChange={(event) => updateChamps(variableChamps, event.hex, index)} />
                                </div>
                            ) : null}
                        </TableCell>
                    );
                case 'triToggle':
                    return (
                        <TableCell className={[css.td].concat(cssNew)} component="th" scope="row">
                            <Select
                                className={css.w70}
                                value={valuesChamps(index, variableChamps, 'triToggle')}
                                onChange={(event) => updateChamps(variableChamps, event.target.value, index)}
                            >
                                <option value={null}></option>
                                <option value={1}>oui</option>
                                <option value={0}>non</option>
                            </Select>
                        </TableCell>
                    ); /* 
                    return (
                        <TableCell className={[css.td, css.w7].concat(cssNew)} component="th" scope="row">
                            <TriToggle
                                objectKey={variableChamps}
                                index={index}
                                label1="oui"
                                label2="non"
                                value={valueTriToggle}
                                className="mt1 "
                                defaultValue={defaultValue(valuesChamps(index, variableChamps))}
                            />
                        </TableCell>
                    ); */
                default:
                    return <></>;
            }
        } else {
            return <></>;
        }
    }

    function checkedValeur(variableChamps, index, row, structureChamp) {
        if (index === true) {
            if (newChamps[variableChamps]) {
                return newChamps[variableChamps].includes(row);
            }
        } else {
            return dataChamps[index][structureChamp[0].key].includes(row);
        }
    }
    function updateSet(event, variableChamps, index) {
        const data = index === true ? (!newChamps[variableChamps] ? [] : newChamps[variableChamps]) : dataChamps[index][variableChamps];
        if (data.includes(event)) {
            const tableauDataSet = data.filter((e) => e !== event);
            return tableauDataSet;
        } else {
            const tableauDataSet = data;
            tableauDataSet.push(event);
            return tableauDataSet;
        }
    }
    function insertionNouvelLigne() {
        const champsNouvelDonnee = [];
        for (let index = 0; index < structure.champs.length; index++) {
            if (structure.champs[index].type !== 'colorPicker') {
                setNewChamps((prevState) => ({
                    ...prevState,
                    [structure.champs[index].key]: structure.champs[index].type === 'set' ? [] : structure.champs[index].type === 'date' ? null : '',
                }));
                if (structure.champs[index].label && structure.champs[index].type !== 'date') {
                    champsNouvelDonnee.push(
                        <div>
                            <h4 className="ml2">{`${structure.champs[index].label} ${structure.champs[index].nonNull ? '*' : ''}`}</h4>
                            {selectChamp([structure.champs[index]], structure.champs[index].key, true, [css.w10])}
                        </div>,
                    );
                }
            }
        }
        return setChampsNouvelLigne(champsNouvelDonnee);
    }
    function Bouton(props) {
        return (
            <Button variant="outlined" color={props.color ? props.color : 'primary'} className={[css.m2].concat(props.className)} onClick={props.onClick}>
                {props.label}
            </Button>
        );
    }
    function Filtre() {
        const select = [];
        if (structure.length !== 0 && filtre) {
            for (let index = 0; index < structure.champs.length; index++) {
                if (structure.champs[index].type === 'select') {
                    select.push(
                        <FormControl className={css.w80}>
                            <InputLabel>{structure.champs[index].label}</InputLabel>
                            <Select
                                className={css.w70}
                                value={filtre[structure.champs[index].key]}
                                onChange={(event) => {
                                    setFiltre((prevState) => ({
                                        ...prevState,
                                        [structure.champs[index].key]: event.target.value,
                                    }));
                                    setDataChamps([]);
                                }}
                            >
                                <option value={''}></option>
                                {structure.champs[index].values.map((elem, index) => {
                                    return (
                                        <option key={index} value={elem.id}>
                                            {elem.libelle}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>,
                    );
                }
            }
            return <div className="flex ">{select}</div>;
        }
        return <></>;
    }

    function valueTriToggleFiltre(childData, index, objectKey) {
        let etatBoolean;
        switch (childData) {
            case 'premierCheck':
                etatBoolean = 1;
                break;
            case 'deuxiemeCheck':
                etatBoolean = null;
                break;
            case 'troisiemeCheck':
                etatBoolean = 0;
                break;
            default:
                break;
        }
        setBooleanFiltre((prevState) => ({
            ...prevState,
            [objectKey]: etatBoolean,
        }));
    }
    /*     function valueTriToggle(childData, index, objectKey) {
        let etatBoolean;
        switch (childData) {
            case 'premierCheck':
                etatBoolean = 1;
                break;
            case 'deuxiemeCheck':
                etatBoolean = null;
                break;
            case 'troisiemeCheck':
                etatBoolean = 0;
                break;
            default:
                break;
        }
        updateChamps(objectKey, etatBoolean, index);
    } */

    function defaultValue(etatBoolean) {
        switch (etatBoolean) {
            case 1:
                return 'premierCheck';
            case 0:
                return 'troisiemeCheck';
            case null:
                return 'deuxiemeCheck';
            default:
                return 'deuxiemeCheck';
        }
    }
    function FiltreBoolean() {
        const boolean = [];
        if (structure.length !== 0 && booleanFiltre) {
            for (let index = 0; index < structure.champs.length; index++) {
                if (structure.champs[index].filtrable) {
                    boolean.push(
                        <TriToggle
                            objectKey={structure.champs[index].key}
                            titre={structure.champs[index].label}
                            index={(index, index)}
                            label1="oui"
                            label2="non"
                            value={valueTriToggleFiltre}
                            className="mt1 "
                            defaultValue={defaultValue(booleanFiltre[structure.champs[index].key])}
                        />,
                    );
                }
            }
            const widthTriTroggle = 100 / +Object.keys(booleanFiltre).length;
            let gridTemplateColumns = ``;
            for (let elem = 0; elem < boolean.length; elem++) {
                gridTemplateColumns = gridTemplateColumns + ` ${widthTriTroggle}%`;
            }
            const style = { display: 'grid', gridTemplateColumns: gridTemplateColumns };
            return (
                <div className="mt1 mb1" style={style}>
                    {boolean}
                </div>
            );
        }
        return <></>;
    }
    function Recherche() {
        if (recherche.length !== 0) {
            return <TableRecherche tableau={dataRequete} className={[css.w50, css.xs_w100]} indice={recherche}></TableRecherche>;
        }
        return <div></div>;
    }

    async function requeteGET() {
        const query = [];
        if (filtre) {
            for (let index = 0; index < Object.keys(filtre).length; index++) {
                if (Object.values(filtre)[index]) {
                    query.push(`${Object.keys(filtre)[index]}=${Object.values(filtre)[index]}`);
                }
            }
        }
        if (booleanFiltre) {
            for (let index = 0; index < Object.keys(booleanFiltre).length; index++) {
                if (Object.values(booleanFiltre)[index] !== null) {
                    query.push(`${Object.keys(booleanFiltre)[index]}=${Object.values(booleanFiltre)[index]}`);
                }
            }
        }
        const response = await GET(`/bdd/${urlGET}${query.length !== 0 ? `?${query.join('&')}` : ''}`);
        const paramTableau = {
            header: [
                {
                    id: '',
                    label: 'Operation',
                },
            ],
            recherche: [],
        };
        let filtreBoolean = {};
        let filtreValue = {};
        for (let index = 0; index < response.data.structure.champs.length; index++) {
            if (response.data.structure.champs[index].label) {
                paramTableau.header.push({
                    id: response.data.structure.champs[index].key,
                    label: response.data.structure.champs[index].label,
                });
            }
            if (response.data.structure.champs[index].recherche) {
                paramTableau.recherche.push(response.data.structure.champs[index].key);
            }
            if (response.data.structure.champs[index].type === 'select') {
                filtreValue[response.data.structure.champs[index].key] = null;
            }
            if (response.data.structure.champs[index].filtrable) {
                filtreBoolean[response.data.structure.champs[index].key] = null;
            }
        }
        if (!filtre) {
            setFiltre(filtreValue);
        }
        if (!booleanFiltre) {
            setBooleanFiltre(filtreBoolean);
        }
        if (structure.grandeTable) {
            paramTableau.header.push({
                id: '',
                label: 'Operation',
            });
        }
        props.setStructureTable(response.data.structure);
        setHeadTableau(paramTableau.header);
        setRecherche(paramTableau.recherche);
        setDataRequete(response.data.data);
        setDataChamps(response.data.data);
        setSelected([]);
        setWait(false);
    }
    async function saveBDD(data) {
        const idKeys = structure.champs.filter((e) => e.type === 'primaryKey');
        for (let index = 0; index < Object.keys(data).length; index++) {
            if (Object.values(data)[index] === '') {
                data[Object.keys(data)[index]] = null;
            }
        }
        if (structure.champs.filter((e) => e.type === 'colorPicker').length !== 0 && data[idKeys[0].key] === null) {
            data.color = '#fff';
        }
        if (!data[idKeys[0].key]) {
            for (let index = 0; index < structure.champs.length; index++) {
                if (structure.champs[index].type === 'boolean' && !data[structure.champs[index].key]) {
                    data[structure.champs[index].key] = 0;
                }
                if (structure.champs[index].type === 'boolean' && data[structure.champs[index].key]) {
                    data[structure.champs[index].key] = 1;
                }
                if (structure.champs[index].nonNull && data[structure.champs[index].key] === null) {
                    return props.notification({ message: 'Les champs avec une * sont obligatoire' });
                }
            }
        }
        const response = await POST(`/bdd/${urlGET}/${data[idKeys[0].key]}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            props.notification({ message: "L'operation a été effectuée avec succès", status: true });
            requeteGET();
            setDisabledSave();
            setNewChamps({});
            setOpenDraw({ open: false, value: undefined });
        }
    }
    async function deleteBDD() {
        const idKeys = structure.champs.filter((e) => e.type === 'primaryKey');
        const response = await DELETE(`/bdd/${urlGET}/${openModal.value[idKeys[0].key]}`);
        if (response.success) {
            setOpenModal({ open: false });
            props.notification({ message: 'La ligne a été supprimée', status: true });
        }
        requeteGET();
    }
    useEffect(() => {
        setWait(true);
        if (cookiesBDD.status === false) {
            history.push('/accueil');
        }
        if (!cookiesBDD.verifAcces.includes(urlGET)) {
            history.push('/viewTable');
        }
    }, []);
    useEffect(() => {
        props.switchPage({
            rowsPerPage: structure.rowParPage ? structure.rowParPage : 40,
            page: props.Page[0].page,
        });
    }, [structure]);
    useEffect(() => {
        requeteGET();
    }, [filtre]);
    useEffect(() => {
        requeteGET();
    }, [booleanFiltre]);
    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [indexColorPicker]);
    return (
        <div className="listeContainer">
            {wait ? <Wait /> : null}
            <UserInactif url={'/accueil'} />
            <Modal />
            <div className="flex directionColumn">
                <div className="flex spaceBetween">
                    {structure.ajouter ? (
                        <Bouton
                            label="Ajouter un element dans la table"
                            onClick={() => {
                                insertionNouvelLigne();
                                setOpenDraw({ open: true, value: undefined });
                            }}
                            color="primary"
                        />
                    ) : null}
                    <Bouton label="Retourner sur la liste" color="secondary" onClick={() => history.push('/viewTable')} />
                </div>
                <h3 className="nomargin">{`Modification de la table ${url.join('').split('&')[1].split('%20').join(' ')}`}</h3>
                <Filtre />
                <FiltreBoolean />
                <div className="flex spaceBetween alignICenter mb2 xs_directionColumn xs_alignIBaseline">
                    {Recherche()}
                    <PaginationTable
                        data={props.recherche.fn(dataRequete)}
                        page={props.Page[0].page}
                        row={props.Page[0].rowsPerPage}
                        onPageChange={() => setDisabledSave()}
                        noRowsPerPage={true}
                    ></PaginationTable>
                </div>
                {structure.length !== 0 && dataChamps.length !== 0 ? (
                    <TableContainer component={Paper}>
                        <Table className={structure.grandeTable ? css.w200 : null}>
                            <TableHeadCustom data={headTableau}></TableHeadCustom>
                            <TableBody>
                                {setData(
                                    props.recherche.fn,
                                    dataRequete,
                                    props.Page[0].page,
                                    props.Page[0].rowsPerPage,
                                    props.orderState,
                                    props.orderByState,
                                ).map((row, index) => {
                                    let structureChamp = structure.champs.filter((e) => e.type === 'primaryKey');
                                    const indexData = dataChamps.findIndex(
                                        // eslint-disable-next-line eqeqeq
                                        (e) => e[structureChamp[0].key] == row[structureChamp[0].key],
                                    );
                                    return (
                                        <TableRow className={css.trbody} key={index} hover selected={isSelected(index)}>
                                            <TableCell
                                                className={[css.td, css.w5]}
                                                component="th"
                                                scope="row"
                                                onClick={() => (disabledSave === undefined ? setSelected([index]) : null)}
                                            >
                                                {structure.delete ? (
                                                    <IconButton
                                                        size="small"
                                                        color="secondary"
                                                        className={[css.mr2]}
                                                        onClick={() =>
                                                            setOpenModal({
                                                                open: true,
                                                                value: dataChamps[indexData],
                                                                content: 'delete',
                                                            })
                                                        }
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                ) : null}
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    className={[css.mr2]}
                                                    disabled={disabledSave !== indexData}
                                                    onClick={() => saveBDD(dataChamps[indexData])}
                                                >
                                                    <SaveIcon />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    color="secondary"
                                                    className={[css.mr2]}
                                                    style={disabledSave !== indexData ? { display: 'none' } : null}
                                                    onClick={() => {
                                                        setDataChamps(dataRequete);
                                                        setDisabledSave();
                                                    }}
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </TableCell>
                                            {Object.entries(row).map((elem, i) => {
                                                structureChamp = structure.champs.filter((e) => e.key === elem[0]);
                                                return structureChamp[0]
                                                    ? structureChamp[0].label
                                                        ? selectChamp(structureChamp, elem[0], indexData)
                                                        : null
                                                    : null;
                                            })}
                                            {structure.grandeTable ? (
                                                <TableCell
                                                    className={[css.td, css.w5]}
                                                    component="th"
                                                    scope="row"
                                                    onClick={() => (disabledSave === undefined ? setSelected([index]) : null)}
                                                >
                                                    {structure.delete ? (
                                                        <IconButton
                                                            size="small"
                                                            color="secondary"
                                                            className={[css.mr2]}
                                                            onClick={() =>
                                                                setOpenModal({
                                                                    open: true,
                                                                    value: dataChamps[indexData],
                                                                    content: 'delete',
                                                                })
                                                            }
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    ) : null}
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        className={[css.mr2]}
                                                        disabled={disabledSave !== indexData}
                                                        onClick={() => saveBDD(dataChamps[indexData])}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        color="secondary"
                                                        className={[css.mr2]}
                                                        style={disabledSave !== indexData ? { display: 'none' } : null}
                                                        onClick={() => {
                                                            setDataChamps(dataRequete);
                                                            setDisabledSave();
                                                        }}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </TableCell>
                                            ) : (
                                                <></>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : null}
                <div>
                    <Drawer
                        anchor={'right'}
                        open={openDraw.open}
                        onClose={() => {
                            setNewChamps({});
                            setOpenDraw({ open: false, value: undefined });
                        }}
                    >
                        <Bouton
                            label="Retour"
                            className={[css.w30, css.floatRight]}
                            onClick={() => {
                                setNewChamps({});
                                setOpenDraw({ open: false, value: undefined });
                            }}
                            color="secondary"
                        />
                        <h3 className="ml2 mb0">Ajouter une ligne dans la table</h3>
                        <span className="ml5 mt0 fontSmallGrey">* Ces champs sont obligatoires.</span>
                        {champsNouvelLigne}
                        <Bouton
                            className={css.w30}
                            label="Sauvegarde"
                            onClick={() => {
                                saveBDD(newChamps, true);
                            }}
                        />
                    </Drawer>
                </div>
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
        switchPage(switchPage) {
            dispatch({ type: 'switchPage', switchPage });
        },
        setStructureTable(structure) {
            dispatch({ type: 'structure', structure });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTableData);
