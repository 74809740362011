export default function formatSelect(array, id, libelle, valueVide) {
    const listeSelect = [];
    if (valueVide)
        listeSelect.push({
            label: valueVide,
            value: null,
        });
    for (let index = 0; index < array.length; index++) {
        listeSelect.push({
            value: array[index][id],
            label: array[index][libelle],
        });
    }
    return listeSelect;
}
