import React from 'react';

function Container({ className, children }) {
    return (
        <div className={className} style={{ width: '100%', margin: '50px auto 0px auto' }}>
            {children}
        </div>
    );
}

export default Container;
