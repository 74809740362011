/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { TablePagination } from '@material-ui/core';

const PaginationTable = (props) => {
    const nbElem = [5, 10, 25, 50, 100];

    const handleChangePage = (event, newPage) => {
        const switchPage = { rowsPerPage: props.row, page: newPage };
        props.switchPage(switchPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const switchPage = {
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        };
        props.switchPage(switchPage);
    };
    return (
        <TablePagination
            labelRowsPerPage={''}
            rowsPerPageOptions={props.noRowsPerPage ? props.noRowsPerPage : nbElem}
            component="div"
            count={props.data.length}
            rowsPerPage={props.row}
            page={props.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={props.style}
            className={props.className}
            onPageChange={props.onPageChange}
        />
    );
};

function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        switchPage(switchPage) {
            dispatch({
                type: 'switchPage',
                switchPage,
            });
        },
    };
}

export default connect(null, mapDispatchToProps)(PaginationTable);
