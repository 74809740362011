/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import valuesQuery from '../../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const AcceuilAgeSite = (props) => {
    const css = useStylesMaterielUIGlobal();

    return (
        <div className=" bgcfff h100 p10_35 flex directionColumn spaceAround">
            <h2>
                Le Conservatoire propose un large panel de pratiques et d’instruments. <br /> Certains instruments ne sont
                disponibles que sur des antennes spécifiques.
            </h2>
            <div className="flex directionColumn">
                <span>Vous pouvez</span>
                <span>- Soit choisir un site et voir les instruments disponibles</span>
                <span>- Soit choisir un instrument et voir sur quel site il est enseigné</span>
            </div>
            <Link
                to={
                    `/inscription/selectionTypeParcours?numeroEleve=${valuesQuery('numeroEleve')}` +
                    `${
                        valuesQuery('reinscription')
                            ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                            : '&parcours=toutesOffres'
                    }`
                }
            >
                <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.w30, css.ml70]}>
                    C'est parti
                </Button>
            </Link>
        </div>
    );
};

export default connect(null, null)(AcceuilAgeSite);
