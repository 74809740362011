/* eslint-disable eqeqeq */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { InputLabel, FormControl, Select, MenuItem, Input, Chip } from '@material-ui/core';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const SelectMultiple = (props) => {
    const css = useStylesMaterielUIGlobal();
    const value = useSelector((state) => state.SelectMultiple[props.indice]);
    const keysTableau = props.tableau[0];
    let idName = '';
    let libName = '';
    if (keysTableau !== undefined) {
        idName = Object.keys(keysTableau)[0];
        libName = Object.keys(keysTableau)[1];
    }
    if (props.tableau[0] && props.valeurNull) {
        if (props.tableau[0][idName] !== null) {
            props.tableau.splice(0, 0, { [idName]: null, [libName]: 'Non renseigné' });
        }
    }
    function updateFiltre(event, keys, liste) {
        props.filtreMultiple([{ [keys]: event }]);
        if (liste) {
            props.saveFiltre({ [keys]: event }, liste);
        }
    }
    const handleDelete = (chipToDelete) => () => {
        const chipDel = value.indexOf(chipToDelete);
        if (chipDel > -1) {
            value.splice(chipDel, 1);
        }
        props.filtreMultiple([{ [props.indice]: value }]);
        if (props.liste) {
            props.saveFiltre({ [props.indice]: value }, props.liste);
        }
    };
    return (
        <FormControl className={props.classe}>
            <InputLabel>{props.inputLabel}</InputLabel>
            <Select
                multiple
                value={value}
                onChange={(event) => {
                    updateFiltre(event.target.value, props.indice, props.liste);
                }}
                input={<Input />}
                disabled={props.disabled}
                renderValue={(selected) => {
                    return (
                        <div>
                            {value.map((item) => {
                                let display = 'flex';
                                let selectMultiple = props.tableau.filter((elem) => elem[idName] == item);
                                if (selectMultiple.length > 0) {
                                    selectMultiple = selectMultiple[0][libName];
                                }
                                if (selectMultiple.length === 0) {
                                    display = 'none';
                                }
                                return (
                                    <Chip
                                        disabled={props.disabled}
                                        style={{ display: display }}
                                        key={item}
                                        label={selectMultiple}
                                        className={css.mr2}
                                        onDelete={handleDelete(item)}
                                        onMouseDown={(event) => {
                                            event.stopPropagation();
                                        }}
                                    />
                                );
                            })}
                        </div>
                    );
                }}
            >
                {props.tableau.map((elem) => (
                    <MenuItem key={elem} value={elem[idName]}>
                        {elem[libName]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        saveFiltre(save, liste) {
            dispatch({ type: 'save', filtre: 'multiple', liste, save });
        },
    };
}

export default connect(null, mapDispatchToProps)(SelectMultiple);
