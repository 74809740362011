/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button, IconButton } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { GET, POST, DELETE } from '../../../components/Utils/requete';
import PaginationTable from '../../../components/organism/Table/Pagination';
import TableRecherche from '../../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import setData from '../../../components/organism/Table/setDataTableau';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import { testDroit } from '../../../components/Utils/testDroit';

import { tableHeadAjoutEleve, tableHeadEleves } from '../const';

import * as fonctionResponsable from '../../../fonctionsRoles/responsable';

const Eleve = (props) => {
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [open, setOpen] = useState(false);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [dataRechercheEleve, setDataRechercheEleve] = useState([]);
    const [dataEleve, setDataEleve] = useState([]);
    async function recupDataEleve() {
        const response = await GET('/api/utilisateursResponsables/' + id + '/elevesAttached');
        setDataEleve(response.data);
        props.reponseRecherche({
            fn: (items) => {
                return items;
            },
        });
    }
    async function recupDataRechercherEleve() {
        const response = await POST('/api/eleves/list/', {
            fk_anneeScolaire: reducerFiltre.AnneeScolaire,
            actif: 1,
        });
        setDataRechercheEleve(response.data);
    }
    async function ajoutEleve(idX, nomEleve) {
        const data = {
            fk_eleve: idX,
            fk_responsable: id,
        };
        const response = await POST('/api/utilisateursResponsables/elevesResponsables', data);
        if (response.success === false) {
            props.notification({
                message: "Suite à une erreur nous n'avons pas pue associer cette éléve, veuillez contater votre administrateur.",
            });
        } else {
            props.notification({
                message: `Mme/Mr ${nomEleve} ajouté en tant que eleve`,
                status: response.success,
            });
            recupDataEleve();
            setOpen(false);
        }
    }
    async function delierEleve(idEleve) {
        const data = {
            fk_eleve: idEleve,
            fk_responsable: id,
        };
        await DELETE('/api/utilisateursResponsables/elevesResponsables', data);
        recupDataEleve();
    }

    useEffect(() => {
        recupDataEleve();
    }, []);
    return (
        <div>
            {testDroit(user[usurpation].roleFonction, fonctionResponsable.Associer_eleve) ? (
                <Button
                    className={[css.mb2, css.xs_mt5, css.xs_ml2]}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        recupDataRechercherEleve();
                        setOpen(true);
                    }}
                    color={'primary'}
                >
                    Associer un éléve à un responsable
                </Button>
            ) : null}
            <DialogCustom
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                children={
                    <>
                        <h3>Recherche d'un élève</h3>
                        <Button size="small" variant="outlined" className={css.floatRight} onClick={() => setOpen(false)} color="secondary">
                            Annuler
                        </Button>
                        <div>
                            <TableRecherche tableau={dataRechercheEleve} indice={['nomPrenomEleve']}></TableRecherche>
                        </div>
                        <PaginationTable data={dataRechercheEleve} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAjoutEleve}></TableHeadCustom>
                                <TableBody>
                                    {setData(props.recherche.fn, dataRechercheEleve, props.Page[0].page, props.Page[0].rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Eleve</div>
                                                    <IconButton
                                                        color={'primary'}
                                                        onClick={() => {
                                                            ajoutEleve(row.idEleve, row.nomPrenomEleve);
                                                        }}
                                                        className={[css.cVert, css.borderCvert, css.p0, css.mr2]}
                                                    >
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                    {row.nomPrenomEleve}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Naissance</div>
                                                    {row.dateNaissance != null ? row.dateNaissance.split('-').reverse().join('/') : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationTable data={dataRechercheEleve} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                    </>
                }
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadEleves} sort={false}></TableHeadCustom>
                    <TableBody>
                        {dataEleve.map((row, index) => {
                            const iconeDelete =
                                testDroit(user[usurpation].roleFonction, fonctionResponsable.Desassocier_eleve) &&
                                !window.matchMedia('(max-width: 576px)').matches ? (
                                    <IconButton
                                        onClick={() => {
                                            delierEleve(row.fk_eleve);
                                        }}
                                        className={css.p2px}
                                        color="secondary"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                ) : null;
                            return (
                                <TableRow key={index} className={css.trbody}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>
                                            Nom Prenom
                                            {window.matchMedia('(max-width: 576px)').matches ? iconeDelete : null}
                                        </div>
                                        {window.matchMedia('(max-width: 576px)').matches ? null : iconeDelete}
                                        <Link to={`/fiche-eleve/${row.fk_eleve}`}>{row.nomPrenomEleve}</Link>
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Naissance</div>
                                        {row.dateNaissance != null ? row.dateNaissance.split('-').reverse().join('/') : null}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Mobile</div>
                                        {row.telephoneMobile}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Eleve);
