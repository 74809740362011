/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function nomPage(nomPage = 'Accueil', action) {
    if (action.type === 'stock') {
        const newnomPage = action.nomPage;
        return newnomPage;
    }

    return nomPage;
}
