import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';

const CookieRGPD = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="cookieRGPD"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={30}
        >
            Ce site utilise des cookies pour vous offrir le meilleur service. En poursuivant votre navigation, vous acceptez
            l'utilisation des cookies.
        </CookieConsent>
    );
};
export default connect(null, null)(CookieRGPD);
