/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import ElementDeSelectionOption from './elementDeSelectionOption';
import valuesQuery from '../../components/Utils/valuesQuery';
import { POST } from '../../components/Utils/requete';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const OptionOffre = (props) => {
    const css = useStylesMaterielUIGlobal();
    const dataChoixOffre = useSelector((state) => state.DataInscriptionTarn.choixOffre);
    const dataOffre = useSelector((state) => state.DataInscriptionTarn.eleves);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState();

    let option = ['site', 'observation'];
    if (dataChoixOffre) {
        if (option.includes('site') && dataChoixOffre.instrument) {
            const ajoutInstrument = option.filter((e) => e !== 'site');
            ajoutInstrument.unshift('site/instrument');
            option = ajoutInstrument;
        }
        if (dataChoixOffre.style) {
            option.splice(option.length - 1, 0, 'disciplines');
        }
        const idOffrePratiqueColective = [40, 3, 47];
        if (idOffrePratiqueColective.includes(dataOffre[valuesQuery('numeroEleve') - 1].scolarites[valuesQuery('numeroOffre') - 1].fk_offre)) {
            option.splice(option.length - 1, 0, 'listePratiqueCollective');
        }
    }
    useEffect(() => {
        if (!dataChoixOffre || !dataOffre) {
            if (valuesQuery('reinscription')) {
                history.push(`/accueilResponsable`);
            } else {
                history.push('/inscription/accueil');
            }
        }
    }, []);
    function indexType(type, index) {
        if (option.includes('site/instrument')) {
            if (type === 'site/instrument') {
                return index;
            } else {
                return index + 1;
            }
        } else {
            return index;
        }
    }
    function dataIsSet(url) {
        if (dataChoixOffre.instrument) {
            if (dataOffre[valuesQuery('numeroEleve') - 1].scolarites[valuesQuery('numeroOffre') - 1].fk_instrument.length === 0) {
                return setErrorMessage('Les champs site et instrument sont obligatoires.');
            }
        }
        if (!dataOffre[valuesQuery('numeroEleve') - 1].scolarites[valuesQuery('numeroOffre') - 1].fk_site) {
            return setErrorMessage('Les champs site et instrument sont obligatoires.');
        } else {
            if (url === '/accueilResponsable') {
                savescolariteInterne();
            }
            history.push(url);
        }
    }
    async function savescolariteInterne() {
        const data = dataOffre[valuesQuery('numeroEleve') - 1].scolarites[valuesQuery('numeroOffre') - 1];
        data.fk_eleve = +valuesQuery('idEleve');
        data.fk_idScolariteParent = null;
        if (data.fk_instrument.length === 0) {
            data.fk_instrument = null;
            await POST('/api/eleves/' + +valuesQuery('idEleve') + '/scolaritesInterne/0', data, {
                redirectOnError: false,
            });
        } else {
            for (let index = 0; index < data.fk_instrument.length; index++) {
                const objetScolarite = {};
                Object.assign(objetScolarite, data);
                objetScolarite.fk_instrument = data.fk_instrument[index];

                objetScolarite.commentaireInscription = `Choix de l' instrument: ${index + 1} \n ${data.commentaireInscription}`;
                await POST('/api/eleves/' + +valuesQuery('idEleve') + '/scolaritesInterne/0', objetScolarite, {
                    redirectOnError: false,
                });
            }
        }
    }
    function Bouton() {
        const defaultBouton = (
            <Button
                size="small"
                variant="contained"
                className={[css.BcBleu, css.radius10, css.cWhite, css.mb5, css.ml15]}
                onClick={() =>
                    dataIsSet(
                        valuesQuery('finalisation')
                            ? '/inscription/finalisationInscription'
                            : `/inscription/formulaireEleve?numeroEleve=${valuesQuery('numeroEleve')}${
                                  valuesQuery('reinscription') ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}` : ''
                              }`,
                    )
                }
            >
                Continuer l'inscription
            </Button>
        );
        if (valuesQuery('reinscription')) {
            if (valuesQuery('idEleve') !== 'null') {
                return (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            className={[css.BcBleu, css.radius10, css.cWhite, css.mb5, css.ml15]}
                            onClick={() => dataIsSet('/accueilResponsable')}
                        >
                            Finaliser cette offre
                        </Button>
                    </>
                );
            } else {
                return defaultBouton;
            }
        }
        if (!valuesQuery('reinscription')) {
            return defaultBouton;
        }

        return <></>;
    }
    function View() {
        return (
            <div className=" bgcfff h100 flex directionColumn p3">
                <div className="flex alignICenter w100">
                    <div className="flex  pl2 w55 radius10 directionColumn" style={{ border: `2px solid ${dataChoixOffre.couleur}` }}>
                        <h2 className="mb0">{dataChoixOffre.libelleOffre}</h2>
                        <div className="w100">
                            <p>{dataChoixOffre.sousTitre}</p>
                        </div>
                    </div>
                    {valuesQuery('finalisation') ? (
                        <></>
                    ) : (
                        <div className="flex directionColumn w50 alignICenter">
                            {valuesQuery('reinscription') ? null : (
                                <Link to="/inscription/FormulaireContact" className={[css.ml15]}>
                                    <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5]}>
                                        Formulaire de contact
                                    </Button>
                                </Link>
                            )}
                            <Link
                                to={
                                    valuesQuery('parcours') === 'toutesOffres'
                                        ? `/inscription/ChoixScolarite?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${valuesQuery(
                                              'numeroOffre',
                                          )}&parcours=toutesOffres`
                                        : valuesQuery('reinscription')
                                        ? `/inscription/ChoixScolarite?numeroEleve=1&numeroOffre=1&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                                        : `/inscription/PropositionOffre?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${valuesQuery('numeroOffre')}`
                                }
                                className={[css.ml15, css.w100]}
                            >
                                <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.w100]}>
                                    Retour
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
                {option.map((element, index) => (
                    <ElementDeSelectionOption
                        indexOffre={+valuesQuery('numeroOffre') - 1}
                        type={element}
                        index={indexType(element, index)}
                        color={dataChoixOffre.color}
                    />
                ))}
                <p className=" mt2" style={{ color: 'red' }}>
                    {errorMessage}
                </p>
                <div className="flex ">
                    <Bouton />
                </div>
            </div>
        );
    }
    if (dataChoixOffre) {
        return View();
    } else {
        return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return {
        ajoutOffre(numeroEleve) {
            dispatch({ type: 'ajoutoffre', numeroEleve: numeroEleve });
        },
    };
}

export default connect(null, mapDispatchToProps)(OptionOffre);
