export function affichagePassword(value) {
    let masquePassword = '';
    for (let index = 0; index < value.length; index++) {
        masquePassword = masquePassword.concat('\u2022');
    }
    return masquePassword;
}
export function insertInput(value, password) {
    return value.length > password.length ? `${password}${value.substring(value.length - 1)}` : password.substring(0, password.length - 1);
}
