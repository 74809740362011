const DateHeureFormat = (props) => {
    const lien = props.lien;
    if (props.date) {
        let DateHeureFormat = new Date(props.date).toLocaleDateString('fr-FR').replace(/\//g, '-').split('-').join('/');
        if (props.lien) {
            return `${DateHeureFormat} ${lien} `;
        }
        if (props.mois) {
            const options = { month: 'long' };
            const date = new Date(props.date);
            const moisFormat = new Intl.DateTimeFormat('fr-FR', options).format(date);
            const anneeFormat = date.getFullYear(date);
            return `${moisFormat} ${anneeFormat}`;
        }
        if (props.BDD === 'date') {
            const date = props.date === '' ? new Date() : new Date(props.date);
            DateHeureFormat = date.toLocaleDateString().replace(/\//g, '-').split('-').reverse().join('-');
        }
        if (props.BDD === 'heure') {
            const heure =
                props.date === ''
                    ? new Date()
                    : typeof props.date !== 'object'
                    ? new Date(`01/01/1970 ${props.date}`)
                    : props.date;
            DateHeureFormat = heure.toLocaleTimeString();
        }
        return DateHeureFormat;
    } else {
        return '';
    }
};
export default DateHeureFormat;
