/* -----------Liste responsable----------- */
export const tableHeadListeResponsable = [
    { id: 'nomPrenomUtilisateur', label: 'Responsable' },
    // { id: 'test', label: '' },
    { id: 'length', label: '' },
    { id: 'eleve', label: 'Élève(s)' },
];
export const tableHeadExport = [
    { label: 'Responsable', value: 'nomPrenomUtilisateur' },
    { label: 'Email', value: 'email' },
    { label: 'tèlèphone Mobile', value: 'telephoneMobile' },
    { label: 'tèlèphone Fixe', value: 'telephoneFixe' },
];
/* -----------Fiche responsable----------- */
export const tableHeadAjoutEleve = [
    { id: 'nomEleve', label: 'Eleve' },
    { id: 'naissance', label: 'Naissance' },
];
export const tableHeadFiscalite = [
    { id: 'libelleAnneeScolaire', label: 'Année scolaire' },
    { id: '', label: 'Nom prenom' },
    { id: 'dateHeure', label: 'Date' },
    { id: 'revenuFiscal', label: 'Revenu fiscal' },
    { id: 'nombrePart', label: 'Nombre parts' },
    { id: 'quotient', label: 'Quotient' },
    /*     { id: 'nombresTranches', label: 'Tranche' }, */
    { id: 'exoneration', label: 'Exoneration' },
    { id: 'fileName', label: 'Fichier' },
];
export const tableHeadCotisation = [
    { id: 'AnneeScolaireCotisation', label: 'Année scolaire' },
    { id: 'explication', label: 'Explications' },
];
export const tableHeadEleves = [
    { id: 'nomPrenomEleve', label: 'Elève' },
    { id: 'dateNaissance', label: 'Naissance' },
    { id: 'telephoneMobile', label: 'Mobile' },
];
export const inputGeneralPart1 = ['nom', 'prenom', 'email', 'adresse1', 'adresse2'];
export const inputGeneralPart2 = [
    { label: 'Lieu de naissance', input: 'lieuNaissance' },
    { label: 'Numéro du département de naissance', input: 'departementNaissance' },
];
export const inputPassword = [
    { label: 'Mot de passe', input: 'password' },
    { label: 'Confirmation mot de passe', input: 'CPassword' },
];
export const verifDataSaveResponsable = [
    'nom',
    'prenom',
    'email',
    'dateNaissance',
    'lieuNaissance',
    'fk_civilite',
    'departementNaissance',
    'adresse1',
    'codePostal',
    'ville',
    'telephoneMobile',
];
export const tableHeadCotisationDetail = [{ label: 'Montant' }, { label: 'Mode' }, { label: 'Date' }, { label: 'Quittance / Abonnement' }];
