/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControl, Checkbox, Button, Select } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import { GET, POST } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import TelephoneInput from '../../../components/organism/Input/telephone';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import dateSave from '../../../components/Utils/dateSave';
import { genre } from '../../../components/Utils/consts';
import DateInput from '../../../components/organism/Input/date';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import CardComponent from '../../../components/organism/card/card';
import creationTableauFiltre from '../../../components/Utils/createTableauFiltre';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import anneeEnCour_Inscription from '../../../components/Utils/requeteParametre/anneeEnCour_Inscription';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadScolariteExterne, input, tableHeadResponsable } from '../const';

const ViewEleve = (props) => {
    let { id } = useParams();
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    const [checkBoxAbandon, setCheckBoxAbandon] = useState(false);
    const [checkBoxAdulte, setCheckBoxAdulte] = useState(false);
    const [age, setAge] = useState();
    const [open, setOpen] = useState({ open: false });
    const [dataEleve, setDataEleve] = useState({ responsable: [], SE: [] });
    const [droitImage, setDroitImage] = useState();
    const [dataInput, setDataInput] = useState({
        nom: '',
        prenom: '',
        telephoneMobile: '',
        dateNaissance: '',
        dateInscription: '',
        dateCreation: '',
        dateAbandon: '',
        commentaireSI: '',
    });
    const [dataFiltre, setDataFiltre] = useState({
        anneeScolaire: [],
        communes: [],
        etablissement: [],
        niveau: [],
    });
    const [scolaritesExterne, setScolaritesExterne] = useState({
        idScolarite: 0,
        fk_eleve: id,
        fk_anneeScolaire: null,
        fk_etablissementScolaire: null,
        fk_niveauScolaire: null,
        coursMercrediMatin: 0,
        communes: '',
    });
    const [checkecedCourMercredi, setCheckecedCourMercredi] = useState(false);

    function updateState(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function notification(note) {
        props.notification({ message: note.message, status: note.status });
    }

    function Modal() {
        if (open.open) {
            return (
                <DialogCustom
                    open={open.open}
                    onClose={() => {
                        setOpen({ open: false });
                        setScolaritesExterne({
                            idScolarite: 0,
                            fk_eleve: id,
                            fk_anneeScolaire: null,
                            fk_etablissementScolaire: null,
                            fk_niveauScolaire: null,
                            coursMercrediMatin: 0,
                        });
                    }}
                    children={
                        <div className="flex directionColumn ">
                            <div className="p2 border1darkgrey radius10">
                                <h4 className="textCenter nomargin mb2">Scolarité éducation nationale</h4>
                                {Object.keys(dataFiltre).map((e, i) => {
                                    let label = '';
                                    switch (e) {
                                        case 'anneeScolaire':
                                            label = { label: 'Année scolaire', state: 'fk_anneeScolaire' };
                                            break;
                                        case 'communes':
                                            label = { label: 'Ville', state: 'communes' };
                                            break;
                                        case 'etablissement':
                                            label = {
                                                label: 'Établissement',
                                                state: 'fk_etablissementScolaire',
                                            };
                                            break;
                                        case 'niveau':
                                            label = {
                                                label: 'Niveau scolaire ',
                                                state: 'fk_niveauScolaire',
                                            };
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <div className="mb5" key={i}>
                                            <span>{label.label} :</span>
                                            <FormControl className={[css.w100]}>
                                                <Select
                                                    native={true}
                                                    onChange={(event) =>
                                                        label.state === 'communes'
                                                            ? requeteEtablissements(event.target.value)
                                                            : setScolaritesExterne((prevState) => ({
                                                                  ...prevState,
                                                                  [label.state]: +event.target.value,
                                                              }))
                                                    }
                                                    value={scolaritesExterne[label.state]}
                                                >
                                                    <option value={''}></option>
                                                    {dataFiltre[e].map((elem, index) => {
                                                        return (
                                                            <option key={index} value={elem.id}>
                                                                {elem.libelle}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    );
                                })}
                                <div className="flex alignICenter">
                                    <Checkbox
                                        checked={checkecedCourMercredi}
                                        onChange={() => {
                                            setCheckecedCourMercredi(!checkecedCourMercredi);
                                            setScolaritesExterne((prevState) => ({
                                                ...prevState,
                                                coursMercrediMatin: checkecedCourMercredi ? 0 : 1,
                                            }));
                                        }}
                                    />
                                    <label>Cours Mercredi</label>
                                </div>
                            </div>
                            <div className="flex mt2 spaceAround">
                                <Button color="primary" variant="outlined" size="small" onClick={() => renouvellementOffre()}>
                                    Oui
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setScolaritesExterne({
                                            idScolarite: 0,
                                            fk_eleve: id,
                                            fk_anneeScolaire: null,
                                            fk_etablissementScolaire: null,
                                            fk_niveauScolaire: null,
                                            coursMercrediMatin: 0,
                                        });
                                        setOpen({ open: false });
                                    }}
                                >
                                    Non
                                </Button>
                            </div>
                        </div>
                    }
                />
            );
        } else {
            return <></>;
        }
    }

    async function renouvellementOffre() {
        const dataScolarite = scolaritesExterne;
        const response = await POST('/api/eleves/' + id + '/scolaritesExterne/null', dataScolarite, {
            redirectOnError: false,
        });
        if (response.success) {
            recupDataEleve();
            setOpen({ open: false });
        }
    }

    async function requeteSitesEtablissements() {
        const siteFiltre = await GET('/api/eleves/SE_etablissements?mode=communes', undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            communes: creationTableauFiltre(siteFiltre.data, '', 'commune'),
        }));
        const niveauFiltre = await GET('/api/eleves/SE_niveaux');
        setDataFiltre((prevState) => ({
            ...prevState,
            niveau: creationTableauFiltre(niveauFiltre.data, 'idNiveau', 'libelle'),
        }));
    }

    async function requeteEtablissements(commune) {
        setScolaritesExterne((prevState) => ({
            ...prevState,
            communes: commune,
        }));
        const etablissementsFiltre = await GET(`/api/eleves/SE_etablissements?mode=etablissements&commune=${commune}`, undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            etablissement: creationTableauFiltre(etablissementsFiltre.data, 'idEtablissement', 'libelleEtablissement'),
        }));
    }

    async function recupDataEleve() {
        const tableauData = dataEleve;
        let response = await GET('/api/eleves/' + id + '/listResponsables');
        tableauData.responsable = response.data;
        response = await GET('/api/eleves/' + id + '/SE_list');
        tableauData.SE = response.data;
        setDataEleve(tableauData);
        response = await GET('/api/eleves/' + id);
        setAge(Math.abs(new Date(Date.now() - new Date(response.data.dateNaissance).getTime()).getFullYear() - 1970));
        for (let elem = 0; elem < input.length; elem++) {
            setDataInput((prevState) => ({
                ...prevState,
                [Object.keys(dataInput)[elem]]: response.data[input[elem].input],
            }));
        }
        if (response.data.adulte === 1) {
            setCheckBoxAdulte(true);
        }
        if (response.data.dateAbandon !== null) {
            setCheckBoxAbandon(true);
        }
        props.filtre([{ Genre: response.data.fk_genre }]);
        const anneeEnCour = await GET(`/api/parametres/getValeur/anneeEnCours`);
        const verifDroitImage = await GET(`/api/droitImage/verificationDroitImage/${id}?fk_anneeScolaire=${anneeEnCour.data.valeur}`);
        if (verifDroitImage.success) setDroitImage(verifDroitImage.data);
    }
    async function saveEleve() {
        const data = {
            idEleve: id,
            nom: dataInput.nom,
            prenom: dataInput.prenom,
            fk_genre: reducerFiltre.Genre,
            dateNaissance: DateHeureFormat({ BDD: 'date', date: dataInput.dateNaissance }),
            adulte: checkBoxAdulte === true ? 1 : 0,
            dateCreation: dateSave(),
            dateInscription: DateHeureFormat({ BDD: 'date', date: dataInput.dateInscription }),
            dateAbandon: !checkBoxAbandon ? null : dataInput.dateAbandon === '' ? null : DateHeureFormat({ BDD: 'date', date: dataInput.dateAbandon }),
            telephoneMobile: dataInput.telephoneMobile,
            dateSuppression: null,
        };
        if (data.nom === '' || data.prenom === '') {
            notification({ message: 'Veuillez remplir tous les champs' });
        } else {
            const response = await POST('/api/eleves/' + id, data);
            if (response.success) {
                notification({ message: 'Les informations ont été mise à jour.', status: true });
            }
        }
    }

    async function anneeScolaireNationale() {
        const data = await anneeEnCour_Inscription();
        setDataFiltre((prevState) => ({
            ...prevState,
            anneeScolaire: data,
        }));
    }

    useEffect(() => {
        anneeScolaireNationale();
        requeteSitesEtablissements();
        recupDataEleve();
    }, [id]);
    return (
        <div className=" ml7 mt5 mr7 overflowAuto h80 " style={{ opacity: '0.9' }}>
            {Modal()}
            <CardComponent>
                <h4 className="textCenter mt2 mb2">Informations générales de l'élève</h4>
                <div className="flex xs_directionColumn mt2 mb2">
                    <div className="w50 xs_w100 ml2">
                        <div className="flex mb1">
                            <label className="w40 xs_fontSize0_8em">Genre</label>
                            <FormControl className={[css.directionRow, css.w60]}>
                                <SelectSimple
                                    disabled={true}
                                    tableau={genre}
                                    indice={'Genre'}
                                    classe={[css.w40, css.mt0, css.xs_w80]}
                                    native={true}
                                ></SelectSimple>
                            </FormControl>
                        </div>
                        {input.map((i) => (
                            <>
                                {i.type === '' && (
                                    <div className="flex mb1">
                                        <label className="w40 xs_fontSize0_8em">{i.label}</label>
                                        {i.input === 'telephoneMobile' ? (
                                            <TelephoneInput
                                                className="textLeft xs_fontSize0_8em"
                                                value={dataInput[i.input]}
                                                onChange={(event) => {
                                                    updateState(i.input, event);
                                                }}
                                            />
                                        ) : (
                                            <Input
                                                value={dataInput[i.input]}
                                                className={[css.w40, css.mt0]}
                                                onChange={(event) => {
                                                    updateState(i.input, i.input === 'nom' ? event.target.value.toUpperCase() : event.target.value);
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                    <div className=" w40 xs_w100 ml2">
                        {input.map((i) => (
                            <>
                                {i.type !== '' && (
                                    <DateInput
                                        label={i.label}
                                        disabled={true}
                                        format="dd/MM/yyyy"
                                        value={dataInput[i.input]}
                                        classesLabel="w40 xs_fontSize0_8em"
                                        classeDiv="flex alignICenter mb0_5 "
                                        className={[css.w40, css.nonargin]}
                                        onChange={(event) => {
                                            updateState(i.input, new Date(event));
                                        }}
                                        children={i.input === 'dateNaissance' && <label className="ml2"> {age} ans</label>}
                                    />
                                )}
                            </>
                        ))}
                        <div className="flex mt5">
                            <label className="w40 xs_fontSize0_8em">Droit à l'image</label>
                            {droitImage && (
                                <label className="textLeft xs_fontSize0_8em">
                                    {+droitImage.etat === 1 ? "Accepté pour l'année en cours" : "Refusé pour l'année en cours "}
                                </label>
                            )}
                            {!droitImage && <label className="textLeft xs_fontSize0_8em"> Pas d'information pour l'année en cours</label>}
                        </div>

                        <div className="mt10 flex spaceAround">
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    saveEleve();
                                }}
                            >
                                enregistrer
                            </Button>
                        </div>
                    </div>
                </div>
            </CardComponent>
            <CardComponent className={[css.mt2, css.pl1, css.pr1, css.pb1]}>
                <h4 className="textCenter mt2 ">Responsable assigné à l'élève</h4>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadResponsable} sort={false}></TableHeadCustom>
                        <TableBody>
                            {dataEleve.responsable.map((row, index) => {
                                return (
                                    <TableRow className={css.trbody} key={index}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Nom Prenom</div>
                                            {row.nomPrenomUtilisateur}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.telephoneFixe && (
                                                <>
                                                    <div>Fixe</div>
                                                    {row.telephoneFixe}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.telephoneMobile && (
                                                <>
                                                    <div>Mobile</div>
                                                    {row.telephoneMobile}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.libelleRole && (
                                                <>
                                                    <div>Role</div>
                                                    {row.libelleRole}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Email</div>
                                            <a href={`mailto:${row.email}`}>{row.email}</a>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardComponent>
            {age >= 25 ? (
                <></>
            ) : (
                <CardComponent className={[css.mt2, css.pl1, css.pr1, css.pb1]}>
                    <div className=" mt2">
                        <h4 className="textCenter nomargin w100">Scolarité Education Nationale </h4>
                        {dataEleve.SE.filter((e) => +e.fk_anneeScolaire === +dataFiltre.anneeScolaire[0].id).length === 0 ? (
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setOpen({ open: true });
                                }}
                                className={[css.floatRight, css.mb1]}
                            >
                                Nouvelle scolarité nationale
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    const SE_EnCour = dataEleve.SE.filter((e) => +e.fk_anneeScolaire === +dataFiltre.anneeScolaire[0].id);
                                    setScolaritesExterne({
                                        idScolarite: SE_EnCour[0].idScolarite,
                                        fk_eleve: id,
                                        fk_anneeScolaire: SE_EnCour[0].fk_anneeScolaire,
                                        fk_etablissementScolaire: SE_EnCour[0].fk_etablissementScolaire,
                                        fk_niveauScolaire: SE_EnCour[0].fk_niveauScolaire,
                                        coursMercrediMatin: SE_EnCour[0].coursMercrediMatin,
                                    });
                                    requeteEtablissements(SE_EnCour[0].commune);
                                    setOpen({ open: true });
                                }}
                                className={[css.floatRight, css.mb1]}
                            >
                                Modifier la scolarité nationale
                            </Button>
                        )}
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHeadCustom data={tableHeadScolariteExterne}></TableHeadCustom>
                            <TableBody>
                                {dataEleve.SE.map((row, index) => {
                                    return (
                                        <TableRow className={css.trbody} key={index}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Année scolaire</div>
                                                {row.libelleAnneeScolaire}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Etablissement</div>
                                                {row.commune && (
                                                    <span className="flex alignICenter nomargin">
                                                        <LocationOnIcon />
                                                        {` ${row.commune}`}
                                                    </span>
                                                )}
                                                {row.libelleEtablissement && (
                                                    <span className="flex alignICenter nomargin">
                                                        <LocationCityIcon />
                                                        {` ${row.libelleEtablissement}`}
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Niveau</div>
                                                {row.libelleNiveauScolaire}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Cour mercredi</div>
                                                {row.coursMercrediMatin === 1 ? 'oui' : 'non'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardComponent>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEleve);
