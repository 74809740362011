export function suppressionDoublon(tableau) {
    let element = [];
    if (tableau !== null) {
        element = tableau
            .split(',')
            .filter((elem) => elem !== '')
            .filter((elem) => elem !== ' ')
            .filter((elem) => elem !== 'null')
            .map(JSON.stringify)
            .filter(function (elem, i, tableauTelephone) {
                return tableauTelephone.indexOf(elem, i + 1) === -1;
            })
            .map(JSON.parse);
    }

    return element;
}
