/* eslint-disable no-else-return */
/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function selectMultiple(
    filtreMultiple = {
        refreshMultiple: false,
        Site: [],
        GroupeAteliers: [],
        SousGroupeAteliers: [],
        Salles: [],
        Enseignants: [],
        Cycles: [],
        Annees: [],
        Groupes: [],
        roles: [],
        Status: [],
        StatusAbsence: [],
        StatusFrais: [],
        Modes: [],
        Visibilite: [],
        Offre: [],
        Agents: [],
    },
    action,
) {
    if (action.type === 'multiple') {
        const newFiltreMultiple = filtreMultiple;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < action.filtreMultiple.length; i++) {
            // eslint-disable-next-line prefer-destructuring
            newFiltreMultiple[Object.keys(action.filtreMultiple[i])] = Object.values(action.filtreMultiple[i])[0];
        }
        newFiltreMultiple.refreshMultiple = !newFiltreMultiple.refreshMultiple;
        return newFiltreMultiple;
    } else if (action.type === 'removeFiltreMultiple') {
        const newFiltreMultiple = {
            refreshMultiple: !filtreMultiple.refreshMultiple,
            Site: [],
            GroupeAteliers: [],
            SousGroupeAteliers: [],
            Salles: [],
            Enseignants: [],
            Cycles: [],
            Annees: [],
            Groupes: [],
            roles: [],
            Status: [],
            StatusAbsence: [],
            StatusFrais: [],
            Modes: [],
            Visibilite: [],
            Offre: [],
            Agents: [],
        };
        return newFiltreMultiple;
    }
    return filtreMultiple;
}
