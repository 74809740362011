import React from 'react';

import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Input from '../../atoms/TextField';

let erreurMinuteMax = '';

export function formatTempHeureMinute(event, value) {
    if (event.length === 2) {
        event = `${event}:`;
    }
    if (event.length === 4) {
        const gg = event.split('');
        if (gg[3] > 5) {
            erreurMinuteMax = 'Valeur pour les minutes incorrecte';
            return value;
        } else {
            erreurMinuteMax = '';
        }
    }
    if (event.length === 6) {
        event = value;
    }
    if (value) {
        if (event.length < value.length) {
            event = value;
        }
    }
    return event;
}

const TempsHeureMinute = (props) => {
    return (
        <>
            <p className="textCenter cRed nomargin">{erreurMinuteMax}</p>
            <div className="textRight alignICenter flex mb10px">
                <label className="w25">{props.label}</label>
                <Input
                    disabled={props.disabled}
                    value={props.value}
                    className={props.className}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={props.onChange}
                />
                <label>format: hh:mm</label>
                <IconButton>
                    <ClearIcon onClick={props.clearValue} />
                </IconButton>
            </div>
        </>
    );
};
export default TempsHeureMinute;
