/* eslint-disable no-else-return */
/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function fraisJour(fraisJour = {}, action) {
    if (action.type === 'jour') {
        const newJour = fraisJour;
        let jour = `jour ${action.nb}`;
        const verifJour = Object.keys(newJour);
        if (verifJour.length < 1) {
            newJour[jour] = {
                date: '',
                typeJour: '',
                heureDepart: '',
                kilometrage: 0,
                NBR: 0,
                heureRetour: '',
                MDivers: 0,
                DernierTrajet: 0,
                refreshTrajet: false,
                newJour: false,
                motif: '',
                idFraisJournee: '',
                fk_frais: '',
            };
            newJour.refreshJour = !newJour.refreshJour;
        } else {
            if (verifJour.indexOf(jour) > -1) {
                const updateJour = newJour[jour];
                updateJour[Object.keys(action.data)] = Object.values(action.data)[0];
                if (Object.keys(action.data) !== 'kilometrage') {
                    newJour.refreshJour = !newJour.refreshJour;
                }
                // eslint-disable-next-line eqeqeq
            } else if (verifJour.indexOf(jour) == -1) {
                newJour[jour] = {
                    date: '',
                    typeJour: '',
                    heureDepart: '',
                    kilometrage: 0,
                    NBR: 0,
                    heureRetour: '',
                    MDivers: 0,
                    DernierTrajet: 0,
                    refreshTrajet: false,
                    newJour: false,
                    motif: '',
                };
                newJour.refreshJour = !newJour.refreshJour;
            }
        }
        return newJour;
    }
    if (action.type === 'addTrajet') {
        const newJour = fraisJour;
        const jour = `jour ${action.nbJ}`;
        const trajet = `trajet ${action.trajet}`;
        const upTrajet = `trajet ${newJour[jour].DernierTrajet - 2}`;
        const verifJour = Object.keys(newJour[jour]);
        if (verifJour.includes(trajet) === false) {
            newJour[jour][trajet] = action.ville;
            newJour.refreshJour = !newJour.refreshJour;
        } else {
            newJour[jour][trajet][Object.keys(action.ville)] = Object.values(action.ville)[0];
            newJour.refreshJour = !newJour.refreshJour;
        }
        // eslint-disable-next-line eqeqeq
        if (trajet == upTrajet) {
            newJour.refreshJour = !newJour.refreshJour;
        }
        if (Object.keys(action.ville)[0] === 'km') {
            let calculKM = 0;
            for (let index = 0; index < newJour[jour].DernierTrajet; index++) {
                const quelTrajet = `trajet ${index}`;
                if (newJour[jour][quelTrajet]) {
                    calculKM =
                        calculKM + parseInt(newJour[jour][quelTrajet].km === undefined ? 0 : newJour[jour][quelTrajet].km, 10);
                }
            }
            newJour[jour].kilometrage = calculKM;
        }
        return newJour;
    }
    if (action.type === 'removeJour') {
        const newJour = fraisJour;
        let jour = `jour ${action.nb}`;
        delete newJour[jour];
        newJour.refreshJour = !newJour.refreshJour;
        return newJour;
    }
    if (action.type === 'removetrajet') {
        const newJour = fraisJour;
        const trajet = `trajet ${action.trajet}`;
        const jour = `jour ${action.nb}`;
        delete newJour[jour][trajet];
        newJour.refreshJour = !newJour.refreshJour;
        return newJour;
    }
    if (action.type === 'removeAll') {
        const newJour = {};
        return newJour;
    }
    if (action.type === 'periode') {
        const newJour = fraisJour;
        newJour.periode = action.periode;
        return newJour;
    }
    return fraisJour;
}
