export const tableHead = [
    { id: 'date', label: 'Date' },
    { id: 'sites', label: 'Site' },
    { id: 'nomPrenomResponsable', label: 'Responsable' },
    { id: 'montant', label: 'Montant / Mode' },
    { id: 'quittance', label: 'Quittance' },
    { id: 'siteR', label: 'Site Reglement / Agent' },
];
export const urlFiltre = [
    { label: 'site', url: '/api/sites/liste' },
    { label: 'mode', url: '/api/cotisations/reglementsModes' },
    { label: 'anneeScolaire', url: '/api/anneesScolaire/list' },
    { label: 'agents', url: '/api/reglements/listeAgentEtatReglement' },
];
export const tableExport = [
    { label: 'Date', value: 'date' },
    { label: 'Site', value: 'sites' },
    { label: 'Nom Responsable', value: 'nomPrenomResponsable' },
    { label: 'Montant', value: 'montant' },
    { label: 'Mode', value: 'libelleMode' },
    { label: 'Quittance', value: 'quittance' },
    { label: 'Site Reglement', value: 'siteReglement' },
    { label: 'Nom Agent', value: 'nomPrenomAdministratif' },
];
