/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { getInformationOffre } from './getInformationOffre';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const infoResponsable = [
    { label: 'Nom', keys: 'nom' },
    { label: 'Prénom', keys: 'prenom' },
    { label: 'Email', keys: 'email' },
    { label: 'Téléphone fixe', keys: 'telephoneFixe' },
    { label: 'Téléphone mobile', keys: 'telephoneMobile' },
    { label: 'Profession', keys: 'profession' },
];
const adresseResponsable = [
    { label: 'Adresse', keys: 'adresse1' },
    { label: 'Adresse suite', keys: 'adresse2' },
    { label: 'Code postal', keys: 'codePostal' },
    { label: 'Ville', keys: 'ville' },
];
const infoNaissance = [
    { label: 'Date de naissance', keys: 'dateNaissance' },
    { label: 'Lieu de naissance', keys: 'lieuNaissance' },
    { label: 'Département de naissance', keys: 'departementNaissance' },
];
const affichageEleve = [
    { label: 'Nom', keys: 'nom' },
    { label: 'Prénom', keys: 'prenom' },
    { label: 'Date de naissance', keys: 'dateNaissance' },
    { label: "Droit à l'image", keys: 'droitImage' },
];

const FinalisationResponsable = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    let data = useSelector((state) => state.DataInscriptionTarn);
    const [informationOffre, setInformationOffre] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    async function recupInformationOffre(refresh) {
        if (informationOffre.length === 0 || refresh) {
            const eleves = [];
            let count = 0;
            for (let index = 0; index < data.eleves.length; index++) {
                eleves.push({ scolarite: [] });
                if (data.eleves[index].scolarites) {
                    if (data.eleves[index].scolarites.length !== 0) count++;
                    for (let elem = 0; elem < data.eleves[index].scolarites.length; elem++) {
                        const scolarite = await getInformationOffre(
                            data.eleves[index].scolarites[elem].fk_offre,
                            data.eleves[index].scolarites[elem].fk_site,
                            data.eleves[index].scolarites[elem].fk_instrument,
                            data.eleves[index].scolarites[elem].fk_style,
                        );

                        if (scolarite) {
                            const informationOffre = [
                                { label: 'Offre', keys: 'fk_offre', libelle: Object.values(scolarite.data[0])[0] },
                                { label: 'Site', keys: 'fk_site', libelle: Object.values(scolarite.data[0])[1] },
                                { label: 'Instrument', keys: 'fk_instrument', libelle: null },
                                { label: 'Style (danse)', keys: 'fk_style', libelle: null },
                            ];
                            if (data.eleves[index].scolarites[elem].fk_instrument && Object.values(scolarite.data[0])[2]) {
                                informationOffre[2].libelle = Object.values(scolarite.data[0])[2];
                            }
                            if (data.eleves[index].scolarites[elem].fk_style && Object.values(scolarite.data[0])[3]) {
                                informationOffre[3].libelle = Object.values(scolarite.data[0])[3];
                            }
                            eleves[index].scolarite.push(informationOffre);
                        }
                    }
                }
            }
            if (count !== data.eleves.length) setDisabledButton(true);
            setInformationOffre(eleves);
        }
    }

    useEffect(() => {
        if (Object.entries(data).length === 0) {
            history.push('/inscription/accueil');
        } else {
            recupInformationOffre();
        }
    }, []);
    if (Object.entries(data).length === 0) {
        return <></>;
    }

    return (
        <div className=" bgcfff h100 flex directionColumn p2">
            <div className="flex alignICenter w100">
                <div className="flex  pl2 w55 radius10 directionColumn" style={{ border: `2px solid #2e76b0 ` }}>
                    <h3>Résumé de votre inscription</h3>
                </div>
                <div className="flex w40 center">
                    {!disabledButton && (
                        <Link to="/inscription/cgu">
                            <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5]}>
                                continuer l'inscription
                            </Button>
                        </Link>
                    )}
                    {disabledButton && <span>Pour poursuivre l'inscription tous les éléves doivent avoir une offre.</span>}
                </div>
            </div>
            <div>
                <div className="flex mt2 ">
                    <div className="w25">
                        <h3 className="nomargin mb5 textCenter">Responsable</h3>
                        <div className=" radius30 p5 mb2" style={{ border: `2px solid #ff6347 ` }}>
                            <div className="flex directionColumn flexJCSpaceBetween">
                                <h4 className="nomargin mb2">Coordonnées</h4>
                                {infoResponsable.map((row, index) =>
                                    data.responsable[row.keys] ? (
                                        <div key={index}>
                                            <span>{row.label}:</span>
                                            <span>
                                                {row.label.split(' ')[0] === 'Téléphone' ? ` +${data.responsable[row.keys]}` : ` ${data.responsable[row.keys]}`}
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    ),
                                )}
                                <h4 className="mt5 mb2">Adresse</h4>
                                {adresseResponsable.map((row, index) =>
                                    data.responsable[row.keys] ? (
                                        <div key={index}>
                                            <span>{row.label}:</span>
                                            <span>{` ${data.responsable[row.keys]}`}</span>
                                        </div>
                                    ) : (
                                        <></>
                                    ),
                                )}
                                <h4 className="mt5 mb2">Autres informations</h4>
                                {infoNaissance.map((row, index) => (
                                    <div key={index}>
                                        <span>{row.label}:</span>
                                        <span>{` ${data.responsable[row.keys]}`}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <hr></hr>
                                <Link to="/inscription/formulaireResponsable?finalisation=true">Modifier</Link>
                            </div>
                        </div>
                    </div>
                    <div className="w70 ml4 ">
                        <div className="flex">
                            <Link
                                to={`/inscription/formulaireEleve?numeroEleve=${data.eleves.length + 1}&finalisation=true`}
                                onClick={() => props.ajoutEleve(true)}
                            >
                                <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5]}>
                                    Ajouter un élève
                                </Button>
                            </Link>
                            <h3 className="w70 nomargin mb2 textCenter">Élèves</h3>
                        </div>
                        {data.eleves.map((elem, i) => {
                            if (elem.nom) {
                                return (
                                    <div className="flex radius30 p2 mb1" style={{ border: `2px solid #32cd32 ` }}>
                                        <div className="w25 flex directionColumn flexJCSpaceBetween mr1">
                                            <div>
                                                <h4 className="nomargin mb10px">Renseignements</h4>
                                                {affichageEleve.map((row, index) => (
                                                    <div key={index}>
                                                        <span>{row.label}:</span>
                                                        {row.keys === 'droitImage' ? (
                                                            <span>{` ${elem[row.keys] ? 'Accepté' : 'refusé'}`}</span>
                                                        ) : (
                                                            <span>{` ${elem[row.keys]}`}</span>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                <hr></hr>
                                                <Link to={`/inscription/formulaireEleve?numeroEleve=${i + 1}&finalisation=true`}>Modifier</Link>
                                            </div>
                                        </div>
                                        <div className="flex w75 justifySpaceEvenly ">
                                            {informationOffre[i] ? (
                                                informationOffre[i].scolarite.map((row, index) => (
                                                    <div key={index} className="flex directionColumn flexJCSpaceBetween maxW37">
                                                        <div>
                                                            <h4 className="nomargin mb10px">Offre {index + 1}</h4>
                                                            {row.map((elem, i) =>
                                                                elem.libelle !== null ? (
                                                                    <div key={i} className="flex">
                                                                        <span className="pr2">{elem.label}:</span>
                                                                        <span>{elem.libelle}</span>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                ),
                                                            )}
                                                        </div>
                                                        <div>
                                                            <hr></hr>
                                                            <Link
                                                                onClick={() => {
                                                                    props.suppressionOffre(index, i);
                                                                    recupInformationOffre(true);
                                                                }}
                                                            >
                                                                Supprimer cette offre
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                            {informationOffre[i] ? (
                                                informationOffre[i].scolarite.length < 3 ? (
                                                    <Link
                                                        to={`/inscription/ChoixOffres?numeroEleve=${i + 1}&finalisation=true`}
                                                        onClick={() => props.ajoutOffre(i)}
                                                    >
                                                        <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5]}>
                                                            Ajouter une offre
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                );
                            } else {
                                props.suppressionEleve(i);
                                return <></>;
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        testDev(data) {
            dispatch({ type: 'testDataDev', data: data });
        },
        suppressionOffre(numeroOffre, numeroEleve) {
            dispatch({ type: 'suppressionOffre', numeroOffre, numeroEleve });
        },
        suppressionEleve(numeroEleve) {
            dispatch({ type: 'suppessionEleve', numeroEleve });
        },
        ajoutOffre(numeroEleve) {
            dispatch({ type: 'ajoutoffre', numeroEleve: numeroEleve });
        },
        ajoutEleve(finalisation) {
            dispatch({ type: 'ajoutEleve', finalisation: finalisation });
        },
    };
}

export default connect(null, mapDispatchToProps)(FinalisationResponsable);
