/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { Button, CardContent, CardActions, IconButton, TextareaAutosize, Drawer } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import { GET, POST } from '../../Utils/requete';
import Card from '../card/card';
import DatePickerRange from '../DatePicker/datePickerRange';
import { semaine } from '../DatePicker/defaultPeriode';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ExcuseEleveAtelier = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [display, setDisplay] = useState({ declarer: 'none', annuler: 'none' });
    const [commentaire, setCommentaire] = useState(null);
    const [atelierExcuser, setAtelierExcuser] = useState([]);
    const [open, setOpen] = useState(false);
    const [periode, setPeriode] = useState(semaine);

    function changeDisplay(keys) {
        for (let index = 0; index < Object.keys(display).length; index++) {
            if (display[Object.keys(display)[index]] && Object.keys(display)[index] !== keys) {
                setDisplay((prevState) => ({
                    ...prevState,
                    [Object.keys(display)[index]]: 'none',
                }));
            }
            setDisplay((prevState) => ({
                ...prevState,
                [keys]: display[keys] === 'none' ? 'block' : 'none',
            }));
        }
    }
    function AbsencesParAtelier(props) {
        return (
            <div className={`flex ${props.className}`}>
                {atelierExcuser.length !== 0 ? (
                    atelierExcuser.map((row, index) => {
                        const dateAtelier = new Date(row.date).toLocaleDateString('fr-FR', {
                            weekday: 'long',
                            month: 'long',
                            day: 'numeric',
                        });
                        if (row.fk_statutPresence === 1) {
                            return (
                                <div id={index} key={index} style={props.style} className="flex alignICenter mt2">
                                    <IconButton size="small" color="secondary" className={[css.mr5]} onClick={() => annuleExcuse(row, index)}>
                                        <CancelIcon />
                                    </IconButton>
                                    <div className="flex directionColumn">
                                        <span>{row.libelleType}</span>
                                        <span>{dateAtelier}</span>
                                    </div>
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })
                ) : (
                    <span>Il n'y a pas d'excuse pour cette semaine</span>
                )}
            </div>
        );
    }
    async function saveExcuseEleve() {
        const data = {
            fk_eleve: props.idEleve,
            atelier: [props.dataAtelier],
            periodeDebut: periode[0].toLocaleString().split(' ')[0].split('/').reverse().join('-'),
            periodeFin: periode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-'),
            commentaire: commentaire,
        };
        const response = await POST(`/api/presences/absencesEleveAuxAteliers`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            setCommentaire('');
            setOpen(false);
        }
    }
    async function recupExcuseParPeriode(datePeriode) {
        setPeriode(datePeriode);
        const response = await GET(
            `/api/ateliers/elevesParAteliersParEleve/${props.idEleve}?dateDebut=${datePeriode[0]
                .toLocaleString('fr-FR')
                .split(' ')[0]
                .split('/')
                .reverse()
                .join('-')}&dateFin=${datePeriode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}}`,
            undefined,
            {
                redirectOnError: false,
            },
        );
        const atelier = response.data.filter((e) => e.idAtelier === props.dataAtelier.idAtelier);
        setAtelierExcuser(atelier);
    }
    async function annuleExcuse(dataEleve, index) {
        const data = {
            fk_responsable: user[usurpation].fk_id,
            eleve: {
                idPresenceEleve: dataEleve.idPresenceEleve,
                fk_presence: dataEleve.fk_presence,
                fk_eleve: dataEleve.fk_eleve,
                fk_statutPresence: null,
                commentaire: '',
                fk_utilisateur: null,
                dateExcuse: null,
            },
        };
        const response = await POST(`/api/presences/presenceEleve/${dataEleve.idPresenceEleve}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            setAtelierExcuser([]);
        }
    }
    function view() {
        return (
            <div className="ml5 mt2">
                <div className="mb2 mt2">
                    <h3>{props.nomEleve}</h3>
                    <div className="flex directionColumn">
                        <div className="m2">
                            <Button size="small" variant="outlined" onClick={() => changeDisplay('declarer')} color="primary">
                                Déclarer une absence
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    recupExcuseParPeriode(periode);
                                    changeDisplay('annuler');
                                }}
                                className={css.ml5}
                            >
                                Annuler une absence
                            </Button>
                        </div>
                        <div
                            style={{
                                display: display.declarer,
                            }}
                            className="m2 spaceAround"
                        >
                            <Card className={css.w35}>
                                <CardContent className="bgcBleu0f4c81 cWhite textCenter">
                                    <h4 className="mb0 mt2">Absence(s) pour le cours {props.dataAtelier.libelleType}</h4>
                                </CardContent>
                                <CardContent>
                                    <DatePickerRange
                                        className="ml10 mt2 mb2"
                                        onChange={(event) => setPeriode(event)}
                                        value={periode}
                                        placement="bottomEnd"
                                        disabledDate={'beforeToday'}
                                    />
                                    <span className="textCenter fontSmallGrey ">Le début et la fin de période sont obligatoires</span>
                                    <TextareaAutosize
                                        placeholder="Commentaire"
                                        className={[css.w100]}
                                        aria-label="minimum height"
                                        rowsMin={3}
                                        onChange={(event) => setCommentaire(event.target.value)}
                                        value={commentaire}
                                    />
                                </CardContent>
                                <CardActions className={css.center}>
                                    <Button variant="outlined" size="small" color="primary" onClick={() => saveExcuseEleve()}>
                                        Validation
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div
                            style={{
                                display: display.annuler,
                            }}
                            className="m2 spaceAround"
                        >
                            <Card className={css.w35}>
                                <CardContent className="bgcBleu0f4c81 cWhite textCenter">
                                    <h4 className="mb0 mt2">Absence déclarée pour le cours {props.dataAtelier.libelleType}</h4>
                                </CardContent>
                                <CardContent>
                                    <DatePickerRange
                                        onChange={(event) => recupExcuseParPeriode(event)}
                                        value={periode}
                                        placement="bottomEnd"
                                        disabledDate={'beforeToday'}
                                    />
                                    <span className="fontSmallGrey">Cliquer sur la croix pour annuler une absence.</span>
                                    <AbsencesParAtelier className="directionColumn" />
                                    <CardActions className={css.center}>
                                        <Button variant="outlined" size="small" color="primary" onClick={() => changeDisplay('annuler')}>
                                            Fermer
                                        </Button>
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <IconButton
                size="small"
                color="secondary"
                className={[css.floatRight, css.p0, css.cVert]}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <EventBusyIcon />
            </IconButton>
            <div>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setOpen(false);
                            changeDisplay();
                        }}
                        color="secondary"
                        className={[css.w25, css.m2]}
                    >
                        retour
                    </Button>
                    {view()}
                </Drawer>
            </div>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ExcuseEleveAtelier);
