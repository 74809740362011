import React from 'react';
import { connect, useSelector } from 'react-redux';
import { InputLabel, FormControl, Select } from '@material-ui/core';

const SelectSimple = (props) => {
    const value = useSelector((state) => state.SelectSimple[props.indice]);
    const keysTableau = props.tableau[0];
    let idName = '';
    let libName = '';
    if (keysTableau !== undefined) {
        idName = Object.keys(keysTableau)[0];
        libName = Object.keys(keysTableau)[1];
    }
    function updateFiltre(event, keys, liste) {
        props.filtre([{ [keys]: event }]);
        if (liste) {
            props.saveFiltre({ [keys]: event }, liste);
        }
    }
    return (
        <FormControl className={props.classe}>
            {props.inputLabel ? <InputLabel>{props.inputLabel}</InputLabel> : null}
            <Select
                disabled={props.disabled}
                native={false || props.native}
                value={props.value ? props.value : value}
                onChange={
                    props.onChange
                        ? props.onChange
                        : (event) => {
                              updateFiltre(event.target.value, props.indice, props.liste);
                          }
                }
            >
                <option value={''}></option>
                {props.tableau.map((elem, index) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <option key={index} value={elem[idName]}>
                            {elem[libName]}
                        </option>
                    );
                })}
            </Select>
        </FormControl>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        saveFiltre(save, liste) {
            dispatch({ type: 'save', filtre: 'simple', liste, save });
        },
    };
}

export default connect(null, mapDispatchToProps)(SelectSimple);
