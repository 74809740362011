import React from 'react';
import { connect } from 'react-redux';

import { Card } from '@material-ui/core';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const CardComponent = (props) => {
    const css = useStylesMaterielUIGlobal();
    return (
        <Card className={[css.p0].concat(props.className)} style={props.style}>
            {props.children}
        </Card>
    );
};

export default connect(null, null)(CardComponent);
