import React from 'react';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import EventIcon from '@material-ui/icons/Event';

import { calculDuree } from '../../Utils/calculTemp';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { Jour } from '../../Utils/consts';

const TempCoursFormat = (props) => {
    const css = useStylesMaterielUIGlobal();
    const heure = `${props.heureDebut} → ${props.heureFin}`;
    let jour;
    const temps = calculDuree(props.heureDebut, props.heureFin);
    if (props.jour) jour = Jour[props.jour - 1].lib;
    return (
        <>
            {props.jour ? (
                <>
                    <EventIcon className={css.fontSize0_9em} />
                    {jour}
                    <br />
                </>
            ) : null}
            <AccessTimeIcon className={css.fontSize0_9em} />
            {heure}
            <br />
            {props.jour ? (
                <>
                    <HourglassEmptyIcon className={css.fontSize0_9em} />
                    {temps}
                </>
            ) : null}
        </>
    );
};
export default TempCoursFormat;
