/**
 * Style des composants HTML
 *
 * @copyright Horizon-Pharma
 */
import styled from 'styled-components';

export const Main = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const BasicForm = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Link = styled.a`
    color: red;
    margin: 15px;
`;
