/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import maintenance from '../../assets/maintenance.png';

const Maintenance = () => {
    function Redirection() {
        return (
            <Link to="/connexion">
                <p>Vous etes autorisé à vous conneter</p>
            </Link>
        );
    }
    return (
        <div className="flex alignICenter">
            <img src={maintenance} alt="" className="w50" />
            <div>
                <h1 className="">Le site est en maintenance</h1>
                <Redirection />
            </div>
        </div>
    );
};

export default connect(null, null)(Maintenance);
