const Brouillon = 1;
const Soumis = 2;
const EnCours = 3;
const Accepte = 4;
const Refuse = 5;
/* gestion des badges status */
export const statutAbsenceCouleur = {
    [Brouillon]: '#C0C0C0',
    [Soumis]: '#ad9384',
    [EnCours]: '#91bdc7',
    [Accepte]: '#78cf99',
    [Refuse]: '#f26a44',
};

/*  gestion des boutons modal */
export const boutonAbsenceCouleur = {
    [Brouillon]: '#ad9384',
    [Soumis]: '#1976d2',
    [EnCours]: '#1976d2',
    [Accepte]: '#19853a',
    [Refuse]: '#de4310',
};

/* tableau head liste absences*/
export const tableHead = [
    { id: 'dateDemande', label: 'Date demande' },
    { id: 'nomPrenomUtilisateur', label: 'Agent' },
    { id: 'fk_absencesObjet', label: 'Objet' },
    { id: 'avisDirection', label: 'Commentaire' },
    { id: 'dateDebut', label: 'Date de début' },
    { id: 'dateFin', label: 'Date de fin' },
    { id: 'fk_absencesStatut', label: 'Statut' },
];

/* tableau export  */
export const tableHeadExport = [
    { value: 'nomPrenomUtilisateur', label: 'Agent' },
    { value: 'dateDemande', label: 'Date demande' },
    { value: 'libelleAbsenceObjet', label: 'Objet' },
    { value: 'motif', label: 'Motif' },
    { value: 'dateDebut', label: 'Date de début' },
    { value: 'dateFin', label: 'Date de fin' },
    { value: 'libelleAbsenceStatut', label: 'Statut' },
];

/* tableau head mes absences */
export const tableHeadAbsence = [
    { id: 'dateDemande', label: 'Date demande' },
    { id: 'fk_absencesObjet', label: 'Objet' },
    { id: 'avisDirection', label: 'Commentaire' },
    { id: 'dateDebut', label: 'Date de début' },
    { id: 'dateFin', label: 'Date de fin' },
    { id: 'fk_absencesStatut', label: 'Statut' },
];
