export const tableHead = [
    { id: 'nomPrenomUtilisateur', label: 'Nom' },
    { id: 'libelleSite', label: 'Site' },
    { id: 'date', label: 'Date' },
    { id: 'nomAgent', label: 'Agent' },
    { id: '', label: 'Règlements' },
    { id: '', label: 'Explications' },
];

export const tableauHeadFacture = [{ label: 'Montant' }, { label: 'Mode' }, { label: 'Date' }, { label: 'Quittance / Abonnement' }, { label: 'Édition' }];
