import React from 'react';

import { Dialog, DialogContent } from '@material-ui/core';

export default function DialogCustom(props) {
    return (
        <div>
            <Dialog
                fullScreen={props.fullScreen}
                open={props.open}
                onClose={props.onClose}
                fullWidth={props.fullWidth}
                maxWidth={props.maxWidth}
            >
                <DialogContent className={props.className}>{props.children}</DialogContent>
            </Dialog>
        </div>
    );
}
