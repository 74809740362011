/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Paper, Checkbox, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, TextareaAutosize } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Edit } from '@material-ui/icons';

import { GET, POST } from '../../components/Utils/requete';
import Input from '../../components/atoms/TextField';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import { durationToMinutes, minutesToDuration } from '../../components/Utils/calculTemp';
import DialogCustom from '../../components/organism/Dialog/dialog';
import InfoAgent from '../../components/organism/InfoAgent/infoAgent';
import TempsHeureMinute from '../../components/organism/Input/tempsHeureMinute';
import { formatTempHeureMinute } from '../../components/organism/Input/tempsHeureMinute';
import { testDroit } from '../../components/Utils/testDroit';
import SelectInstrument from '../../components/organism/Instrument&Affectation/select';
import InputMonetaire from '../../components/organism/Input/monetaire';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHeadAffectation, tableHeadDiscipline, selectEmploi, inputContrat, inputDecharge } from './const';

import * as fonctionServices from '../../fonctionsRoles/services';

const FicheService = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const [dataInput, setDataInput] = useState({
        dureeCDD: null,
        heuresContrat: null,
        quotite: null,
        coutHS: null,
        dechargeSyndicaleHeures: null,
        dechargeAntenneHeures: null,
        dechargeAntenneMotif: null,
        dechargeAutreHeures: null,
        dechargeAutreMotif: null,
        RTE: 0,
        commentaire: '',
        heures: null,
    });
    const [tableauFiltre, setTableauFiltre] = useState({
        Corps: [],
        Grade: [],
        Filiere: [],
        Diplome: [],
        Emploi: [],
        AnneeScolaire: [],
        Contrat: [],
        SiteResidence: [],
        Site: [],
        Instrument: [],
        Antenne: [],
    });
    const [checkTempPartiel, setCheckTempPartiel] = useState(false);
    const [open, setOpen] = useState({ open: false });
    const [dataAffectation, setDataAffectation] = useState([]);
    const [dataDiscipline, setDataDiscipline] = useState([]);
    const [serviceExist, setServiceExist] = useState(false);
    const [discipline, setDicipline] = useState(false);
    const [commentaireNewAffectation, setCommentaireNewAffectation] = useState('');

    const valueFiltre = useSelector((state) => state.SelectSimple);
    const refreshSimple = useSelector((state) => state.SelectSimple.AnneeScolaire);
    function update(FT, keys, valueI) {
        const inputHeure = ['heuresContrat', 'dechargeSyndicaleHeures', 'dechargeAntenneHeures', 'dechargeAutreHeures', 'heures'];
        if (inputHeure.includes(keys) && valueI) {
            valueI = formatTempHeureMinute(valueI, dataInput[keys]);
        }
        const state = FT === 'filtre' ? setTableauFiltre : setDataInput;
        state((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function verificationDroit(Mesdroit, autreDroit) {
        if (+user[usurpation].fk_id !== +id) return testDroit(user[usurpation].roleFonction, autreDroit);
        return testDroit(user[usurpation].roleFonction, Mesdroit);
    }
    function ButtonCustom(props) {
        if (verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)) {
            if (props.duplicate && serviceExist) {
                return <></>;
            }
            const link = function (balise) {
                return props.link ? <Link to={props.link}>{balise}</Link> : <>{balise}</>;
            };
            const tooltip = function (balise) {
                return props.title ? <CustomTooltips title={props.title} children={balise} /> : <>{balise}</>;
            };
            switch (props.type) {
                case 'bouton':
                    return link(
                        <Button
                            variant="outlined"
                            size="small"
                            className={props.className}
                            onClick={props.onClick}
                            color={props.color ? props.color : 'primary'}
                        >
                            {props.label ? props.label : 'Valider'}
                        </Button>,
                    );
                case 'icone':
                    return tooltip(
                        <IconButton disabled={props.disabled} className={props.className} onClick={props.onClick} color="secondary">
                            {props.label}
                        </IconButton>,
                    );
                default:
                    break;
            }
        }
        return <></>;
    }
    function Emploi() {
        if (verificationDroit(fonctionServices.Moncompte_Emploi, fonctionServices.Emploi)) {
            return (
                <div style={{ marginBottom: '26px' }}>
                    <h3 className="w100 nomargin">Emploi</h3>
                    <hr></hr>
                    {selectEmploi.map((e, i) =>
                        i < 6 ? (
                            <div key={i} className="textRight alignICenter flex mb10px">
                                <label className="w25">{e.indice === 'SiteResidence' ? 'Site de Residence' : e.indice}</label>
                                <SelectSimple
                                    disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                                    tableau={tableauFiltre[e.indice]}
                                    indice={e.indice}
                                    classe={[css.nomargin, css.ml30px, css.w50]}
                                ></SelectSimple>
                            </div>
                        ) : null,
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    }
    function Contrat() {
        if (verificationDroit(fonctionServices.Moncompte_Contrat, fonctionServices.Contrat)) {
            return (
                <div>
                    <h3 className="w100 nomargin">Contrat</h3>
                    <hr></hr>
                    <div className="textRight alignICenter flex mb10px">
                        <label className="w25">Contrat</label>
                        <SelectSimple
                            disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                            tableau={tableauFiltre.Contrat}
                            indice={'Contrat'}
                            classe={[css.nomargin, css.ml30px, css.w50]}
                        ></SelectSimple>
                    </div>
                    <TempsHeureMinute
                        label={'Heure Contrat'}
                        disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                        value={dataInput.heuresContrat}
                        className={[css.nomargin, css.ml30px, css.w15]}
                        onChange={(event) => {
                            update('input', 'heuresContrat', event.target.value);
                        }}
                        clearValue={() => update('input', 'heuresContrat', '')}
                    />
                    {/* {verificationDroit(fonctionServices.RTE) ? (
                        <div className="textRight alignICenter flex mb10px">
                            <label className="w25">RTE</label>
                            <InputMonetaire
                                value={dataInput.RTE}
                                onChange={(value) => {
                                    update('input', 'RTE', value);
                                }}
                            />
                        </div>
                    ) : null} */}
                    {inputContrat.map((e, i) => (
                        <div key={i} className="textRight alignICenter flex mb10px">
                            <label className="w25">{e.label}</label>
                            <Input
                                disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                                value={dataInput[e.indice]}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                type={e.type}
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(event) => {
                                    update('input', e.indice, event.target.value);
                                }}
                            />
                        </div>
                    ))}
                    <div className="textRight alignICenter flex mb10px">
                        <label className="w25">Temps Partiel</label>
                        <Checkbox
                            disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                            checked={checkTempPartiel}
                            className={css.ml30px}
                            onChange={() => {
                                setCheckTempPartiel(!checkTempPartiel);
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }
    function Decharges() {
        if (verificationDroit(fonctionServices.Moncompte_Décharge, fonctionServices.Décharge)) {
            return (
                <div>
                    {valueFiltre.AnneeScolaire < 7 ? (
                        <>
                            <h3 className="w100 nomargin">Décharge</h3>
                            <hr></hr>
                            {inputDecharge.map((e, i) =>
                                e.type === 'input' ? (
                                    <div key={i} className="textRight alignICenter flex mb10px">
                                        <label className="w25">{e.label}</label>
                                        <Input
                                            disabled={
                                                !verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)
                                            }
                                            value={dataInput[e.indice]}
                                            className={[css.nomargin, css.ml30px, css.w50]}
                                            type="time"
                                            onChange={(event) => {
                                                update('input', e.indice, event.target.value);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div key={i} className="textRight alignICenter flex mb10px">
                                        <label className="w25">{e.label}</label>
                                        <TextareaAutosize
                                            disabled={
                                                !verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)
                                            }
                                            className={css.textareaML30pxW50}
                                            aria-label="minimum height"
                                            rowsMin={8}
                                            placeholder={e.placeholder}
                                            value={dataInput[e.indice]}
                                            onChange={(event) => {
                                                update('input', e.indice, event.target.value);
                                            }}
                                        />
                                    </div>
                                ),
                            )}
                            <div className="textRight alignICenter flex mb10px">
                                <label className="w25">Total décharges</label>
                                <span className="ml30px">
                                    {minutesToDuration(
                                        durationToMinutes(dataInput.dechargeSyndicaleHeures) +
                                            durationToMinutes(dataInput.dechargeAntenneHeures) +
                                            durationToMinutes(dataInput.dechargeAutreHeures),
                                    )}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>{Commentaire()}</>
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    }
    function Commentaire() {
        if (verificationDroit(fonctionServices.Moncompte_Modification_commentaire, fonctionServices.Modification_commentaire)) {
            return (
                <div className="textRight alignICenter flex mb10px">
                    <label className="w25">Commentaire</label>
                    <TextareaAutosize
                        disabled={!verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services)}
                        className={css.textareaML30pxW50}
                        aria-label="minimum height"
                        rowsMin={5}
                        placeholder="Commentaire"
                        value={dataInput.commentaire}
                        onChange={(event) => {
                            update('input', 'commentaire', event.target.value);
                        }}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    }
    function Modal() {
        if (open.affectation) props.filtre([{ Site: open.affectation.fk_site }]);

        return (
            <DialogCustom
                open={open.open}
                onClose={() => {
                    setOpen({ open: false });
                    setDicipline(false);
                }}
                children={
                    <>
                        <div className="textRight alignICenter flex mb10px">
                            <label className="w25">Site</label>
                            <SelectSimple tableau={tableauFiltre.Site} indice={'Site'} classe={[css.nomargin, css.ml30px, css.w50]}></SelectSimple>
                        </div>
                        <div className="mb10px">
                            {open.affectation ? (
                                <SelectInstrument
                                    anneeEnCour={user[usurpation].idAnneeScolaire}
                                    value={valueAffectation}
                                    section={open.affectation.fk_section}
                                    instrument={open.affectation.fk_instrument}
                                />
                            ) : (
                                <SelectInstrument anneeEnCour={user[usurpation].idAnneeScolaire} value={valueAffectation} />
                            )}
                        </div>
                        <TempsHeureMinute
                            label={'Heure'}
                            value={dataInput.heures}
                            className={[css.nomargin, css.ml30px, css.w50, css.w30]}
                            onChange={(event) => {
                                update('input', 'heures', event.target.value);
                            }}
                            clearValue={() => update('input', 'heures', '')}
                        />
                        <div className="textRight alignICenter flex mb10px">
                            <label className="w25">Commentaire</label>
                            <TextareaAutosize
                                className={css.textareaML30pxW50}
                                rowsMin={3}
                                placeholder="Commentaire"
                                value={commentaireNewAffectation}
                                onChange={(event) => {
                                    setCommentaireNewAffectation(event.target.value);
                                }}
                            />
                        </div>
                        <ButtonCustom type="bouton" onClick={() => updateAffectation()} />
                    </>
                }
            />
        );
    }
    function TotalAffectation() {
        let totalAffectations = 0;
        for (let index = 0; index < dataAffectation.length; index++) {
            totalAffectations += durationToMinutes(dataAffectation[index].heures);
        }
        return (
            <div className="textRight alignICenter flex mb10px">
                <label className="w25">Total affectations</label>
                <span className="ml30px">{minutesToDuration(totalAffectations)}</span>
            </div>
        );
    }
    async function recupFiltre() {
        if (tableauFiltre.Instrument.length === 0) {
            for (let index = 0; index < selectEmploi.length; index++) {
                const response = await GET(selectEmploi[index].requete);
                update('filtre', selectEmploi[index].indice, response.data);
            }
            const instrumentFiltre = await POST('/api/cotisations/instruments');
            update('filtre', 'Instrument', instrumentFiltre.data);
        }
    }
    async function recupDataService() {
        const responseService = await GET(`/api/enseignantsFichesService?fk_utilisateur=${id}&fk_anneeScolaire=${valueFiltre.AnneeScolaire}`, undefined, {
            redirectOnError: false,
        });
        if (responseService.success === true) {
            setServiceExist(true);
            for (let index = 0; index < 10; index++) {
                update('input', Object.keys(dataInput)[index], responseService.data[Object.keys(dataInput)[index]]);
            }
            setCheckTempPartiel(!responseService.data.tempsPartiel ? false : true);
            props.addNomPage(`Fiche services - ${responseService.data.nomPrenomUtilisateur}`);
            const data = {
                fk_utilisateur: id,
                fk_anneeScolaire: valueFiltre.AnneeScolaire,
            };
            props.filtre([
                { Corps: responseService.data.fk_corps },
                { Grade: responseService.data.fk_grade },
                { Filiere: responseService.data.fk_filiere },
                { Diplome: responseService.data.fk_diplome },
                { Emploi: responseService.data.fk_typeEmploi },
                { Contrat: responseService.data.fk_contrat },
                { SiteResidence: responseService.data.fk_siteResidence },
            ]);
            const responseAffectation = await POST('/api/enseignantsFichesService/listEnseignantsAffectations', data);
            setDataAffectation(responseAffectation.data);
            const responseDiscipline = await POST('/api/enseignantsFichesService/listEnseignantsDisciplines', data);
            setDataDiscipline(responseDiscipline.data);
        } else {
            setServiceExist(false);
            setDataInput({
                dureeCDD: null,
                heuresContrat: null,
                quotite: null,
                coutHS: null,
                dechargeSyndicaleHeures: null,
                dechargeAntenneHeures: null,
                dechargeAntenneMotif: null,
                dechargeAutreHeures: null,
                dechargeAutreMotif: null,
                RTE: 0,
                commentaire: '',
                heures: null,
            });
            setDataAffectation([]);
            setDataDiscipline([]);
            props.filtre([{ Corps: '' }, { Grade: '' }, { Filiere: '' }, { Diplome: '' }, { Emploi: '' }, { Contrat: '' }, { SiteResidence: '' }]);
            setCheckTempPartiel(false);
        }
    }
    async function saveService() {
        let heure;
        if (dataInput.heuresContrat) {
            heure = dataInput.heuresContrat.split(':');
            for (let index = 0; index < 3; index++) {
                if (!heure[index]) {
                    heure.push('00');
                }
            }
            heure = heure.join(':');
        }
        const data = {
            fk_utilisateur: id,
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            fk_contrat: valueFiltre.Contrat,
            fk_filiere: valueFiltre.Filiere,
            fk_diplome: valueFiltre.Diplome,
            fk_corps: valueFiltre.Corps,
            fk_grade: valueFiltre.Grade,
            fk_typeEmploi: valueFiltre.Emploi,
            heuresContrat: heure,
            RTE: dataInput.RTE,
            quotite: dataInput.quotite,
            dechargeAntenneHeures: dataInput.dechargeAntenneHeures,
            dechargeAntenneMotif: dataInput.dechargeAntenneMotif,
            dechargeSyndicaleHeures: dataInput.dechargeSyndicaleHeures,
            dechargeAutreHeures: dataInput.dechargeAutreHeures,
            dechargeAutreMotif: dataInput.dechargeAutreMotif,
            dureeCDD: dataInput.dureeCDD,
            tempsPartiel: checkTempPartiel ? 1 : 0,
            coutHS: dataInput.coutHS,
            formationMusicale: null,
            commentaire: dataInput.commentaire,
            fk_siteResidence: valueFiltre.SiteResidence,
        };
        const verif = ['fk_contrat', 'fk_filiere', 'fk_diplome', 'fk_corps', 'fk_grade', 'fk_typeEmploi', 'heuresContrat'];
        for (let index = 0; index < verif.length; index++) {
            if (!data[verif[index]]) {
                return props.notification({
                    message: 'Veuillez remplir les champs (corps, grade, filiére, diplôme, emploi, les heures de contrat et le type de contrat)',
                });
            }
        }
        const response = await POST('/api/enseignantsFichesService', data, {
            redirectOnError: false,
        });
        if (response.success) {
            return props.notification({ message: 'La fiche de service a été enregistrée', status: response.status });
        } else {
            return props.notification({ message: response.message, status: response.status });
        }
    }
    async function dupliquerService() {
        const response = await GET(`/api/enseignantsFichesService/duplicate?fk_utilisateur=${id}&fk_newAnneeScolaire=${valueFiltre.AnneeScolaire}`, undefined, {
            redirectOnError: false,
        });
        if (response.success) {
            // mettre le select sur la bonne annéeScolaire
            valueFiltre.AnneeScolaire = user.idAnneeScolaire;
            await recupDataService();
        } else {
            return props.notification({
                message: 'Une erreur est survenue lors de la replication de la fiche de service',
                status: response.success,
            });
        }
    }

    async function updateAffectation(fk_site, fk_instrument) {
        if (dataInput.heures === undefined || !dataInput.heures) {
            return props.notification({ message: 'Veuillez renseigner les heures' });
        }
        let newAffectation = [];
        let tableauAffectation = fk_site ? dataAffectation.filter((e) => e.fk_site !== fk_site || e.fk_instrument !== fk_instrument) : dataAffectation;
        if (fk_site) tableauAffectation = dataAffectation.filter((e) => e.fk_site !== fk_site || e.fk_instrument !== fk_instrument);
        if (open.affectation) {
            const verifDouble = dataAffectation.filter((e) => +e.fk_site === +valueFiltre.Site && +e.fk_instrument === +discipline);
            if (verifDouble.length !== 0 && open.affectation.heures === dataInput.heures) {
                return props.notification({
                    message: 'Une affectation pour ce site et discipline est déjà present dans le tableau.',
                });
            }

            tableauAffectation = dataAffectation.filter((e) => e.fk_site !== open.affectation.fk_site || e.fk_instrument !== open.affectation.fk_instrument);
        }
        for (let elem = 0; elem < tableauAffectation.length; elem++) {
            const data = {
                fk_site: tableauAffectation[elem].fk_site,
                heures: tableauAffectation[elem].heures,
                fk_instrument: tableauAffectation[elem].fk_instrument,
                ordre: elem + 1,
                commentaire: tableauAffectation[elem].commentaire,
            };
            newAffectation.push(data);
        }
        if (!fk_site || open.affectation) {
            newAffectation.push({
                heures: dataInput.heures,
                fk_site: valueFiltre.Site,
                fk_instrument: discipline,
                ordre: newAffectation.length + 1,
                commentaire: commentaireNewAffectation,
            });
        }
        const data = {
            fk_utilisateur: id,
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            listOfJson: newAffectation,
        };
        await POST('/api/enseignantsFichesService/affectations', data);
        setOpen({ open: false });
        recupDataService();
        setCommentaireNewAffectation('');
    }
    function valueAffectation(value) {
        setDicipline(value);
    }

    useEffect(() => {
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        recupFiltre();
        props.addNomPage('Fiche services');
    }, []);
    useEffect(() => {
        recupDataService();
    }, [refreshSimple]);

    return (
        <div className="ficheContainer pr2">
            {Modal()}
            <InfoAgent className="mt2" idUtilisateur={id} type="services" idCours={fonctionServices.Lien_cours} idProfil={fonctionServices.Lien_profil} />
            <div className="mt5 ml5 flex xs_directionColumn m_mt10">
                <div className="w50 xs_flex xs_w100  ">
                    <label className="w25 xs_w33">Année scolaire</label>
                    <SelectSimple
                        tableau={tableauFiltre.AnneeScolaire}
                        indice={'AnneeScolaire'}
                        classe={[css.ml30px, css.mr30px, css.w30, css.alignBaseline, css.AnneeScolaire, css.xs_w50, css.xs_mb5]}
                    ></SelectSimple>
                </div>
                <div className=" flex xs_directionColumn">
                    <ButtonCustom link={'/liste-agents'} type="bouton" label="Retour" color="secondary" className={css.xs_w90} />
                    {verificationDroit(fonctionServices.Moncompte_Modification_services, fonctionServices.Modification_services) ? (
                        <ButtonCustom
                            className={[css.ml30px, css.xs_nomargin, css.xs_w90, css.xs_mb2, css.xs_mt2]}
                            onClick={() => saveService()}
                            type="bouton"
                            label="Enregistrer"
                        />
                    ) : null}
                    <ButtonCustom
                        className={[css.ml30px, css.xs_nomargin, css.xs_w90]}
                        onClick={() => dupliquerService()}
                        type="bouton"
                        label="Dupliquer"
                        duplicate={true}
                    />
                </div>
            </div>
            <div className="flex xs_directionColumn m_directionColumn">
                <div className="m2 w100">
                    <Emploi />
                    <div>
                        <div className="flex">
                            <h3 className="w100 nomargin">Affectations</h3>
                            {verificationDroit(fonctionServices.Moncompte_Ajout_affectation, fonctionServices.Ajout_affectation) ? (
                                <ButtonCustom
                                    disabled={!serviceExist}
                                    className={[css.cVert, css.p0, css.floatRight]}
                                    onClick={() => setOpen({ open: true })}
                                    type="icone"
                                    label={<AddBoxIcon />}
                                />
                            ) : null}
                        </div>
                        <hr></hr>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAffectation}></TableHeadCustom>
                                <TableBody>
                                    {dataAffectation.map((row, index) => {
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Site</div>
                                                    {row.libelleSite}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Instrument</div>
                                                    <p className="nomargin xs_flex">{row.libelleInstrument}</p>
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Commentaire</div>
                                                    <p className="nomargin xs_flex">{row.commentaire}</p>
                                                </TableCell>
                                                <TableCell className={[css.td, css.w25, css.xs_w100]} component="th" scope="row">
                                                    <div>Heure</div>
                                                    {row.heures}

                                                    {verificationDroit(
                                                        fonctionServices.Moncompte_Suppression_affectation,
                                                        fonctionServices.Suppression_affectation,
                                                    ) && !window.matchMedia('(max-width: 576px)').matches ? (
                                                        <>
                                                            <ButtonCustom
                                                                type="icone"
                                                                onClick={() => updateAffectation(row.fk_site, row.fk_instrument)}
                                                                className={[css.p0, css.floatRight, css.xs_floatLeft]}
                                                                title={"supprimer l'affection"}
                                                                label={<DeleteForeverIcon />}
                                                            />
                                                            <IconButton
                                                                className={[css.p0, css.floatRight, css.xs_floatLeft]}
                                                                onClick={() => {
                                                                    console.log(row);
                                                                    update('input', 'heures', row.heures);
                                                                    setCommentaireNewAffectation(row.commentaire);
                                                                    setDicipline(row.fk_instrument);
                                                                    setOpen({ open: true, affectation: row });
                                                                }}
                                                                color="primary"
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                        </>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TotalAffectation />
                    </div>
                    {dataDiscipline.length !== 0 ? (
                        <div>
                            <div className="flex">
                                <h3 className="w100 nomargin">Discipline</h3>
                            </div>
                            <hr></hr>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHeadCustom data={tableHeadDiscipline}></TableHeadCustom>
                                    <TableBody>
                                        {dataDiscipline.map((row, index) => {
                                            return (
                                                <TableRow className={css.trbody} key={index}>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Instrument</div>
                                                        <p className="nomargin xs_flex">{row.libelleInstrument}</p>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="m2 w100">
                    {Contrat()}
                    {Decharges()}
                </div>
            </div>
            {valueFiltre.AnneeScolaire < 7 ? Commentaire() : <></>}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
    };
}

export default connect(null, mapDispatchToProps)(FicheService);
