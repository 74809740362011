import { Button, withStyles } from '@material-ui/core';

const ListeButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 13,
        border: '1px solid',
        marginTop: '2.5px',
        marginBottom: '  0px',
        marginRight: '10px',
        textDecoration: 'none',
    },
})(Button);

export default ListeButton;
