/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function onglet(onglet = '', action) {
    if (action.type === 'absence') {
        const newonglet = action.onglet;
        return newonglet;
    }

    return onglet;
}
