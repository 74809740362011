import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IconUser from '@material-ui/icons/PersonOutline';
import SchoolIcon from '@material-ui/icons/School';
import MailIcon from '@material-ui/icons/MailOutlineTwoTone';
import PhoneIcon from '@material-ui/icons/Phone';
import MobileIcon from '@material-ui/icons/PhoneAndroid';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { testDroit } from '../../Utils/testDroit';

export default function Contact(props) {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    let contact = [];
    const br = window.matchMedia('(max-width: 768px)').matches ? <br /> : <></>;
    const style = window.matchMedia('(max-width: 768px)').matches ? null : { whiteSpace: 'nowrap' };
    const urlResp = `/fiche-responsable/${props.idresponsable}`;
    if (props.email) {
        if (Array.isArray(props.email)) {
            let mail = [...new Set(props.email.filter((e) => e !== ''))];
            const mailto = mail
                .join()
                .split('')
                .filter((e) => e !== ' ')
                .join('');
            if (props.pressPapier) {
                return <div onClick={() => navigator.clipboard.writeText(mailto)}>Email en presse papier</div>;
            }
            return (
                <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:?bcc=${mailto}`}>
                    {props.lib}
                </a>
            );
        }
    }
    if (props.nomPrenom) {
        let nomPrenom;
        if (props.idresponsable) {
            nomPrenom = testDroit(user[usurpation].roleFonction, props.idFonction) ? (
                <>
                    <Link to={urlResp} style={style}>
                        <IconUser color="secondary" className={[css.fontSize1em, css.mr1]} />
                        {props.nomPrenom};
                    </Link>
                    {br}
                </>
            ) : (
                props.nomPrenom
            );
        } else {
            nomPrenom = (
                <>
                    <span style={style}>
                        <SchoolIcon className={[css.fontSize1em, css.mr1]} />
                        {props.nomPrenom}
                    </span>
                    {br}
                </>
            );
        }
        contact.push(nomPrenom);
    }
    if (props.email && props.email !== ' ') {
        const email = (
            <>
                <a href={`mailto:${props.email}`} style={style}>
                    <MailIcon color="action" className={[css.fontSize1em, css.mr1]} />
                    {props.email}
                </a>
                {br}
            </>
        );
        contact.push(email);
    }
    if (props.telephone) {
        const telephone = (
            <>
                <a href={`tel:${props.telephone}`} style={style}>
                    <PhoneIcon color="action" className={[css.fontSize1em, css.mr1]} />
                    {props.telephone}
                </a>
                {br}
            </>
        );
        contact.push(telephone);
    }
    if (props.mobile) {
        const mobile = (
            <>
                <a href={`tel:${props.mobile}`} style={style}>
                    <MobileIcon color="action" className={[css.fontSize1em, css.mr1]} />
                    {props.mobile}
                </a>
                {br}
            </>
        );
        contact.push(mobile);
    }

    return (
        <>
            {contact}
            <br />
        </>
    );
}
