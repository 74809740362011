export const tableHead = [
    { id: 'nomPrenomEleve', label: 'Elèves' },
    { id: 'dateNaissance', label: 'Naissance' },
    { id: 'instrument', label: `Date d'inscription - Instrument` },
    { id: 'concatInfoResponsable', label: 'Contact(s)' },
];
export const acceptedRoles = ['SuperAdmin', 'Administratif', 'Enseignant Resp.'];
export const acceptedRolesConsult = ['SuperAdmin', 'Administratif', 'Enseignant Resp.', 'Enseignant'];
export const requeteGETFiltre = [
    { url: '/api/cotisations/offres' },
    { url: '/api/cotisations/cycles' },
    { url: '/api/ateliers/ateliersStatut' },
    { url: '/api/anneesScolaire/list' },
    { url: '/api/eleves/SI_annees' },
    { url: '/api/sites/liste' },
];
export const requetePOSTFiltre = [{ url: '/api/cotisations/instruments' }, { url: '/api/cotisations/styles' }];
export const tableauSaveFiltre = [{ simple: ['AnneeScolaire', 'Style', 'Offre', 'Cycle', 'Annee', 'Statut', 'Instrument'] }, { multiple: ['Site'] }];
export const filtreSimple = [
    { label: 'Cycle', indice: 'Cycle' },
    { label: 'Année', indice: 'Annee' },
    { label: 'Statut', indice: 'Statut' },
    { label: 'Instrument', indice: 'Instrument' },
    { label: 'Style', indice: 'Style' },
];
export const tableHeadExport = [
    { label: 'Elèves', value: 'nomPrenomEleve' },
    { label: 'id', value: 'idEleve' },
    { label: 'Naissance', value: 'dateNaissance' },
    { label: 'Télèphone', value: 'telephoneMobile' },
];
