/* eslint-disable no-else-return */
/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function pagination(switchPage = [{ rowsPerPage: 50, page: 0 }], action) {
    if (action.type === 'switchPage') {
        const newswitchPage = [action.switchPage];
        return newswitchPage;
    } else if (action.type === 'resetPagination') {
        const newswitchPage = [{ rowsPerPage: 50, page: 0 }];
        return newswitchPage;
    }

    return switchPage;
}
