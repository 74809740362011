/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
const setData = (fn, dataT, page, rowsPerPage, order, orderBy) => {
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const orderComparator = comparator(a[0], b[0]);
            if (order !== 0) return orderComparator;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? // eslint-disable-next-line no-use-before-define
            (a, b) => descendingComparator(a, b, orderBy)
            : // eslint-disable-next-line no-use-before-define
            (a, b) => -descendingComparator(a, b, orderBy);
    }
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    return stableSort(fn(dataT), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );
};

export default setData;
