/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { TextareaAutosize, Switch, Checkbox } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import ButtonLivret from '../ButtonLivret';

import Input from '../../../components/atoms/TextField';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { exclure, verifKeysGeneral } from '../consts';

const ModuleGeneralCreate = (props) => {
    const css = useStylesMaterielUIGlobal();
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const [inputCompetence, setInputCompetence] = useState();
    const [titre, settitre] = useState();
    const [visible, setVisible] = useState(false);
    const [afterDelete, setAfterDelete] = useState(false);
    const [addInput, setAddInput] = useState([]);
    const [checkbox, setCheckbox] = useState({
        fk_enseignantFM: false,
        fk_enseignantSupplementaire: false,
        niveauFM: false,
        pratiqueCollective: false,
    });
    const [dataInput, setDataInput] = useState({ niveauFM: 0, pratiqueCollective: 0 });
    function update(FT, keys, valueI) {
        const state = FT === 'checkbox' ? setCheckbox : setDataInput;
        state((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function nombreLigne(nombre) {
        const tableau = [];
        for (let index = 0; index < nombre; index++) {
            tableau.push('');
        }
        return tableau;
    }
    function createModule() {
        const elementDuModule = [];
        if (inputCompetence) {
            const niveau = [];
            for (let index = 0; index < inputCompetence.split('\n').length; index++) {
                niveau.push({ lib: inputCompetence.split('\n')[index], value: '0' });
            }
            elementDuModule.push({ niveauAcquisition: { note: 5, tableau: niveau } });
        }
        for (let index = 0; index < Object.keys(checkbox).length; index++) {
            if (verifKeysGeneral.includes(Object.keys(checkbox)[index])) {
                if (checkbox[Object.keys(checkbox)[index]]) {
                    elementDuModule.push({
                        [Object.keys(checkbox)[index]]: nombreLigne(+dataInput[Object.keys(checkbox)[index]]),
                    });
                } else {
                    props.delete(Object.keys(checkbox)[index]);
                }
            } else {
                if (checkbox[Object.keys(checkbox)[index]]) {
                    elementDuModule.push({ [Object.keys(checkbox)[index]]: '' });
                } else {
                    props.delete(Object.keys(checkbox)[index]);
                }
            }
        }
        for (let index = 0; index < Object.keys(dataInput).length; index++) {
            if (!verifKeysGeneral.includes(Object.keys(dataInput)[index])) {
                elementDuModule.push({
                    [Object.values(dataInput)[index]]: '',
                });
            }
        }
        for (let index = 0; index < Object.keys(elementDuModule).length; index++) {
            props.addModule({ [Object.keys(elementDuModule[index])[0]]: Object.values(elementDuModule[index])[0] });
        }
        props.addModule({ refreshLivret: !refresh }, true);
    }
    function deleteChamps(idChamps) {
        props.delete(idChamps);
        setDataInput({ niveauFM: 0, pratiqueCollective: 0 });
        setAfterDelete(!afterDelete);
    }

    function addElement(variable) {
        const chargeElement = [];
        let element = [];
        if (variable) {
            for (let index = 0; index < variable.length; index++) {
                const Titre = `Titre${index}`;
                chargeElement.push(
                    <div className="flex mt1">
                        <div className="flex alignICenter w100">
                            <ButtonLivret
                                type={'icone'}
                                label={<IndeterminateCheckBoxIcon />}
                                tooltip={'Suppimer un champs'}
                                onClick={() => deleteChamps(Object.keys(variable[index])[0])}
                                className={css.cVert}
                            />
                            <label className="mr2">Titre:</label>
                            <span>{Object.keys(variable[index])[0]}</span>
                        </div>
                    </div>,
                );
                update('', Titre, Object.keys(variable[index])[0]);
            }
            setAddInput(chargeElement);
        } else {
            const variableTitre = `Titre${addInput.length}`;
            update('', variableTitre, '');
            element = (
                <div className="flex mt1">
                    <div className="flex directionColumn w100">
                        <label className="mr2">Titre:</label>
                        <Input
                            className={[css.w80, css.ml30px, css.mt1]}
                            value={variable ? variable : dataInput[variableTitre]}
                            onChange={(event) => update('', variableTitre, event.target.value)}
                        />
                    </div>
                </div>
            );

            setAddInput([...addInput, element]);
        }
    }
    useEffect(() => {
        if (props.data.general) {
            settitre(props.data.titre);
            setVisible(props.data.visible === 1 ? true : false);
            let competence = '';
            for (let index = 0; index < props.data.general.niveauAcquisition.tableau.length; index++) {
                const sautLigne = index === props.data.general.niveauAcquisition.tableau.length - 1 ? '' : '\n';
                competence = competence + `${props.data.general.niveauAcquisition.tableau[index].lib}${sautLigne}`;
            }
            setInputCompetence(competence);
            for (let index = 0; index < Object.keys(checkbox).length; index++) {
                if (props.data.general[Object.keys(checkbox)[index]] !== undefined) {
                    update('checkbox', Object.keys(checkbox)[index], true);
                }
            }
            const tableauObservation = [];
            for (let index = 0; index < Object.keys(props.data.general).length; index++) {
                if (!exclure.includes(Object.keys(props.data.general)[index])) {
                    if (verifKeysGeneral.includes(Object.keys(props.data.general)[index])) {
                        update(
                            '',
                            Object.keys(props.data.general)[index],
                            props.data.general[Object.keys(props.data.general)[index]].length,
                        );
                    } else {
                        tableauObservation.push({
                            [Object.keys(props.data.general)[index]]:
                                props.data.general[Object.keys(props.data.general)[index]],
                        });
                    }
                }
            }
            addElement(tableauObservation);
        }
    }, [afterDelete]);
    return (
        <div>
            <ButtonLivret className={css.mb2} type={'button'} label={'Ajouter le module'} onClick={() => createModule()} />
            <div className="flex mt1">
                <label>Titre du livret :</label>
                <Input
                    value={titre}
                    className={[css.ml30px, css.w80, css.mb1]}
                    onChange={(event) => {
                        settitre(event.target.value);
                        props.addModule({ titre: event.target.value }, true);
                    }}
                />
            </div>
            <div className="flex mt1">
                <div className="flex directionColumn w50 mr5">
                    <h4 className="nomargin">Compétences évaluées :</h4>
                    <span className="fontSmallGrey">Séparer les compétences par des sauts de ligne</span>
                </div>
                <TextareaAutosize
                    value={inputCompetence}
                    className={css.textareaML30pxW50}
                    rowsMin={5}
                    onChange={(event) => setInputCompetence(event.target.value)}
                />
            </div>
            <div className="flex">
                <span>Visibilité du livret :</span>
                <div className="textCenter mr1 ml2">
                    <span>non</span>
                    <Switch
                        checked={visible}
                        onChange={() => {
                            setVisible(!visible);
                            props.addModule({ visible: !visible }, true);
                        }}
                    />
                    <span>oui</span>
                </div>
            </div>
            <h4>Sélection du type de professeur</h4>
            <div className="flex ">
                <div className=" w40">
                    <Checkbox
                        checked={checkbox.fk_enseignantFM}
                        onChange={() => {
                            update('checkbox', 'fk_enseignantFM', !checkbox.fk_enseignantFM);
                        }}
                    />
                    <label>Professeur de FM</label>
                </div>
                <div className="mr5">
                    <Checkbox
                        checked={checkbox.fk_enseignantSupplementaire}
                        onChange={() => {
                            update('checkbox', 'fk_enseignantSupplementaire', !checkbox.fk_enseignantSupplementaire);
                        }}
                    />
                    <label>Autres enseignants</label>
                </div>
            </div>
            <h4>Sélection du type d'instrument</h4>
            <div className="flex ">
                <div className="flex directionColumn  w40 alignIStart">
                    <div>
                        <Checkbox
                            checked={checkbox.niveauFM}
                            onChange={() => {
                                update('checkbox', 'niveauFM', !checkbox.niveauFM);
                                update('', 'niveauFM', !checkbox.niveauFM ? 1 : 0);
                            }}
                        />
                        <label> Niveau FM</label>
                    </div>
                    <div className="flex  alignICenter">
                        <Input
                            disabled={!checkbox.niveauFM}
                            type="number"
                            value={dataInput.niveauFM}
                            className={[css.w15, css.mr2]}
                            onChange={(event) => {
                                update('', 'niveauFM', event.target.value);
                            }}
                        />
                        <label>Nombre de lignes</label>
                    </div>
                </div>
                <div className="flex directionColumn  w40 alignIStart">
                    <div>
                        <Checkbox
                            checked={checkbox.pratiqueCollective}
                            onChange={() => {
                                update('checkbox', 'pratiqueCollective', !checkbox.pratiqueCollective);
                                update('', 'pratiqueCollective', !checkbox.pratiqueCollective ? 1 : 0);
                            }}
                        />
                        <label>Pratiques collectives</label>
                    </div>
                    <div className="flex  alignICenter">
                        <Input
                            disabled={!checkbox.pratiqueCollective}
                            type="number"
                            value={dataInput.pratiqueCollective}
                            className={[css.w15, css.mr2]}
                            onChange={(event) => {
                                update('', 'pratiqueCollective', event.target.value);
                            }}
                        />
                        <label>Nombre de lignes</label>
                    </div>
                </div>
            </div>
            <div className="flex mt1">
                <h4>Ajouter les champs d'observation</h4>{' '}
                <ButtonLivret
                    type={'icone'}
                    label={<AddBoxIcon />}
                    tooltip={'Ajouter un champs'}
                    onClick={() => addElement()}
                    className={css.cVert}
                />
            </div>
            {addInput}
            <h2 className="textCenter">Visuel final</h2>
            <hr></hr>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addModule(module, racine) {
            dispatch({ type: 'ModelGeneral', module, racine });
        },
        delete(key) {
            dispatch({ type: 'delete', key, module: 'general' });
        },
    };
}
export default connect(null, mapDispatchToProps)(ModuleGeneralCreate);
