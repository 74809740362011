import React from 'react';
import Slide from './Slide';

export default function DiscreteSlider(props) {
    function veiwSlide() {
        const elem = [];
        for (let index = 0; index < props.niveau.tableau.length; index++) {
            elem.push(
                <Slide
                    lib={props.niveau.tableau[index].lib}
                    value={props.niveau.tableau[index].value}
                    note={props.niveau.note}
                    index={index}
                    key={index}
                    disabled={props.disabled}
                />,
            );
        }
        return <>{elem}</>;
    }
    return veiwSlide();
}
