import { POST } from './requete';

export default async function downloadFile(type, data, nomFichier) {
    const response = await POST(`/api/fichiers/download/${type}`, data, { download: true, redirectOnError: false });

    if (undefined !== response.success && !response.success) {
        alert(response.message);
        return;
    }

    let lienDownload = document.createElement('a');
    lienDownload.href = URL.createObjectURL(response);
    lienDownload.download = nomFichier;
    document.body.appendChild(lienDownload);
    const a = lienDownload.click();
    return a;
}
