export function copyObject(object) {
    const creationNouvelleReference = {};
    Object.assign(creationNouvelleReference, object);
    return creationNouvelleReference;
}
export function copyArrayOfObject(array) {
    const creationNouvelleReference = [];
    for (let elem = 0; elem < array.length; elem++) {
        creationNouvelleReference.push({});
        Object.assign(creationNouvelleReference[elem], array[elem]);
    }
    return creationNouvelleReference;
}
