/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */
const scolarites = {
    idScolarite: 0,
    fk_eleve: null,
    fk_anneeScolaire: null,
    fk_offre: null,
    fk_site: null,
    fk_instrument: [],
    fk_style: null,
    fk_statut: 10,
    fk_cycle: null,
    fk_annee: null,
    fk_enseignant: null,
    complementairePossible: 0,
    complementaire: 0,
    commentaire: '',
    commentaireInscription: '',
};

const eleves = [
    {
        idEleve: 0,
        nom: null,
        prenom: null,
        fk_genre: null,
        dateNaissance: null, //DateHeureFormat({ BDD: "date", date: dataInput.dateNaissance }),
        adulte: null,
        dateCreation: null,
        dateInscription: null,
        dateAbandon: null,
        telephoneMobile: null,
        dateSuppression: null,
    },
];
const responsable = {
    idUtilisateur: null,
    nom: null,
    prenom: null,
    nomPrenomUtilisateur: null,
    fk_civilite: null,
    fk_role: null,
    dateNaissance: null,
    lieuNaissance: null,
    departementNaissance: null,
    adresse1: null,
    adresse2: null,
    codePostal: null,
    ville: null,
    telephoneFixe: null,
    telephoneMobile: null,
    fk_categorieSocioProfessionnelle: null,
    profession: null,
    email: null,
    role: 'Responsable',
    password: '',
};
const document = {
    fiscalite: {
        idRevenu: null,
        fk_responsable: null,
        fk_anneeScolaire: null,
        revenuFiscal: null,
        nombrePart: null,
        quotient: null,
        exoneration: 0,
        absence: 1,
        document: '',
    },
    justificatifDomicile: '',
};
const scolaritesExterne = {
    idScolarite: 0,
    fk_eleve: null,
    fk_anneeScolaire: null,
    fk_etablissementScolaire: null,
    fk_niveauScolaire: null,
    coursMercrediMatin: 0 /* dataInscription.eleves[index].courMercredi === true ? 1 : 0 */,
    communes: null,
    nonScolarise: false,
    listeEtablissement: [],
};
/* ajout un champ pour le justificatif et un autre pour l'avis d'imposition */
function copyObject(object) {
    const creationNouvelleReference = {};
    Object.assign(creationNouvelleReference, object);
    return creationNouvelleReference;
}

export default function dataInscriptionTarn(data = {}, action) {
    if (action.type === 'nouvelInscription') {
        data.responsable = responsable;
        data.document = document;
        data.document.fiscalite.fk_anneeScolaire = +action.anneeScolaire;
        data.eleves = [copyObject(eleves[0])];
        data.anneeScolaire = +action.anneeScolaire;
        data.eleves[0].scolarites = [copyObject(scolarites)];
        data.eleves[0].scolarites[0].fk_anneeScolaire = data.anneeScolaire;
        data.reloadView = false;
    }
    if (action.type === 'antenne') {
        data.antenne = action.antenne;
    }
    if (action.type === 'categoriesEnfants') {
        data.categoriesEnfants = action.data;
    }
    if (action.type === 'ajoutoffre') {
        if (!data.eleves[action.numeroEleve].scolarites) {
            data.eleves[action.numeroEleve].scolarites = [copyObject(scolarites)];
        } else {
            data.eleves[action.numeroEleve].scolarites.push(copyObject(scolarites));
        }
        data.eleves[action.numeroEleve].scolarites[data.eleves[action.numeroEleve].scolarites.length - 1].fk_anneeScolaire = data.anneeScolaire;
    }
    if (action.type === 'ajoutEleve') {
        data.eleves.push(copyObject(eleves[0]));
        if (!action.finalisation) {
            data.eleves[data.eleves.length - 1].scolarites = [copyObject(scolarites)];
            data.eleves[data.eleves.length - 1].scolarites[0].fk_anneeScolaire = data.anneeScolaire;
        }
    }
    if (action.type === 'idOffre') {
        data.eleves[action.numeroEleve - 1].scolarites[action.numeroOffre - 1].fk_offre = action.fk_offre;
    }
    if (action.type === 'choixOffre') {
        data.choixOffre = action.data;
    }
    if (action.type === 'optionOffre') {
        data.eleves[action.numeroEleve - 1].scolarites[action.numeroOffre - 1][action.key] = action.data;
    }
    if (action.type === 'optionInstrument') {
        data.eleves[action.numeroEleve - 1].scolarites[action.numeroOffre - 1].fk_instrument = action.instrument;
    }
    if (action.type === 'dataEleve') {
        data.eleves[action.numeroEleve - 1][Object.keys(action.data)[0]] = Object.values(action.data)[0];
        data.reloadView = !data.reloadView;
    }
    if (action.type === 'scolariteExterne') {
        data.eleves[action.numeroEleve - 1].scolaritesExterne = copyObject(scolaritesExterne);
        data.eleves[action.numeroEleve - 1].scolaritesExterne.fk_anneeScolaire = data.anneeScolaire;
        data.reloadView = !data.reloadView;
    }
    if (action.type === 'scolariteExterneData') {
        data.eleves[action.numeroEleve - 1].scolaritesExterne[Object.keys(action.data)[0]] = Object.values(action.data)[0];
        data.reloadView = !data.reloadView;
    }
    if (action.type === 'dataResponsable') {
        data.responsable[Object.keys(action.data)[0]] = Object.values(action.data)[0];
        data.reloadView = !data.reloadView;
    }
    if (action.type === 'document') {
        if (Object.keys(action.data)[0] === 'justificatifDomicile') {
            data.document[Object.keys(action.data)[0]] = Object.values(action.data)[0];
            data.reloadView = !data.reloadView;
        } else {
            data.document.fiscalite[Object.keys(action.data)[0]] = Object.values(action.data)[0];
            if (data.document.fiscalite.abs === 1) {
                data.document.fiscalite.abs = 0;
            }
            data.reloadView = !data.reloadView;
        }
    }
    if (action.type === 'suppressionOffre') {
        data.eleves[action.numeroEleve].scolarites.splice(action.numeroOffre, 1);
    }
    if (action.type === 'suppessionEleve') {
        data.eleves.splice(action.numeroEleve, 1);
    }
    if (action.type === 'testDataDev') {
        const newData = action.data;
        return newData;
    }

    return data;
}
