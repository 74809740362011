/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tabs, Tab } from '@material-ui/core';

import { POST, GET } from '../../components/Utils/requete';
import TabPanel from '../../components/organism/Onglet/TabPanel';
import ModuleDisciplineCreate from './module/ModuleDisciplineCreate';
import ModuleDisciplineView from './module/ModuleDisciplineView';
import ModuleValidationView from './module/ModuleValidationView';
import ModuleGeneralCreate from './module/ModuleGeneralCreate';
import ModuleGeneralView from './module/ModuleGeneralView';
import ModuleValidationCreate from './module/ModuleValidationCreate';
import ButtonLivret from './ButtonLivret';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionLivret from '../../fonctionsRoles/livret';

const FicheLivret = (props) => {
    let { id } = useParams();
    let { type } = useParams();
    const css = useStylesMaterielUIGlobal();
    const donneeslivret = useSelector((state) => state.LivretDonnees);
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const inputDisabled = type === 'creation' && !testDroit(user[usurpation].roleFonction, fonctionLivret.Modification_model);
    const [value, setValue] = useState(0);
    const [viewModule, setViewModule] = useState([]);
    const [onglet, setOnglet] = useState('general');
    const handleChange = (event, newValue) => {
        props.editModule(null);
        setValue(newValue);
    };

    function AffichageButton(props) {
        if (!inputDisabled) {
            let label = '';
            switch (props.statut) {
                case 0:
                    label = 'Enregistrer le modèle';
                    break;
                case 1:
                    label = 'Mémoriser';
                    break;
                case 2:
                    label = 'Valider le livret';
                    break;
                case 3:
                    label = 'Dupliquer le modèle';
                    break;
                default:
                    break;
            }
            let button = (
                <ButtonLivret
                    className={[css.floatRight, css.m2]}
                    color="primary"
                    type={'button'}
                    label={label}
                    onClick={() => saveData(props.statut, props.statut === 3 ? true : false)}
                />
            );
            if (donneeslivret.general) {
                if (donneeslivret.general.fk_anneeScolaire !== user.idAnneeScolaire && type !== 'creation') {
                    button = <></>;
                }
            }
            return button;
        }
        return <></>;
    }
    function CreationModel(props) {
        if (type === 'creation') {
            if (props.onglet === 'discipline') {
                return <ModuleDisciplineCreate data={donneeslivret} />;
            }
            if (props.onglet === 'general') {
                return <ModuleGeneralCreate data={donneeslivret} />;
            }
            if (props.onglet === 'validation') {
                return <ModuleValidationCreate data={donneeslivret} />;
            }
        }
        return <></>;
    }

    function dataView(data, module) {
        let element = [];
        let moduleView;
        setOnglet(module);
        switch (module) {
            case 'general':
                if (data.general) {
                    moduleView = <ModuleGeneralView data={data.general} disabled={inputDisabled} />;
                    element.push(moduleView);
                }
                break;
            case 'discipline':
                if (data.discipline) {
                    data.discipline.map((row, index) => {
                        moduleView = <ModuleDisciplineView key={index} data={row} disabled={inputDisabled} />;
                        return element.push(moduleView);
                    });
                }
                break;
            case 'validation':
                if (data.validation) {
                    moduleView = <ModuleValidationView data={data.validation} disabled={inputDisabled} />;
                    element.push(moduleView);
                }
                break;
            default:
                break;
        }
        setViewModule(element);
    }
    async function getData() {
        if (type === 'creation') {
            const response = await GET(`/api/livrets/types/${id}`);
            props.getdata(response.data);
        } else {
            const response = await GET(`/api/livrets/${id}/${type}`, undefined, { redirectOnError: false });
            if (response.success) {
                props.getdata(response.data);
                // dans le cadre de la création d'un nouveau livret pour un élève, le back a créé le nécessaire.
                // Il faut donc reprendre l'id fourni et afficher la bonne URL
                if (id === '0') {
                    id = response.data.idLivret;
                    return props.history.push(`/fiche-livret/${id}/${type}`);
                }
            }
        }
    }

    async function saveData(statut, duplique) {
        if (duplique) {
            donneeslivret.fk_livretType = '';
        }
        if (type === 'creation') {
            const response = await POST(`/api/livrets/types/${id}`, donneeslivret);
            return props.history.push(`/fiche-livret/${response.data.fk_livretType}/creation`);
        } else {
            const response = await POST(`/api/livrets/${id}/${statut}`, donneeslivret);
            return props.history.push(`/fiche-livret/${response.data.idLivret}/${type}`);
        }
    }

    useEffect(() => {
        props.newLivret();
        if (type === 'creation') {
            if (id !== '0') {
                props.addNomPage('Modification modèle livret');
                getData();
            } else {
                props.addNomPage('Création modèle livret');
            }
        } else {
            props.addNomPage('Livret élève');
            getData();
        }
    }, []);
    useEffect(() => {
        dataView(donneeslivret, onglet);
    }, [refresh]);
    if (type === 'creation' && window.matchMedia('(max-width: 576px)').matches) {
        return <p className="textCenter ">Vous ne pouvez modifier ou crée de livret sur mobile</p>;
    }

    if ((donneeslivret.fk_scolariteInterne && donneeslivret.idLivret) || type === 'creation')
        return (
            <div>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                    <Tab label="Synthèse" onClick={() => dataView(donneeslivret, 'general')} />
                    <Tab
                        label="Compétences "
                        onClick={() => {
                            dataView(donneeslivret, 'discipline');
                        }}
                    />
                    <Tab label="Validation" onClick={() => dataView(donneeslivret, 'validation')} />
                </Tabs>
                {type === 'creation' ? (
                    <>
                        <AffichageButton statut={0} />
                        <AffichageButton statut={3} />
                    </>
                ) : (
                    <>
                        <AffichageButton statut={2} />
                        <AffichageButton statut={1} />
                    </>
                )}

                {type !== 'creation' ? (
                    <h1 className="textCenter xs_fontSize20px">{donneeslivret.titre}</h1>
                ) : id !== '0' ? (
                    <h1 className="textCenter">Modification d'un modèle de livret</h1>
                ) : (
                    <h1 className="textCenter">Création d'un modèle de livret</h1>
                )}
                <TabPanel value={value} index={0} className={css.smallContainer}>
                    <CreationModel onglet="general" />
                    {viewModule}
                </TabPanel>
                <TabPanel value={value} index={1} className={css.smallContainer}>
                    <CreationModel onglet="discipline" id="discipline" />
                    {viewModule}
                </TabPanel>
                <TabPanel value={value} index={2} className={css.smallContainer}>
                    <CreationModel onglet="validation" id="validation" />
                    {viewModule}
                </TabPanel>
            </div>
        );
    return (
        <div className="flex textCenter mt20">
            <h2 className="w100">
                Le livret n'a pas pu etre créé car il manque des informations. <br />
                Verifier que l'enseignant soit bien renseigné dans la scolarité.
            </h2>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        newLivret() {
            dispatch({ type: 'creation' });
        },
        getdata(data) {
            dispatch({ type: 'getData', data });
        },
        editModule(idModule) {
            dispatch({ type: 'editModule', idModule });
        },
    };
}

export default connect(null, mapDispatchToProps)(FicheLivret);
