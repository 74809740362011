/* eslint-disable react/prop-types */
/**
 * liste élèves
 *
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const TableHeadCustom = (props) => {
    const handleSortRequest = (cellId) => {
        if (props.sort !== false) {
            const isAsc = props.orderByState === cellId && props.orderState === 'asc';
            props.order(isAsc ? 'desc' : 'asc');
            props.orderBy(cellId);
        }
    };
    return (
        <TableHead style={(props.style, { backgroundColor: '#afafb6' })} className={props.className}>
            <TableRow>
                {props.data.map((tableHead) => (
                    <TableCell key={tableHead.id} sortDirection={props.orderByState === tableHead.id ? props.orderState : false}>
                        <TableSortLabel
                            active={props.orderByState === tableHead.id}
                            direction={props.orderByState === tableHead.id ? props.orderState : 'asc'}
                            onClick={() => {
                                handleSortRequest(tableHead.id);
                            }}
                        >
                            {tableHead.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        order(order) {
            dispatch({ type: 'order', order });
        },
        orderBy(orderBy) {
            dispatch({ type: 'orderBy', orderBy });
        },
    };
}
function mapStateToProps(state) {
    return { orderState: state.Order, orderByState: state.OrderBy };
}
export default connect(mapStateToProps, mapDispatchToProps)(TableHeadCustom);
