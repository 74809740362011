/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function orderBy(orderBy = '', action) {
    if (action.type === 'orderBy') {
        const neworderBy = action.orderBy;
        return neworderBy;
    }

    return orderBy;
}
