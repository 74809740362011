export default function formatNumeroTel(numero) {
    if (numero) {
        if (numero === '....') {
            return '+33';
        }
        const numerotableau = numero.split('');
        const codePays = '33';
        if (numerotableau[2] === '.') {
            numerotableau.join('.');
            const formatTel = numerotableau.filter((e) => e !== '.');
            if (formatTel[0] !== '3') {
                formatTel.splice(0, 1);
                return codePays.concat(formatTel.join());
            }
            return formatTel.join();
        }
        if (numerotableau[0] !== '3' && numerotableau[0] !== '+') {
            numerotableau.splice(0, 1);
            return codePays.concat(numerotableau.join());
        }
        if (numerotableau[0] === '+') {
            numerotableau.splice(0, 1);
            return numerotableau.join();
        }
    }
    return numero;
}
