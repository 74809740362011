import React, { useState, useEffect } from 'react';

import { connect, useSelector } from 'react-redux';

import { POST } from '../../Utils/requete';
import ListeButton from '../../atoms/ButtonCustom';
import { testDroit } from '../../Utils/testDroit';
import valeurParametre from '../../Utils/requeteParametre/valeurParametre';
import anneeInscription from '../../Utils/requeteParametre/anneeInscription';

const CalculCotisation = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [infoAnnee, setInfoAnnee] = useState({ libelleAnneeScolaire: '', valeur: '' });
    const [activationCalculCotisation, setActivationCalculCotisation] = useState();
    let callback = undefined;
    const params = {
        fk_anneeScolaire: null,
    };

    async function infoAnneeInscription() {
        setInfoAnnee(await anneeInscription());
        setActivationCalculCotisation(await valeurParametre('reglement', 'calculCotisation'));
    }
    async function handleClickCotisation(event) {
        event.preventDefault();

        const result = await POST('/api/cotisations/calcul', params, { redirectOnError: false });
        if (result.success) {
            props.notification({
                message: 'Le calcul des cotisations a été effectué',
                status: result.success,
            });
            if (callback) {
                callback();
            }
        } else {
            props.notification({
                message: result.message,
            });
        }
        return;
    }

    useEffect(() => {
        infoAnneeInscription();
    }, []);
    if (testDroit(user[usurpation].roleFonction, props.idFonction) && +activationCalculCotisation && !window.matchMedia('(max-width: 1080px)').matches) {
        params.fk_anneeScolaire = infoAnnee.valeur;
        delete params.fk_responsable;
        callback = props.callback;
        if (props.responsable) {
            params.fk_responsable = props.responsable;
        }
        return (
            <div className={props.className} style={props.style}>
                <ListeButton variant="outlined" size="small" color="primary" onClick={handleClickCotisation}>
                    Calculer les cotisations {infoAnnee.libelleAnneeScolaire}
                </ListeButton>
            </div>
        );
    } else {
        return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(CalculCotisation);
