/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@material-ui/core/TextField';

const DefaultTextField = ({
    id = '',
    label = '',
    variant,
    type = 'text',
    name,
    value,
    onChange,
    inputRef,
    error = null,
    defaultValue,
    disabled = false,
    size = 'small',
    required = false,
    InputLabelProps,
    inputProps,
    onClick,
    step,
    helperText,
    placeholder,
    ...other
    // eslint-disable-next-line arrow-body-style
}) => {
    return (
        <TextField
            disabled={disabled}
            id={id}
            margin="normal"
            onChange={onChange}
            label={label}
            type={type}
            variant={variant}
            name={name}
            value={value}
            size={size}
            inputRef={inputRef}
            defaultValue={defaultValue}
            required={required}
            InputLabelProps={InputLabelProps}
            inputProps={inputProps}
            onClick={onClick}
            step={step}
            helperText={helperText}
            placeholder={placeholder}
            {...(error && { error: true, helperText: error })}
            {...other}
        />
    );
};

export default DefaultTextField;
