/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Tooltip } from 'antd';

import { Button, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { DELETE, GET, POST } from '../../components/Utils/requete';
import downloadFile from '../../components/Utils/downloadFile';
import Input from '../../components/atoms/TextField';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionGed from '../../fonctionsRoles/GED';
import valuesQuery from '../../components/Utils/valuesQuery';

const Documents = (props) => {
    const RACINE = '/';
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [dossier, setDossier] = useState(RACINE);
    const [contenuDossier, setContenuDossier] = useState([]);
    const [nomDossier, setNomDossier] = useState('');
    const [indexLigne, setIndexLigne] = useState({ content: 0, ligne: -1, type: '' });
    const [renomageDossier, setRenomageDossier] = useState('');
    const [sensTrie, setSensTrie] = useState(false);

    async function chargeDossier(_dossier, trie) {
        setIndexLigne({ content: 0, ligne: -1, type: '' });
        const response = await GET(`/api/fichiers/ged/liste?path=${_dossier}`);
        if (response.success) {
            setDossier(_dossier);
            if (trie) {
                response.data.sort((a, b) => {
                    setSensTrie(!sensTrie);
                    let elem1 = a[trie];
                    let elem2 = b[trie];
                    let valueTrie = 0;
                    if (typeof elem1 === 'number') {
                        if (elem1 < elem2) valueTrie = sensTrie ? -1 : 1;
                        if (elem1 > elem2) valueTrie = sensTrie ? 1 : -1;
                        return valueTrie;
                    }
                    if (sensTrie) valueTrie = elem1.localeCompare(elem2, 'fr');
                    if (!sensTrie) valueTrie = elem2.localeCompare(elem1, 'fr');
                    return valueTrie;
                });
            }
            if (_dossier !== '/') {
                response.data.unshift({ name: 'Dossier parent', type: -1 });
            }
            setContenuDossier(response.data);
        }
    }

    async function creerDossier() {
        if (!nomDossier)
            return props.notification({
                message: `Vous devez spécifier le nom du dossier.`,
            });

        const response = await POST(`/api/fichiers/ged/creerDossier`, { dossier, nom: nomDossier });

        if (response.success) {
            chargeDossier(dossier);
        } else {
            props.notification({
                message: response.message,
            });
        }
        setNomDossier('');
    }

    async function download(nomFichier) {
        downloadFile('GED', { fichier: `${dossier}/${nomFichier}` }, nomFichier);
    }

    async function uploadFile(file) {
        const size = await GET(`/api/parametres/getValeur/sizeFile`);

        if (file.size > +size.data.valeur * 1024 * 1024) {
            return props.notification({
                message: `Votre fichier est trop gros, il ne doit pas dépasser ${size.data.valeur}Mo`,
            });
        }
        const data = new FormData();
        data.delete('file');
        data.delete('dossier');
        data.delete('nomFichier');
        data.append('file', file);
        data.append('dossier', dossier);
        data.append('nomFichier', file.name);

        const response = await POST('/api/fichiers/upload/GED', data, {
            upload: true,
            redirectOnError: false,
        });
        if (response.success) {
            chargeDossier(dossier);
            return props.notification({
                message: 'Document importé',
                status: true,
            });
        } else {
            return props.notification({
                message: response.message,
            });
        }
    }

    async function renameDir(ancienNom) {
        if (!renomageDossier)
            return props.notification({
                message: `Vous devez spécifier le nouveau nom du dossier.`,
            });
        const response = await POST('/api/fichiers/GED/renommerDossier', { ancienNom: `${dossier}/${ancienNom}`, nouveauNom: `${dossier}/${renomageDossier}` });
        if (response.success) {
            chargeDossier(dossier);
            setIndexLigne({ content: 0, ligne: -1, type: '' });
        } else {
            props.notification({
                message: response.message,
            });
        }

        setRenomageDossier('');
    }

    async function delete_FileOrDir(nom, type) {
        let response;
        switch (type) {
            case 0:
                response = await DELETE('/api/fichiers/GED/supprimerDossier', { nom: `${dossier}/${nom}` });
                break;
            case 1:
                response = await DELETE('/api/fichiers/GED/supprimerFichier', { nom: `${dossier}/${nom}` });
                break;
            default:
                break;
        }
        if (response.success) {
            chargeDossier(dossier);
        } else {
            props.notification({
                message: response.message,
            });
        }
        setIndexLigne({ content: 0, ligne: -1, type: '' });
    }

    function viewDelete_rename(element) {
        const retour = (
            <IconButton onClick={() => setIndexLigne({ content: 0, ligne: -1, type: '' })} color="secondary" className={[css.p0, css.floatRight]}>
                <ClearOutlinedIcon />
            </IconButton>
        );
        switch (indexLigne.type) {
            case 'rename':
                return (
                    <div className="flex spaceBetween alignICenter w100">
                        <Input
                            placeholder="Nouveau nom"
                            value={renomageDossier}
                            className={[css.nomargin, css.w80, css.mt1, css.mb1]}
                            onChange={(event) => {
                                setRenomageDossier(event.target.value);
                            }}
                        />
                        <div>
                            {retour}
                            <IconButton onClick={() => renameDir(element.name)} style={{ color: 'green' }} className={[css.p0, css.floatRight]}>
                                <CheckOutlinedIcon />
                            </IconButton>
                        </div>
                    </div>
                );
            case 'delete':
                return (
                    <div className="flex spaceBetween alignICenter w100">
                        <span>Voulez vous supprimer ce {element.type === 0 ? 'dossier' : 'fichier'}.</span>
                        <div>
                            {retour}
                            <IconButton
                                onClick={() => delete_FileOrDir(element.name, element.type)}
                                style={{ color: 'green' }}
                                className={[css.p0, css.floatRight]}
                            >
                                <CheckOutlinedIcon />
                            </IconButton>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }

    function conversionSize(size) {
        const units = ['ko', 'Mo', 'Go'];
        let index = -1;
        do {
            size /= 1024;
            ++index;
        } while (Math.round(Math.abs(size) * 10) / 10 >= 1024 && index < units.length - 1);
        return `${size.toFixed(2)} ${units[index]}`;
    }

    function ViewRow(props) {
        const element = props.elements;
        const p = dossier.lastIndexOf('/');
        const _dossier = dossier.substring(0, p);
        return (
            <div className="flex spaceBetween" key={props.index}>
                {indexLigne.ligne === props.index && indexLigne.content === props.content ? (
                    viewDelete_rename(element)
                ) : (
                    <>
                        <div className="w75 flex spaceBetween">
                            <span
                                key={element.name}
                                onClick={() =>
                                    element.type === 1 ? download(element.name) : chargeDossier(element.type === 0 ? `${dossier}/${element.name}` : _dossier)
                                }
                            >
                                {element.type === -1 && (
                                    <div className="flex ">
                                        <ArrowBackIcon style={{ color: 'green' }} />
                                        <h3 className="mb20" style={{ cursor: 'pointer' }}>
                                            {element.name}
                                        </h3>
                                    </div>
                                )}
                                {element.type !== -1 && (
                                    <div className="flex mb1 alignICenter">
                                        {element.type === 0 ? <FolderIcon style={{ color: '#F8D775' }} /> : <InsertDriveFileOutlinedIcon />}
                                        <span className="flaotRight fontSize0_8em" style={{ cursor: 'pointer' }}>
                                            {element.name}
                                        </span>
                                    </div>
                                )}
                            </span>
                            {element.type !== -1 && <span className="flaotRight fontSize0_8em">{dayjs(element.date).format('DD/MM/YYYY HH:mm')}</span>}
                        </div>
                        {element.type === 1 && <span className="w10 fontSize0_8em"> {` ${conversionSize(element.size)}`}</span>}
                        <div className="w10">
                            {element.type !== -1 && testDroit(user[usurpation].roleFonction, fonctionGed.Delete) && (
                                <IconButton
                                    onClick={() => setIndexLigne({ content: props.content, ligne: props.index, type: 'delete' })}
                                    color="secondary"
                                    className={[css.p0, css.floatRight]}
                                >
                                    <DeleteForeverIcon fontSize="small" />
                                </IconButton>
                            )}
                            {element.type === 0 && testDroit(user[usurpation].roleFonction, fonctionGed.Rename_dossier) && (
                                <IconButton
                                    onClick={() => setIndexLigne({ content: props.content, ligne: props.index, type: 'rename' })}
                                    color="primary"
                                    className={[css.p0, css.floatRight]}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }

    function fileAriane() {
        return (
            <div className="mb1 flex spaceBetween">
                <div className="flex alignICenter">
                    <Tooltip placement="bottom" title="Lien de cet emplacement" color={'#0f4c81'}>
                        <IconButton onClick={() => navigator.clipboard.writeText(`${document.location.href}?chemin=${dossier}`)} className={[css.cVert]}>
                            <FileCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    Contenu de : {dossier}
                </div>
                <div className="w30 flex alignICenter ">
                    <h4 className="nomargin">Tri des documents :</h4>
                    <Button className={[css.ml5, css.mr2]} size="small" variant="outlined" onClick={() => chargeDossier(dossier, 'name')}>
                        Nom
                    </Button>
                    <Button className={css.mr2} size="small" variant="outlined" onClick={() => chargeDossier(dossier, 'date')}>
                        Date
                    </Button>
                    <Button size="small" variant="outlined" onClick={() => chargeDossier(dossier, 'size')}>
                        Taille
                    </Button>
                </div>
            </div>
        );
    }

    useEffect(() => {
        chargeDossier(valuesQuery('chemin') ? `//${valuesQuery('chemin').split('//')[1]}` : RACINE);
        props.addNomPage(`Gestion des documents`);
    }, []);

    return (
        <div className="p1">
            <div className="flex  mb1">
                <input
                    id="raised-button-file"
                    type="file"
                    hidden
                    onChange={(event) => {
                        uploadFile(event.target.files[0]);
                    }}
                />
                {testDroit(user[usurpation].roleFonction, fonctionGed.Create_dossier) && (
                    <div className="flex w40 alignICenter ml5">
                        <Input
                            placeholder="Nom du dossier"
                            value={nomDossier}
                            className={[css.nomargin, css.w50, css.mr2]}
                            onChange={(event) => {
                                setNomDossier(event.target.value);
                            }}
                        />
                        <Button size="small" variant="outlined" color="primary" onClick={() => creerDossier()}>
                            Créer le dossier
                        </Button>
                    </div>
                )}
                {testDroit(user[usurpation].roleFonction, fonctionGed.Add_doc) && (
                    <div className="flex directionColumn mr5">
                        <label htmlFor="raised-button-file" className="flex">
                            <Button variant="outlined" size="small" color="primary" component="span">
                                Ajouter un document
                            </Button>
                        </label>
                    </div>
                )}
                <div className="w30">
                    <Button className={css.mr5} size="small" variant="outlined" color="primary" onClick={() => chargeDossier(RACINE)}>
                        Revenir à la racine
                    </Button>
                    <Button size="small" variant="outlined" color="primary" onClick={() => chargeDossier(dossier)}>
                        Actualiser
                    </Button>
                </div>
            </div>
            {fileAriane()}
            <div className="flex">
                <div className=" ml2 w45 ">
                    {contenuDossier.length > 20 &&
                        contenuDossier.map((e, i) => i < contenuDossier.length / 2 && <>{ViewRow({ elements: e, index: i, content: 1 })}</>)}
                    {contenuDossier.length <= 20 && contenuDossier.map((e, i) => <>{ViewRow({ elements: e, index: i, content: 1 })}</>)}
                </div>
                {contenuDossier.length > 20 && (
                    <div className=" ml2 w45 ">
                        {contenuDossier.map((e, i) => i > contenuDossier.length / 2 && <>{ViewRow({ elements: e, index: i, content: 2 })}</>)}
                    </div>
                )}
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(Documents);
