import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect, useSelector } from 'react-redux';

const TextNotification = (props) => {
    const note = useSelector((state) => state.Notification);
    if (note.message) {
        const customId = `id_${note.message}`;
        switch (note.status) {
            case false:
                toast.error(note.message, {
                    toastId: customId,
                    onClose: () => props.removeNote(),
                });
                break;
            case true:
            case 200:
                toast.success(note.message, {
                    toastId: customId,
                    onClose: () => props.removeNote(),
                });
                break;
            default:
                break;
        }

        return (
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick />
        );
    } else {
        return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return {
        removeNote() {
            dispatch({ type: 'removeNote' });
        },
    };
}

export default connect(null, mapDispatchToProps)(TextNotification);
