export const requeteFiltre = [
    { url: '/api/anneesScolaire/list', id: 'idAnneeScolaire', libelle: 'libelleAnneeScolaire' },
    { url: '/api/sites/liste', id: 'idSite', libelle: 'libelleSite' },
    { url: '/api/cotisations/cycles', id: 'idCycle', libelle: 'libelleCycle' },
    { url: '/api/ateliers/ateliersGroupes', id: 'idGroupe', libelle: 'libelleGroupe' },
    { url: '/api/ateliers/ateliersRepetitions', id: 'idRepetition', libelle: 'libelleRepetition' },
    { url: '/api/eleves/SI_annees', id: 'idAnnee', libelle: 'libelleAnnee' },
    { url: '/api/eleves/SI_groupes', id: 'idGroupe', libelle: 'libelleGroupe' },
    { url: '/api/ateliers/ateliersVisibilites', id: 'idVisibilite', libelle: 'libelleVisibilite' },
    { url: '/api/ateliers/ateliersRepetitions', id: 'idRepetition', libelle: 'libelleRepetition' },
    { url: '/api/ateliers/ateliersStatut', id: 'idStatut', libelle: 'libelleStatut' },
];
/*  ------------------ Liste Atelier */
export const tableHeadAtelier = [
    { id: 'libelleType', label: 'Type' },
    { id: 'libelleSite', label: 'Site/Salle' },
    { id: 'jour', label: 'Horaire' },
    { id: 'nomPrenomUtilisateur', label: 'Enseignant' },
];
export const tableHeadEleve = [
    { id: 'libelleType', label: 'Type' },
    { id: 'libelleSite', label: 'Site' },
    { id: 'nomPrenomEnseignant', label: 'Enseignant' },
    { id: 'nomPrenomEleve', label: 'Élève' },
    { id: 'libelleInstrument', label: 'Instrument' },
    { id: 'telephoneEleve', label: 'Télèphone' },
    { id: 'emails', label: 'Email' },
];
export const individuelFiltre = [
    { id: 2, lib: 'Afficher ind. et col.' },
    { id: 0, lib: 'Afficher uniquement collectif' },
    { id: 1, lib: 'Afficher uniquement individuel' },
];
export const tableauSaveFiltre = [
    { simple: ['AnneeScolaire', 'AtelierIndividuel'] },
    { multiple: ['Site', 'Salle', 'GroupeAteliers', 'SousGroupeAteliers', 'Enseignants', 'Cycles', 'Annees', 'Groupes'] },
];
/* gestion des badge tableau */
const cycle = 1;
const annee = 2;
const atelier = 3;
const eleve = 4;
const groupe = 5;
export const badge = {
    [annee]: { style: 'cWhite bgcBadgeAnneeCycle pBadge  ml1 radius10 nowrap', label: 'Année' },
    [cycle]: { style: 'cWhite bgcBadgeAnneeCycle pBadge  ml1 radius10 nowrap', label: 'Cycle' },
    [atelier]: { style: 'cWhite bgcBadgeAtelier pBadge  ml1 radius10 nowrap', label: '' },
    [eleve]: { style: 'floatRight bgcBadgeCountEleve fontSize10  pBadge ', label: '' },
    [groupe]: { style: 'cWhite bgcBadgeAnneeCycle pBadge  ml1 radius10 nowrap ', label: 'Groupe' },
};
export const tableHeadAtelierExport = [
    { label: 'Type', value: 'libelleType' },
    { label: 'Cycle', value: 'libelleCycle' },
    { label: 'Site', value: 'libelleSite' },
    { label: 'Salle', value: 'libelleSalle' },
    { label: 'Heure Debut', value: 'heureDebut' },
    { label: 'Heure Fin', value: 'heureFin' },
    { label: 'Enseignant', value: 'nomPrenomUtilisateur' },
];
export const tableHeadEleveExport = [
    { label: 'Type', value: 'libelleType' },
    { label: 'Site', value: 'libelleSite' },
    { label: 'Enseignant', value: 'nomPrenomEnseignant' },
    { label: 'Eleve', value: 'nomPrenomEleve' },
    { label: 'Instrument', value: 'libelleInstrument' },
    { label: 'Telephone', value: 'telephoneEleve' },
    { label: 'Emails', value: 'emails' },
];
export const selectMultipleListeAtelierPart1 = [
    { label: 'Site', indice: 'Site' },
    { label: 'Salle', indice: 'Salles' },
];
export const selectMultipleListeAtelierPart2 = [
    { label: 'Groupe Ateliers', indice: 'GroupeAteliers' },
    { label: 'Sous Groupe Ateliers', indice: 'SousGroupeAteliers', valeurNull: true },
    { label: 'Enseignant', indice: 'Enseignants', valeurNull: true },
    { label: 'Cycle', indice: 'Cycles' },
    { label: 'Année', indice: 'Annees' },
    { label: 'Groupe', indice: 'Groupes' },
];
/*  ------------------ Fiche Atelier */
export const tableHeadEnseignantF = [
    { id: 'libEnseignant', label: 'Enseignant' },
    { id: 'libelleInstrument', label: 'Discipline' },
];
export const tableHeadEleveF = [
    { id: 'antenne', label: 'Élèves' },
    { id: 'instrument', label: 'Instrument' },
    { id: 'statut', label: 'Statut' },

    { id: 'dateCreation', label: "Date d'inscription" },
    { id: 'droit', label: '' },
];
export const tableHeadInfoEleveF = [
    { id: 'libEleveList', label: 'Élèves' },
    { id: 'libStatut', label: 'Statut' },
    { id: 'telephoneMobileEleve', label: 'Mobile' },
    { id: 'concatEmails', label: 'Email' },
];
export const tableHeadAjoutEnseignant = [
    { id: 'libEnseignant', label: 'Nom Prenom' },
    { id: 'telephoneFixe', label: 'Fixe' },
    { id: 'telephoneMobile', label: 'Mobile' },
];
export const tableHeadInfoEleveExport = [
    { label: 'Nom Eleve', value: 'nomPrenomEleve' },
    { label: 'Statut', value: 'libelleStatut' },
    { label: 'Responsable mobile', value: 'concatTelephoneMobile' },
    { label: 'Responsable fixe', value: 'concatTelephoneFixe' },
    { label: 'Mobile', value: 'telephoneMobileEleve' },
    { label: 'Emails', value: 'concatEmails' },
];
export const selectFicheAtelierPart1 = [
    { label: 'Année Scolaire', indice: 'AnneeScolaire' },
    { label: "Groupe d'Ateliers", indice: 'GroupeAtelier' },
    { label: "sous groupe d'Ateliers", indice: 'SousGroupeAtelier' },
    { label: 'Site', indice: 'Site' },
];
export const selectFicheAtelierPart2 = [
    { label: 'Fréquence', indice: 'Repetition' },
    { label: 'Visibilité', indice: 'Visibilite' },
    { label: 'Cycle', indice: 'Cycle' },
    { label: 'Année', indice: 'Annee' },
    { label: 'Groupe', indice: 'Groupe' },
];
export const selectDialogAddEleve = [
    { label: 'Scolarité Interne', indice: 'fk_scolariteInterne' },
    { label: 'Instrument', indice: 'fk_instrument' },
    { label: 'Statut', indice: 'fk_statut' },
];
export const inputFicheAtelierTemp = [
    { label: 'Heure début', indice: 'HeureDebut' },
    { label: 'Heure fin', indice: 'HeureFin' },
];
export const inputFicheAtelierCommentaire = [
    { label: 'Commentaire', indice: 'Commentaire' },
    { label: 'Évaluation 1', indice: 'Evaluation1' },
    { label: 'Évaluation 2', indice: 'Evaluation2' },
];
