/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import NoteAdd from '@material-ui/icons/NoteAdd';

import Input from '../../components/atoms/TextField';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const FormulaireResponsable = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const dataDocument = useSelector((state) => state.DataInscriptionTarn.document.fiscalite);
    const reloadView = useSelector((state) => state.DataInscriptionTarn.reloadView);
    const [valideDataFile, setValideDataFile] = useState({
        document: 'none',
        justificatifDomicile: 'none',
    });
    async function uploadFile(file, key) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            return;
        }
        const data = new FormData();
        data.delete('file');
        data.delete('fk_responsable');
        data.delete('fk_anneeScolaire');
        data.append('file', file);
        data.append('fk_responsable', 0);
        data.append('fk_anneeScolaire', 9);
        setValideDataFile((prevState) => ({
            ...prevState,
            [key]: 'block',
        }));
        props.dataDocument({ [key]: data });
    }
    function view() {
        return (
            <div className=" bgcfff h100 flex directionColumn p3">
                <div className="flex alignICenter w100">
                    <div className="flex  pl2 w55 radius10 directionColumn" style={{ border: `2px solid #2e76b0` }}>
                        <h3> Documents nécessaires au calcul de la cotisation.</h3>
                    </div>
                </div>
                <div className="p2 ">
                    <div className="flex mt2 ">
                        <div className="w50 ">
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Revenu fiscal de référence</label>
                                <Input
                                    value={dataDocument.revenuFiscal}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => {
                                        props.dataDocument({ revenuFiscal: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="flex alignICenter mb5">
                                <label className="w40 ">Nombre de parts</label>
                                <Input
                                    value={dataDocument.nombrePart}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => {
                                        props.dataDocument({ nombrePart: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="flex alignICenter">
                                <div className="w100 flex">
                                    <label className="w40 ">Avis d'imposition</label>
                                    <input
                                        id="fiscalite"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            uploadFile(event.target.files[0], 'document');
                                        }}
                                    />
                                    <label htmlFor="fiscalite" className="flex">
                                        <Button
                                            startIcon={<NoteAdd />}
                                            className={[css.ml2em, css.mt0]}
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            size="small"
                                        >
                                            Import
                                        </Button>
                                        <CheckIcon style={{ marginLeft: '10px', color: 'green', display: valideDataFile.document }} />
                                    </label>
                                </div>
                            </div>
                            <div className="flex alignICenter mt5">
                                <div className="w100 flex ">
                                    <label className="w40 ">Justificatif de domicile</label>
                                    <input
                                        id="justificatifDomicile"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            uploadFile(event.target.files[0], 'justificatifDomicile');
                                        }}
                                    />
                                    <label htmlFor="justificatifDomicile" className="flex ">
                                        <Button
                                            startIcon={<NoteAdd />}
                                            className={[css.ml2em, css.mt0]}
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            size="small"
                                        >
                                            Import
                                        </Button>
                                        <CheckIcon
                                            style={{
                                                marginLeft: '10px',
                                                color: 'green',
                                                display: valideDataFile.justificatifDomicile,
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="w50">
                            <p>
                                Avis d'imposition {new Date().getFullYear() - 1} sur revenus {new Date().getFullYear() - 2}
                                <br />
                                <br />
                                Ces documents ne sont pas obligatoires pour continuer l’inscription.
                                <br /> Vous pourrez les fournir à tout moment jusqu’au 30 septembre.
                                <br /> Attention, en l’absence de ces éléments, l’administration sera amenée à appliquer la tranche 6 de cotisation ou bien le
                                tarif « extérieurs ».
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex mt2">
                    <Link to="/inscription/finalisationInscription" className={[css.ml15]}>
                        <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5]}>
                            Continuer l'inscription
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
    useEffect(() => {
        view();
    }, [reloadView]);
    useEffect(() => {
        if (!dataDocument) {
            history.push('/inscription/accueil');
        }
    }, []);
    if (!dataDocument) {
        return <></>;
    }
    return view();
};

function mapDispatchToProps(dispatch) {
    return {
        dataDocument(data) {
            dispatch({ type: 'document', data: data });
        },
    };
}

export default connect(null, mapDispatchToProps)(FormulaireResponsable);
