/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function structure(structure = [], action) {
    if (action.type === 'structure') {
        return action.structure;
    }

    return structure;
}
