import { GET } from '../requete';

export default async function anneeInscription() {
    const response = await GET('/api/parametres/list?categorie=inscription', undefined, { redirectOnError: false });
    const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
    if (response) {
        let index = response.data.findIndex((e) => e.cle === 'anneeInscriptions');
        response.data[index].libelleAnneeScolaire = filtreAnneeScolaire.data.filter(
            (e) => e.idAnneeScolaire === +response.data[index].valeur,
        )[0].libelleAnneeScolaire;
        return response.data[index];
    }
}
