export const acceptedRoles = ['SuperAdmin'];
export const tableHeadDecharge = [
    { id: 'type', label: 'Type' },
    { id: 'site', label: 'Site' },
    { id: 'commentaire', label: 'Commentaire' },
    { id: 'heure', label: 'Heures' },
];
export const tableHeadAffectation = [
    { id: 'site', label: 'Site' },
    { id: 'libelleInstrument', label: 'Discipline' },
    { id: 'commentaire', label: 'Commentaire' },
    { id: 'heure', label: 'Heures' },
];
export const tableHeadDiscipline = [{ id: 'instrument', label: 'Libellé' }];
export const selectEmploi = [
    { requete: '/api/utilisateursAdministratifs/listEnseignantsCorps', indice: 'Corps' },
    { requete: '/api/utilisateursAdministratifs/listEnseignantsGrades', indice: 'Grade' },
    { requete: '/api/utilisateursAdministratifs/listEnseignantsFilieres', indice: 'Filiere' },
    { requete: '/api/utilisateursAdministratifs/listEnseignantsDiplomes', indice: 'Diplome' },
    { requete: '/api/utilisateursAdministratifs/listEnseignantsEmplois', indice: 'Emploi' },
    { requete: '/api/sites/liste', indice: 'SiteResidence' },
    { requete: '/api/sites/liste', indice: 'Site' },
    { requete: '/api/utilisateursAdministratifs/listEnseignantsContrats', indice: 'Contrat' },

    { requete: '/api/anneesScolaire/list', indice: 'AnneeScolaire' },
    { requete: '/api/antennes/list', indice: 'Antenne' },
];
export const inputContrat = [
    { type: 'number', label: 'Durée CDD', indice: 'dureeCDD' },
    { type: 'number', label: 'Quotité', indice: 'quotite' },
    { type: 'number', label: 'Coût Heure Supp', indice: 'coutHS' },
];
export const inputDecharge = [
    { type: 'input', label: 'Décharge syndicale', indice: 'dechargeSyndicaleHeures' },
    { type: 'input', label: 'Décharge antenne', indice: 'dechargeAntenneHeures' },
    {
        type: 'textArea',
        label: 'Motif décharge antenne',
        indice: 'dechargeAntenneMotif',
        placeholder: 'Motif décharge antenne',
    },
    { type: 'input', label: 'Décharge autre', indice: 'dechargeAutreHeures' },
    { type: 'textArea', label: 'Motif décharge autre', indice: 'dechargeAutreMotif', placeholder: 'Motif décharge autre' },
];
