/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, TextareaAutosize, Select } from '@material-ui/core';

import { POST, GET } from '../../components/Utils/requete';
import Input from '../../components/atoms/TextField';
import TelephoneInput from '../../components/organism/Input/telephone';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const FormaulaireContact = () => {
    const css = useStylesMaterielUIGlobal();
    const [valueInput, setValueInput] = useState({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        message: '',
        subject: '',
    });
    const [site, setSite] = useState([]);
    const [sites, setSites] = useState([]);

    async function sendMail() {
        const data = {
            nom: valueInput.nom,
            prenom: valueInput.prenom,
            email: valueInput.email,
            telephone: valueInput.telephone,
            message: valueInput.message,
            subject: valueInput.subject,
            antenne: site,
        };
        await POST('/api/cotisations/mailContactInscription', data, {
            redirectOnError: false,
        });
    }
    function updateState(keys, value) {
        setValueInput((prevState) => ({
            ...prevState,
            [keys]: value,
        }));
    }
    async function recupSites() {
        const listeSites = await GET('/api/antennes/list', undefined, { redirectOnError: false });
        if (listeSites) {
            const antenne = listeSites.data.filter((e) => e.libelleAntenne !== 'Hors-antenne');
            setSites(antenne);
        }
    }

    useEffect(() => {
        recupSites();
    }, []);
    return (
        <div className=" bgcfff h100 flex directionColumn mt1">
            <div className="flex alignICenter w100">
                <div className="flex  pl2 w55 radius10 directionColumn">
                    <h2 className="mb0">Formulaire de contact</h2>
                </div>
            </div>
            <div className="pl5 mt1">
                <div className="flex alignICenter w100">
                    <h3 className="mr10 w10">- Nom :</h3>
                    <Input variant="outlined" className={[css.w40]} value={valueInput.nom} onChange={(event) => updateState('nom', event.target.value)} />
                </div>
            </div>
            <div className="pl5 ">
                <div className="flex alignICenter w100">
                    <h3 className="mr10 w10">- Prénom :</h3>
                    <Input variant="outlined" className={[css.w40]} value={valueInput.prenom} onChange={(event) => updateState('prenom', event.target.value)} />
                </div>
            </div>
            <div className="pl5 ">
                <div className="flex alignICenter w100">
                    <h3 className="mr10 w10">- Email :</h3>
                    <Input variant="outlined" className={[css.w40]} value={valueInput.email} onChange={(event) => updateState('email', event.target.value)} />
                </div>
            </div>
            <div className="pl5 mt1">
                <div className="flex alignICenter w100">
                    <h3 className="mr10 w10">- Téléphone :</h3>
                    <TelephoneInput className={[css.w40]} value={valueInput.telephone} onChange={(event) => updateState('telephone', event)} />
                </div>
            </div>
            <div className="pl5 mt1">
                <div className="flex alignICenter w100">
                    <h3 className="w20">- site concerné :</h3>
                    <Select
                        value={site}
                        className={css.w40}
                        onChange={(event) => {
                            setSite(event.target.value);
                            updateState('site', event);
                        }}
                    >
                        <option value=""></option>
                        {sites.map((elem, index) => (
                            <option key={index} value={elem.libelleAntenne}>
                                {elem.libelleAntenne}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="pl5 mt1">
                <div className="flex alignICenter w100">
                    <h3 className="w20">- Objet du message :</h3>
                    <Input
                        placeholder={'Inscription - Demande de prise de contact'}
                        variant="outlined"
                        className={[css.w40]}
                        value={valueInput.subject}
                        onChange={(event) => updateState('subject', event.target.value)}
                    />
                </div>
            </div>
            <div className="pl5 mt1">
                <div className="flex w100">
                    <h3 className="mr10 w10">- Message :</h3>
                    <TextareaAutosize
                        rowsMin={10}
                        className={[css.w40]}
                        value={valueInput.message}
                        onChange={(event) => updateState('message', event.target.value)}
                    />
                </div>
            </div>
            <Button size="small" variant="outlined" className={[css.w10, css.ml50, css.mt1, css.floatRight]} onClick={() => sendMail()}>
                Envoyer
            </Button>
        </div>
    );
};

export default connect(null, null)(FormaulaireContact);
