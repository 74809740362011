/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import valuesQuery from '../../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ChoixScolarite = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const dataCategorieEnfant = useSelector((state) => state.DataInscriptionTarn.categoriesEnfants);
    function viewOffre() {
        let nonDebutant = [];
        let toutNiveau = [];
        let debutant = [];
        for (let index = 0; index < dataCategorieEnfant.offres.length; index++) {
            if (dataCategorieEnfant.offres[index].debutant === 1) {
                debutant.push(dataCategorieEnfant.offres[index]);
            } else if (dataCategorieEnfant.offres[index].debutant === 0) {
                nonDebutant.push(dataCategorieEnfant.offres[index]);
            } else {
                toutNiveau.push(dataCategorieEnfant.offres[index]);
            }
        }
        return (
            <div>
                {toutNiveau.map((row, index) => {
                    row.couleur = dataCategorieEnfant.couleur;
                    return (
                        <div
                            key={index}
                            className="flex directionColumn m1 pl2 pr5 "
                            style={{ border: `4px solid ${dataCategorieEnfant.couleur}` }}
                        >
                            <div className="flex   alignICenter pr5 mb0">
                                <h3 className="w45 ">{row.libelleOffre}</h3>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: row.commentaire,
                                    }}
                                    className="w55"
                                ></p>
                                <Link
                                    to={
                                        `/inscription/OptionOffre?numeroEleve=${valuesQuery(
                                            'numeroEleve',
                                        )}&numeroOffre=${valuesQuery('numeroOffre')}` +
                                        `${
                                            valuesQuery('reinscription')
                                                ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                                                : '&parcours=toutesOffres'
                                        }` +
                                        `${valuesQuery('finalisation') ? '&finalisation=true' : ''}`
                                    }
                                    className={css.ml2}
                                    onClick={() => {
                                        props.dataCategorieEnfant(row);
                                        props.idOffre(valuesQuery('numeroEleve'), valuesQuery('numeroOffre'), row.idOffre);
                                    }}
                                >
                                    <Button size="small" variant="outlined">
                                        Choisir
                                    </Button>
                                </Link>
                            </div>
                            <div className="flex mb0_5 mt1">
                                <div className="btn">Voir les sites d’enseignement</div>
                                <div className="box">
                                    <span>{` ${row.siteOffre.join(', ')}`}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {debutant.length !== 0 ? (
                    <div>
                        <h4>Les offres suivantes sont réservées pour les élèves voulant débuter ou poursuivre une activité.</h4>
                        {debutant.map((row, index) => {
                            row.couleur = dataCategorieEnfant.couleur;
                            return (
                                <div
                                    key={index}
                                    className="flex directionColumn m1 pl2 pr5 "
                                    style={{ border: `4px solid ${dataCategorieEnfant.couleur}` }}
                                >
                                    <div className="flex   alignICenter  pr5 mb0">
                                        <h3 className="w45 mb0">{row.libelleOffre}</h3>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: row.commentaire,
                                            }}
                                            className="w55"
                                        ></p>
                                        <Link
                                            to={
                                                `/inscription/OptionOffre?numeroEleve=${valuesQuery(
                                                    'numeroEleve',
                                                )}&numeroOffre=${valuesQuery('numeroOffre')}` +
                                                `${
                                                    valuesQuery('reinscription')
                                                        ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                                                        : '&parcours=toutesOffres'
                                                }` +
                                                `${valuesQuery('finalisation') ? '&finalisation=true' : ''}`
                                            }
                                            className={css.ml2}
                                            onClick={() => {
                                                props.dataCategorieEnfant(row);
                                                props.idOffre(
                                                    valuesQuery('numeroEleve'),
                                                    valuesQuery('numeroOffre'),
                                                    row.idOffre,
                                                );
                                            }}
                                            target="_self"
                                        >
                                            <Button size="small" variant="outlined">
                                                Choisir
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="flex mb0_5 mt1">
                                        <div className="btn">Voir les sites d’enseignement</div>
                                        <div className="box">
                                            <span>{` ${row.siteOffre.join(', ')}`}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
                {nonDebutant.length !== 0 ? (
                    <div>
                        <h4>Les offres suivantes ne sont pas accessibles aux débutants.</h4>
                        {nonDebutant.map((row, index) => {
                            row.couleur = dataCategorieEnfant.couleur;
                            return (
                                <div
                                    key={index}
                                    className="flex directionColumn m1 pl2 pr5 "
                                    style={{ border: `4px solid ${dataCategorieEnfant.couleur}` }}
                                >
                                    <div className="flex   alignICenter  pr5 mb0">
                                        <h3 className="w45 mb0">{row.libelleOffre}</h3>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: row.commentaire,
                                            }}
                                            className="w55"
                                        ></p>
                                        <Link
                                            to={
                                                `/inscription/OptionOffre?numeroEleve=${valuesQuery(
                                                    'numeroEleve',
                                                )}&numeroOffre=${valuesQuery('numeroOffre')}` +
                                                `${
                                                    valuesQuery('reinscription')
                                                        ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                                                        : '&parcours=toutesOffres'
                                                }` +
                                                `${valuesQuery('finalisation') ? '&finalisation=true' : ''}`
                                            }
                                            className={css.ml2}
                                            onClick={() => {
                                                props.dataCategorieEnfant(row);
                                                props.idOffre(
                                                    valuesQuery('numeroEleve'),
                                                    valuesQuery('numeroOffre'),
                                                    row.idOffre,
                                                );
                                            }}
                                            target="_self"
                                        >
                                            <Button size="small" variant="outlined">
                                                Choisir
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="flex mb0_5 mt1">
                                        <div className="btn">Voir les sites d’enseignement</div>
                                        <div className="box">
                                            <span>{` ${row.siteOffre.join(', ')}`}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
    useEffect(() => {
        if (!dataCategorieEnfant) {
            if (valuesQuery('reinscription')) {
                history.push(`/accueilResponsable`);
            } else {
                history.push('/inscription/accueil');
            }
        }
    }, []);
    return (
        <div className=" bgcfff h100 flex directionColumn p5 ">
            {valuesQuery('finalisation') ? (
                <></>
            ) : (
                <div>
                    <Link
                        to={
                            `/inscription/ChoixOffres?numeroEleve=${valuesQuery('numeroEleve')}` +
                            `${valuesQuery('reinscription') ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}` : ''}`
                        }
                    >
                        <Button
                            size="small"
                            variant="contained"
                            className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.floatRight]}
                        >
                            Retour
                        </Button>
                    </Link>
                </div>
            )}
            {dataCategorieEnfant ? (
                <>
                    <h1>{`Offre ${dataCategorieEnfant.libelleEnfantCategorie}`}</h1>
                    {viewOffre()}
                </>
            ) : (
                <></>
            )}
            <div className="w100">
                <a href="https://www.cmdtarn.fr/musique.html" className="floatRight" target="_blank" rel="noreferrer">
                    voir règlement des études
                </a>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        dataCategorieEnfant(choixOffre) {
            dispatch({ type: 'choixOffre', data: choixOffre });
        },
        idOffre(numeroEleve, numeroOffre, fk_offre) {
            dispatch({ type: 'idOffre', numeroEleve: numeroEleve, numeroOffre: numeroOffre, fk_offre: fk_offre });
        },
    };
}

export default connect(null, mapDispatchToProps)(ChoixScolarite);
