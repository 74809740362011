/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { Select, Checkbox, TextareaAutosize } from '@material-ui/core';

import { POST, GET } from '../../components/Utils/requete';
import anneeInscription from '../../components/Utils/requeteParametre/anneeInscription';
import valuesQuery from '../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ElementDeSelectionOption = (props) => {
    const css = useStylesMaterielUIGlobal();
    const dataOffre = useSelector((state) => state.DataInscriptionTarn);

    const [checkedDiscipline, setCheckedDiscipline] = useState({});
    const [niveauxScolaire, setNiveauxScolaire] = useState([]);
    const [selectNiveauxScolaire, setSelectNiveauxScolaire] = useState();
    const [observation, setObservation] = useState();
    const [sites, setSites] = useState([]);
    const [instrument, setInstrument] = useState([]);
    const [selectInstrument, setTableauTest] = useState([]);
    const [selectSite, setSelectSite] = useState(null);
    const [pratiqueCollective, setPratiqueCollective] = useState([]);
    const [styles, setStyles] = useState([]);
    const date = new Date();

    async function requeteSites(refreshListe) {
        if (sites.length === 0 || refreshListe) {
            const data = {
                fk_instrument: selectInstrument[0],
                fk_offre: dataOffre.choixOffre.idOffre,
            };
            const response = await POST('/api/cotisations/cotisationsSiteParOffreEtInstrument', data, {
                redirectOnError: false,
            });
            setSites(response.data);
        }
    }

    async function requeteInstrument(refreshListe) {
        if (instrument.length === 0 || refreshListe) {
            const fk_anneeScolaire = await anneeInscription();
            const data = {
                fk_section: 1,
                fk_site: !selectSite ? [] : [selectSite],
                fk_anneeScolaire: fk_anneeScolaire.valeur,
            };
            const response = await POST('/api/cotisations/instruments', data);
            setInstrument(response.data);
        }
    }

    async function requetePratiqueCollective() {
        if (pratiqueCollective.length === 0) {
            const response = await GET(
                `/api/ateliers/atelierTypeParOffre?fk_cotisationOffre=${dataOffre.choixOffre.idOffre}`,
                undefined,
                {
                    redirectOnError: false,
                },
            );
            setPratiqueCollective(response.data);
        }
    }
    async function requeteNiveauxScolaire() {
        if (niveauxScolaire.length === 0) {
            const response = await GET(`/api/eleves/SE_niveaux`, undefined, {
                redirectOnError: false,
            });
            setNiveauxScolaire(response.data);
        }
    }
    async function recupStyle() {
        if (styles.length === 0) {
            const data = { fk_nomStyle: dataOffre.choixOffre.style };
            const response = await POST('/api/cotisations/styles', data, {
                redirectOnError: false,
            });
            const objectChecked = {};
            for (let index = 0; index < response.data.length; index++) {
                objectChecked[response.data[index].libelleStyle] = 0;
            }
            setCheckedDiscipline(objectChecked);
            setStyles(response.data);
        }
    }

    function disabledCheckboxDiscipline() {
        const initialValue = 0;
        return (
            Object.values(checkedDiscipline).reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue,
            ) === 2
        );
    }
    function concatDisciplineObservation(row, event, concatPratique) {
        let disciplines = 'Discipline choisie: ';
        let pratique;
        if (concatPratique) {
            pratique = `Pratique collective choisie: ${event.target.value}`;
        }
        const commentaire =
            dataOffre.eleves[valuesQuery('numeroEleve') - 1].scolarites[
                valuesQuery('numeroOffre') - 1
            ].commentaireInscription.split('\n');
        if (row) {
            for (let index = 0; index < Object.keys(checkedDiscipline).length; index++) {
                if (Object.keys(checkedDiscipline)[index] === row.libelleStyle && !Object.values(checkedDiscipline)[index]) {
                    disciplines = disciplines + `${row.libelleStyle}, `;
                }
                if (Object.keys(checkedDiscipline)[index] !== row.libelleStyle && Object.values(checkedDiscipline)[index]) {
                    disciplines = disciplines + `${Object.keys(checkedDiscipline)[index]}, `;
                }
            }
            props.dataOption('commentaireInscription', `${disciplines}\n${commentaire[1] ? commentaire[1] : ''}`);
            setCheckedDiscipline((prevState) => ({
                ...prevState,
                [row.libelleStyle]: checkedDiscipline[row.libelleStyle] ? 0 : 1,
            }));
        } else {
            if (concatPratique) {
                props.dataOption('commentaireInscription', `${pratique}\n${commentaire[1] ? commentaire[1] : ''}`);
            } else {
                setObservation(event.target.value);
                props.dataOption('commentaireInscription', `${commentaire[0] ? commentaire[0] : ''}\n${event.target.value}`);
            }
        }
    }
    function stateInstrument(numeroInstrument, value) {
        const tableauInstrument =
            dataOffre.eleves[valuesQuery('numeroEleve') - 1].scolarites[valuesQuery('numeroOffre') - 1].fk_instrument;
        const newTableauInstrument = [];
        for (let index = 0; index < tableauInstrument.length + 1; index++) {
            if (index === numeroInstrument - 1) {
                if (value) {
                    newTableauInstrument.push(value);
                }
            } else {
                if (tableauInstrument[index]) {
                    newTableauInstrument.push(tableauInstrument[index]);
                }
            }
        }
        setTableauTest(newTableauInstrument);
        props.instrument(newTableauInstrument);
    }

    function View() {
        const styleBorder = { border: `2px solid ${props.couleur}` };
        switch (props.type) {
            case 'site/instrument':
                requeteSites();
                requeteInstrument();
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${+props.index + 1} - Choix du site d’enseignement`}</h3>
                            </div>
                            <Select
                                className={css.w20}
                                value={selectSite}
                                onChange={(event) => {
                                    setSelectSite(event.target.value);
                                    props.dataOption('fk_site', event.target.value);
                                }}
                            >
                                <option value={null}></option>
                                {sites.map((elem, index) => (
                                    <option key={index} value={elem.idSite}>
                                        {elem.libelleSite}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="flex alignIStart w100 mt2 ">
                            <div className="flex alignICenter pl2 w40 radius10 mr10 " style={styleBorder}>
                                <h3>{`${+props.index + 2} - Choix de l'instrument`}</h3>
                            </div>
                            <div className="flex w40 directionColumn">
                                <div>
                                    <p className="mb2 fontSize0_9em">Premier choix d’instrument</p>
                                    <Select
                                        className={css.w40}
                                        value={selectInstrument[0]}
                                        onChange={(event) => stateInstrument(1, event.target.value)}
                                    >
                                        <option value={null}></option>
                                        {instrument.map((elem, index) => (
                                            <option key={index} value={elem.idInstrument}>
                                                {elem.libelleInstrument}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <p className="mb2 fontSize0_9em">
                                        Second choix d’instrument (si le premier n’est pas disponible)
                                    </p>
                                    <Select
                                        className={css.w40}
                                        value={selectInstrument[1]}
                                        onChange={(event) => stateInstrument(2, event.target.value)}
                                    >
                                        <option value={null}></option>
                                        {selectInstrument[0] ? (
                                            instrument
                                                .filter((e) => e.idInstrument !== selectInstrument[0])
                                                .map((elem, index) => (
                                                    <option key={index} value={elem.idInstrument}>
                                                        {elem.libelleInstrument}
                                                    </option>
                                                ))
                                        ) : (
                                            <option value=""></option>
                                        )}
                                    </Select>
                                </div>
                                <div>
                                    <p className="mb2 fontSize0_9em">
                                        Troisième choix d’instrument (si le premier et le second ne sont pas disponibles)
                                    </p>
                                    <Select
                                        className={css.w40}
                                        value={selectInstrument[2]}
                                        onChange={(event) => stateInstrument(3, event.target.value)}
                                    >
                                        <option value={null}></option>
                                        {selectInstrument[1] ? (
                                            instrument
                                                .filter((e) => e.idInstrument !== selectInstrument[0])
                                                .filter((e) => e.idInstrument !== selectInstrument[1])
                                                .map((elem, index) => (
                                                    <option key={index} value={elem.idInstrument}>
                                                        {elem.libelleInstrument}
                                                    </option>
                                                ))
                                        ) : (
                                            <option value=""></option>
                                        )}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'site':
                requeteSites();
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${+props.index + 1} - Choix du site d’enseignement`}</h3>
                            </div>
                            <Select
                                className={css.w20}
                                value={selectSite}
                                onChange={(event) => {
                                    setSelectSite(event.target.value);
                                    props.dataOption('fk_site', event.target.value);
                                }}
                            >
                                <option value={null}></option>
                                {sites.map((elem, index) => (
                                    <option key={index} value={elem.idSite}>
                                        {elem.libelleSite}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                );
            case 'instrument':
                requeteInstrument();
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${+props.index + 1} - Choix du premier instrument`}</h3>
                            </div>
                            <Select
                                className={css.w20}
                                value={selectInstrument[0]}
                                onChange={(event) => {
                                    setTableauTest([event.target.value]);
                                    props.dataOption('fk_instrument', event.target.value);
                                }}
                            >
                                <option value={null}></option>
                                {instrument.map((elem, index) => (
                                    <option key={index} value={elem.idInstrument}>
                                        {elem.libelleInstrument}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                );
            case 'disciplines':
                recupStyle();
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${+props.index + 1} - Choix des disciplines (2 sur les 3 proposées)`}</h3>
                            </div>
                            <div>
                                {styles.map((row, index) => (
                                    <div key={index}>
                                        <Checkbox
                                            disabled={disabledCheckboxDiscipline() && !checkedDiscipline[row.libelleStyle]}
                                            checked={checkedDiscipline[row.libelleStyle]}
                                            onChange={() => concatDisciplineObservation(row)}
                                        />
                                        <span>{row.libelleStyle}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            case 'niveauScolaire':
                requeteNiveauxScolaire();
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${
                                    +props.index + 1
                                } - Niveau scolaire de l’enfant en septembre ${date.getFullYear()}`}</h3>
                            </div>
                            <Select
                                className={css.w20}
                                value={selectNiveauxScolaire}
                                onChange={(event) => {
                                    setSelectNiveauxScolaire(event.target.value);
                                }}
                            >
                                <option value={null}></option>
                                {niveauxScolaire.map((elem, index) => (
                                    <option key={index} value={elem.idNiveau}>
                                        {elem.libelle}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                );
            case 'listePratiqueCollective':
                requetePratiqueCollective();
                return (
                    <>
                        <div className="pl5 mt2">
                            <div className="flex alignICenter w100">
                                <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                    <h3>{`${+props.index + 1} - Liste des pratiques collectives disponible`}</h3>
                                </div>
                                <Select
                                    className={css.w20}
                                    onChange={(event) => concatDisciplineObservation(undefined, event, true)}
                                >
                                    <option value={null}></option>
                                    {pratiqueCollective.map((elem, index) => (
                                        <option key={index} value={elem.libelleType}>
                                            {elem.libelleType}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </>
                );
            case 'observation':
                return (
                    <div className="pl5 mt2">
                        <div className="flex alignICenter w100">
                            <div className="flex alignICenter pl2 w40 radius10 mr10" style={styleBorder}>
                                <h3>{`${+props.index + 1} - Observations personnelles`}</h3>
                            </div>
                            <TextareaAutosize
                                value={observation}
                                onChange={(event) => concatDisciplineObservation(undefined, event)}
                                className={css.w40}
                                rowsMin={5}
                            />
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    }
    useEffect(() => {
        requeteInstrument(true);
    }, [selectSite]);
    useEffect(() => {
        requeteSites(true);
    }, [selectInstrument[0]]);
    return View();
};

function mapDispatchToProps(dispatch) {
    return {
        dataOption(key, dataOption) {
            dispatch({
                type: 'optionOffre',
                key: key,
                data: dataOption,
                numeroEleve: valuesQuery('numeroEleve'),
                numeroOffre: valuesQuery('numeroOffre'),
            });
        },
        instrument(instrument) {
            dispatch({
                type: 'optionInstrument',
                instrument,
                numeroEleve: valuesQuery('numeroEleve'),
                numeroOffre: valuesQuery('numeroOffre'),
            });
        },
    };
}

export default connect(null, mapDispatchToProps)(ElementDeSelectionOption);
