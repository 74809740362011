import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './Styles.module.css';

const PageErreur = () => {
    const { statut } = useParams();
    let message;
    switch (statut) {
        case '400':
            message = "L'opération demandée ne peut aboutir, les informations transmises ne le permettent pas.";
            break;
        case '401':
        case '403':
            message = "Vous n'êtes pas autorisé à accéder à cette page.";
            break;
        case undefined:
        case '404':
            message = "Cette page n'existe pas.";
            break;
        case '500':
            message = 'Le serveur a rencontré une erreur dans le traitement de votre demande.';
            break;
        default:
            break;
    }

    return (
        <div className={styles.container}>
            <div className={styles.notfound}>
                <div className={styles.notfound404}></div>
                <h1>{statut === undefined ? 404 : statut}</h1>
                <h2>Oops! Un problème est survenu.</h2>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default PageErreur;
