/**
 * @author Rajanan Ganesalingam
 * @copyright Horizon-pharma
 * @module Routes
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import { Main, BasicForm } from '../style';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/TextField';
import { POST } from '../../../components/Utils/requete';
import { useHistory } from 'react-router-dom';

const MotDePasseOublie = () => {
    const { register, handleSubmit, setError, clearErrors, errors } = useForm();
    const history = useHistory();
    let { token } = useParams();
    const onSubmit = async (data) => {
        const newData = { ...data, token };
        const response = await POST('/api/saveNewPassword', newData);
        response.success && history.push('/connexion');

        !response.success &&
            setError('onSubmit', {
                types: {
                    message: response.message,
                },
            });
    };

    return (
        <Main>
            <Paper
                style={{
                    padding: '15px',
                    marginTop: '25px',
                    borderRadius: '25px',
                    maxWidth: '400px',
                }}
                elevation={3}
            >
                <h2>Renouvelez votre mot de passe</h2>
                <p>Saisissez votre nouveau mot de passe.</p>
                <BasicForm onSubmit={handleSubmit(onSubmit)}>
                    <Input label="Email" name="email" onChange={() => clearErrors(['onSubmit'])} inputRef={register} />
                    <Input
                        label="Password"
                        name="password"
                        type="password"
                        onChange={() => clearErrors(['onSubmit'])}
                        inputRef={register}
                    />
                    {errors.onSubmit && errors.onSubmit.types.message}
                    <Button>Envoyer le nouveau mot de passe</Button>
                </BasicForm>
            </Paper>
        </Main>
    );
};

export default MotDePasseOublie;
