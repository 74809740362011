import React from 'react';
import { Link } from 'react-router-dom';

import { IconButton, Button } from '@material-ui/core';

import CustomTooltips from '../../components/organism/Tooltip/customTooltip';

const ButtonLivret = (props) => {
    const link = function (balise) {
        return props.url ? <Link to={props.url}>{balise}</Link> : <>{balise}</>;
    };
    const tooltip = function (balise) {
        return props.tooltip ? <CustomTooltips title={props.tooltip}>{balise}</CustomTooltips> : <>{balise}</>;
    };
    if (props.type === 'button') {
        return link(
            <Button
                variant="outlined"
                size="small"
                color={props.color ? props.color : 'primary'}
                className={props.className ? props.className : undefined}
                onClick={props.onClick}
            >
                {props.label}
            </Button>,
        );
    }
    if (props.type === 'icone') {
        return tooltip(
            <IconButton
                className={props.className ? props.className : undefined}
                onClick={props.onClick}
                color={props.color ? props.color : 'primary'}
            >
                {props.label}
            </IconButton>,
        );
    }
    return <></>;
};
export default ButtonLivret;
