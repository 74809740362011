export const tableHeadEleve = [
    { id: 'nomPrenomEleve', label: 'Elève' },
    { id: '', label: 'Atelier(s)' },
    { id: '', label: 'Contact(s)' },
];
export const tableHeadAtelier = [
    { id: 'libelleType', label: 'Type' },
    { id: 'libelleSite', label: 'Site/Salle' },
    { id: 'jour', label: 'Horaires' },
    { id: '', label: 'Élève(s) & Contact(s)' },
];
export const tableHeadAtelierExport = [
    { label: 'Type', value: 'libelleType' },
    { label: 'Site', value: 'libelleSite' },
    { label: 'Salle', value: 'libelleSalle' },
    { label: 'Heure Debut', value: 'heureDebut' },
    { label: 'Heure Fin', value: 'heureFin' },
];
export const tableHeadEleveExport = [
    { label: 'Élève', value: 'nomPrenomEleve' },
    { label: 'Contact - Eleve', value: 'eleveTelephoneMobile' },
];
const annee = 1;
const cycle = 2;
const libelleStatut = 3;
const libelleGroupe = 4;
export const badge = {
    [annee]: { style: 'cWhite bgcBadgeAnneeCycle pBadge  ml1 radius10 nowrap', label: 'Année' },
    [cycle]: { style: 'cWhite bgcBadgeAnneeCycle pBadge  ml1 radius10 nowrap', label: 'Cycle' },
    [libelleStatut]: { style: 'cWhite bgcBadgeAtelier pBadge  ml1 radius10 nowrap', label: '' },
    [libelleGroupe]: { style: 'cWhite bgcBadgeGroupe pBadge  ml1 radius10 nowrap', label: '' },
};
export const individuelFiltre = [
    { id: 2, lib: 'Afficher ind. et col.' },
    { id: 0, lib: 'Afficher uniquement collectif' },
    { id: 1, lib: 'Afficher uniquement individuel' },
];
