/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { CardContent, Button, Select, Checkbox } from '@material-ui/core';

import { GET } from '../../../components/Utils/requete';
import CardComponent from '../../../components/organism/card/card';
import DateInput from '../../../components/organism/Input/date';
import valuesQuery from '../../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const SelectionTypeParcours = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const numeroOffre = useSelector((state) => state.DataInscriptionTarn.eleves);
    const [dateNaissance, setDateNaissance] = useState(null);
    const [displayNone, setDisplayNone] = useState({ card2: 'none', card3: 'none', card4: 'none' });
    const [plusDe7Ans, setPlusDe7ans] = useState('none');
    const [diplomantOuLoisir, setDiplomantOuLoisir] = useState();
    const [danseOuMusique, setDanseOuMusique] = useState({ danse: false, musique: false });
    const [site, setSite] = useState();
    const [dataOffre, setDataOffre] = useState([]);
    const [sites, setSites] = useState([]);
    const [typeOffre, setTypeOffre] = useState([]);
    const [selectTypeOffre, setSelectTypeOffre] = useState();
    const [displayMoinsDe7Ans, setDisplayMoinsDe7Ans] = useState({ display: 'none' });
    const [displayMoinsDe4Ans, setDisplayMoinsDe4Ans] = useState({ display: 'block' });
    const [TextMoinsDe4Ans, setTextMoinsDe4Ans] = useState({ display: 'none' });
    async function recupSites() {
        const listeSites = await GET('/api/sites/liste?full=1&onlyVisible=1', undefined, { redirectOnError: false });
        if (listeSites) {
            setSites(listeSites.data.filter((e) => e.libelleSite !== 'Hors-site'));
        }
    }

    function age(date) {
        if (date === null) {
            setDateNaissance(date);
            changeDisplay(-1);
            props.dataEleve({ dateNaissance: date });
        } else {
            setDateNaissance(date);
            props.dataEleve({ dateNaissance: date.toLocaleDateString('fr-FR') });
            if (
                Math.abs(
                    new Date(new Date(new Date().setMonth(11)).setDate(31) - new Date(date).getTime()).getFullYear() - 1970,
                ) <= 7
            ) {
                if (
                    Math.abs(
                        new Date(new Date(new Date().setMonth(11)).setDate(31) - new Date(date).getTime()).getFullYear() - 1970,
                    ) < 4
                ) {
                    setDisplayMoinsDe4Ans({ display: 'none' });
                    setTextMoinsDe4Ans({ display: 'block' });
                    setPlusDe7ans('none');
                    setDisplayMoinsDe7Ans({ display: 'none' });
                } else {
                    setPlusDe7ans('block');
                    setDisplayMoinsDe7Ans({ display: 'none' });
                    setDisplayMoinsDe4Ans({ display: 'block' });
                    setTextMoinsDe4Ans({ display: 'none' });
                }
            } else {
                setDisplayMoinsDe7Ans({ display: 'block' });
                setDisplayMoinsDe4Ans({ display: 'block' });
                setTextMoinsDe4Ans({ display: 'none' });
                setDisplayNone((prevState) => ({
                    ...prevState,
                    card2: 'contents',
                }));
                setPlusDe7ans('none');
            }
        }
    }
    function sectionScolaire(event) {
        if (event.target.value !== '') {
            setDisplayNone((prevState) => ({
                ...prevState,
                card4: 'contents',
            }));
            if (sites.length === 0) {
                recupSites();
            }
        } else {
            changeDisplay(-1);
        }
    }
    function changeDisplay(key) {
        for (let index = 0; index < Object.keys(displayNone).length; index++) {
            if (index > key) {
                switch (index) {
                    case 0:
                        setDiplomantOuLoisir({ diplomant: false, loisir: false });
                        setDisplayNone((prevState) => ({
                            ...prevState,
                            [Object.keys(displayNone)[index]]: 'none',
                        }));
                        setDataOffre([]);
                        setSite();
                        break;
                    case 1:
                        setDanseOuMusique({ danse: false, musique: false });
                        setDisplayNone((prevState) => ({
                            ...prevState,
                            [Object.keys(displayNone)[index]]: 'none',
                        }));
                        setDataOffre([]);
                        setSite();
                        break;
                    case 2:
                        setSite();
                        setDisplayNone((prevState) => ({
                            ...prevState,
                            [Object.keys(displayNone)[index]]: 'none',
                        }));
                        setDataOffre([]);
                        break;
                    default:
                        break;
                }
            }
        }
    }
    function choixPratique(keys, index) {
        if (diplomantOuLoisir[keys] === true) {
            changeDisplay(0);
        } else {
            setDisplayNone((prevState) => ({
                ...prevState,
                card3: 'contents',
            }));
        }
        setDiplomantOuLoisir((prevState) => ({
            ...prevState,
            [keys]: !diplomantOuLoisir[keys],
        }));
    }
    function choixTypePratique(keys) {
        if (danseOuMusique[keys] === true) {
            changeDisplay(1);
        } else {
            setDisplayNone((prevState) => ({
                ...prevState,
                card4: 'contents',
            }));
            if (sites.length === 0) {
                recupSites();
            }
        }
        setDanseOuMusique((prevState) => ({
            ...prevState,
            [keys]: !danseOuMusique[keys],
        }));
    }
    function BouttonOffre() {
        if (
            Math.abs(
                new Date(new Date(new Date().setMonth(11)).setDate(31) - new Date(dateNaissance).getTime()).getFullYear() -
                    1970,
            ) < 4
        ) {
            return (
                <div className="w50 mt5 ml25">
                    <Link to="/inscription/FormulaireContact" onClick={() => props.dataCategorieEnfant(dataOffre)}>
                        <Button vsize="small" ariant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.w100]}>
                            Contacter le conservatoire
                        </Button>
                    </Link>
                </div>
            );
        }
        if (dataOffre[0] === "pas d'offre") {
            return (
                <div className="w50 mt5 ml25">
                    <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.w100]}>
                        Aucune offre n'est disponible avec les options sélectionnées
                    </Button>
                </div>
            );
        }

        if (site) {
            recupOffre();
            return (
                <div className="w50 mt5 ml25">
                    <Link
                        to={
                            `/inscription/PropositionOffre?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${
                                numeroOffre[+valuesQuery('numeroEleve') - 1].scolarites.length
                            }` +
                            `${valuesQuery('reinscription') ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}` : ''}`
                        }
                        onClick={() => props.dataCategorieEnfant(dataOffre)}
                    >
                        <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.w100]}>
                            Voir les offres
                        </Button>
                    </Link>
                </div>
            );
        } else {
            return <></>;
        }
    }

    async function recupOffre() {
        const ageEleve = Math.abs(
            new Date(new Date(new Date().setMonth(11)).setDate(31) - new Date(dateNaissance).getTime()).getFullYear() - 1970,
        );
        if (dataOffre.length === 0) {
            let response;
            if (ageEleve >= 7) {
                response = await GET(
                    `/api/cotisations/cotisationsCategories?fk_typeOffre=${selectTypeOffre}&certificatMedical=${
                        danseOuMusique.danse ? '1' : '0'
                    }&age=${ageEleve}&site=${site}`,
                    undefined,
                    {
                        redirectOnError: false,
                    },
                );
            } else {
                response = await GET(`/api/cotisations/cotisationsCategories?age=${ageEleve}&site=${site}`, undefined, {
                    redirectOnError: false,
                });
            }

            if (response.data.length === 0) {
                setDataOffre(["pas d'offre"]);
            } else {
                setDataOffre(response.data);
            }
        }
    }
    async function recupTypeOffre() {
        const response = await GET('/api/cotisations/cotisationsTypesOffre');
        const checked = {};
        for (let index = 0; index < response.data.length; index++) {
            checked[response.data[index].libelleTypeOffre] = false;
        }
        setDiplomantOuLoisir(checked);
        setTypeOffre(response.data);
    }
    function disabledCheckboxDiscipline() {
        const initialValue = 0;
        return (
            Object.values(diplomantOuLoisir).reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue,
            ) === 1
        );
    }
    function view() {
        return (
            <div className=" bgcfff h100 flex directionColumn p5">
                <div>
                    <Link to={valuesQuery('reinscription') ? '/accueilResponsable' : '/inscription/ChoixParcours'}>
                        <Button
                            size="small"
                            variant="contained"
                            className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.floatRight]}
                        >
                            {valuesQuery('reinscription') ? 'Retour à votre espace' : 'Retour '}
                        </Button>
                    </Link>
                </div>
                <div className="flex spaceAround h60 alignICenter mt5">
                    <CardComponent className={[css.h100, css.mt5, css.w20, css.border5, css.textCenter]}>
                        <CardContent className={plusDe7Ans === 'none' ? [css.h100] : [css.h100, css.flex, css.directionColumn]}>
                            <h2 className="cGris mb20">Date de naissance</h2>
                            <DateInput
                                classeDiv="block"
                                className={[css.w80]}
                                format="dd/MM/yyyy"
                                disableFuture={true}
                                value={dateNaissance}
                                onChange={(event) => age(event)}
                            />
                            <div className={plusDe7Ans}>
                                <p className="mt15">Niveau scolaire</p>
                                <Select className={css.w80} onChange={(event) => sectionScolaire(event)}>
                                    <option value=""></option>
                                    <option value="1">Moyenne section</option>
                                    <option value="2">Grande section</option>
                                    <option value="3">CP</option>
                                </Select>
                                <p className="fontSmallGrey">En septembre 2022</p>
                            </div>
                        </CardContent>
                    </CardComponent>
                    <div className="fontSize50" style={displayMoinsDe7Ans}>{`>`}</div>
                    <CardComponent className={[css.h100, css.mt5, css.w20, css.border5]} style={displayMoinsDe7Ans}>
                        <CardContent className={[css.h100, css.flex, css.directionColumn]}>
                            <div className={displayNone.card2}>
                                <h2 className="cGris textCenter ">Choix de pratique</h2>
                                <div className="flex directionColumn mt20">
                                    {typeOffre.map((row, index) => (
                                        <div key={index}>
                                            <Checkbox
                                                checked={diplomantOuLoisir[row.libelleTypeOffre]}
                                                disabled={
                                                    disabledCheckboxDiscipline() && !diplomantOuLoisir[row.libelleTypeOffre]
                                                }
                                                onChange={() => {
                                                    setSelectTypeOffre(row.idTypeOffre);
                                                    choixPratique(row.libelleTypeOffre, index);
                                                }}
                                            />
                                            <label>{`Parcours ${row.libelleTypeOffre}`}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </CardComponent>
                    <div className="fontSize50" style={displayMoinsDe7Ans}>{`>`}</div>
                    <CardComponent className={[css.h100, css.mt5, css.w20, css.border5]} style={displayMoinsDe7Ans}>
                        <CardContent className={[css.h100, css.flex, css.directionColumn]}>
                            <div className={displayNone.card3}>
                                <h2 className="cGris textCenter">Type de pratique</h2>
                                <div className="mt20">
                                    <Checkbox
                                        checked={danseOuMusique.danse}
                                        disabled={danseOuMusique.musique}
                                        onChange={() => choixTypePratique('danse')}
                                    />
                                    <label>Danse</label>
                                </div>
                                <div className="mt20">
                                    <Checkbox
                                        checked={danseOuMusique.musique}
                                        disabled={danseOuMusique.danse}
                                        onChange={() => choixTypePratique('musique')}
                                    />
                                    <label>Musique</label>
                                </div>
                            </div>
                        </CardContent>
                    </CardComponent>
                    <div className="fontSize50" style={displayMoinsDe4Ans}>{`>`}</div>
                    <CardComponent
                        className={[css.h100, css.mt5, css.w20, css.border5, css.textCenter]}
                        style={displayMoinsDe4Ans}
                    >
                        <CardContent className={[css.h100]}>
                            <div className={displayNone.card4}>
                                <h2 className="cGris">Site d’enseignement</h2>
                                <p className="mt15">Site</p>
                                <Select
                                    className={css.w80}
                                    onChange={(event) => {
                                        setDataOffre([]);
                                        setSite(event.target.value);
                                    }}
                                >
                                    <option value=""></option>
                                    {sites.map((elem, index) => (
                                        <option key={index} value={elem.idSite}>
                                            {elem.libelleSite}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </CardContent>
                    </CardComponent>
                    <p style={TextMoinsDe4Ans}>
                        Nous ne proposons pas d’offre pour les enfants de moins de 4 ans. <br />
                        Vous pouvez nous contacter pour plus de renseignements.
                    </p>
                </div>
                <BouttonOffre />
            </div>
        );
    }
    useEffect(() => {
        if (!numeroOffre) {
            history.push('/inscription/accueil');
        }
        recupTypeOffre();
    }, []);

    return view();
};

function mapDispatchToProps(dispatch) {
    return {
        dataCategorieEnfant(categorieEnfant) {
            dispatch({ type: 'categoriesEnfants', data: categorieEnfant });
        },
        dataEleve(data) {
            dispatch({ type: 'dataEleve', data: data, numeroEleve: valuesQuery('numeroEleve') });
        },
    };
}

export default connect(null, mapDispatchToProps)(SelectionTypeParcours);
