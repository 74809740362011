export const Lien_profil = 167;
export const Lien_services = 168;
export const Lien_profil_eleve = 169;
export const Lien_atelier = 170;
export const Lien_profil_responsable = 171;
export const Lien_livret_eleve = 172;
export const Lien_liste_de_presences = 173;
export const Envoi_email = 174;

export const MonCompte_lien_profil_eleve = 262;
export const MonCompte_vers_atelier = 263;
export const MonCompte_lien_profil_responsable = 264;
export const MonCompte_lien_livret_de_eleve = 265;
export const MonCompte_lien_liste_de_presences = 266;
export const MonCompte_Envoi_email = 267;
