/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, CardContent, CardActions, Checkbox, IconButton, TextareaAutosize } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { GET, POST } from '../../Utils/requete';
import Card from '../card/card';
import DatePickerRange from '../DatePicker/datePickerRange';
import { semaine } from '../DatePicker/defaultPeriode';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ExcuseEleveEspaceResponsable = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [fichePresences, setFichePresence] = useState([]);
    const [displayDeclarer, setDisplayDeclarer] = useState({});
    const [displayAnnuler, setDisplayAnnuler] = useState({});
    const [checkedAtelier, setCheckedAtelier] = useState([]);
    const [refreshView, setRefreshView] = useState(false);
    const [commentaire, setCommentaire] = useState(null);
    const [atelierExcuser, setAtelierExcuser] = useState([]);
    const [periode, setPeriode] = useState(semaine);

    const date = new Date();
    async function creationFichePresence() {
        const atelierParEleve = [];
        const atelierDeLaSemaine = {};
        for (let index = 0; index < props.data.length; index++) {
            atelierDeLaSemaine[props.data[index].prenomEleve] = {};
            for (let elem = 0; elem < props.data[index].atelier.length; elem++) {
                atelierDeLaSemaine[props.data[index].prenomEleve][`${[props.data[index].atelier[elem].idAtelier]}`] = false;
                const dateAtelier = new Date(date.setDate(date.getDate() - date.getDay() + props.data[index].atelier[elem].jour))
                    .toLocaleDateString()
                    .split('/')
                    .reverse();

                const response = await GET(
                    `/api/presences/parEleve/${props.data[index].fk_eleve}` +
                        `?date=${dateAtelier.join('-')}&fk_atelier=${props.data[index].atelier[elem].idAtelier}&fk_responsable=${
                            user[usurpation].idUtilisateur
                        }`,
                    undefined,
                    {
                        redirectOnError: false,
                    },
                );
                response.data.libelleType = props.data[index].atelier[elem].libelleType;
                atelierParEleve.push(response.data);
            }
        }
        setCheckedAtelier(atelierDeLaSemaine);
        setFichePresence(atelierParEleve);
    }
    function changeDisplay(keys, quelAction) {
        switch (quelAction) {
            case 'declarer':
                if (Object.keys(displayDeclarer).includes(keys)) {
                    for (let index = 0; index < Object.keys(displayDeclarer).length; index++) {
                        if (displayDeclarer[Object.keys(displayDeclarer)[index]] && Object.keys(displayDeclarer)[index] !== keys) {
                            setDisplayDeclarer((prevState) => ({
                                ...prevState,
                                [Object.keys(displayDeclarer)[index]]: 'none',
                            }));
                        }
                        setDisplayDeclarer((prevState) => ({
                            ...prevState,
                            [keys]: displayDeclarer[keys] === 'none' ? 'flex' : 'none',
                        }));
                    }
                    for (let index = 0; index < Object.keys(displayAnnuler).length; index++) {
                        setDisplayAnnuler((prevState) => ({
                            ...prevState,
                            [Object.keys(displayAnnuler)[index]]: 'none',
                        }));
                    }
                } else {
                    setDisplayDeclarer((prevState) => ({
                        ...prevState,
                        [keys]: 'flex',
                    }));
                    for (let index = 0; index < Object.keys(displayAnnuler).length; index++) {
                        setDisplayAnnuler((prevState) => ({
                            ...prevState,
                            [Object.keys(displayAnnuler)[index]]: 'none',
                        }));
                    }
                }
                break;

            case 'annuler':
                if (Object.keys(displayAnnuler).includes(keys)) {
                    for (let index = 0; index < Object.keys(displayAnnuler).length; index++) {
                        if (displayAnnuler[Object.keys(displayDeclarer)[index]] && Object.keys(displayAnnuler)[index] !== keys) {
                            setDisplayAnnuler((prevState) => ({
                                ...prevState,
                                [Object.keys(displayAnnuler)[index]]: 'none',
                            }));
                        }
                        setDisplayAnnuler((prevState) => ({
                            ...prevState,
                            [keys]: displayAnnuler[keys] === 'none' ? 'flex' : 'none',
                        }));
                    }
                    for (let index = 0; index < Object.keys(displayDeclarer).length; index++) {
                        setDisplayDeclarer((prevState) => ({
                            ...prevState,
                            [Object.keys(displayDeclarer)[index]]: 'none',
                        }));
                    }
                } else {
                    setDisplayAnnuler((prevState) => ({
                        ...prevState,
                        [keys]: 'flex',
                    }));
                    for (let index = 0; index < Object.keys(displayDeclarer).length; index++) {
                        setDisplayDeclarer((prevState) => ({
                            ...prevState,
                            [Object.keys(displayDeclarer)[index]]: 'none',
                        }));
                    }
                }
                break;

            default:
                break;
        }
    }
    function updateCheckbox(data, libelleType) {
        const dataCheckbox = checkedAtelier;
        dataCheckbox[data.prenomEleve][libelleType] = !checkedAtelier[data.prenomEleve][libelleType];
        setCheckedAtelier(dataCheckbox);
        setRefreshView(!refreshView);
    }
    function PresencesParAtelier(props) {
        const presenceEleve = fichePresences.filter((e) => e.fk_eleve === props.data.fk_eleve);

        return (
            <div className={`flex directionColumn`}>
                {presenceEleve.map((row, index) => {
                    const atelier = props.data.atelier.filter((e) => e.idAtelier === row.fk_atelier);
                    const dateAtelier = new Date(date.setDate(date.getDate() - date.getDay() + atelier[0].jour)).toLocaleDateString('fr-FR', {
                        weekday: 'long',
                    });
                    let statut = 'non précisé';
                    switch (row.fk_statutPresence) {
                        case 1:
                            statut = 'Excusé';
                            break;
                        case 2:
                            statut = 'Présent';
                            break;
                        case 4:
                            statut = 'Absent';
                            break;
                        default:
                            break;
                    }
                    return (
                        <div key={index} style={props.style} className={props.checkbox ? 'flex alignICenter mt1 ' : `ml5 `}>
                            {props.checkbox ? (
                                <Checkbox
                                    checked={checkedAtelier[props.data.prenomEleve][row.fk_atelier]}
                                    onChange={() => {
                                        updateCheckbox(props.data, row.fk_atelier);
                                    }}
                                />
                            ) : null}
                            {props.checkbox ? (
                                <div className="flex directionColumn ">
                                    <span> {row.libelleType}</span>
                                    <span>{`${dateAtelier} à ${atelier[0].heureDebut}`}</span>
                                </div>
                            ) : (
                                <div className="flex spaceBetween w70 xs_block xs_mb2">
                                    <div>
                                        <span>Cours :</span>
                                        <span> {row.libelleType}</span>
                                    </div>
                                    <div>
                                        <span>Statut :</span>
                                        <span> {statut}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
    function AbsencesParAtelier(props) {
        return (
            <div className={`flex ${props.className}`}>
                {atelierExcuser.length !== 0 ? (
                    atelierExcuser.map((row, index) => {
                        if (row.fk_statutPresence === 1) {
                            return (
                                <div id={index} key={index} style={props.style} className="flex alignICenter mt2">
                                    <IconButton size="small" color="secondary" className={[css.mr5]} onClick={() => annuleExcuse(row, index)}>
                                        <CancelIcon />
                                    </IconButton>
                                    <div className="flex directionColumn">
                                        <span>{row.libelleType}</span>
                                        <span>
                                            {new Date(row.date).toLocaleDateString('fr-FR', {
                                                weekday: 'long',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </span>
                                    </div>
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })
                ) : (
                    <span>Il n'y a pas d'excuse pour cette semaine</span>
                )}
            </div>
        );
    }
    async function saveExcuseEleve(fk_eleve, prenomEleve, nom) {
        const dataEleve = props.data.filter((e) => e.fk_eleve === fk_eleve);
        const atelier = [];
        const testselectAtelier = Object.values(checkedAtelier[dataEleve[0].prenomEleve]);
        const checkAtelierParEleve = checkedAtelier[prenomEleve];
        if (!testselectAtelier.includes(true)) {
            return props.notification({
                message: 'Veuillez sélectionner au moins un cours',
            });
        }
        for (let index = 0; index < Object.keys(checkAtelierParEleve).length; index++) {
            if (checkAtelierParEleve[Object.keys(checkAtelierParEleve)[index]]) {
                atelier.push(dataEleve[0].atelier.filter((e) => e.idAtelier === +Object.keys(checkAtelierParEleve)[index])[0]);
            }
        }
        const data = {
            fk_eleve: fk_eleve,
            atelier: atelier,
            periodeDebut: periode[0].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-'),
            periodeFin: periode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-'),
            fk_responsable: user[usurpation].fk_id,
            nomPrenomResponsable: user[usurpation].nomPrenomUtilisateur,
            commentaire: commentaire,
            nomPrenom: `${nom} ${prenomEleve}`,
        };
        const response = await POST(`/api/presences/absencesEleveAuxAteliers`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            changeDisplay(prenomEleve, 'declarer');
            creationFichePresence();
            setCommentaire('');
        }
    }
    async function recupExcuseParPeriode(dataEleve, datePeriode) {
        setPeriode(datePeriode);
        const response = await GET(
            `api/ateliers/elevesParAteliersParEleve/${dataEleve.fk_eleve}?dateDebut=${datePeriode[0]
                .toLocaleString('fr-FR')
                .split(' ')[0]
                .split('/')
                .reverse()
                .join('-')}&dateFin=${datePeriode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}&fk_responsable=${
                user[usurpation].fk_id
            }`,
            undefined,
            {
                redirectOnError: false,
            },
        );

        setAtelierExcuser(response.data);
    }
    async function annuleExcuse(dataEleve, index) {
        const data = {
            fk_responsable: user[usurpation].fk_id,
            eleve: {
                idPresenceEleve: dataEleve.idPresenceEleve,
                fk_presence: dataEleve.fk_presence,
                fk_eleve: dataEleve.fk_eleve,
                fk_statutPresence: null,
                commentaire: '',
                fk_utilisateur: null,
                dateExcuse: null,
            },
        };
        const response = await POST(`/api/presences/presenceEleve/${dataEleve.idPresenceEleve}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            creationFichePresence();
            atelierExcuser.splice(index, 1);
        }
    }
    function view() {
        return (
            <div className="ml5 mt2">
                {props.data.map((row, index) => (
                    <div key={index} className="mb2 mt2">
                        {row.atelier.length !== 0 ? (
                            <div className="flex directionColumn">
                                <div className="ml2 mb1">
                                    <h4 className="mr5 nomargin mb1">Cours de la semaine pour {row.prenomEleve}</h4>
                                    <Link
                                        className="ml25 mr10 xs_nomargin xs_mr5"
                                        onClick={() => {
                                            changeDisplay(row.prenomEleve, 'declarer');
                                            setPeriode(semaine);
                                        }}
                                        color="primary"
                                    >
                                        Déclarer une absence
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            recupExcuseParPeriode(row, periode);
                                            changeDisplay(row.prenomEleve, 'annuler');
                                            setPeriode(semaine);
                                        }}
                                    >
                                        Annuler une absence
                                    </Link>
                                </div>
                                <PresencesParAtelier
                                    style={{
                                        display: displayDeclarer[row.prenomEleve] ? (displayDeclarer[row.prenomEleve] === 'none' ? 'flex' : 'none') : 'flex',
                                    }}
                                    data={row}
                                    index={index}
                                />
                                <div
                                    style={{
                                        display: displayDeclarer[row.prenomEleve] ? displayDeclarer[row.prenomEleve] : 'none',
                                    }}
                                    className="m2 spaceAround"
                                >
                                    <Card className={css.w35}>
                                        <CardContent className="bgcBleu0f4c81 cWhite textCenter">
                                            <h4 className="mb0 mt2">Absences pour un cours</h4>
                                        </CardContent>
                                        <CardContent>
                                            <h5 className="mb0 mt0 textCenter">Sélectionner les cours </h5>
                                            <PresencesParAtelier checkbox={true} data={row} index={index} className="directionColumn" />
                                            <hr className="w80 "></hr>
                                            <DatePickerRange
                                                className="ml10 mt2 mb2"
                                                onChange={(event) => setPeriode(event)}
                                                value={periode}
                                                placement="topEnd"
                                                disabledDate={'beforeToday'}
                                            />
                                            <span className="textCenter fontSmallGrey ">Le début et la fin de période sont obligatoires</span>
                                            <TextareaAutosize
                                                placeholder="Commentaire"
                                                className={[css.w100]}
                                                aria-label="minimum height"
                                                rowsMin={3}
                                                onChange={(event) => setCommentaire(event.target.value)}
                                                value={commentaire}
                                            />
                                        </CardContent>
                                        <CardActions className={css.center}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => saveExcuseEleve(row.fk_eleve, row.prenomEleve, row.nom)}
                                            >
                                                Validation
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                                <div
                                    style={{
                                        display: displayAnnuler[row.prenomEleve] ? displayAnnuler[row.prenomEleve] : 'none',
                                    }}
                                    className="m2 spaceAround"
                                >
                                    <Card className={css.w35}>
                                        <CardContent className="bgcBleu0f4c81 cWhite textCenter">
                                            <h4 className="mb0 mt2">Absences déclarées</h4>
                                        </CardContent>
                                        <CardContent>
                                            <DatePickerRange
                                                onChange={(event) => recupExcuseParPeriode(row, event)}
                                                value={periode}
                                                placement="topEnd"
                                                disabledDate={'beforeToday'}
                                            />
                                            <span className="fontSmallGrey">Cliquer sur la croix pour annuler une absence.</span>
                                            <AbsencesParAtelier index={index} className="directionColumn" />
                                            <CardActions className={css.center}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => changeDisplay(row.prenomEleve, 'annuler')}
                                                >
                                                    Fermer
                                                </Button>
                                            </CardActions>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        ) : (
                            <h4 className="mr5 nomargin mb1">Il n'y a pas de cours programmé pour {row.prenomEleve}</h4>
                        )}
                    </div>
                ))}
            </div>
        );
    }
    useEffect(() => {
        creationFichePresence();
    }, [props.data]);
    useEffect(() => {
        view();
    }, [refreshView]);
    return view();
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ExcuseEleveEspaceResponsable);
