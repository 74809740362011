/* eslint-disable react-hooks/exhaustive-deps */
/**
 *@author Vijaya Srikar PORALLA
 * @author Viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@material-ui/core';

import Container from '../../components/atoms/Container/container';
import { GET, POST } from '../../components/Utils/requete';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import euroFormate from '../../components/Utils/euroFormate';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { statutFraisCouleur, tableHeadListeFrais } from './consts';

const ListesFraisAgent = (props) => {
    const { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const [dataFrais, setDataFrais] = useState([]);
    const [tableauFiltreAnneeScolaire, setTableauFiltreAnneeScolaire] = useState([]);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    function StatusFrais(props) {
        return (
            <p className="mr10px textCenter cWhite p2 radius10 bgcBleu" style={{ backgroundColor: statutFraisCouleur[props.status] }}>
                {props.libelleFraisStatut}
            </p>
        );
    }
    async function recupDataFrais() {
        const data = {
            idAgent: id,
            anneeScolaire: reducerFiltre.AnneeScolaire,
            listStatuts: [1, 2, 3, 4, 5],
        };
        const response = await POST('/api/utilisateursAdministratifs/listFrais/', data);
        setDataFrais(response.data);
    }
    async function recupFiltre() {
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        setTableauFiltreAnneeScolaire(anneeScolaireFiltre.data);
    }
    useEffect(() => {
        props.addNomPage('Mes frais');
        recupFiltre();
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }, []);
    useEffect(() => {
        recupDataFrais();
    }, [refreshSimple]);
    return (
        <div className="listeContainer">
            <Container>
                <div className="flex alignICenter xs_directionColumn">
                    <SelectSimple
                        tableau={tableauFiltreAnneeScolaire}
                        inputLabel={'Année Scolaire'}
                        indice={'AnneeScolaire'}
                        classe={[css.mr5, css.mb2, css.w30, css.xs_w100, css.xs_mb5]}
                    ></SelectSimple>
                    <Link to="/fiche-frais/0">
                        <Button className={css.xs_mb5} variant="outlined">
                            Nouvelle note de frais
                        </Button>
                    </Link>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadListeFrais}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {setData(props.recherche.fn, dataFrais, props.Page[0].page, props.Page[0].rowsPerPage).map((row, index) => {
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Date demande</div>
                                            <Link to={`/fiche-frais/${row.idFrais}`}>
                                                <DateHeureFormat date={row.dateDemande} />
                                            </Link>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Période</div>
                                            <DateHeureFormat date={row.periode} mois={true} />
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Kilometrage</div>
                                            {row.kilometrage}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Nombre Repas</div>
                                            {row.nombreRepas}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Montant Divers</div>
                                            {euroFormate(row.montantDivers)}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div tableHeadListeFrais>Statut</div>
                                            <StatusFrais status={row.idFraisStatut} libelleFraisStatut={row.libelleFraisStatut} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListesFraisAgent);
