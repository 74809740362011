/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

import { GET, POST } from '../../../components/Utils/requete';
import PaginationTable from '../../../components/organism/Table/Pagination';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import setData from '../../../components/organism/Table/setDataTableau';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import TempCoursFormat from '../../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import { testDroit } from '../../../components/Utils/testDroit';
import ListePresences from '../../../components/organism/Presence/Liste';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import * as fonctionEleve from '../../../fonctionsRoles/eleve';

const tableHeadAtelier = [
    { id: 'type', label: 'Type' },
    { id: 'site', label: 'Site' },
    { id: 'salle', label: 'Salle' },
    { id: 'horaire', label: 'Horaire' },
    { id: 'enseignant', label: 'Enseignant' },
];

const FicheEleve = (props) => {
    let { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const dataFiltre = useSelector((state) => state.SelectSimple);
    const rechargementhPage = useSelector((state) => state.SelectSimple.refreshSimple);
    const historyLoc = useLocation();
    const css = useStylesMaterielUIGlobal();
    const [dataAtelier, setDataAtelier] = useState([]);
    const [AnneeScolaire, setAnneeScolaire] = useState([]);

    function Enseignant(props) {
        const enseignant = props.enseignant.split('|');
        return testDroit(user[usurpation].roleFonction, fonctionEleve.Lien_atelier) ? (
            <Link to={`/fiche-agent/${enseignant[0]}`}>{enseignant[1]}</Link>
        ) : (
            enseignant[1]
        );
    }

    function Enseignants(props) {
        if (props.enseignants) {
            return props.enseignants.split('|-|').map((enseignant) => (
                <>
                    <Enseignant enseignant={enseignant} />
                    <br />
                </>
            ));
        } else return <></>;
    }

    async function recupDataAtelier() {
        const data = {
            fk_anneeScolaire: dataFiltre.AnneeScolaire,
        };
        const response = await POST('/api/eleves/' + id + '/listAteliers', data);

        setDataAtelier(response.data);
        if (AnneeScolaire.length === 0) {
            const requete = await GET('/api/anneesScolaire/list');
            setAnneeScolaire(requete.data);
        }
    }

    useEffect(() => {
        recupDataAtelier();
    }, [rechargementhPage]);
    useEffect(() => {
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }, []);

    return (
        <div>
            <h3 className="none xs_block textCenter mt5">Atelier</h3>
            <SelectSimple tableau={AnneeScolaire} indice={'AnneeScolaire'}></SelectSimple>
            <PaginationTable data={dataAtelier} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadAtelier}></TableHeadCustom>
                    <TableBody>
                        {setData(props.recherche.fn, dataAtelier, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                            (row, index) => {
                                return (
                                    <TableRow className={css.trbody} key={index}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Type</div>
                                            {testDroit(user[usurpation].roleFonction, fonctionEleve.Lien_atelier) ? (
                                                <Link
                                                    to={`/fiche-atelier/${row.idAtelier}`}
                                                    onClick={() => {
                                                        props.savePathAtelier(historyLoc.pathname);
                                                    }}
                                                >
                                                    {row.libelleType}
                                                </Link>
                                            ) : (
                                                row.libelleType
                                            )}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Site</div>
                                            {row.libelleSite}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Salle</div>
                                            {row.libelleSalle}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>jour</div>
                                            <TempCoursFormat heureDebut={row.heureDebut} heureFin={row.heureFin} jour={row.jour} />
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Enseignant</div>
                                            <Enseignants enseignants={row.enseignants} />
                                        </TableCell>
                                    </TableRow>
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {window.matchMedia('(max-width: 576px)').matches ? null : <ListePresences fk_eleve={id} />}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FicheEleve);
