/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, TextareaAutosize, Checkbox, FormControl, Select } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import DialogCustom from '../../components/organism/Dialog/dialog';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ModuleDeReinscription = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const css = useStylesMaterielUIGlobal();
    const usurpation = user.userC;
    const [open, setOpen] = useState({ open: false });
    const [checkecedCourMercredi, setCheckecedCourMercredi] = useState(false);
    const [eleve, setEleve] = useState();
    const [parametresDroit, setParametresDroit] = useState(0);
    const [dataFiltre, setDataFiltre] = useState({
        communes: [],
        etablissement: [],
        niveau: [],
    });
    const [nonScolarise, setNonScolarise] = useState(false);
    const [commentaireInscription, setCommentaireIncription] = useState('');
    const [cssText, setCssText] = useState('none');
    const [scolaritesExterne, setScolaritesExterne] = useState({
        idScolarite: 0,
        fk_eleve: null,
        fk_anneeScolaire: null,
        fk_etablissementScolaire: null,
        fk_niveauScolaire: null,
        coursMercrediMatin: 0,
    });
    const [statusSI, setStatutSI] = useState([]);
    async function requeteSitesEtablissements() {
        const siteFiltre = await GET('/api/eleves/SE_etablissements?mode=communes', undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            communes: creationTableauFiltre(siteFiltre.data, '', 'commune'),
        }));
        const niveauFiltre = await GET('/api/eleves/SE_niveaux');
        setDataFiltre((prevState) => ({
            ...prevState,
            niveau: creationTableauFiltre(niveauFiltre.data, 'idNiveau', 'libelle'),
        }));
    }
    async function requeteEtablissements(commune) {
        let communes = commune;
        if (commune === 'Autre') communes = ` Autre`;

        const etablissementsFiltre = await GET(`/api/eleves/SE_etablissements?mode=etablissements&commune=${communes}`, undefined, {
            redirectOnError: false,
        });
        setDataFiltre((prevState) => ({
            ...prevState,
            etablissement: creationTableauFiltre(etablissementsFiltre.data, 'idEtablissement', 'libelleEtablissement'),
        }));
    }
    function validationDroitImage(row) {
        if (!row.dataEleve.image) row.dataEleve.image = true;
        else delete row.dataEleve.image;
    }
    async function renouvellementOffre(dataOffre, numeroEleve, numeroScolarite, nomPrenomEleve) {
        if (!eleve.dataEleve.condition) return setCssText('red wavy underline');
        const data = dataOffre;
        data.commentaireInscription = commentaireInscription;
        data.fk_anneeScolaire = props.reinscription.valeur;
        data.commentaire = '';
        data.idScolarite = 0;
        data.fk_statut = 11;
        data.fk_idScolariteParent = null;
        delete data.color;
        if (dataOffre.passage && dataOffre.fk_offreSuperieure !== null) data.fk_offre = dataOffre.fk_offreSuperieure;

        const infoEleve = props.reinscriptionData.filter((e) => e.fk_eleve === data.fk_eleve);
        let response;
        if (nonScolarise) {
            const dataScolarite = scolaritesExterne;
            dataScolarite.fk_eleve = data.fk_eleve;
            dataScolarite.fk_anneeScolaire = props.reinscription.valeur;
            response = await POST('/api/eleves/' + data.fk_eleve + '/scolaritesExterne/null', dataScolarite, {
                redirectOnError: false,
            });

            if (response.success) {
                infoEleve[0].ajout_SE = false;
            }
        }
        if (eleve.dataEleve.droitImage === undefined && (+parametresDroit === 0 || (+parametresDroit === 1 && eleve.dataEleve.image))) {
            const droit = {
                fk_eleve: data.fk_eleve,
                etat: 1,
                utilisateur: user[usurpation].idUtilisateur,
                fk_anneeScolaire: props.reinscription.valeur,
            };

            response = await POST('/api/droitImage/', droit);
            eleve.dataEleve.droitImage = true;
        }

        response = await POST('/api/eleves/' + data.fk_eleve + '/scolaritesInterne/0', data, {
            redirectOnError: false,
        });
        if (response.success) {
            props.reinscriptionData[numeroEleve].scolariteInterne[numeroScolarite].color = '#FFBF80';
            props.reinscriptionData[numeroEleve].scolariteInterne[numeroScolarite].reinscription = true;
            setNonScolarise(false);
            dataOffre.nomPrenomEleve = nomPrenomEleve;
            const dataMail = {
                email: user[usurpation].email,
                offre: dataOffre,
            };
            await POST('/api/cotisations/mailConfirmationReinscription', dataMail, {
                redirectOnError: false,
            });
            setOpen({ open: false });
            setCommentaireIncription();
            setCssText('none');
        }
    }
    async function requetestatusSI() {
        const requete = await GET('/api/ateliers/ateliersStatut');
        setStatutSI(requete.data);
    }
    function nombreOffreRenouveller(row) {
        let offreNonRenouveller = 0;
        for (let index = 0; index < row.length; index++) {
            if (row[index].reinscription) offreNonRenouveller = offreNonRenouveller + 1;
        }
        return 3 - offreNonRenouveller;
    }
    function findStatut(idStatut) {
        const index = statusSI.findIndex((e) => e.idStatut === idStatut);
        if (index !== -1) return statusSI[index].libelleStatut;
        return '';
    }

    function validationCondition(row) {
        if (!row.dataEleve.condition) row.dataEleve.condition = true;
        else delete row.dataEleve.condition;
    }

    function Modal() {
        if (open.open) {
            const select = (
                <div className="p2 border1darkgrey radius10">
                    <h4 className="textCenter nomargin mb2">Scolarité éducation nationale</h4>
                    {Object.keys(dataFiltre).map((e, i) => {
                        let label = '';
                        switch (e) {
                            case 'communes':
                                label = { label: 'Ville', state: 'communes' };
                                break;
                            case 'etablissement':
                                label = {
                                    label: 'Établissement',
                                    state: 'fk_etablissementScolaire',
                                };
                                break;
                            case 'niveau':
                                label = {
                                    label: `Niveau scolaire ${props.reinscription.libelleAnnee}`,
                                    state: 'fk_niveauScolaire',
                                };
                                break;
                            default:
                                break;
                        }
                        return (
                            <div className="mb5" key={i}>
                                <span>{label.label} :</span>
                                <FormControl className={[css.w100]}>
                                    <Select
                                        native={true}
                                        onChange={(event) =>
                                            label.state === 'communes'
                                                ? requeteEtablissements(event.target.value)
                                                : setScolaritesExterne((prevState) => ({
                                                      ...prevState,
                                                      [label.state]: +event.target.value,
                                                  }))
                                        }
                                    >
                                        <option value={''}></option>
                                        {dataFiltre[e].map((elem, index) => {
                                            return (
                                                <option key={index} value={elem.id}>
                                                    {elem.libelle}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    })}
                    <div className="flex alignICenter">
                        <Checkbox
                            checked={checkecedCourMercredi}
                            onChange={() => {
                                setCheckecedCourMercredi(!checkecedCourMercredi);
                                setScolaritesExterne((prevState) => ({
                                    ...prevState,
                                    coursMercrediMatin: checkecedCourMercredi ? 0 : 1,
                                }));
                            }}
                        />
                        <label>Ecole mercredi matin</label>
                    </div>
                </div>
            );
            let content;
            switch (open.content) {
                case 'renouvellement':
                    content = (
                        <div className="flex directionColumn ">
                            {eleve.dataEleve.ajout_SE && (
                                <div className="flex alignICenter  mr5">
                                    <Checkbox checked={nonScolarise} onChange={() => setNonScolarise(!nonScolarise)} />
                                    <label className="w100">{`je connais la scolarité éducation nationale ${props.reinscription.libelleAnnee}`}</label>
                                </div>
                            )}
                            {eleve.dataEleve.ajout_SE && nonScolarise && select}
                            <p className="nomargin mb2 textCenter"> Je désire continuer cette offre pour l’année prochaine.</p>
                            <TextareaAutosize
                                rowsMin={4}
                                placeholder="Commentaire à la réinscription."
                                onChange={(event) => setCommentaireIncription(event.target.value)}
                            />
                            <div className="flex mt2 spaceAround">
                                <Button color="primary" variant="outlined" size="small" onClick={() => setOpen({ open: true, content: 'CGUInscription' })}>
                                    Poursuivre
                                </Button>
                                <Button color="secondary" variant="outlined" size="small" onClick={() => setOpen({ open: false })}>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                    );
                    break;
                case 'CGUInscription':
                    content = (
                        <>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.CGUInscription.message,
                                }}
                            ></div>
                            <div className="flex alignICenter spaceBetween ">
                                <div className="w70">
                                    {eleve.dataEleve.droitImage === undefined && +parametresDroit === 1 && (
                                        <div className="flex alignICenter">
                                            <Checkbox checked={eleve.dataEleve.image} onChange={() => validationDroitImage(eleve)} />
                                            <label>
                                                J'autorise le conservatoire de musique du Tarn à utiliser mon image pour les besoins de sa communication.
                                            </label>
                                        </div>
                                    )}
                                    <div className="flex alignICenter ">
                                        <Checkbox checked={eleve.dataEleve.condition} onChange={() => validationCondition(eleve)} />
                                        <div className="flex directionColumn">
                                            <label style={{ textDecoration: cssText }}>
                                                Je comprends et j'accepte que chaque inscription validée donnera lieu à la facturation de 15€ de frais de
                                                dossier qui s'ajoutent aux frais de scolarité.
                                            </label>
                                            {cssText !== 'none' && (
                                                <label style={{ color: 'red', fontSize: '0.8em' }}>
                                                    Vous devez accepeter les conditions pour poursuivre votre inscription.
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => renouvellementOffre(eleve.data, eleve.numeroEleve, eleve.numeroScolarite, eleve.dataEleve.nomPrenomEleve)}
                                >
                                    Je confirme l'inscription
                                </Button>
                            </div>
                        </>
                    );
                    break;

                default:
                    content = <></>;
                    break;
            }
            return <DialogCustom open={open.open} onClose={() => setOpen({ open: false })} children={content} maxWidth="md" />;
        } else {
            return <></>;
        }
    }
    async function parametreDroitImage() {
        const droitImage = await GET(`/api/parametres/getValeur/droitImage`);
        setParametresDroit(droitImage.data.valeur);
    }
    useEffect(() => {
        requeteSitesEtablissements();
        requetestatusSI();
        parametreDroitImage();
    }, []);
    return (
        <div className="ml2 mr2">
            {Modal()}
            <p className="fontSize0_8em ">
                Ce module vous permet de sélectionner les offres pour l'année prochaine (3 offres maximum par élève). Vous pouvez :<br /> - Soit vous réinscrire
                pour une offre déjà existante
                <br /> - Et/ou ajouter une nouvelle offre
                <br />
                {props.inscription && (
                    <Link
                        to={`/inscription/ChoixParcours?&reinscription=true`}
                        className={[css.floatRight]}
                        onClick={() => props.dataCategorieEnfant(props.reinscription.valeur)}
                    >
                        <Button>Cliquez ici pour inscrire un nouvel élève</Button>
                    </Link>
                )}
            </p>
            {props.reinscriptionData.map((row, i) => {
                return (!row.dateAbandon && row.scolariteInterne.length !== 0) || props.inscription ? (
                    <div key={i} className="flex directionColumn mt5 w100">
                        <h4 className="nomargin textCenter mb1">{row.nomPrenomEleve}</h4>
                        {row.scolariteInterne.map((elem, index) => (
                            <div key={index} className="flex spaceBetween border1darkgrey radius10 mb2 p1" style={{ backgroundColor: elem.color }}>
                                <div className={elem.reinscription ? 'flex directionColumn w100' : 'flex directionColumn '}>
                                    <span>
                                        {`Offre : ${elem.libelleOffre}`}
                                        {elem.fk_instrument !== null && ` (${elem.libelleInstrument})`}
                                    </span>
                                    <div className="flex spaceBetween">
                                        <span>
                                            {`Site : ${elem.libelleSite}`}
                                            {elem.fk_enseignant !== null && ` (${elem.nomPrenomEnseignant})`}
                                        </span>
                                        {elem.reinscription && elem.reinscription.length !== 0 && (
                                            <span>{`${findStatut(elem.fk_statut)} pour l'année ${props.reinscription.libelleAnnee}`}</span>
                                        )}
                                    </div>
                                </div>
                                {elem.color === '#fff' &&
                                    (nombreOffreRenouveller(row.scolariteInterne) > 0 ? (
                                        <div className="flex directionColumn mr5">
                                            <Link
                                                onClick={() => {
                                                    setEleve({ data: elem, numeroEleve: i, numeroScolarite: index, dataEleve: row });
                                                    setOpen({ open: true, content: 'renouvellement' });
                                                }}
                                            >
                                                Se réinscrire pour l'année prochaine
                                            </Link>
                                        </div>
                                    ) : (
                                        " Vous avez déjà 3 offre pour l'année prochaine"
                                    ))}
                            </div>
                        ))}
                        {nombreOffreRenouveller(row.scolariteInterne) > 0 ? (
                            <Link
                                to={`/inscription/ChoixOffres?numeroEleve=1&reinscription=true&idEleve=${row.fk_eleve}`}
                                className={[css.textCenter]}
                                onClick={() => props.dataCategorieEnfant(props.reinscription.valeur)}
                            >
                                <Button>Cliquez ici pour ajouter une offre</Button>
                            </Link>
                        ) : null}
                        <hr className="w80"></hr>
                    </div>
                ) : (
                    <div key={i} className="flex directionColumn mt5 w100">
                        <h4 className="nomargin textCenter mb1">{row.nomPrenomEleve}</h4>
                        <p>{`Scolarité inactive en ${user.libelleAnneeScolaire}. Réinscription possible à partir du 1er juin`}</p>
                        <hr className="w80"></hr>
                    </div>
                );
            })}
            <hr className="mt5"></hr>
            <p className="fontSize0_8em">
                Merci de mettre à jour : <br />
                <ul style={{ listStyleType: 'circle', listStylePosition: 'outside' }}>
                    <li>
                        Vos informations de fiscalité dans l’onglet <Link to={`/responsableInformation/${user[usurpation].fk_id}?cotisation`}>cotisation</Link>
                    </li>
                    <li>
                        Le justificatif de domicile dans l’onglet <Link to={`/responsableInformation/${user[usurpation].fk_id}?general`}>général</Link>.
                    </li>
                </ul>
            </p>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataCategorieEnfant(anneeScolaire) {
            dispatch({ type: 'nouvelInscription', anneeScolaire: anneeScolaire });
        },
    };
};

export default connect(null, mapDispatchToProps)(ModuleDeReinscription);
