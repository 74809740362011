/* eslint-disable react-hooks/exhaustive-deps */
/**
 * TopBar
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Cookies from 'js-cookie';
import newLogo from '../../../assets/CMDT-LOGO-PRINCIPAL-BLANC.png';

import Usurpation from '../Usurpation/Usurpation';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const TopBar = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const color = process.env.REACT_APP_ENVIRONNEMENT === 'TEST' ? '#881C1C' : '#0f4c81';
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function deconnection() {
        Cookies.remove('user');
        history.push('/connexion');
    }
    function profil() {
        if (user[usurpation].role !== 'Responsable') {
            history.push(`/fiche-agent/${user[usurpation].fk_id}`);
        } else {
            history.push(`/fiche-responsable/${user[usurpation].fk_id}`);
        }
    }

    return (
        <div className={`w100 ${window.matchMedia('(max-width: 576px)').matches ? '' : 'fixed index10'}`}>
            <AppBar position="static" style={{ backgroundColor: color }} elevation={0}>
                <Toolbar>
                    <Typography component={'span'} variant="h6" className={css.grow1}>
                        <div className="flex alignICenter" style={{ backgroundColor: color }}>
                            <div className="nomargin mr1 xs_none">
                                <Link to="/accueil">
                                    <img src={newLogo} className="w4em" alt="" />
                                </Link>
                            </div>
                            <div className="flex">
                                <span className=" xs_fontSize0_6em xs_nomargin mt0_5em">{[props.nomPage]}</span>
                            </div>
                        </div>
                    </Typography>
                    <Usurpation />
                    <div>
                        <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    profil();
                                }}
                            >
                                Profil
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    deconnection();
                                }}
                            >
                                Déconnexion
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        removeUsuration() {
            dispatch({ type: 'REMOVE_USURPATION' });
        },
    };
}
export default connect(null, mapDispatchToProps)(TopBar);
