import { GET } from '../requete';

export default async function anneeEnCour_Inscription() {
    const annee_Libelle_Id = [];
    const cle = ['anneeEnCours', 'anneeInscriptions'];
    const response = await GET('/api/parametres/list', undefined, { redirectOnError: false });
    const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
    for (let elem = 0; elem < cle.length; elem++) {
        const index = response.data.findIndex((e) => e.cle === cle[elem]);
        const objectSelect = {
            id: response.data[index].valeur,
            libelle: filtreAnneeScolaire.data.filter((e) => e.idAnneeScolaire === +response.data[index].valeur)[0].libelleAnneeScolaire,
        };
        annee_Libelle_Id.push(objectSelect);
    }
    const suppressionDoublon = (tableau) => [...new Set(tableau.map((el) => JSON.stringify(el)))].map((e) => JSON.parse(e));
    return suppressionDoublon(annee_Libelle_Id);
}
