/* eslint-disable arrow-body-style */
/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function reponseRecherche(
    reponseRecherche = {
        fn: (items) => {
            return items;
        },
    },
    action,
) {
    if (action.type === 'reponseRecherche') {
        const newreponseRecherche = action.reponse;
        return newreponseRecherche;
    }
    if (action.type === 'removeRecherche') {
        const newreponseRecherche = {
            fn: (items) => {
                return items;
            },
        };
        return newreponseRecherche;
    }

    return reponseRecherche;
}
