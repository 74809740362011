/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CodeIcon from '@material-ui/icons/Code';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const TriToggle = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [check, setCheck] = useState({
        premierCheck: false,
        deuxiemeCheck: true,
        troisiemeCheck: false,
    });
    function changeChecked(keys) {
        for (let index = 0; index < Object.keys(check).length; index++) {
            if (check[Object.keys(check)[index]] && Object.keys(check)[index] !== keys && keys !== undefined) {
                setCheck((prevState) => ({
                    ...prevState,
                    [Object.keys(check)[index]]: false,
                }));
            }
            setCheck((prevState) => ({
                ...prevState,
                [keys]: true,
            }));
        }
    }
    function returnValue(quelCheckBox) {
        props.value(quelCheckBox, props.index, props.objectKey);
    }
    useEffect(() => {
        changeChecked(props.defaultValue);
    }, []);
    return (
        <div className="block">
            <h4 className="nomargin mt1">{props.titre}</h4>
            <div className={`flex alignICenter  ` + props.className} style={props.style}>
                <span className="mr2">{props.label1}</span>
                <div className="block radius40 bgcGrisClair">
                    <Checkbox
                        checked={check.premierCheck}
                        checkedIcon={<CheckCircleIcon className={css.cVertCheck} />}
                        icon={<CheckCircleIcon />}
                        onChange={() => changeChecked('premierCheck')}
                        onClick={() => returnValue('premierCheck')}
                    />
                    <Checkbox
                        checked={check.deuxiemeCheck}
                        checkedIcon={<CodeIcon className={css.cbleuCheck} />}
                        icon={<CodeIcon />}
                        onChange={() => changeChecked('deuxiemeCheck')}
                        onClick={() => returnValue('deuxiemeCheck')}
                    />
                    <Checkbox
                        checked={check.troisiemeCheck}
                        checkedIcon={<CancelIcon className={css.cRouge} />}
                        icon={<CancelIcon />}
                        onChange={() => changeChecked('troisiemeCheck')}
                        onClick={() => returnValue('troisiemeCheck')}
                    />
                </div>
                <span className="ml2">{props.label2}</span>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}

export default connect(null, mapDispatchToProps)(TriToggle);
