export function calculDuree(debut, fin) {
    if (debut && fin) {
        const minuteD = parseInt(debut.substring(0, 2), 10) * 60 + parseInt(debut.substring(3, 5), 10);
        const minuteF = parseInt(fin.substring(0, 2), 10) * 60 + parseInt(fin.substring(3, 5), 10);
        return minuteF - minuteD;
    }
}

export function durationToMinutes(d) {
    if (d) {
        let a = d.split(':');
        return +a[0] * 60 + +a[1];
    }
    return 0;
}

export function minutesToDuration(duration) {
    const heures = Math.floor(+duration / 60);
    const minutes = +duration % 60;
    return `${heures.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${minutes.toLocaleString(undefined, {
        minimumIntegerDigits: 2,
    })}`;
}
