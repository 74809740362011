/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TextareaAutosize, FormControl, Select } from '@material-ui/core';

import { POST } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import DiscreteSlider from '../../../components/organism/NotationParPoint/notationParPoint';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { inputInformation, anneeInstrument, select, exclure, verifKeysGeneral, ObservationExclusion } from '../consts';

const ModuleGeneralView = (props) => {
    const css = useStylesMaterielUIGlobal();
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const [listeEnseignant, setListeEnseignant] = useState([]);
    const [dataInput, setDataInput] = useState({
        libelle_anneeScolaire: '',
        eleveNomPrenom: '',
        enseignantNomPrenom: '',
        EnseignantReferent: '',
        fk_enseignantFM: '',
        fk_enseignantSupplementaire: '',
        anneeDisciplinePrincipale: '',
        anneeDisciplineAutre: '',
    });
    const [valueInstrument, setValueInstrument] = useState([{ pratiqueCollective: [] }, { niveauFM: [] }]);
    function viewGrille() {
        return <DiscreteSlider niveau={props.data.niveauAcquisition} disabled={props.disabled} />;
    }
    function updateState(keys, valueI, chargeData) {
        if (!chargeData) {
            props.addSaveData({ [keys]: valueI });
        }
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    async function recupFiltre() {
        const enseignantFiltre = await POST('/api/utilisateursAdministratifs/enseignants');
        setListeEnseignant(enseignantFiltre.data);
    }

    function setNiveauFM(id, value) {
        const valueDeLaVariable = props.data.niveauFM;
        valueDeLaVariable.splice(id, 1, value);
        setValueInstrument((prevState) => ({
            ...prevState,
            niveauFM: valueDeLaVariable,
        }));
        props.addSaveData({ niveauFM: valueDeLaVariable });
    }
    function setPratiqueCollective(id, value) {
        const valueDeLaVariable = props.data.pratiqueCollective;
        valueDeLaVariable.splice(id, 1, value);
        setValueInstrument((prevState) => ({
            ...prevState,
            pratiqueCollective: valueDeLaVariable,
        }));
        props.addSaveData({ pratiqueCollective: valueDeLaVariable });
    }
    function ChampsObservation() {
        const TableauObservation = [];
        for (let index = 0; index < Object.keys(props.data).length; index++) {
            if (!exclure.concat(verifKeysGeneral).concat(ObservationExclusion).includes(Object.keys(props.data)[index])) {
                TableauObservation.push(
                    <div>
                        <h3 className="xs_textCenter">{Object.keys(props.data)[index]}</h3>
                        <hr></hr>
                        <TextareaAutosize
                            className={css.textareaML30pxW80MB1emMT0}
                            rowsMin={5}
                            value={dataInput[Object.keys(props.data)[index]]}
                            onChange={(event) => updateState([Object.keys(props.data)[index]], event.target.value)}
                        />
                    </div>,
                );
            }
        }
        return <>{TableauObservation}</>;
    }
    function LinkView(props) {
        let linkOuSpan = <span className="w40 mr5">{props.data.indice}</span>;
        if (props.data.label !== 'Année Scolaire') {
            linkOuSpan = (
                <Link className="w40" to={`${props.data.url}${props.data.id}`}>
                    {props.data.indice}
                </Link>
            );
        }
        return (
            <div key={props.data.key} className="textRight alignICenter flex mb2">
                <label className="w30">{props.data.label}:</label>
                {linkOuSpan}
            </div>
        );
    }
    function view() {
        return (
            <>
                <div>
                    <h3 className="xs_textCenter">Informations sur l'élève</h3>
                    <hr></hr>
                    {inputInformation.map((row, i) => (
                        <LinkView
                            data={{
                                key: i,
                                indice: props.data[row.indice],
                                id: props.data[row.id],
                                label: row.label,
                                url: row.url,
                            }}
                        />
                    ))}
                    {select.map((row, i) =>
                        Object.keys(props.data).includes(row.indice) ? (
                            <div key={i} className="textRight alignICenter flex mb2">
                                <label className="w30">{row.label}:</label>
                                <FormControl className={[css.nomargin, css.ml30px, css.w40]}>
                                    <Select
                                        value={props.data[row.indice]}
                                        onChange={(event) => {
                                            updateState(row.indice, event.target.value);
                                        }}
                                        disabled={props.disabled}
                                    >
                                        <option value={''}></option>
                                        {listeEnseignant.map((row, index) => {
                                            return (
                                                <option key={index} value={row.idUtilisateur}>
                                                    {row.nomPrenomUtilisateur}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : (
                            <></>
                        ),
                    )}
                </div>
                <div className="mt5">
                    <h3 className="xs_textCenter">Informations sur les scolarités</h3>
                    <hr></hr>
                    <div>
                        <h5 className="textCenter mb1 ml2">Nombre d’années d’instrument(s)</h5>
                        {anneeInstrument.map((e, i) => (
                            <div className="textRight alignICenter flex mb10px">
                                <label className="w30">{e.label}</label>
                                <Input
                                    value={dataInput[e.indice]}
                                    className={[css.nomargin, css.ml30px, css.w40]}
                                    onChange={(event) => updateState(e.indice, event.target.value)}
                                    disabled={props.disabled}
                                />
                            </div>
                        ))}
                    </div>
                    {props.data.niveauFM ? (
                        <>
                            <h5 className="textCenter mb1 ml2">Niveau FM</h5>
                            {props.data.niveauFM.map((row, i) => (
                                <div className="textRight alignICenter flex mb10px">
                                    <label className="w30">-</label>
                                    <Input
                                        value={valueInstrument[1].niveauFM[i]}
                                        className={[css.nomargin, css.ml30px, css.w40]}
                                        onChange={(event) => setNiveauFM(i, event.target.value)}
                                        disabled={props.disabled}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {props.data.pratiqueCollective ? (
                        <>
                            <h5 className="textCenter mb1 ml2">Pratiques collectives</h5>
                            {props.data.pratiqueCollective.map((row, i) => (
                                <div className="textRight alignICenter flex mb10px">
                                    <label className="w30">-</label>
                                    <Input
                                        value={valueInstrument[0].pratiqueCollective[i]}
                                        className={[css.nomargin, css.ml30px, css.w40]}
                                        onChange={(event) => setPratiqueCollective(i, event.target.value)}
                                        disabled={props.disabled}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <hr className="mt5"></hr>
                <div className="flex xs_directionColumn">
                    <div className="flex directionColumn w50 mr5 xs_w100">{ChampsObservation()}</div>
                    <div className="w40 mr5 xs_w100">
                        <h3>Acquisitions de fin de Cycle</h3>
                        <hr></hr>
                        <div className="xs_ml5">{viewGrille()}</div>
                    </div>
                </div>
            </>
        );
    }
    useEffect(() => {
        recupFiltre();
    }, []);
    useEffect(() => {
        const champsInstrument = valueInstrument;
        for (let index = 0; index < Object.keys(props.data).length; index++) {
            if (!verifKeysGeneral.includes(Object.keys(props.data)[index])) {
                updateState(Object.keys(props.data)[index], props.data[Object.keys(props.data)[index]], true);
            } else {
                champsInstrument.splice(Object.keys(props.data)[index] === 'pratiqueCollective' ? 0 : 1, 1, {
                    [Object.keys(props.data)[index]]: props.data[Object.keys(props.data)[index]],
                });
            }
        }
        setValueInstrument(champsInstrument);
    }, [props]);
    useEffect(() => {
        view();
    }, [refresh]);
    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        addSaveData(data) {
            dispatch({ type: 'saveDataGeneral', data });
        },
    };
}

export default connect(null, mapDispatchToProps)(ModuleGeneralView);
