import React from 'react';
import PhoneInput from 'react-phone-input-2';
import formatNumeroTel from '../../Utils/formatNumeroTel';
import 'react-phone-input-2/lib/plain.css';

const TelephoneInput = (props) => {
    return (
        <div className={props.className}>
            <PhoneInput
                disabled={props.disabled}
                country="fr"
                onlyCountries={['fr']}
                style={props.style}
                value={formatNumeroTel(props.value)}
                onChange={props.onChange}
                countryCodeEditable={false}
                containerStyle={props.containerStyle ? props.containerStyle : { width: '200px' }}
            />
            <div className="flex directionColumn">
                <span className={props.styleSpan ? props.styleSpan : 'fontSmallGrey'}>
                    Merci de saisir un numéro sans le 0 initial
                </span>
                <span className="fontSize0_8em" style={{ color: 'red' }}>
                    {props.errorMessage}
                </span>
            </div>
        </div>
    );
};
export default TelephoneInput;
