/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 * @author Viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    TextareaAutosize,
    Paper,
    FormControl,
    Input,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Edit from '@material-ui/icons/Edit';
import Container from '../../components/atoms/Container/container';
import { GET, POST, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import dateSave from '../../components/Utils/dateSave';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import DatePickerRange from '../../components/organism/DatePicker/datePickerRange';
import { semaine } from '../../components/organism/DatePicker/defaultPeriode';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { statutAbsenceCouleur, tableHeadAbsence } from './consts';

const MesAbsences = (props) => {
    let { id } = useParams();
    const [dataAbsence, setDataAbsence] = useState([]);
    const [open, setOpen] = useState(false);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const user = useSelector((state) => state.Utilisateur);
    const [periode, setPeriode] = useState(semaine);
    const [tableauFiltre, setTableauFiltre] = useState({
        status: [],
        anneeScolaire: [],
        objet: [],
    });
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const css = useStylesMaterielUIGlobal();
    const [dataInput, setDataInput] = useState({
        dateCreated: '',
        dateStart: '',
        dateEnd: '',
        motif: '',
        heuresAnnulees: 0,
        reportHeures: 0,
        reportDetail: '',
    });
    const [idAbsenceEdit, setIdAbsenceEdit] = useState(0);
    const [disabledSoumettre, setDisabledSoumettre] = useState(false);
    async function DelDraftedAbs(idAbs) {
        await DELETE(`/api/absences/${idAbs}`);
        recupDataAbsence();
    }
    async function recupDataAbsence() {
        const data = {
            idAgent: id,
            anneeScolaire: reducerFiltre.AnneeScolaire,
        };
        const response = await POST('/api/absences/listAbsences/', data);
        setDataAbsence(response.data);
    }
    async function ajoutAbsence(absStatus) {
        if (reducerFiltre.AbsenceObjet === '' || periode === null) {
            return props.notification({ message: 'Veuillez remplir les champs date de debut, date de fin et le motif de la demande' });
        }
        const data = {
            idAbsence: idAbsenceEdit,
            fk_anneeScolaire: user.idAnneeScolaire,
            fk_absencesStatut: absStatus,
            dateDemande: dateSave(),
            fk_user: id,
            fk_absencesObjet: reducerFiltre.AbsenceObjet,
            dateDebut: periode[0].toLocaleString().split(' ')[0].split('/').reverse().join('-'),
            dateFin: periode[1].toLocaleString().split(' ')[0].split('/').reverse().join('-'),
            heuresAnnulees: dataInput.heuresAnnulees ? dataInput.heuresAnnulees : 0,
            reportDemande: 0,
            reportHeures: dataInput.reportHeures ? dataInput.reportHeures : 0,
            reportDetail: dataInput.reportDetail,
            motif: dataInput.motif,
            avisDirection: '',
        };

        if (tableauFiltre.objet.filter((e) => e.idAbsenceObjet === +reducerFiltre.AbsenceObjet)[0].reportHeures) {
            if ((typeof +data.heuresAnnulees !== 'number' || !data.reportDetail) && (typeof +data.reportHeures !== 'number' || !data.reportDetail)) {
                return props.notification({
                    message: 'Vous devez renseigner les detail du report et les heures reportés ou annulé',
                });
            }
        }
        await POST('/api/absences/' + idAbsenceEdit, data, {
            redirectOnError: false,
        });
        setDisabledSoumettre(true);
        handleClose();
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        recupDataAbsence();
        setIdAbsenceEdit(0);
    }
    function updateState(keys, valueI) {
        const negatifNombre = ['heureAnnuler', 'reportHeures'];
        if (negatifNombre.includes(keys) && valueI < 0) {
            return props.notification({ message: 'veuillez mettre une valeur positive' });
        }
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function clearModalData() {
        setDataInput((prevState) => ({
            ...prevState,
            dateCreated: '',
            dateStart: '',
            dateEnd: '',
            motif: '',
            heuresAnnulees: 0,
            reportHeures: 0,
            reportDetail: '',
        }));
    }
    const handleClose = () => {
        setOpen(false);
        clearModalData();
    };
    function setFiltre(fk_absencesObjet) {
        props.filtre([{ AbsenceObjet: fk_absencesObjet }, { AnneeScolaire: user.idAnneeScolaire }]);
    }
    function StatutAbsence(props) {
        const edit = function () {
            if (props.data.fk_absencesStatut === 1) {
                return (
                    <>
                        <IconButton
                            color="primary"
                            className={css.p2}
                            onClick={() => {
                                setIdAbsenceEdit(props.data.idAbsence);
                                setFiltre(props.data.fk_absencesObjet);
                                setDataInput((prevState) => ({
                                    ...prevState,
                                    dateStart: props.data.dateDebut,
                                    dateEnd: props.data.dateFin,
                                    motif: props.data.motif,
                                    heuresAnnulees: props.data.heuresAnnulees,
                                    reportHeures: props.data.reportHeures,
                                    reportDetail: props.data.reportDetail,
                                }));
                                setOpen(true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                        {!window.matchMedia('(max-width: 576px)').matches && (
                            <IconButton
                                color="secondary"
                                className={css.p2}
                                onClick={() => {
                                    DelDraftedAbs(props.data.idAbsence);
                                }}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        )}
                    </>
                );
            }
        };
        return (
            <>
                <p className="textCenter p5 radius10" style={{ backgroundColor: statutAbsenceCouleur[props.data.fk_absencesStatut] }}>
                    {props.data.libelleAbsenceStatut}
                </p>
                {edit()}
            </>
        );
    }
    async function recupFiltre() {
        const statusFiltre = await GET('/api/absences/absencesStatuts');
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        const objetFiltre = await GET('/api/absences/absencesObjets');
        setTableauFiltre((prevState) => ({
            ...prevState,
            status: statusFiltre.data,
            anneeScolaire: anneeScolaireFiltre.data,
            objet: objetFiltre.data,
        }));
    }
    useEffect(() => {
        recupFiltre();
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        props.addNomPage('Mes absences');
    }, []);
    useEffect(() => {
        recupDataAbsence();
    }, [refreshSimple]);

    return (
        <div className="listeContainer">
            <Dialog open={open} onClose={() => handleClose()} maxWidth={'md'} fullWidth={true}>
                <DialogContent>
                    <DialogContentText>
                        <h2 className="flex">Demande d'autorisation absence</h2>
                        <div className="flex alignICenter textRight mb10px mt10px xs_directionColumn">
                            <label className="w40 textLeft xs_w100">Periode de congé</label>
                            <DatePickerRange onChange={(event) => setPeriode(event)} value={periode} placement="bottomEnd" />
                        </div>
                        <div className="flex alignICenter textRight mb10px mt10px xs_directionColumn">
                            <label className="w40 textLeft xs_w100">Motif de la demande</label>
                            <SelectSimple tableau={tableauFiltre.objet} indice={'AbsenceObjet'} classe={css.w60} native={true}></SelectSimple>
                        </div>
                        <div className="flex alignICenter textRight mb10px mt10px xs_directionColumn">
                            <label className="w40 textLeft xs_w100">Argumentaire</label>
                            <TextareaAutosize
                                className={css.w60}
                                aria-label="minimum height"
                                rowsMin={4}
                                value={dataInput.motif}
                                onChange={(e) => {
                                    updateState('motif', e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex  alignICenter spaceAround xs_directionColumn">
                            <div className="w40   mb10px  xs_w100">
                                <label className="mr5">Heures Reportées</label>
                                <Input
                                    type="number"
                                    value={dataInput.reportHeures}
                                    className={[css.nomargin]}
                                    onChange={(e) => {
                                        if (e.target.value >= 0) updateState('reportHeures', e.target.value);
                                    }}
                                />
                            </div>
                            <span>ou</span>
                            <div className="w40   mb10px  xs_w100">
                                <label className="mr5">Heures Annulées</label>
                                <Input
                                    type="number"
                                    value={dataInput.heuresAnnulees}
                                    className={[css.nomargin]}
                                    onChange={(e) => {
                                        if (e.target.value >= 0) updateState('heuresAnnulees', e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex alignICenter textRight mb10px mt10px xs_directionColumn">
                            <label className="w40 textLeft xs_w100">Detail du report</label>
                            <TextareaAutosize
                                className={css.w60}
                                aria-label="minimum height"
                                rowsMin={4}
                                value={dataInput.reportDetail}
                                onChange={(e) => {
                                    updateState('reportDetail', e.target.value);
                                }}
                            />
                        </div>
                        <div className="xs_flex xs_directionColumn">
                            <Button
                                className={[css.mr2, css.xs_mb2]}
                                variant="outlined"
                                onClick={() => {
                                    ajoutAbsence(1);
                                }}
                            >
                                Brouillon
                            </Button>
                            <Button
                                disabled={disabledSoumettre}
                                className={[css.mr2, css.xs_mb2]}
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    ajoutAbsence(2);
                                }}
                            >
                                Soumettre
                            </Button>
                            <Button
                                className={css.mr2}
                                onClick={() => {
                                    handleClose();
                                }}
                                variant="outlined"
                                color="secondary"
                            >
                                Annuler
                            </Button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Container>
                <div>
                    <SelectSimple
                        tableau={tableauFiltre.anneeScolaire}
                        inputLabel={'Année Scolaire'}
                        indice={'AnneeScolaire'}
                        classe={[css.w25, css.mr2, css.xs_w100]}
                    ></SelectSimple>
                    <TableRecherche
                        label="Recherche par objet"
                        tableau={dataAbsence}
                        className={[css.w50, css.nomargin, css.mr2, css.xs_mb2, css.xs_w100]}
                        indice={['libelleAbsenceObjet']}
                    ></TableRecherche>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setDisabledSoumettre(false);
                            setFiltre('');
                            setOpen(true);
                            updateState('dateStart', new Date());
                        }}
                    >
                        Faire une demande d'absence
                    </Button>
                </div>
                <PaginationTable data={props.recherche.fn(dataAbsence)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadAbsence}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataAbsence, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Date Demande</div>
                                            <DateHeureFormat date={row.dateDemande} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.w30, css.xs_w100]} component="th" scope="row">
                                            <div>Objet</div>
                                            <td>
                                                <p className="cGris">{`${row.libelleAbsenceObjet} :`}</p>
                                                {row.motif}
                                            </td>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Avis direction</div>
                                            {row.avisDirection}
                                        </TableCell>
                                        <TableCell className={[css.td, css.w10, css.xs_w100]} component="th" scope="row">
                                            <div>Date de début</div>
                                            <DateHeureFormat date={row.dateDebut} />
                                        </TableCell>
                                        <TableCell className={[css.td, css.w10, css.xs_w100]} component="th" scope="row">
                                            <div>Date de fin</div>
                                            <DateHeureFormat date={row.dateFin} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div>Statut</div>
                                            <FormControl className={[css.directionRow, css.textCenter]}>
                                                <StatutAbsence data={row} />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataAbsence)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MesAbsences);
