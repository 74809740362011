import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { IconButton } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const LienLivret = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    if (props.data.fk_livret === null || props.data.fk_enseignant === null || props.data.fk_statut !== 1) {
        return <></>;
    }

    if (props.data.fk_livret === 0 && props.data.fk_anneeScolaire !== user.idAnneeScolaire) {
        return <></>;
    }
    if (user[usurpation].role === 'Responsable' && (props.data.fk_livret === 0 || props.data.statutLivret === 1)) {
        return <></>;
    }

    let couleur = '';
    if (props.data.fk_livret !== 0) {
        if (props.data.statutLivret === 1) {
            couleur = 'orange';
        } else {
            couleur = '#8dae51';
        }
    } else {
        couleur = '#df3207';
    }

    return (
        <p className={props.className}>
            <Link to={`/fiche-livret/${props.data.fk_livret}/${props.data.idScolarite}`}>
                <CustomTooltips title="Éditer le livret.">
                    <IconButton className={props.data.className ? props.data.className : [css.p0, css.floatRight]}>
                        <MenuBookIcon style={{ color: couleur }} />
                    </IconButton>
                </CustomTooltips>
            </Link>
        </p>
    );
};
export default connect(null, null)(LienLivret);
