/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @copyright Horizon-Pharma
 */

export default function setSelect(props) {
    const user = props.user;
    const selectMultiple = props.selectMultiple;
    const saveFiltre = props.saveFiltre;
    const selectSimple = props.selectSimple;
    const tableauSaveFiltre = props.tableauSaveFiltre;
    if (props.selectSimple) {
        for (let index = 0; index < Object.keys(selectSimple).length; index++) {
            props.filtre([{ [Object.keys(selectSimple)[index]]: '' }]);
        }
    }
    if (props.selectMultiple) {
        for (let index = 0; index < Object.keys(selectMultiple).length; index++) {
            props.filtreMultiple([{ [Object.keys(selectMultiple)[index]]: [] }]);
        }
    }
    if (saveFiltre.saveOk) {
        for (let elem = 0; elem < tableauSaveFiltre[0].simple.length; elem++) {
            props.filtre(
                (selectSimple[tableauSaveFiltre[0].simple[elem]] = saveFiltre.filtreSimple[tableauSaveFiltre[0].simple[elem]]),
            );
            if (!tableauSaveFiltre[0].simple[elem].AnneeScolaire) {
                props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
            }
        }
        for (let elem = 0; elem < tableauSaveFiltre[1].multiple.length; elem++) {
            props.filtreMultiple([
                {
                    [tableauSaveFiltre[1].multiple[elem]]: saveFiltre.filtreMultiple[tableauSaveFiltre[1].multiple[elem]],
                },
            ]);
        }
    } else {
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }
}
