import React from 'react';
import { TimePicker } from '@material-ui/pickers';

const HeureInput = (props) => {
    return (
        <div className={props.styleDiv ? props.styleDiv : 'textRight algnICenter flex mb2'}>
            <label className={props.styleLabel ? props.styleLabel : 'w25'}>{props.label}</label>
            <TimePicker
                clearable
                ampm={false}
                label={props.inputLabel}
                disabled={props.disabled}
                value={
                    props.value === '' || props.value === null
                        ? null
                        : typeof props.value === 'object'
                        ? props.value
                        : new Date(`01/01/1970 ${props.value}`)
                }
                className={props.className}
                onChange={props.onChange}
                minutesStep={5}
            />
            {props.children}
        </div>
    );
};
export default HeureInput;
