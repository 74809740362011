/* eslint-disable no-else-return */
/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function saveFiltre(
    savefiltre = {
        listeEleves: {
            saveOk: false,
            filtreSimple: {
                Style: '',
                AnneeScolaire: '',
                Offre: '',
                Cycle: '',
                Annee: '',
                Statut: '',
                Instrument: '',
            },
            filtreMultiple: {
                Site: [],
            },
        },
        listeAteliers: {
            saveOk: false,
            filtreSimple: {
                AnneeScolaire: '',
                AtelierIndividuel: '',
            },
            filtreMultiple: {
                Site: [],
                GroupeAteliers: [],
                SousGroupeAteliers: [],
                Salles: [],
                Enseignants: [],
                Cycles: [],
                Annees: [],
                Groupes: [],
            },
        },
    },
    action,
) {
    if (action.type === 'save') {
        const newSave = savefiltre;
        // eslint-disable-next-line no-plusplus
        if (action.filtre === 'multiple') {
            if (action.liste === 'atelier') {
                newSave.listeAteliers.filtreMultiple[Object.keys(action.save)] = Object.values(action.save)[0];
                newSave.listeAteliers.saveOk = true;
            }
            if (action.liste === 'eleve') {
                newSave.listeEleves.filtreMultiple[Object.keys(action.save)] = Object.values(action.save)[0];
                newSave.listeEleves.saveOk = true;
            }
        }
        if (action.filtre === 'simple') {
            if (action.liste === 'atelier') {
                newSave.listeAteliers.filtreSimple[Object.keys(action.save)] = Object.values(action.save)[0];
                newSave.listeAteliers.saveOk = true;
            }
            if (action.liste === 'eleve') {
                newSave.listeEleves.filtreSimple[Object.keys(action.save)] = Object.values(action.save)[0];
                newSave.listeEleves.saveOk = true;
            }
        }
        return newSave;
    }
    if (action.type === 'removeSave') {
        const newSave = savefiltre;
        if (action.liste === 'atelier') {
            newSave.listeAteliers = {
                saveOk: false,
                filtreSimple: {
                    AnneeScolaire: '',
                    AtelierIndividuel: '',
                },
                filtreMultiple: {
                    Site: [],
                    GroupeAteliers: [],
                    SousGroupeAteliers: [],
                    Salles: [],
                    Enseignants: [],
                    Cycles: [],
                    Annees: [],
                    Groupes: [],
                },
            };
        }
        if (action.liste === 'eleve') {
            newSave.listeEleves = {
                saveOk: false,
                filtreSimple: {
                    Style: '',
                    AnneeScolaire: '',
                    Offre: '',
                    Cycle: '',
                    Annee: '',
                    Statut: '',
                    Instrument: '',
                },
                filtreMultiple: {
                    Site: [],
                },
            };
        }
        return newSave;
    }
    return savefiltre;
}
