/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
import SiteIcon from '@material-ui/icons/AccountBalance';
import AgentIcon from '@material-ui/icons/PersonOutline';

import { GET, POST } from '../../components/Utils/requete';
import Container from '../../components/atoms/Container/container';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import euroFormate from '../../components/Utils/euroFormate';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { testDroit } from '../../components/Utils/testDroit';
import DatePickerRange from '../../components/organism/DatePicker/datePickerRange';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHead, urlFiltre, tableExport } from './const';
import * as fonctionCotisation from '../../fonctionsRoles/cotisationEtReglement';

const Reglement = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const reducerFiltreMultiple = useSelector((state) => state.SelectMultiple);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [montantTotal, setMontantTotal] = useState([]);
    const [dataReglement, setDataReglement] = useState([]);
    const [tableauFiltre, setTableauFiltre] = useState({
        site: [],
        mode: [],
        anneeScolaire: [],
        agents: [],
    });
    const [periode, setPeriode] = useState([]);

    function VisuelreglementAgent(props) {
        const site = props.site ? (
            <>
                <SiteIcon color="primary" className={[css.fontSize14px, css.mr1]} /> {props.site}
            </>
        ) : null;
        const agent = props.agent ? (
            <>
                <AgentIcon color="secondary" className={[css.fontSize14px, css.mr1]} />
                {props.agent}
            </>
        ) : null;
        return (
            <>
                {site}
                {props.site && props.agent ? ` / ` : ''}
                {agent}
            </>
        );
    }
    function MontantReglements() {
        return (
            <div>
                <h4>Montant des reglements</h4>
                <table className="w40">
                    <tbody>
                        {montantTotal.map((row, index) => (
                            <tr key={index}>
                                <td>{row.site ? row.site : 'Site non renseigné'}</td>
                                <td className="textRight">{euroFormate(row.montant)}</td>
                                <br />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    function compare(a, b) {
        const siteA = a.site != null ? a.site.toUpperCase() : '';
        const siteB = b.site != null ? b.site.toUpperCase() : '';
        if (siteA < siteB) {
            return -1;
        }
        if (siteA > siteB) {
            return 1;
        }
        return 0;
    }
    async function recupData(date) {
        const data = {
            fk_anneeScolaire: reducerFiltre.AnneeScolaire,
            fk_site: reducerFiltreMultiple.Site,
            fk_mode: reducerFiltreMultiple.Modes,
            fk_user: reducerFiltreMultiple.Agents,
        };
        const response = await POST('/api/reglements/list/', data);
        let dataRequete = [];
        const tableauSiteMontant = [];
        const tableauDesMontantParSite = [];
        let calculMontantTotal = 0;
        if (date) {
            for (let index = 0; index < response.data.length; index++) {
                if (
                    date[0].setHours(0, 0, 0) <= new Date(response.data[index].date).getTime() &&
                    date[1].setHours(23, 59, 59) >= new Date(response.data[index].date).getTime()
                ) {
                    dataRequete.push(response.data[index]);
                }
            }
        } else {
            dataRequete = response.data;
        }
        for (let index = 0; index < dataRequete.length; index++) {
            calculMontantTotal = +calculMontantTotal + +dataRequete[index].montant;
            tableauSiteMontant.push({
                site: dataRequete[index].sites,
                montant: dataRequete[index].montant,
            });
            dataRequete[index].date = DateHeureFormat({ date: dataRequete[index].date });
        }
        for (let index = 0; index < tableauSiteMontant.length; index++) {
            if (tableauDesMontantParSite.length > 0) {
                const indexSite = tableauDesMontantParSite.findIndex((e) => e.site === tableauSiteMontant[index].site);
                if (indexSite !== -1) {
                    tableauDesMontantParSite[indexSite].montant = tableauDesMontantParSite[indexSite].montant + tableauSiteMontant[index].montant;
                } else {
                    tableauDesMontantParSite.push({
                        site: tableauSiteMontant[index].site,
                        montant: tableauSiteMontant[index].montant,
                    });
                }
            } else {
                tableauDesMontantParSite.push({
                    site: tableauSiteMontant[index].site,
                    montant: tableauSiteMontant[index].montant,
                });
            }
        }
        tableauDesMontantParSite.sort(compare);
        tableauDesMontantParSite.push({
            site: 'Total',
            montant: calculMontantTotal,
        });
        setDataReglement(dataRequete);
        setMontantTotal(tableauDesMontantParSite);
    }

    async function recupFiltre() {
        if (tableauFiltre.site.length === 0) {
            for (let index = 0; index < urlFiltre.length; index++) {
                const requete = await GET(urlFiltre[index].url);
                setTableauFiltre((prevState) => ({
                    ...prevState,
                    [urlFiltre[index].label]: requete.data,
                }));
            }
        }
    }

    async function getCorrespondances() {
        const data = user[usurpation].antennes.map((e) => `fk_antenne[]=${e.fk_antenne}`);
        const correspondances = await GET('/api/sites/correspondances?' + data.join('&'), undefined, {
            redirectOnError: false,
        });
        props.filtreMultiple([{ Site: correspondances.data }]);
    }

    useEffect(() => {
        recupFiltre();
        props.addNomPage('Liste reglements');
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        props.removeFiltreMultiple();
        getCorrespondances();
    }, []);

    useEffect(() => {
        recupData();
        props.resetPagination();
    }, [refreshSimple, refreshMultiple]);

    return (
        <div className="listeContainer">
            <Container>
                <div className="xs_flex xs_directionColumn ">
                    <SelectSimple
                        tableau={tableauFiltre.anneeScolaire}
                        inputLabel={'Année Scolaire'}
                        indice={'AnneeScolaire'}
                        classe={[css.ml2, css.mb2, css.w20, css.xs_w80]}
                        liste={'absence'}
                    ></SelectSimple>
                    <SelectMultiple
                        tableau={tableauFiltre.mode}
                        inputLabel={'Mode'}
                        indice={'Modes'}
                        classe={[css.ml2, css.mb2, css.w20, css.xs_w80]}
                    ></SelectMultiple>
                    <SelectMultiple
                        tableau={tableauFiltre.site}
                        inputLabel={'Site'}
                        indice={'Site'}
                        classe={[css.ml2, css.mb2, css.w20, css.xs_w80]}
                        disabled={!testDroit(user[usurpation].roleFonction, fonctionCotisation.Trie_site_liste_reglement)}
                    ></SelectMultiple>
                    <SelectMultiple
                        tableau={tableauFiltre.agents}
                        inputLabel={'Agents'}
                        indice={'Agents'}
                        classe={[css.ml2, css.mb2, css.w20, css.xs_w80]}
                        disabled={!testDroit(user[usurpation].roleFonction, fonctionCotisation.trie_sur_agent)}
                    ></SelectMultiple>
                </div>
                <DatePickerRange
                    className="mt2 mb2"
                    onChange={(event) => {
                        setPeriode(event);
                        recupData(event);
                    }}
                    value={periode}
                />
                <div className="flex alignICenter ">
                    <TableRecherche
                        label="Responsable/Agent/Site-Reglement"
                        tableau={dataReglement}
                        className={[css.ml2, css.w40, css.xs_w80, css.lg_w100]}
                        indice={['date', 'nomPrenomResponsable', 'siteReglement', 'nomPrenomAdministratif', 'sites', 'libelleMode']}
                    ></TableRecherche>
                    {!window.matchMedia('(max-width: 1080px)').matches ? (
                        <div className="ml2">
                            <ExportExcel data={dataReglement} head={tableExport} nomFichier="Liste Reglement" />
                        </div>
                    ) : null}
                </div>
                <PaginationTable data={props.recherche.fn(dataReglement)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {setData(
                                props.recherche.fn,
                                dataReglement,
                                props.Page[0].page,
                                props.Page[0].rowsPerPage,
                                props.orderState,
                                props.orderByState,
                            ).map((row, index) => {
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Date</div>
                                            {row.date}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Site</div>
                                            {row.sites}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Responsable</div>
                                            <Link to={`/fiche-responsable/${row.idUtilisateur}`}>{row.nomPrenomResponsable}</Link>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Montant / Mode</div>
                                            {`${euroFormate(row.montant)} / ${row.libelleMode}`}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Quittance</div>
                                            {row.quittance}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.nomPrenomAdministratif ? <div>Site Reglement / Nom Agent</div> : null}
                                            <VisuelreglementAgent site={row.siteReglement} agent={row.nomPrenomAdministratif} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataReglement)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <MontantReglements />
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
        resetPagination() {
            dispatch({ type: 'resetPagination' });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Reglement);
