/**
 * Point d'entrée du site
 *
 * les reducer doivent être déclarer ici avant d'être utilisé
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import PathURL from './components/organism/Route/Route';
import DatePickerLocal from '../src/components/organism/DateLocal/DatePickerLocal';
import ClearCacheComponent from './ClearCache';
import CookieRGPD from '../src/components/organism/cookieConsent/cookieConsent';

/*  import des reducer */

import Onglet from './reducer/onglet';
import NomPage from './reducer/nomPage';
import Pagination from './reducer/pagination';
import ReponseRecherche from './reducer/reponseRecherche';
import OrderBy from './reducer/orderBy';
import Order from './reducer/order';
import SelectSimple from './reducer/selectSimple';
import SelectMultiple from './reducer/selectMultiple';
import Utilisateur from './reducer/utilisateur';
import SaveFiltre from './reducer/saveFiltre';
import FraisJour from './reducer/fraisJour';
import PreviousPath from './reducer/previousPath';
import LivretDonnees from './reducer/livretDonnees';
import GrilleNotation from './reducer/grilleNotationData';
import Inscription from './reducer/inscription';
import Notification from './reducer/notification';
import EditModuleLivret from './reducer/editModuleLivret';
import StructureTable from './reducer/structureTable';
import DataInscriptionTarn from './reducer/inscription-tarn';

import './styles/CSS_rsuite.less';
import './styles/App.scss';

const store = createStore(
    combineReducers({
        Onglet,
        NomPage,
        Pagination,
        ReponseRecherche,
        OrderBy,
        Order,
        SelectSimple,
        SelectMultiple,
        Utilisateur,
        SaveFiltre,
        FraisJour,
        PreviousPath,
        LivretDonnees,
        GrilleNotation,
        Inscription,
        Notification,
        EditModuleLivret,
        StructureTable,
        DataInscriptionTarn,
    }),
);
function App() {
    ClearCacheComponent();
    return (
        <Provider store={store}>
            <DatePickerLocal>
                <PathURL />
            </DatePickerLocal>
            <CookieRGPD />
        </Provider>
    );
}

export default App;
