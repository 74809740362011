import { useEffect } from 'react';
import packageJson from '../package.json';

function ClearCacheComponent() {
    useEffect(() => {
        const latestVersionDate = localStorage.getItem('buildDate');
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = latestVersionDate < currentVersionDate ? true : false;
        if (shouldForceRefresh || latestVersionDate === undefined) {
            refreshCacheAndReload(packageJson.buildDate);
        }
    }, []);

    const refreshCacheAndReload = (build) => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (const name of names) {
                    caches.delete(name);
                }
            });

            localStorage.setItem('buildDate', build);
        }

        // delete browser cache and hard reload
        window.location.reload();
    };
}

export default ClearCacheComponent;
