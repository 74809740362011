/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { CardContent, Button } from '@material-ui/core';

import { GET } from '../../../components/Utils/requete';
import CardComponent from '../../../components/organism/card/card';
import valuesQuery from '../../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ChoixOffres = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();

    const numeroOffre = useSelector((state) => state.DataInscriptionTarn.eleves);
    const [dataOffre, setDataOffre] = useState([]);

    async function recupOffre() {
        const response = await GET('/api/cotisations/cotisationsCategories', undefined, { redirectOnError: false });

        setDataOffre(response.data);
    }
    useEffect(() => {
        recupOffre();
    }, []);
    useEffect(() => {
        if (!numeroOffre) {
            if (valuesQuery('reinscription')) {
                history.push(`/accueilResponsable`);
            } else {
                history.push('/inscription/accueil');
            }
        }
    }, []);
    if (!numeroOffre) {
        return <></>;
    }
    return (
        <div className=" bgcfff h100 flex directionColumn p5">
            {valuesQuery('finalisation') ? (
                <></>
            ) : (
                <div>
                    <Link to={valuesQuery('reinscription') ? '/accueilResponsable' : '/inscription/ChoixParcours'}>
                        <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.floatRight]}>
                            {valuesQuery('reinscription') ? 'Retour à votre espace' : 'Retour '}
                        </Button>
                    </Link>
                </div>
            )}

            <h1 className="textCenter">Offres de parcours - Conservatoire du Tarn</h1>
            <div className="flex space-between h70">
                {dataOffre.map((row, index) => (
                    <div
                        key={index}
                        className={`radius10 p0_3 bgcGris mr0_5 mb2 ${row.categorieEnfant.length === 1 ? 'w20' : 'w' + 20 * row.categorieEnfant.length}`}
                    >
                        <h2 className="nomargin cWhite textCenter ">{row.libelleCategorie}</h2>
                        <div className="flex h80 ">
                            {row.categorieEnfant.map((ligne, i) => (
                                <div key={i} className="mr1 w100 ">
                                    <CardComponent className={[css.h100, css.mt5]} style={{ backgroundColor: ligne.couleur }}>
                                        <CardContent className={[css.h100]}>
                                            <h3 className="textCenter cWhite mb0">{ligne.libelleEnfantCategorie}</h3>
                                            <p className="fontSmallWhite textCenter">{ligne.descriptionEnfant}</p>
                                            <div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: ligne.descriptionAge,
                                                    }}
                                                    className="cWhite textCenter mb5"
                                                ></div>
                                                <div className="flex justifyContantCenter">
                                                    <div className="trangleLeft "></div>
                                                    <div className="bgcfffOpacity0_3 flex alignICenter ">
                                                        <span className="cWhite">{ligne.descriptionPrix}</span>
                                                    </div>
                                                    <div className="trangleRight "></div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardComponent>
                                    <div className=" positionRelative left40 top-5 w15  flex spaceAround ">
                                        <Link
                                            to={
                                                `/inscription/ChoixScolarite?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${
                                                    numeroOffre[+valuesQuery('numeroEleve') - 1].scolarites.length
                                                }` +
                                                `${
                                                    valuesQuery('reinscription')
                                                        ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}`
                                                        : '&parcours=toutesOffres'
                                                }` +
                                                `${valuesQuery('finalisation') ? '&finalisation=true' : ''}`
                                            }
                                            onClick={() => props.dataCategorieEnfant(ligne)}
                                        >
                                            <Button size="small" variant="contained" className={[css.BcWhite, css.radius25, css.ml5]}>
                                                S'inscrire
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <p className="textCenter">
                Tous ces parcours sont présentés à titre indicatif. La fréquence, la durée et le nombre de cours sont adaptés à l’âge et au niveau de chaque
                élève ={'>'}&nbsp;
                <a target="_blank" rel="noreferrer" href="https://www.cmdtarn.fr/musique.html">
                    voir règlement des études.
                </a>
                <br /> *tarifs indicatifs pour les élèves de moins de 26 ans issus d'un territoire adhérent, hors frais d'inscription.
            </p>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        dataCategorieEnfant(categorieEnfant) {
            dispatch({ type: 'categoriesEnfants', data: categorieEnfant });
        },
    };
}

export default connect(null, mapDispatchToProps)(ChoixOffres);
