/* eslint-disable import/prefer-default-export */
export const roles = [
    { id: 'SuperAdmin', lib: 'SuperAdmin' },
    { id: 'Administratif', lib: 'Administratif' },
    // { id: 'Responsable', lib: 'Responsable' },
    { id: 'Enseignant', lib: 'Enseignant' },
    { id: 'Chargé de Formation', lib: 'Chargé de Formation' },
    { id: 'Opérateur extérieur', lib: 'Opérateur extérieur' },
];
export const Jour = [
    { id: 1, lib: 'Lundi' },
    { id: 2, lib: 'Mardi' },
    { id: 3, lib: 'Mercredi' },
    { id: 4, lib: 'Jeudi' },
    { id: 5, lib: 'Vendredi' },
    { id: 6, lib: 'Samedi' },
    { id: 7, lib: 'Dimanche' },
];
export const rolePMT = [
    { id: 1, lib: 'Père' },
    { id: 2, lib: 'Mère' },
    { id: 3, lib: 'Tuteur' },
];
export const genre = [
    { id: 1, lib: 'Féminin' },
    { id: 2, lib: 'Masculin' },
];
export const StatutFrais = [
    { id: 1, lib: 'Demande mémorisée' },
    { id: 2, lib: 'Demande soumise' },
    { id: 3, lib: 'Instruction en cours' },
    { id: 4, lib: 'Demande acceptée' },
    { id: 5, lib: 'Demande refusée' },
];
export const civilite = [
    { id: 1, lib: 'Madame' },
    { id: 2, lib: 'Monsieur' },
];
export const socialCateg = [
    { id: 1, lib: 'Agriculteurs' },
    { id: 2, lib: "Artisans, Commerçants, Chefs d'entreprise" },
    { id: 3, lib: 'Cadres, professions intellectuelles supérieures' },
    { id: 4, lib: 'Employés' },
    { id: 5, lib: 'Ouvriers (y compris ouvrier agricole)' },
    { id: 6, lib: 'Professions intermédiaires (contremaître, techniciens)' },
    { id: 7, lib: 'Retraités' },
    { id: 8, lib: 'Sans activité' },
];
export const tranche = [
    { tranche: 1, limiteBasse: 0, limiteHaute: 300 },
    { tranche: 2, limiteBasse: 301, limiteHaute: 500 },
    { tranche: 3, limiteBasse: 501, limiteHaute: 990 },
    { tranche: 4, limiteBasse: 991, limiteHaute: 2200 },
    { tranche: 5, limiteBasse: 2201, limiteHaute: 5900 },
    { tranche: 6, limiteBasse: 5900, limiteHaute: 9999999 },
];
export const color = [
    '#34568B',
    '#FF6F61',
    '#6B5B95',
    '#88B04B',
    '#92A8D1',
    '#cc7876',
    '#955251',
    '#B565A7',
    '#009B77',
    '#ffc107',
    '#317d82',
    '#2196F3',
    '#87CEEB',
    '#795548',
    '#00bcd4',
    '#607d8b',
    '#4CAF50',
    '#8bc34a',
    '#3f51b5',
    '#4f4b25',
    '#ff9800',
    '#e91e63',
    '#9c27b0',
    '#98DDDE',
    '#009688',
    '#9e9e9e',
    '#616161',
    '#7a6161',
    '#ddffdd',
    '#ffffcc',
    '#ddffff',
];
