/**
 * @author Rajanan Ganesalingam
 * @copyright Horizon-pharma
 * @module Routes
 */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import { Main, Link, BasicForm } from './style';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/TextField';
import { POST, GET } from '../../components/Utils/requete';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import accueilResponsable from '../../assets/operaAccueil.jpg';
import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const Connexion = (props) => {
    const { register, handleSubmit, setError, clearErrors, errors } = useForm();
    const css = useStylesMaterielUIGlobal();
    const [message, setMessage] = useState();
    const [inscription, setIncription] = useState([]);
    const history = useHistory();
    const onSubmit = async (data) => {
        props.removeNote();
        const response = await POST('/api/login', data, { redirectOnError: false });
        if (response.success === true) {
            props.connexionUtilisateur(response.data);
            Cookies.set('user', props.user, { expires: 0.6 });
            if (response.data.user.role === 'Responsable' && process.env.REACT_APP_ACCESS_MODULE.split(',').includes('espaceResponsable')) {
                response.data.status && history.push('/accueilResponsable');
            } else {
                response.data.status && history.push('/accueil');
            }
            !response.success &&
                setError('onSubmit', {
                    types: {
                        message: response.data.message,
                    },
                });
        } else {
            setMessage('Email ou mot de passe incorrect');
        }
    };
    async function inscriptionOuverte() {
        if (inscription.length === 0) {
            const response = await GET('/api/parametres/list?categorie=inscription', undefined, { redirectOnError: false });
            if (response) {
                const index = response.data.findIndex((e) => e.cle === 'inscriptionsPubliquesOuvertes');
                if (index >= 0) {
                    setIncription(response.data[index].valeur);
                }
            }
        }
    }
    function LienInscription() {
        inscriptionOuverte();
        if (inscription.length !== 0 && !window.matchMedia('(max-width: 1279px)').matches) {
            if (new Date().getTime() > new Date(inscription).getTime()) {
                return (
                    <Link href="/inscription/accueil">
                        <Button variant="contained" size="large" color="primary" className={[css.cWhite]}>
                            Première inscription
                        </Button>
                    </Link>
                );
            }
        }
        return <></>;
    }
    return (
        <Main style={{ height: '100%', backgroundImage: `url(${accueilResponsable})`, backgroundSize: 'cover' }}>
            <h1 className="cWhite fontSize2_5em xs_none textCenter">Bienvenue au conservatoire de Musique et de Danse du Tarn</h1>
            <Paper
                style={{
                    padding: '15px',
                    marginTop: '5%',
                    borderRadius: '25px',
                }}
                elevation={3}
            >
                {/* <h1>CmdT</h1>
                <p>Conservatoire de Musique et de Danse du Tarn</p> */}
                <p>Merci de vous identifier pour accéder aux services</p>
                <span style={{ color: 'red' }}>{message}</span>
                <BasicForm onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        label="Email"
                        name="email"
                        inputRef={register({
                            pattern:
                                // eslint-disable-next-line no-useless-escape
                                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        })}
                        onChange={() => clearErrors(['onSubmit'])}
                    />
                    <Input label="Mot de passe" type="password" name="password" onChange={() => clearErrors(['onSubmit'])} inputRef={register} />
                    {errors.onSubmit && errors.onSubmit.types.message}
                    {errors.email && 'Entrez un email valide'}
                    <Button>Connexion</Button>
                </BasicForm>
                <Link href="/mot-de-passe-oublie" style={{ color: '#508cc1' }}>
                    Mot de passe oublié ?
                </Link>
            </Paper>
            <LienInscription />
        </Main>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.Utilisateur,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        connexionUtilisateur(user) {
            dispatch({ type: 'CONNEXION', user });
        },
        removeNote() {
            dispatch({ type: 'removeNote' });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connexion));
