export const Lien_profil_responsable = 91;
export const Trie_site = 92;
export const Trie_annee_scolaire = 93;
export const Ajout_nouvel_responsable = 94;
export const Suppression_responsable = 95;
export const Usurpation_responsable = 96;
export const Export_excel = 97;
export const Lien_profil_eleve = 99;
export const general = 100;
export const eleve = 101;
export const cotisation = 102;
export const Modifer_responsable = 103;
export const Ajout_justificatif = 104;
export const Associer_eleve = 105;
export const Desassocier_eleve = 106;
export const Ajouter_fiscalite = 107;
export const Editer_fiscalite = 108;
export const Suppression_fiscalite = 109;
export const Calcul_cotisation = 110;
export const Retour_Liste_Responsable = 98;
export const reactivation_paiement = 278;
