/* eslint-disable eqeqeq */
export default function inscription(
    inscription = {
        responsable: {
            nom: '',
            prenom: '',
            email: '',
            dateNaissance: null,
            lieuNaissance: null,
            departementNaissance: null,
            adresse1: '',
            adresse2: '',
            codePostal: '',
            ville: '',
            telephoneFixe: '',
            telephoneMobile: '',
            profession: '',
            password: '',
            confirmePassword: '',
        },
        login: {
            email: '',
            password: '',
        },
        eleves: [],
        documents: {
            imposition: {},
        },
    },
    action,
) {
    const newInscription = inscription;
    if (action.type === 'info') {
        const login = ['email', 'password'];
        newInscription.responsable[Object.keys(action.data)[0]] = Object.values(action.data)[0];
        if (login.includes(Object.keys(action.data)[0])) {
            newInscription.login[Object.keys(action.data)[0]] = Object.values(action.data)[0];
        }
        return newInscription;
    }
    if (action.type === 'login') {
        newInscription.login[Object.keys(action.data)[0]] = Object.values(action.data)[0];
        return newInscription;
    }
    if (action.type === 'updateTableauStepEleve') {
        newInscription.eleves[action.idEleve].tableauStepEleve = action.tableau;
        return newInscription;
    }
    if (action.type === 'updateTableauStepOffre') {
        newInscription.eleves[action.idEleve].offre[action.idOffre].tableauStepOffre = action.tableau;
        return newInscription;
    }
    if (action.type === 'newEleve') {
        newInscription.eleves.push({
            id: action.idEleve,
            dateNaissance: '',
            fk_eleve: action.fk_eleve,
            fk_role: '',
            libelleRole: '',
            nomPrenomEleve: '',
            telephoneMobile: '',
            nom: '',
            prenom: '',
            offre: [],
            niveauScolaire: '',
            etablissement: '',
            anneeScolaire: '',
            niveau: '',
            genre: null,
            courMercredi: false,
            tableauStepEleve: [`Futur inscrit`],
            edition: false,
        });
        return newInscription;
    }
    if (action.type === 'deleteEleve') {
        const recupID = (elem) => elem.id == action.idEleve;
        newInscription.eleves.splice(newInscription.eleves.findIndex(recupID), 1);
        for (let index = 0; index < newInscription.eleves.length; index++) {
            newInscription.eleves[index].id = index;
        }
        return newInscription;
    }
    if (action.type === 'infoEleve') {
        for (let index = 0; index < newInscription.eleves.length; index++) {
            if (newInscription.eleves[index].id == action.idEleve) {
                newInscription.eleves[index][Object.keys(action.data)[0]] = Object.values(action.data)[0];
            }
        }
        return newInscription;
    }
    if (action.type === 'newOffre') {
        for (let index = 0; index < newInscription.eleves.length; index++) {
            if (newInscription.eleves[index].id == action.idEleve) {
                const offre = {
                    id: newInscription.eleves[index].offre.length,
                    typeOffre: '',
                    optionOffre: '',
                    cycleOffre: '',
                    site: '',
                    instrument: '',
                    libelleOffre: '',
                    commentaire: '',
                    style: [],
                    idOffre: '',
                    edition: false,
                    suivant: true,
                    tableauStepOffre: ['Domaine souhaité', 'Mon parcours'],
                };
                newInscription.eleves[index].offre.push(offre);
            }
        }
        return newInscription;
    }
    if (action.type === 'offre') {
        if (!action.remove) {
            for (let index = 0; index < newInscription.eleves.length; index++) {
                if (newInscription.eleves[index].id == action.idEleve) {
                    const nbBoucle = Object.values(action.offre)[0] == 29 ? 1 : newInscription.eleves[index].offre.length;
                    for (let elem = 0; elem < nbBoucle; elem++) {
                        if (newInscription.eleves[index].offre[elem].id == action.idOffre) {
                            const stylekyes = ['stylePrincipal', 'styleSecondaire'];
                            if (stylekyes.includes(Object.keys(action.offre)[0])) {
                                if (newInscription.eleves[index].offre[elem].style.length === 2) {
                                    newInscription.eleves[index].offre[elem].style = [];
                                }
                                newInscription.eleves[index].offre[elem].style.push(Object.values(action.offre)[0]);
                                return newInscription;
                            }
                            newInscription.eleves[index].offre[elem][Object.keys(action.offre)[0]] = Object.values(
                                action.offre,
                            )[0];
                            if (Object.values(action.offre)[0] == 'danse') {
                                newInscription.eleves[index].offre[elem].instrumentOffre = 'danse';
                            }
                        }
                    }
                }
            }
        }
        if (action.remove === 'niveauScolaire') {
            newInscription.eleves[action.idEleve].offre = [];
        }
        return newInscription;
    }
    if (action.type === 'deleteOffre') {
        const recupID = (elem) => elem.id == action.idOffre;
        newInscription.eleves[action.idEleve].offre.splice(newInscription.eleves[action.idEleve].offre.findIndex(recupID), 1);
        for (let index = 0; index < newInscription.eleves[action.idEleve].offre.length; index++) {
            newInscription.eleves[action.idEleve].offre[index].id = index;
        }
        return newInscription;
    }
    if (action.type === 'uploadDoc') {
        const impot = ['income', 'parts', 'exo', 'abs', 'avisImposition', 'quotient'];
        if (impot.includes(Object.keys(action.file)[0])) {
            newInscription.documents.imposition[Object.keys(action.file)[0]] = Object.values(action.file)[0];
        } else {
            newInscription.documents[Object.keys(action.file)[0]] = Object.values(action.file)[0];
        }
        return newInscription;
    }
    return inscription;
}
