/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper, Table, TableBody, TableContainer, TableRow, TableCell, Checkbox } from '@material-ui/core';

import { GET } from '../../Utils/requete';
import PaginationTable from '../Table/Pagination';
import TableHeadCustom from '../Table/TableHead';
import DatePickerRange from '../DatePicker/datePickerRange';
import { semaine } from '../DatePicker/defaultPeriode';
import TempCoursFormat from '../MiseEnFormeTempsCours/TempCoursFormat';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ListePresences = (props) => {
    const [dataPresences, setDataPresences] = useState([]);
    const [periode, setPeriode] = useState(semaine);
    const [statut, setStatut] = useState([4]);
    const [checkStatuts, setCheckStatuts] = useState([
        { id: 4, check: true },
        { id: 2, check: false },
        { id: 1, check: false },
        { id: 0, check: false },
    ]);
    const [refreshCheckbox, serRefreshCheckbox] = useState(false);
    const css = useStylesMaterielUIGlobal();

    let tableHeadEleve = [
        { id: 'jour', label: 'Jour' },
        { id: 'heure', label: 'Heure' },
        { id: 'libelleStatut', label: 'Présence' },
    ];

    if (!props.fk_atelier) tableHeadEleve.push({ id: 'libelleType', label: 'Atelier' });
    if (props.fk_eleve) tableHeadEleve.push({ id: 'nom', label: 'Enseignants' });
    if (props.fk_enseignant || props.fk_atelier) tableHeadEleve.push({ id: 'nom', label: 'Elève' });

    async function recupListePressences() {
        if (periode) {
            let options = { weekday: 'long', month: 'long', day: 'numeric' };
            if (props.fk_atelier) options = { month: 'long', day: 'numeric' };

            const dateDebut = `dateDebut=${periode[0].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
            const dateFin = `dateFin=${periode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
            let url = `/api/presences/listePresencesParId?${dateDebut}&${dateFin}`;
            if (props.fk_atelier) url += `&fk_atelier=${props.fk_atelier}`;
            if (props.fk_eleve) url += `&fk_eleve=${props.fk_eleve}`;
            if (props.fk_enseignant) url += `&fk_enseignant=${props.fk_enseignant}`;
            if (statut.length !== 0) url += `&fk_statutPresence=${statut}`;

            const response = await GET(url, undefined, {
                redirectOnError: false,
            });
            for (let index = 0; index < response.data.length; index++) {
                response.data[index].jour = new Date(response.data[index].date).toLocaleString('fr-FR', options);
                if (!response.data[index].libelleStatut) response.data[index].libelleStatut = 'non renseigné';
                if (props.fk_eleve) response.data[index].nom = response.data[index].nomPrenomUtilisateur;
                if (props.fk_enseignant || props.fk_atelier) response.data[index].nom = response.data[index].nomPrenomEleve;
            }
            setDataPresences(response.data);
        }
    }
    function checkStatut(idStatut) {
        let tableauIdStatut;
        const tableauCheckStatut = checkStatuts;
        if (statut.includes(idStatut)) {
            tableauIdStatut = statut.filter((e) => e !== idStatut);
            for (let index = 0; index < tableauCheckStatut.length; index++) {
                if (tableauCheckStatut[index].id === idStatut) tableauCheckStatut[index].check = false;
            }
        } else {
            tableauIdStatut = statut;
            tableauIdStatut.push(idStatut);
            for (let index = 0; index < tableauCheckStatut.length; index++) {
                if (tableauCheckStatut[index].id === idStatut) tableauCheckStatut[index].check = true;
            }
        }
        serRefreshCheckbox(!refreshCheckbox);
        setCheckStatuts(tableauCheckStatut);
        setStatut(tableauIdStatut);
    }
    function checkboxStatut() {
        return (
            <div className="flex mt1">
                <div>
                    <Checkbox onChange={() => checkStatut(4)} checked={checkStatuts[0].check} />
                    <label>Absent</label>
                </div>
                <div>
                    <Checkbox onChange={() => checkStatut(2)} checked={checkStatuts[1].check} />
                    <label>Present</label>
                </div>
                <div>
                    <Checkbox onChange={() => checkStatut(1)} checked={checkStatuts[2].check} />
                    <label>Excusé</label>
                </div>
                <div>
                    <Checkbox onChange={() => checkStatut(0)} checked={checkStatuts[3].check} />
                    <label>Non renseigné</label>
                </div>
            </div>
        );
    }
    useEffect(() => {
        recupListePressences();
    }, [periode, refreshCheckbox]);
    useEffect(() => {
        checkboxStatut();
    }, [refreshCheckbox]);
    return (
        <div className={props.className}>
            <div className="flex w100 mt2 ">
                <DatePickerRange onChange={(event) => setPeriode(event)} value={periode} />
                <h4 className="w60 textCenter nomargin">Présences en atelier</h4>
            </div>
            {checkboxStatut()}
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadEleve}></TableHeadCustom>
                    <TableBody>
                        {dataPresences.map((row, index) => {
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Jour</div>
                                        {row.jour}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Heure</div>
                                        <TempCoursFormat heureDebut={row.heureDebut} heureFin={row.heureFin} />
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Présence</div> {row.libelleStatut}
                                    </TableCell>
                                    {!props.fk_atelier ? (
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Atelier</div>
                                            {row.libelleType}
                                        </TableCell>
                                    ) : null}
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Enseignant</div> {row.nom}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.pagination ? <PaginationTable data={dataPresences} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable> : null}
        </div>
    );
};

export default connect(null, null)(ListePresences);
