import React from 'react';
import { Spin } from 'antd';

export default function Wait() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                margin: '  0',
                background: ' rgba(112, 128, 144, 0.3)',
                display: 'flex',
                position: 'fixed',
                zIndex: '10',
                top: '64px',
                bottom: 0,
                right: 0,
                left: '210px',
            }}
        >
            <div style={{ marginLeft: '40%', marginTop: '20%' }}>
                <Spin tip="Chargement des données" size="large">
                    <div style={{ width: '100px' }} />
                </Spin>
            </div>
        </div>
    );
}
