/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';

import Input from '../../atoms/TextField';

import { insertInput, affichagePassword } from '../../Utils/password';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const MotDePasse = (props) => {
    const css = useStylesMaterielUIGlobal();

    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [errorPassword, seterrorPassword] = useState('');

    function verificationPassword(event) {
        if (event.length < confirmationPassword.length) {
            setConfirmationPassword(event);
        } else {
            for (let index = 0; index < event.length; index++) {
                if (event.split('')[index] === password.split('')[index]) {
                    setConfirmationPassword(event);
                    props.value(event);
                    seterrorPassword('');
                } else {
                    setConfirmationPassword(event);
                    props.value(null);
                    seterrorPassword('Les deux mot de passe doivent être identique.');
                }
            }
        }
    }

    return (
        <>
            <div className="flex alignICenter mb0_5 ">
                <label className={props.labelCSS ? props.labelCSS : 'w40 '}>Mot de passe</label>
                <Input
                    disabled={props.disabled}
                    value={affichagePassword(password)}
                    className={[css.w40, css.mt0]}
                    onChange={(event) => {
                        setPassword(insertInput(event.target.value, password));
                        props.value(null);
                    }}
                />
            </div>
            <div className="flex alignICenter mb0_5">
                <label className={props.labelCSS ? props.labelCSS : 'w40 '}>Confirmation mot de passe</label>
                <Input
                    disabled={props.disabled}
                    value={affichagePassword(confirmationPassword)}
                    className={[css.w40, css.mt0]}
                    onChange={(event) => verificationPassword(insertInput(event.target.value, confirmationPassword))}
                    helperText={<span style={{ color: 'red' }}>{errorPassword}</span>}
                />
            </div>
        </>
    );
};

export default connect(null, null)(MotDePasse);
