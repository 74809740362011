/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, FormControl, InputLabel, Select, Switch } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import { semaine } from '../../components/organism/DatePicker/defaultPeriode';
import DatePickerRange from '../../components/organism/DatePicker/datePickerRange';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import TempCoursFormat from '../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import setData from '../../components/organism/Table/setDataTableau';
import PaginationTable from '../../components/organism/Table/Pagination';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';
import TriToggle from '../../components/organism/Tri_Toggle/triToggle';

const ListePresence = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const css = useStylesMaterielUIGlobal();
    const [periode, setPeriode] = useState(semaine);
    const [dataPresences, setDataPresences] = useState([]);
    const [copieData, setCopieData] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectSite, setSelectSite] = useState();
    const [salles, setSalles] = useState([]);
    const [enseignants, setEnseignants] = useState([]);
    const refreshViaFiltre = useSelector((state) => state.SelectSimple.refreshSimple);
    const dataFiltre = useSelector((state) => state.SelectSimple);
    const tableHeadListeResponsable = [
        { id: 'libelleType', label: 'Atelier' },
        { id: 'date', label: 'Date' },
        { id: 'heure', label: 'Heure' },
        { id: 'libelleSalle', label: 'Salle' },
        { id: 'enseignant', label: 'Enseignant' },
        { id: 'dateValidation', label: 'Validation' },
    ];
    let options = { weekday: 'long', month: 'long', day: 'numeric' };

    async function listePresence() {
        const dateDebut = `dateDebut=${periode[0].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
        const dateFin = `dateFin=${periode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
        let url = `/api/presences/listePresences?${dateDebut}&${dateFin}`;
        if (selectSite) url += `&fk_site=${selectSite}`;
        if (dataFiltre.Salle) url += `&fk_salle=${dataFiltre.Salle}`;
        if (dataFiltre.Enseignant) url += `&fk_enseignant=${dataFiltre.Enseignant}`;

        const response = await GET(url, undefined, {
            redirectOnError: false,
        });
        setDataPresences(response.data);
        setCopieData(response.data);

        if (sites.length === 0) {
            const listeSite = await GET('/api/sites/liste', undefined, {
                redirectOnError: false,
            });
            setSites(listeSite.data);
        }
    }
    async function listeSallesEnseignant() {
        const dataSalle = {
            fk_site: [selectSite],
        };
        const listeSalle = await POST('api/salles/liste', dataSalle);
        setSalles(creationTableauFiltre(listeSalle.data, 'idSalle', 'libelleSalle', 'libelleSite'));
        const dataEnseignant = {
            fk_anneeScolaire: user.idAnneeScolaire,
            fk_site: [selectSite],
            fk_instrument: [],
        };
        const enseignantFiltre = await POST('api/utilisateursAdministratifs/enseignants', dataEnseignant);
        setEnseignants(enseignantFiltre.data);
    }

    useEffect(() => {
        props.addNomPage('Liste présence');
        listePresence();
    }, [periode, refreshViaFiltre, selectSite]);

    useEffect(() => {
        listeSallesEnseignant();
        listePresence();
    }, [periode, refreshViaFiltre, selectSite]);

    useEffect(() => {
        listeSallesEnseignant();
    }, [selectSite]);

    function valueTriToggle(childData) {
        switch (childData) {
            case 'premierCheck':
                setDataPresences(copieData.filter((e) => e.dateValidation));
                break;
            case 'deuxiemeCheck':
                setDataPresences(copieData);
                break;
            case 'troisiemeCheck':
                setDataPresences(copieData.filter((e) => !e.dateValidation));
                break;
            default:
                break;
        }
    }

    return (
        <div className="listeContainer mb2">
            <DatePickerRange onChange={(event) => setPeriode(event)} value={periode} className="mt2 mb2" />
            <div className="flex spaceAround alignICenter  mb2">
                <FormControl className={css.w30}>
                    <InputLabel>Site</InputLabel>
                    <Select
                        native={true}
                        onChange={(event) => {
                            props.filtre([{ Salle: '' }, { refreshSimple: !refreshViaFiltre.refreshSimple }]);
                            setSelectSite(event.target.value);
                        }}
                        value={selectSite}
                    >
                        <option value={''}></option>
                        {sites.map((elem, index) => {
                            return (
                                <option key={index} value={elem.idSite}>
                                    {elem.libelleSite}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
                <div className="w30">
                    <SelectSimple tableau={salles} inputLabel={'Salle'} indice={'Salle'} disabled={!selectSite} classe={[css.w100, css.mb5]}></SelectSimple>
                    <SelectSimple
                        tableau={enseignants}
                        inputLabel={'Enseignant'}
                        indice={'Enseignant'}
                        disabled={!selectSite}
                        classe={[css.w100]}
                    ></SelectSimple>
                </div>
            </div>
            <div className="w100">
                <div>
                    <span>Validation présence</span>
                    <TriToggle label1="Validé" label2="Non validé" value={valueTriToggle} />
                </div>
                <PaginationTable
                    className="w35 floatRight"
                    data={props.recherche.fn(dataPresences)}
                    page={props.Page[0].page}
                    row={props.Page[0].rowsPerPage}
                ></PaginationTable>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadListeResponsable}></TableHeadCustom>
                    <TableBody>
                        {setData(props.recherche.fn, dataPresences, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                            (row, index) => {
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>
                                                <span>Atelier</span>
                                            </div>
                                            <Link to={`/fiche-presence/${row.idAtelier}?date=${row.date}`}>{row.libelleType}</Link>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Date</div>
                                            {new Date(row.date).toLocaleString('fr-FR', options)}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>heure</div>
                                            <TempCoursFormat heureDebut={row.heureDebut} heureFin={row.heureFin} />
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Salle</div>
                                            {row.libelleSalle}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Enseignant</div>
                                            {row.enseignant}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Validation</div>
                                            {row.dateValidation ? 'oui' : 'non'}
                                        </TableCell>
                                    </TableRow>
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="w100">
                <PaginationTable data={props.recherche.fn(dataPresences)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListePresence);
