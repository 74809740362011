/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import valuesQuery from '../../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const PropositionOffre = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const dataOffre = useSelector((state) => state.DataInscriptionTarn);

    useEffect(() => {
        if (!dataOffre) {
            history.push('/inscription/accueil');
        }
    }, []);
    return (
        <div className=" bgcfff h100 flex directionColumn p5 ">
            <div>
                <Link
                    to={`/inscription/selectionTypeParcours?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${valuesQuery('numeroOffre')}${
                        valuesQuery('reinscription') ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}` : ''
                    }`}
                >
                    <Button size="small" variant="contained" className={[css.Bcf9cb9c, css.radius10, css.cWhite, css.floatRight]}>
                        Retour
                    </Button>
                </Link>
            </div>
            <h1>Sélectionner une offre </h1>
            {dataOffre.categoriesEnfants.map((e, id) =>
                e.categorieEnfant.map((row, index) => (
                    <div key={index}>
                        <h3 className="mb0">{row.libelleEnfantCategorie}</h3>
                        <span className="ml5 fontSmallGrey">{row.descriptionEnfant}</span>
                        {row.offres.map((elem, i) => {
                            elem.color = row.couleur;
                            return (
                                <div key={i} className="flex  m1 alignICenter pl2 pr5 " style={{ border: `4px solid ${row.couleur}` }}>
                                    <h3 className="w45">{elem.libelleOffre}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: elem.commentaire,
                                        }}
                                        className="w55 ml2"
                                    ></p>
                                    <Link
                                        to={
                                            `/inscription/OptionOffre?numeroEleve=${valuesQuery('numeroEleve')}&numeroOffre=${valuesQuery('numeroOffre')}` +
                                            `${valuesQuery('reinscription') ? `&reinscription=true&idEleve=${valuesQuery('idEleve')}` : ''}`
                                        }
                                        className={css.ml2}
                                        onClick={() => {
                                            props.dataCategorieEnfant(elem);
                                            props.idOffre(valuesQuery('numeroEleve'), valuesQuery('numeroOffre'), elem.idOffre);
                                        }}
                                    >
                                        <Button size="small" variant="outlined">
                                            Choisir
                                        </Button>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                )),
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        dataCategorieEnfant(choixOffre) {
            dispatch({ type: 'choixOffre', data: choixOffre });
        },
        idOffre(numeroEleve, numeroOffre, fk_offre) {
            dispatch({ type: 'idOffre', numeroEleve: numeroEleve, numeroOffre: numeroOffre, fk_offre: fk_offre });
        },
    };
}

export default connect(null, mapDispatchToProps)(PropositionOffre);
