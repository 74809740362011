import { GET } from '../requete';

export default async function anneeEnCour() {
    const response = await GET('/api/parametres/list', undefined, { redirectOnError: false });
    const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
    const index = response.data.findIndex((e) => e.cle === 'anneeEnCours');
    const objectSelect = {
        id: response.data[index].valeur,
        libelle: filtreAnneeScolaire.data.filter((e) => e.idAnneeScolaire === +response.data[index].valeur)[0]
            .libelleAnneeScolaire,
    };

    return objectSelect;
}
