import React, { useState } from 'react';

import { SketchPicker } from 'react-color';

export const ColorPicker = (props) => {
    const [color, setColor] = useState();

    return <SketchPicker color={color} onChangeComplete={(event) => setColor(event.hex)} onChange={props.onChange} />;
};
export default ColorPicker;
