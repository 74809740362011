/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { TextareaAutosize, Switch } from '@material-ui/core';

import useStylesMaterielUIGlobal from '../../../../styles/StyleMaterielUIGlobal';
const ModuleValidationView = (props) => {
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const [dataInput, setDataInput] = useState({
        dateResultat: '',
    });
    const css = useStylesMaterielUIGlobal();
    function updateState(keys, valueI, type, idCheckbox, idModule, libReducer, idPiece) {
        if (type === 'reducer') {
            if (idCheckbox !== undefined) {
                props.saveDataCheckbox(idCheckbox);
            } else {
                props.saveDataValidation({ [libReducer ? libReducer : keys]: valueI }, idModule, idPiece);
            }
        }
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function view() {
        return (
            <>
                <p className="fontSmallGrey">{props.data.informationValidation}</p>
                <div>
                    {props.data.module.map((row, index) => (
                        <div key={index} id={row.idModule}>
                            <div className="flex center">
                                <h3 className="textCenter">{row.titre}</h3>
                            </div>
                            <hr></hr>
                            <h5 className="xs_ml1 xs_mr1">{row.informationModule}</h5>
                            {row.piece.map((e, i) => (
                                <div key={i} className="textRight alignICenter flex mb1">
                                    <label className="w30">{e.titre}: </label>
                                    <span className="ml30px w50">{dataInput[`${row.piece[i].titre}Module${index + 1}`]}</span>
                                </div>
                            ))}
                            <p className="fontSmallGrey">{row.infoComplementaire}</p>
                            <div>
                                <div className="flex mt1">
                                    <h5 className="mb1 xs_w50">Commentaires du professeur référent :</h5>
                                    <TextareaAutosize
                                        className={css.textareaML30pxW50}
                                        rowsMin={5}
                                        value={dataInput[`commentaireModule${row.idModule}`]}
                                        disabled={true}
                                    />
                                </div>
                                <div className="mt2 ml53 xs_nomargin m_nomargin">
                                    <label className="w30">Date de validation: </label>
                                    <span className="ml30px w50">
                                        {dataInput[`dateModule${row.idModule}`]
                                            ? new Date(dataInput[`dateModule${row.idModule}`]).toLocaleDateString('FR-fr')
                                            : ''}
                                    </span>
                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    ))}
                </div>
                {props.data.checkboxResultat.length > 0 ? (
                    <div className="mt5 xs_mb2">
                        <h3 className="textCenter">Résultats</h3>
                        <hr></hr>
                        <p className="fontSmallGrey">{props.data.informationResultat}</p>
                        {props.data.checkboxResultat.map((e, i) => (
                            <div className="flex alignICenter m_directionColumn">
                                <span className="xs_w50 ">{e.titre}: </span>
                                <div className=" ml2 textCenter">
                                    <span>non</span>
                                    <Switch
                                        checked={props.data.checkboxResultat[i].valeur}
                                        onChange={() => updateState('', '', 'reducer', i)}
                                        disabled={true}
                                    />
                                    <span>oui</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
                <div className="ml53 xs_nomargin m_nomargin">
                    <label className="w30">Date de validation: </label>
                    <span className="ml30px w50">{dataInput.dateResultat ? new Date(dataInput.dateResultat).toLocaleDateString('FR-fr') : ''}</span>
                </div>
            </>
        );
    }
    useEffect(() => {
        for (let index = 0; index < props.data.module.length; index++) {
            updateState(`commentaireModule${index + 1}`, props.data.module[index].commentaire);
            updateState(`dateModule${index + 1}`, props.data.module[index].date);
            for (let elem = 0; elem < props.data.module[index].piece.length; elem++) {
                updateState(`${props.data.module[index].piece[elem].titre}Module${index + 1}`, props.data.module[index].piece[elem].valeur);
            }
        }
        updateState('dateResultat', props.data.dateResultat);
        view();
    }, [refresh]);
    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        saveDataCheckbox(idCheckbox) {
            dispatch({ type: 'saveDataCheckbox', idCheckbox });
        },
        saveDataValidation(data, idModule, idPiece) {
            dispatch({ type: 'saveDataValidation', data, idModule, idPiece });
        },
    };
}
export default connect(null, mapDispatchToProps)(ModuleValidationView);
