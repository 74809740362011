/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Button, Input, Tabs, Checkbox, Switch, Modal, Select } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { GET, POST } from '../../../components/Utils/requete';
import formatSelect from '../../../components/Utils/FormatSelect';
import ListeRoleEmployes from './listeRoleEmployes';
import { copyArrayOfObject } from '../../../components/Utils/newPointeurArrayObject';

import 'antd/dist/reset.css';
import '../../../index.css';

const { TextArea } = Input;

const FicheRoles = (props) => {
    const history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [donnees, setDonnees] = useState({
        idGroupe: null,
        libelleGroupe: '',
        supprimable: 1,
        commentaire: '',
    });
    const [tabOngletFonction, setTabOngletFonction] = useState([]);
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const [listeFonctionDansGroupe, setListeFonctionDansGroupe] = useState([]);
    const [idGroupe, setIdGroupe] = useState();
    function compare(a, b) {
        return a.libelleFonction.localeCompare(b.libelleFonction);
    }
    const tabOnglet = [
        {
            label: 'Fonctions',
            key: '1',
            children: (
                <Tabs
                    defaultActiveKey="0"
                    type="card"
                    items={tabOngletFonction.map((onglet, i) => {
                        return {
                            label: onglet.libelleFonction,
                            key: `${i}`,
                            children: (
                                <div className="bgcGrisClair p1 mt0 ">
                                    <div className="flex spaceBetween mb1 bg-slate-100 p1 radius10">
                                        <label className="ml1">{onglet.libelleFonction}</label>
                                        <label className="ml1">{onglet.commentaire}</label>
                                        <Checkbox
                                            checked={check(onglet.idFonction)}
                                            className="mr2em"
                                            onChange={() => updateFonctionParGroupe(onglet.fk_parent, onglet.idFonction)}
                                        />
                                    </div>
                                    {onglet.niveau1 &&
                                        onglet.niveau1.sort(compare).map((niveau1, i) => (
                                            <div key={i}>
                                                <div className=" ml5 flex spaceBetween mb1 bg-slate-200 p1 radius10">
                                                    <label className="ml1">{niveau1.libelleFonction}</label>
                                                    <label className="ml1">{niveau1.commentaire}</label>
                                                    <Checkbox
                                                        checked={check(niveau1.idFonction)}
                                                        className="mr2em"
                                                        onChange={() => updateFonctionParGroupe(niveau1.fk_parent, niveau1.idFonction)}
                                                    />
                                                </div>
                                                <div className=" ml10">
                                                    {niveau1.niveau2 &&
                                                        niveau1.niveau2.sort(compare).map((niveau2, index) => (
                                                            <div key={index}>
                                                                <div className="flex spaceBetween mb1  bg-slate-300 p1 radius10">
                                                                    <label className="ml1">{niveau2.libelleFonction}</label>
                                                                    <label className="ml1">{niveau2.commentaire}</label>
                                                                    <Checkbox
                                                                        checked={check(niveau2.idFonction)}
                                                                        onChange={() => updateFonctionParGroupe(niveau2.fk_parent, niveau2.idFonction)}
                                                                        className="mr2em"
                                                                    />
                                                                </div>
                                                                <div className=" ml5">
                                                                    {niveau2.niveau3 &&
                                                                        niveau2.niveau3.sort(compare).map((niveau3, index) => (
                                                                            <div key={index}>
                                                                                <div className="flex spaceBetween mb1 bg-slate-400 p1 radius10">
                                                                                    <label className="ml1">{niveau3.libelleFonction}</label>
                                                                                    <label className="ml1">{niveau3.commentaire}</label>
                                                                                    <Checkbox
                                                                                        checked={check(niveau3.idFonction)}
                                                                                        onChange={() =>
                                                                                            updateFonctionParGroupe(niveau3.fk_parent, niveau3.idFonction)
                                                                                        }
                                                                                        className="mr2em"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ),
                        };
                    })}
                />
            ),
        },
    ];

    if (+id !== 0)
        tabOnglet.push({
            label: 'Agents',
            key: '2',
            children: <ListeRoleEmployes idRole={id} />,
        });

    function check(idFonction) {
        const index = listeFonctionDansGroupe.findIndex((e) => e.fk_fonction === idFonction);
        if (index === -1) return false;
        if (index !== -1) return true;
    }
    function updateFonctionParGroupe(fk_parent, idFonction) {
        const findFonction = listeFonctionDansGroupe.findIndex((e) => e.fk_fonction === idFonction);
        if (findFonction === -1) {
            setListeFonctionDansGroupe([
                ...listeFonctionDansGroupe,
                {
                    fk_groupe: +id,
                    fk_fonction: idFonction,
                    fk_parent: fk_parent,
                },
            ]);
        } else {
            const newFonctionGroupe = copyArrayOfObject(listeFonctionDansGroupe);
            const niveau1 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                if (listeFonctionDansGroupe[index].fk_parent === idFonction) {
                    niveau1.push(listeFonctionDansGroupe[index]);
                    const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                    newFonctionGroupe.splice(findFonction, 1);
                }
            }
            const niveau2 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                for (let elem = 0; elem < niveau1.length; elem++) {
                    if (listeFonctionDansGroupe[index].fk_parent === niveau1[elem].fk_fonction) {
                        niveau2.push(listeFonctionDansGroupe[index]);
                        const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                        newFonctionGroupe.splice(findFonction, 1);
                    }
                }
            }
            const niveau3 = [];
            for (let index = 0; index < listeFonctionDansGroupe.length; index++) {
                for (let elem = 0; elem < niveau2.length; elem++) {
                    if (listeFonctionDansGroupe[index].fk_parent === niveau2[elem].fk_fonction) {
                        niveau3.push(listeFonctionDansGroupe[index]);
                        const findFonction = newFonctionGroupe.findIndex((e) => e.fk_fonction === listeFonctionDansGroupe[index].fk_fonction);
                        newFonctionGroupe.splice(findFonction, 1);
                    }
                }
            }
            const suppressionFonction = newFonctionGroupe.filter((e) => e.fk_fonction !== idFonction);
            setListeFonctionDansGroupe(suppressionFonction);
        }
    }

    const getDonnees = async () => {
        let response = await GET(`/api/gestionRole/ListeFonction`);
        response.data.sort((a, b) => a.libelleFonction.localeCompare(b.libelleFonction, 'fr'));
        setTabOngletFonction(response.data);
        if (+id !== 0) {
            response = await GET(`/api/gestionRole/groupe?idGroupe=${id}`);
            setDonnees(response.data);
            ListeFonction(id);
        }
        if (listeGroupeRole.length === 0) {
            response = await GET(`/api/gestionRole/listeGroupe`);
            setListeGroupeRole(formatSelect(response.data, 'idGroupe', 'libelleGroupe'));
        }
    };
    async function ListeFonction(idGroupe, duplique) {
        const response = await GET(`/api/gestionRole/ListeFonctionParGroupe?idGroupe=${idGroupe}`);
        if (duplique) {
            for (let index = 0; index < response.data.length; index++) {
                response.data[index].fk_groupe = id;
            }
            setOpen(false);
        }
        setListeFonctionDansGroupe(response.data);
    }
    const saveRole = async () => {
        const data = {
            idGroupe: +id,
            supprimable: donnees.supprimable,
            libelleGroupe: donnees.libelleGroupe,
            commentaire: donnees.commentaire,
            fonctionsGroupe: { fk_groupe: +id, listeFonction: listeFonctionDansGroupe },
        };

        if (!data.libelleGroupe)
            return props.notification({
                message: 'Vous devez nommer le groupe',
            });
        if (data.libelleGroupe.length > 55)
            return props.notification({
                message: 'Le nom du groupe est limité à 55 caractères',
            });
        const response = await POST(`/api/gestionRole/${id}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            props.notification({
                message: "L'enregistrement ok",
                status: response.success,
            });
            history.push(`/groupe-role/${response.data.idGroupe}`);
        } else {
            props.notification({
                message: "L'enregistrement a rencontré un probleme, veuillez contacter votre adminitrateur",
            });
        }
    };
    function updateInput(keys, value) {
        setDonnees((prevState) => ({
            ...prevState,
            [keys]: value,
        }));
    }

    useEffect(() => {
        getDonnees();
    }, [id]);

    return (
        <div className="listeContainer">
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button danger key="back" onClick={() => setOpen(false)}>
                        Retour
                    </Button>,
                    <Button type="primary" onClick={() => ListeFonction(idGroupe, true)}>
                        Charger les rôles du groupe
                    </Button>,
                ]}
            >
                <div className="flex w100 directionColumn mb5">
                    <label className="mb2">Sélectionner un groupe:</label>
                    <Select className="w70" onChange={(values) => setIdGroupe(values)} options={listeGroupeRole} />
                </div>
            </Modal>
            <div>
                <div className="  mt2 ml5 mr10 p2 flex alignICenter">
                    <div className="w80 mb2">
                        <div className="flex alignICenter ">
                            <label className="w20">Nom du groupe : </label>
                            <Input className="w60" value={donnees?.libelleGroupe} onChange={({ target }) => updateInput('libelleGroupe', target.value)} />
                        </div>
                        <div className="flex alignICenter mt1 mb2">
                            <label className="w20">Commentaire :</label>
                            <TextArea
                                autoSize={{ minRows: 5 }}
                                className="w60"
                                value={donnees?.commentaire}
                                onChange={({ target }) => updateInput('commentaire', target.value)}
                            />
                        </div>
                        <div>
                            <label>Ce groupe peut être supprimé :</label>
                            <Switch
                                className="ml5"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={donnees?.supprimable}
                                onChange={() => updateInput('supprimable', donnees.supprimable ? 0 : 1)}
                            />
                        </div>
                    </div>
                    <div>
                        {+id !== 1 && (
                            <Button
                                form="promotionForm"
                                className="w100 mb10 bgcVert32CD32"
                                style={{ color: '#0f4c81', borderColor: '#0f4c81' }}
                                type="primary"
                                ghost
                                onClick={() => saveRole()}
                            >
                                Enregistrer le groupe
                            </Button>
                        )}
                        {+id !== 0 && (
                            <Button form="promotionForm" className="w100 mb10" type="primary" ghost onClick={() => setOpen(true)}>
                                Dupliquer un Groupe
                            </Button>
                        )}

                        <Link to="/liste-groupe-role">
                            <Button danger form="promotionForm" className="w100 mb10" type="primary" ghost>
                                retour
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            {+id !== 0 && (
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    items={tabOnglet.map((row) => {
                        return {
                            label: row.label,
                            key: row.key,
                            children: row.children,
                        };
                    })}
                />
            )}
            {+id === 0 && <p className="textCenter mt10">Vous devez enregistrer le groupe avant de pouvoir administrer les rôles.</p>}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
export default connect(null, mapDispatchToProps)(FicheRoles);
