/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function previousPath(pathURL = {}, action) {
    if (action.type === 'savePathAtelier') {
        const newpathURL = pathURL;
        newpathURL.atelier = action.pathAtelier;
        return newpathURL;
    }
    return pathURL;
}
