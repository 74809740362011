/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste élèves
 *
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import InputText from '../../atoms/TextField/index';

const TableRecherche = (props) => {
    const [value, setValue] = useState(props.value);
    function getFilteredCodes(array, key, valueR) {
        // eslint-disable-next-line array-callback-return
        return array.filter(function (e = []) {
            if (e[key] !== null) {
                let tableauR;
                if (Array.isArray(e[key])) {
                    // eslint-disable-next-line no-plusplus
                    for (let elem = 0; elem < e[key].length; elem++) {
                        tableauR = e[key][elem]
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(valueR.toLowerCase());
                    }
                } else {
                    if (e[key] !== undefined) {
                        tableauR = e[key]
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(valueR.toLowerCase());
                    }
                }
                return tableauR;
            }
        });
    }

    const handleSearch = (e) => {
        props.resetPagination();
        const { target } = e;
        setValue(e.target.value);
        const indiceRecherche = props.indice;
        const tableauRetourR = [];
        for (let elem = 0; elem < indiceRecherche.length; elem++) {
            tableauRetourR.push(...getFilteredCodes(props.tableau, indiceRecherche[elem], target.value));
        }
        const suppressionDoublon = (tableau) => [...new Set(tableau.map((el) => JSON.stringify(el)))].map((e) => JSON.parse(e));
        props.reponseRecherche({
            fn: (items) => {
                if (target.value === '') return items;
                else {
                    return suppressionDoublon(tableauRetourR);
                }
            },
        });
    };
    useEffect(() => {
        if (props.valeurRecherche) {
            handleSearch({ target: { value: props.valeurRecherche.valeur } });
        }
    }, [props.valeurRecherche ? props.valeurRecherche.refresh : null]);

    return (
        <InputText
            label={!props.label ? 'Recherche' : props.label}
            className={props.className}
            value={value}
            id={props.id}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
            onChange={handleSearch}
        />
    );
};
function mapDispatchToProps(dispatch) {
    return {
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
        resetPagination() {
            dispatch({ type: 'resetPagination' });
        },
    };
}
export default connect(null, mapDispatchToProps)(TableRecherche);
