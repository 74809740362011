export const inputInformation = [
    { label: 'Année Scolaire', indice: 'libelle_anneeScolaire' },
    { label: 'Nom', indice: 'eleveNomPrenom', url: '/fiche-eleve/', id: 'fk_eleve' },
    { label: 'Professeur référent', indice: 'enseignantNomPrenom', url: '/fiche-agent/', id: 'fk_enseignant' },
];
export const anneeInstrument = [
    { label: 'Discipline principale', indice: 'anneeDisciplinePrincipale' },
    { label: 'Autres', indice: 'anneeDisciplineAutre' },
];
export const select = [
    { label: 'Professeur de FM', indice: 'fk_enseignantFM' },
    { label: 'Autres enseignants', indice: 'fk_enseignantSupplementaire' },
];
export const exclure = [
    'fk_eleve',
    'fk_anneeScolaire',
    'anneeDisciplinePrincipale',
    'anneeDisciplineAutre',
    'niveauAcquisition',
    'fk_enseignantFM',
    'fk_enseignantSupplementaire',
    'fk_enseignant',
];
export const verifKeysGeneral = ['niveauFM', 'pratiqueCollective'];
export const ObservationExclusion = ['libelle_anneeScolaire', 'eleveNomPrenom', 'enseignantNomPrenom'];
export const verifKeysResultat = ['informationResultat', 'informationValidation'];
export const verifKeysValidation = ['infoComplementaire', 'informationModule', 'titre'];
