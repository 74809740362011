/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControl, Button, Select } from '@material-ui/core';

import TelephoneInput from '../../../components/organism/Input/telephone';
import { GET, POST } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import DateInput from '../../../components/organism/Input/date';
import downloadFile from '../../../components/Utils/downloadFile';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import { insertInput, affichagePassword } from '../../../components/Utils/password';
import recupCommune from '../../../components/Utils/recupCommune';
import CardComponent from '../../../components/organism/card/card';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import anneeInscription from '../../../components/Utils/requeteParametre/anneeInscription';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { inputGeneralPart1, inputGeneralPart2, verifDataSaveResponsable, inputPassword } from '../const';

const ViewResponsable = (props) => {
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const [open, setOpen] = useState(false);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [dataJustificatifDomicile, setDataJustificatifDomicile] = useState([]);
    const [Civilite, setCivilite] = useState([]);
    const [SocialCateg, setSocialCat] = useState([]);
    const [dataInput, setDataInput] = useState({
        nom: '',
        prenom: '',
        email: '',
        dateNaissance: null,
        lieuNaissance: null,
        departementNaissance: null,
        adresse1: '',
        adresse2: '',
        codePostal: '',
        ville: '',
        telephoneFixe: '',
        telephoneMobile: '',
        profession: '',
        password: '',
        CPassword: '',
    });
    const [uploadFileData, setUploadFileData] = useState();
    const [listeCommune, setListeCommune] = useState([]);
    const [villeOrigine, setVilleOrigine] = useState('');
    const [erreurVille, setErreurVille] = useState(false);
    const [nameFile, setNameFile] = useState();
    const [infoAnneeInscription, setInfoAnneeInscription] = useState({ libelleAnneeScolaire: '' });
    const [domaine, setDomaine] = useState('');

    async function parametreDomaine() {
        const response = await GET(`/api/parametres/getValeur/blocageDomaine`);
        if (response.success) setDomaine(response.data.valeur);
    }

    function infoDmaine() {
        let domaineString = '';
        if (domaine.includes('|')) {
            for (let index = 0; index < domaine.split('$|').length; index++) {
                if (index === domaine.split('$|').length - 1)
                    domaineString += `${domaine.split('$|')[index].replace('\\@', '').replace('\\', '').replace('$', '')} `;
                else domaineString += `${domaine.split('$|')[index].replace('\\@', '').replace('\\', '')}, `;
            }
        } else {
            domaineString += `${domaine.replace('\\@', '').replace('\\', '').replace('$', '')} `;
        }
        return domaineString;
    }

    function CustomButton(props) {
        return (
            <Button size="small" variant="outlined" className={props.className} onClick={props.onClick} color={props.color ? props.color : 'primary'}>
                {props.label}
            </Button>
        );
    }
    function ViewJustificatifDomicile() {
        let tableauJustificatif = <></>;
        if (dataJustificatifDomicile[0]) {
            tableauJustificatif = (
                <div className=" xs_w80 xs_ml5 xs_w90">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {dataJustificatifDomicile.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={[css.td, css.xs_pl2_Important]} component="th" scope="row">
                                                {`Année Scolaire ${row.libelleAnneeScolaire}`}
                                            </TableCell>
                                            <TableCell className={[css.td, css.xs_pl2_Important]} component="th" scope="row">
                                                <Link
                                                    onClick={() => {
                                                        downloadFile(
                                                            'justificatifDomicile',
                                                            {
                                                                fk_responsable: id,
                                                                fk_anneeScolaire: row.fk_anneeScolaire,
                                                            },
                                                            row.realFileName,
                                                        );
                                                    }}
                                                >
                                                    <span>{row.realFileName}</span>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        }
        return (
            <div>
                <div className="mb2 mt5 flex spaceAround alignICenter">
                    <h4 className="nomargin xs_none">Justificatif de domicile</h4>
                    <h5 className="textCenter mb2 mt2 none xs_block">Justificatifs de domicile</h5>
                    <CustomButton className={[css.xs_w80, css.m2]} type="bouton" label="Ajouter " onClick={() => setOpen(true)} />
                </div>
                {tableauJustificatif}
                {Modal({ content: 'justificatif' })}
            </div>
        );
    }
    function updateState(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function Modal() {
        return (
            <DialogCustom
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                children={
                    <div>
                        <div className=" flex mt5 mb5 ">
                            <label className="w100 textCenter">Justificatif de domicile pour l'année {infoAnneeInscription.libelleAnneeScolaire}</label>
                        </div>
                        <div className="flex spaceAround">
                            <input
                                id="justificatifDomicile-file"
                                type="file"
                                hidden
                                onChange={(event) => {
                                    uploadFile(event.target.files[0]);
                                }}
                            />
                            <label htmlFor="justificatifDomicile-file" className="flex directionColumn">
                                <Button variant="outlined" component="span">
                                    Piece jointe
                                </Button>
                                <label className="mt5 cVert32CD32">{nameFile}</label>
                            </label>
                        </div>
                        <div className="mt10 mb5 flex spaceAround">
                            <CustomButton
                                className={[css.cBleu, css.borderCbleu, css.ml2_5em]}
                                type="bouton"
                                label="Valider"
                                onClick={() => {
                                    envoiJustificatifDomicile();
                                }}
                            />
                            <CustomButton
                                type="bouton"
                                label="Annuler"
                                color="secondary"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            />
                        </div>
                    </div>
                }
                maxWidth="lg"
            />
        );
    }
    function VilleOrigine(props) {
        if (erreurVille) {
            return (
                <div>
                    <span>
                        Le nom de la ville est mal orthographié
                        <br />
                        Merci de ressaisir la ville
                    </span>
                    <div className="flex   mb1">
                        <label className="w40 xs_fontSize0_8em">Ville (original)</label>
                        <Input disabled={true} value={villeOrigine} className={[css.w50, css.mt0]} />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    async function libelleAnneeInscription() {
        const response = await anneeInscription();
        setInfoAnneeInscription(response);
    }
    async function uploadFile(file) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            setNameFile();
            setUploadFileData();
            return;
        }
        setNameFile(file.name);
        const data = new FormData();
        data.delete('file');
        data.delete('fk_responsable');
        data.delete('fk_anneeScolaire');
        data.append('file', file);
        data.append('fk_responsable', id);
        data.append('fk_anneeScolaire', infoAnneeInscription.valeur);
        setUploadFileData(data);
    }
    async function recupFiltre() {
        const civiliteSelect = await GET('/api/utilisateursResponsables/civilites');
        setCivilite(civiliteSelect.data);
        const socialCatSelect = await GET('/api/utilisateursResponsables/categorieSocioProfessionnelle');
        setSocialCat(socialCatSelect.data);
    }
    async function LoadListeCommune(codePostal) {
        const tableauCommune = await recupCommune(codePostal);
        setListeCommune(tableauCommune);
        return tableauCommune;
    }
    async function recupDataResponsable() {
        const response = await GET('/api/utilisateursResponsables/' + id);
        const verif = ['password', 'CPassword'];
        let commune;
        for (let index = 0; index < Object.keys(dataInput).length; index++) {
            if (!verif.includes([Object.keys(dataInput)[index]][0])) {
                setDataInput((prevState) => ({
                    ...prevState,
                    [Object.keys(dataInput)[index]]: response.data[Object.keys(dataInput)[index]],
                }));
            }
            if (Object.keys(dataInput)[index] === 'codePostal') {
                commune = await LoadListeCommune(response.data[Object.keys(dataInput)[index]]);
            }
            if (Object.keys(dataInput)[index] === 'ville') {
                if (commune.length > 0) {
                    setVilleOrigine(response.data[Object.keys(dataInput)[index]]);
                    const bonneOrthographeCommune = commune.filter((e) => e === response.data[Object.keys(dataInput)[index]]);
                    if (bonneOrthographeCommune.length > 0) {
                        updateState(Object.keys(dataInput)[index], response.data[Object.keys(dataInput)[index]]);
                        setErreurVille(false);
                    } else {
                        setErreurVille(true);
                    }
                } else {
                    setListeCommune([response.data[Object.keys(dataInput)[index]]]);
                }
            }
        }
        props.filtre([{ SocialCateg: response.data.fk_categorieSocioProfessionnelle }, { Civilite: response.data.fk_civilite }]);
        recupJustificatifDomicile();
    }
    async function recupJustificatifDomicile() {
        const justificatifsDomicile = await GET(`/api/utilisateursResponsables/${id}/justificatifs/list`);
        setDataJustificatifDomicile(justificatifsDomicile.data);
    }
    async function envoiJustificatifDomicile() {
        const response = await POST(`/api/fichiers/upload/justificatifDomicile`, uploadFileData, {
            upload: true,
            redirectOnError: false,
        });
        if (response.success === true) {
            recupJustificatifDomicile();
            setNameFile();
            setUploadFileData();
            setOpen(false);
        }
    }

    async function saveResponsable() {
        const data = {
            idUtilisateur: id,
            nom: dataInput.nom,
            prenom: dataInput.prenom,
            nomPrenomUtilisateur: dataInput.nom + dataInput.prenom,
            fk_civilite: reducerFiltre.Civilite === '' ? null : reducerFiltre.Civilite,
            dateNaissance: DateHeureFormat({ BDD: 'date', date: dataInput.dateNaissance }),
            lieuNaissance: dataInput.lieuNaissance,
            departementNaissance: dataInput.departementNaissance,
            adresse1: dataInput.adresse1,
            adresse2: dataInput.adresse2,
            codePostal: dataInput.codePostal,
            ville: dataInput.ville,
            telephoneFixe: dataInput.telephoneFixe,
            telephoneMobile: dataInput.telephoneMobile,
            fk_categorieSocioProfessionnelle: reducerFiltre.SocialCateg === '' ? null : reducerFiltre.SocialCateg,
            profession: dataInput.profession,
            email: dataInput.email,
            role: 'Responsable',
        };
        if (dataInput.password) {
            if (!dataInput.password) {
                return props.notification({ message: 'Les champs mots de passe doivent être renseignés' });
            }
            if (dataInput.password === dataInput.CPassword) {
                data.password = dataInput.password;
            } else {
                return props.notification({ message: 'Les champs mots de passe doivent être identiques' });
            }
        }
        if (dataInput.email.match(domaine)) {
            return props.notification({ message: 'Vous devez renseigner autre adresse mail.' });
        }
        for (let index = 0; index < verifDataSaveResponsable.length; index++) {
            if (!data[verifDataSaveResponsable[index]]) {
                return props.notification({
                    message:
                        "Veuillez remplir les champs (nom, prenom, email, civilité, téléphone mobile, l'adresse compléte et les informations de naissance)",
                });
            }
        }

        if (!data.nom || !data.prenom) {
            return props.notification({
                message: 'Veuillez remplir les champs (nom, prenom)',
            });
        } else {
            const response = await POST('/api/utilisateursResponsables/' + id, data, { redirectOnError: false });
            if (response.success) {
                props.notification({
                    message: 'Les modifications ont été enregistrées',
                    status: response.success,
                });
            } else {
                props.notification({
                    message: `Une erreur est survenue lors de l'enregistrement : ${response.message}`,
                });
            }
        }
    }

    useEffect(() => {
        parametreDomaine();
        recupFiltre();
        libelleAnneeInscription();
        recupDataResponsable();
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }, []);

    return (
        <div className=" ml7 mt5 mr7  overflowAuto h80" style={{ opacity: '0.9' }}>
            <CardComponent>
                <div className="flex xs_directionColumn mt2 mb2">
                    <div className="w50 xs_w100 ml2">
                        <h5 className="textCenter mb2 mt2 none xs_block">Information général</h5>
                        <div className="flex alignICenter mb0_5">
                            <label className="w40 xs_fontSize0_8em">Civilité</label>
                            <SelectSimple native={true} tableau={Civilite} indice={'Civilite'} classe={[css.w40, css.mt0, css.xs_w50]}></SelectSimple>
                        </div>
                        {inputGeneralPart1.map((elem, index) => (
                            <>
                                {elem.input !== 'email' && (
                                    <div key={index} className={`flex alignICenter mb0_5`}>
                                        <label className="w40 xs_fontSize0_8em">{elem.label}</label>
                                        <Input
                                            value={dataInput[elem.input]}
                                            className={[css.w40, css.mt0, css.xs_w50]}
                                            onChange={(event) => {
                                                updateState(elem.input, elem === 'nom' ? event.target.value.toUpperCase() : event.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                                {elem.input === 'email' && (
                                    <div key={index} className={`flex alignICenter mb0_5`}>
                                        <label className="w40 flex directionColumn">
                                            Email
                                            <span className="fontSize0_7em ">Les adresses mail finissant par {infoDmaine()} ne sont pas accepté.</span>
                                        </label>
                                        <Input
                                            value={dataInput.email}
                                            className={[css.w40, css.mt0, css.xs_w50]}
                                            onChange={(event) => {
                                                updateState('email', event.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        ))}
                        <div className="flex alignICenter mb0_5">
                            <label className="w40 xs_fontSize0_8em">CodePostal</label>
                            <Input
                                value={dataInput.codePostal}
                                className={[css.w40, css.mt0, css.xs_w50]}
                                onChange={async (event) => {
                                    updateState('codePostal', event.target.value);
                                    LoadListeCommune(event.target.value);
                                }}
                            />
                        </div>
                        <VilleOrigine />
                        <div className="flex alignICenter mb0_5">
                            <label className="w40 xs_fontSize0_8em">Ville</label>
                            <FormControl className={[css.w40, css.mt0, css.mb8px, css.xs_w50]}>
                                <Select
                                    native={true}
                                    value={dataInput.ville}
                                    onChange={(event) => {
                                        updateState('ville', event.target.value);
                                    }}
                                >
                                    <option value={''}></option>
                                    {listeCommune.map((elem, index) => {
                                        return (
                                            <option key={index} value={elem}>
                                                {elem}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <h5 className="textCenter mb2 mt2 none xs_block">Information de naissances</h5>
                        <DateInput
                            classesLabel="w40 xs_fontSize0_8em"
                            label={'Date de naissance'}
                            classeDiv="flex alignICenter mb0_5 "
                            format="dd/MM/yyyy"
                            value={dataInput.dateNaissance}
                            className={[css.w40, css.nonargin, css.xs_w50]}
                            onChange={(event) => {
                                updateState('dateNaissance', new Date(event));
                            }}
                        />
                        {inputGeneralPart2.map((elem, index) => (
                            <div key={index} className="flex alignICenter mb0_5">
                                <label className="w40 xs_fontSize0_8em">{elem.label}</label>
                                <Input
                                    value={dataInput[elem.input]}
                                    className={[css.w40, css.mt0, css.xs_w50]}
                                    onChange={(event) => {
                                        updateState(elem.input, event.target.value);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className=" w40 xs_w100 ml2">
                        <h5 className="textCenter mb2 mt2 none xs_block">Profession</h5>
                        <div className="flex alignICenter mb0_5">
                            <label className="w50 xs_fontSize0_8em">Profession</label>
                            <Input
                                value={dataInput.profession}
                                className={[css.w40, css.mt0, css.xs_w50]}
                                onChange={(event) => {
                                    updateState('profession', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex alignICenter mb2">
                            <label className="w50 xs_fontSize0_8em">Catégorie Socio-professionnelle</label>
                            <FormControl className={[css.w40, css.xs_w50]}>
                                <SelectSimple native={true} tableau={SocialCateg} indice={'SocialCateg'} classe={css.w100}></SelectSimple>
                            </FormControl>
                        </div>
                        <h5 className="textCenter mb2 mt2 none xs_block">Contact</h5>
                        <div className="flex mb1">
                            <label className="w50 xs_fontSize0_8em">Téléphone fixe</label>
                            <TelephoneInput
                                className="textLeft "
                                value={dataInput.telephoneFixe}
                                onChange={(event) => {
                                    updateState('telephoneFixe', event);
                                }}
                                styleSpan="fontSize0_6em"
                            />
                        </div>
                        <div className="flex mb1">
                            <label className="w50 xs_fontSize0_8em">Téléphone mobile</label>
                            <TelephoneInput
                                className="textLeft "
                                value={dataInput.telephoneMobile}
                                onChange={(event) => {
                                    updateState('telephoneMobile', event);
                                }}
                                styleSpan="fontSize0_6em"
                            />
                        </div>
                        <h5 className="textCenter mb2 mt2 none xs_block">Mots de passes</h5>
                        {inputPassword.map((elem, index) => (
                            <div key={index} className="flex alignICenter mb0_5">
                                <label className="w50 xs_fontSize0_8em">{elem.label}</label>
                                <Input
                                    value={affichagePassword(dataInput[elem.input])}
                                    className={[css.w40, css.mt0, css.xs_w50]}
                                    onChange={(event) => {
                                        updateState(elem.input, insertInput(event.target.value, dataInput[elem.input]));
                                    }}
                                />
                            </div>
                        ))}
                        {ViewJustificatifDomicile()}
                    </div>
                </div>
                <div className="mt2 mb2 flex spaceAround">
                    <CustomButton
                        type="bouton"
                        label="Enregistrer les modifications"
                        className={[css.cBleu, css.xs_w90, css.borderCbleu]}
                        color=""
                        onClick={() => {
                            saveResponsable();
                        }}
                    />
                </div>
            </CardComponent>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ViewResponsable);
