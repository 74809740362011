/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { FormControl, Select } from '@material-ui/core';

import { POST } from '../../../../components/Utils/requete';
import DiscreteSlider from '../../../../components/organism/NotationParPoint/notationParPoint';

import useStylesMaterielUIGlobal from '../../../../styles/StyleMaterielUIGlobal';

import { inputInformation, anneeInstrument, select, exclure, verifKeysGeneral, ObservationExclusion } from '../../../livret/consts';

const ModuleGeneralView = (props) => {
    const css = useStylesMaterielUIGlobal();
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const [listeEnseignant, setListeEnseignant] = useState([]);
    const [dataInput, setDataInput] = useState({
        libelle_anneeScolaire: '',
        eleveNomPrenom: '',
        enseignantNomPrenom: '',
        EnseignantReferent: '',
        fk_enseignantFM: '',
        fk_enseignantSupplementaire: '',
        anneeDisciplinePrincipale: '',
        anneeDisciplineAutre: '',
    });
    const [valueInstrument, setValueInstrument] = useState([{ pratiqueCollective: [] }, { niveauFM: [] }]);
    function viewGrille() {
        return <DiscreteSlider niveau={props.data.niveauAcquisition} disabled={true} />;
    }
    function updateState(keys, valueI, chargeData) {
        if (!chargeData) {
            props.addSaveData({ [keys]: valueI });
        }
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    async function recupFiltre() {
        const enseignantFiltre = await POST('/api/utilisateursAdministratifs/enseignants');
        setListeEnseignant(enseignantFiltre.data);
    }

    function ChampsObservation() {
        const TableauObservation = [];
        for (let index = 0; index < Object.keys(props.data).length; index++) {
            if (!exclure.concat(verifKeysGeneral).concat(ObservationExclusion).includes(Object.keys(props.data)[index])) {
                TableauObservation.push(
                    <div>
                        <h3 className="xs_textCenter">{Object.keys(props.data)[index]}</h3>
                        <hr></hr>
                        <p className="w80 ">{dataInput[Object.keys(props.data)[index]]}</p>
                    </div>,
                );
            }
        }
        return <>{TableauObservation}</>;
    }

    function view() {
        return (
            <>
                <div>
                    <h3 className="xs_textCenter">Informations sur l'élève</h3>
                    <hr></hr>
                    {inputInformation.map((row, i) => (
                        <div key={i} className="textRight alignICenter flex mb2">
                            <label className="w30">{row.label}:</label>
                            <span className="w40 mr5">{props.data[row.indice]}</span>
                        </div>
                    ))}
                    {select.map((row, i) =>
                        Object.keys(props.data).includes(row.indice) ? (
                            <div key={i} className="textRight alignICenter flex mb2">
                                <label className="w30">{row.label}:</label>
                                <FormControl className={[css.nomargin, css.ml30px, css.w40]}>
                                    <Select value={props.data[row.indice]} disabled={true}>
                                        <option value={''}></option>
                                        {listeEnseignant.map((row, index) => {
                                            return (
                                                <option key={index} value={row.idUtilisateur}>
                                                    {row.nomPrenomUtilisateur}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : (
                            <></>
                        ),
                    )}
                </div>
                <div className="mt5">
                    <h3 className="xs_textCenter">Informations sur les scolarités</h3>
                    <hr></hr>
                    <div>
                        <h5 className="textCenter mb1 ml2">Nombre d'années d'instrument(s)</h5>
                        {anneeInstrument.map((e, i) => (
                            <div key={i} className="textRight alignICenter flex mb10px">
                                <label className="w30">{e.label}</label>
                                <span className="ml30px w40 nomargin">{dataInput[e.indice]}</span>
                            </div>
                        ))}
                    </div>
                    {props.data.niveauFM ? (
                        <>
                            <h5 className="textCenter mb1 ml2">Niveau FM</h5>
                            {props.data.niveauFM.map((row, i) => (
                                <div key={i} className="textRight alignICenter flex mb10px">
                                    <label className="w30">-</label>
                                    <span className="ml30px w40 nomargin">{valueInstrument[1].niveauFM[i]}</span>
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {props.data.pratiqueCollective ? (
                        <>
                            <h5 className="textCenter mb1 ml2">Pratiques collectives</h5>
                            {props.data.pratiqueCollective.map((row, i) => (
                                <div key={i} className="textRight alignICenter flex mb10px">
                                    <label className="w30">-</label>
                                    <span className="ml30px w40 nomargin">{valueInstrument[0].pratiqueCollective[i]}</span>
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <hr className="mt5"></hr>
                <div className="flex xs_directionColumn">
                    <div className="flex directionColumn w50 mr5 xs_w100">{ChampsObservation()}</div>
                    <div className="w40 mr5 xs_w100">
                        <h3>Acquisitions de fin de Cycle</h3>
                        <hr></hr>
                        <div className="xs_ml5">{viewGrille()}</div>
                    </div>
                </div>
            </>
        );
    }
    useEffect(() => {
        recupFiltre();
    }, []);
    useEffect(() => {
        const champsInstrument = valueInstrument;
        for (let index = 0; index < Object.keys(props.data).length; index++) {
            if (!verifKeysGeneral.includes(Object.keys(props.data)[index])) {
                updateState(Object.keys(props.data)[index], props.data[Object.keys(props.data)[index]], true);
            } else {
                champsInstrument.splice(Object.keys(props.data)[index] === 'pratiqueCollective' ? 0 : 1, 1, {
                    [Object.keys(props.data)[index]]: props.data[Object.keys(props.data)[index]],
                });
            }
        }
        setValueInstrument(champsInstrument);
    }, [props]);
    useEffect(() => {
        view();
    }, [refresh]);
    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        addSaveData(data) {
            dispatch({ type: 'saveDataGeneral', data });
        },
    };
}

export default connect(null, mapDispatchToProps)(ModuleGeneralView);
