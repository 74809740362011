/**
 * state des Nom de Page
 *
 * @author Rajanan Ganesalingam
 * @copyright Horizon-Pharma
 */

const initialState = {
    idAnneeScolaire: '',
    libelleAnneeScolaire: '',
    status: false,
    token: '',
    user: {
        antennes: [],
        email: '',
        idUtilisateur: 0,
        fk_id: '',
        nomPrenomUtilisateur: '',
        role: '',
    },
    usurpation: false,
    usurpationUtilisateur: {},
};
export default function utilisateur(state = initialState, action) {
    if (action.type === 'CONNEXION') {
        const user = Object.assign(state, action.user);
        user.userC = 'user';
        return user;
    }
    if (action.type === 'USURPATION_UTILISATEUR') {
        const user = state;
        user.usurpation = true;
        user.usurpationUtilisateur = Object.values(action.user)[0];
        user.userC = 'usurpationUtilisateur';
        return user;
    }
    if (action.type === 'REMOVE_USURPATION') {
        const user = state;
        user.usurpation = false;
        user.usurpationUtilisateur = {};
        user.userC = 'user';
        return user;
    }
    if (action.type === 'REMOVE_UTILISATEUR') {
        const newUser = {
            idAnneeScolaire: '',
            libelleAnneeScolaire: '',
            status: false,
            token: '',
            user: {
                antennes: [],
                email: '',
                idUtilisateur: 0,
                fk_id: '',
                nomPrenomUtilisateur: '',
                role: '',
            },
            usurpation: false,
            usurpationUtilisateur: {},
        };
        return newUser;
    }
    return state;
}
