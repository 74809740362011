/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import { GET } from '../../components/Utils/requete';
import anneeInscription from '../../components/Utils/requeteParametre/anneeInscription';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import school from '../../assets/school.png';

const AcceuilInscriptionTarn = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [sites, setSites] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState();
    async function recupSites() {
        const listeSites = await GET('/api/antennes/list', undefined, { redirectOnError: false });
        if (listeSites) {
            const antenne = listeSites.data.filter((e) => e.libelleAntenne !== 'Hors-antenne');
            const antenneFiltre = antenne.filter((e) => e.libelleAntenne !== 'Montredon');
            setSites(antenneFiltre);
            props.antenne(antenne);
        }

        setAnneeScolaire(await anneeInscription());
    }

    useEffect(() => {
        recupSites();
    }, []);
    if (window.matchMedia('(max-width: 576px)').matches || window.matchMedia('(max-width: 1080px)').matches) {
        return (
            <div>
                <p className="textCenter ">
                    Vous ne pouvez pas acceder à cette page sur mobile
                    <br /> <Link to="/accueil">Retour vers l'acceuil</Link>
                </p>
            </div>
        );
    }
    return (
        <div className=" bgcfff h100 ">
            <div className="h100 center flex p10_20 ">
                <div className="bgcBleu688bb7 radius30 border10 w100 h100 ">
                    <div className=" flex spaceAround nomargin mt-2em h10">
                        <div className="alignICenter nomargin bgcfff flex spaceAround radius40 pl1 pr1">
                            <h3 className="nomargin">Bienvenue au Conservatoire de Musique et de Danse du Tarn</h3>
                        </div>
                    </div>
                    <div className="flex directionColumn textCenter nomargin">
                        <span className="cWhite fontSize17 mt2 ">
                            Conservatoire à Rayonnement Départemental géré par un syndicat mixte réunissant 12 collectivités adhérentes
                        </span>
                    </div>
                    <div className="flex mt2">
                        <div className="w60 mr2  flex ">
                            <div className="w40 mr2 ml2  textCenter">
                                <div className="bgc000  radius40 ">
                                    <p className="fontSize17 cWhite ">
                                        9 antennes
                                        <br /> 16 sites d’enseignements
                                    </p>
                                </div>
                                <img src={school} alt="" className="w50 mt20" />
                            </div>
                            <div className="w25 m2 flex directionColumn">
                                {sites.map((row, index) => {
                                    if (index < 10) {
                                        return (
                                            <div key={index}>
                                                <IconButton className={[css.p0, css.mr5, css.cWhite]}>
                                                    <CheckIcon />
                                                </IconButton>
                                                <span className="cWhite">{row.libelleAntenne}</span>
                                            </div>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </div>
                            <div className="w25 m2 flex directionColumn">
                                {sites.map((row, index) => {
                                    if (index >= 10) {
                                        return (
                                            <div key={index}>
                                                <IconButton className={[css.p0, css.mr5, css.cWhite]}>
                                                    <CheckIcon />
                                                </IconButton>
                                                <span className="cWhite">{row.libelleAntenne}</span>
                                            </div>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </div>
                        </div>
                        <div className="w35">
                            <p className="m2 cWhite lineHeight1_5 fontSize1_1em">
                                Notre ambition :<br /> Assurer un enseignement artistique spécialisé de qualité.
                                <br /> Accompagner et développer les pratiques amateurs.
                                <br /> Mettre en œuvre des programmes d’Education Artistique et Culturelle, notamment à travers les partenariats avec
                                l’Education Nationale.
                                <br /> Participer à l’animation culturelle des territoires.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" flex spaceAround">
                <div className="alignICenter bgc000 positionAbsolute  top77 w15 h5 flex spaceAround radius40">
                    <Link to="/inscription/ChoixParcours">
                        <Button className={css.cWhite} size="small" onClick={() => props.dataCategorieEnfant(anneeScolaire.valeur)}>
                            Débuter l'inscription
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataCategorieEnfant(anneeScolaire) {
            dispatch({ type: 'nouvelInscription', anneeScolaire: anneeScolaire });
        },
        antenne(antenne) {
            dispatch({ type: 'antenne', antenne });
        },
    };
};

export default connect(null, mapDispatchToProps)(AcceuilInscriptionTarn);
