/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import Cookies from 'js-cookie';

const UserInactif = (props) => {
    const history = useHistory();
    const setURL = () => {
        Cookies.remove('GestionBDD');
        history.push(props.url);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: setURL,
    });
    return <div />;
};
export default UserInactif;
