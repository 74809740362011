import { GET } from '../requete';

export default async function valeurParametre(categorie, cle) {
    const response = await GET(`/api/parametres/list?categorie=${categorie}`, undefined, {
        redirectOnError: false,
    });
    let valeur = 0;
    if (response) {
        const index = response.data.findIndex((e) => e.cle === cle);
        if (index >= 0) {
            valeur = response.data[index].valeur;
        }
    }
    return valeur;
}
