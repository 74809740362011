/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function order(order = '', action) {
    if (action.type === 'order') {
        const neworder = action.order;
        return neworder;
    }

    return order;
}
