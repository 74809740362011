/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { FormControl, Select } from '@material-ui/core';

import { POST, GET } from '../../../components/Utils/requete';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const SelectInstrument = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [instrument, setInstrument] = useState();
    const [instrumentListe, setInstrumentListe] = useState([]);
    const [sectionsListe, setSectionsListe] = useState([]);
    const [section, setSection] = useState();
    async function recupInstrument() {
        const response = await POST('/api/cotisations/instrumentSections', {
            fk_section: section,
        });
        setInstrumentListe(response.data);
    }

    async function recupSection() {
        let url = '/api/cotisations/section';
        if (props.enseignement) url += `?enseignement=${props.enseignement}`;
        const response = await GET(url);

        setSectionsListe(response.data);
    }
    function returnValue(instrument) {
        setInstrument(instrument);
        props.value(instrument);
    }
    useEffect(() => {
        recupSection();
        if (props.section) setSection(props.section);
        if (props.instrument) setInstrument(props.instrument);
    }, []);
    useEffect(() => {
        recupInstrument();
    }, [section]);

    return (
        <div>
            <div className="textRight alignICenter flex mb10px">
                <span className="w25">Type</span>
                <FormControl className={[css.ml30px, css.w50]}>
                    <Select
                        value={section}
                        native={true}
                        onChange={(event) => {
                            setSection(event.target.value);
                        }}
                    >
                        <option value={''}></option>
                        {sectionsListe.map((elem, index) => {
                            return (
                                <option key={index} value={elem.idSection}>
                                    {elem.libelleSection}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="textRight alignICenter flex mb10px">
                <span className="w25">Discipline</span>
                <FormControl className={[css.ml30px, css.w50]}>
                    <Select
                        disabled={!section}
                        value={instrument}
                        native={true}
                        onChange={(event) => {
                            returnValue(event.target.value);
                        }}
                    >
                        <option value={''}></option>
                        {instrumentListe.map((elem, index) => {
                            return (
                                <option key={index} value={elem.idInstrument}>
                                    {elem.libelleInstrument}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default connect(null, null)(SelectInstrument);
