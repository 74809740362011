/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@material-ui/core/Button';

const Buttons = ({
    variant = 'contained',
    color = 'primary',
    type = 'submit',
    children,
}) => (
    <Button
        style={{ margin: '10px' }}
        variant={variant}
        color={color}
        type={type}
    >
        {children}
    </Button>
);

export default Buttons;
