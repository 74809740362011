import { GET } from '../../components/Utils/requete';

export async function getInformationOffre(idOffre, idSite, idInstrument, idStyle) {
    if (idOffre && idSite) {
        let query = `idOffre=${idOffre}&idSite=${idSite}`;
        if (idInstrument !== null && idInstrument !== undefined && idInstrument.length > 0) {
            query += `&idInstrument=${idInstrument}`;
        }
        if (idStyle !== null && idStyle !== undefined) {
            query += `&idStyle=${idStyle}`;
        }
        const response = await GET(`/api/cotisations/cotisationsInformation?${query}`, undefined, {
            redirectOnError: false,
        });
        return response;
    }
}
