/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function livretDonnees(
    livret = {
        refreshLivret: false,
        idLivret: '',
        fk_livretType: '',
        fk_scolariteInterne: '',
        titre: '',
        visible: false,
        general: {
            fk_eleve: '',
            fk_anneeScolaire: '',
            fk_enseignant: '',
            anneeDisciplinePrincipale: '',
            anneeDisciplineAutre: '',
            niveauAcquisition: {
                noteMax: 5,
                tableau: [],
            },
        },
        discipline: [],
        validation: {
            module: [],
            informationValidation: '',
            informationResultat: '',
            dateResultat: '',
            checkboxResultat: [],
        },
    },
    action,
) {
    if (action.type === 'creation') {
        let newLivret = {
            refreshLivret: false,
            idLivret: '',
            fk_livretType: '',
            fk_scolariteInterne: '',
            titre: '',
            visible: false,
            general: {
                fk_eleve: '',
                fk_anneeScolaire: '',
                fk_enseignant: '',
                anneeDisciplinePrincipale: '',
                anneeDisciplineAutre: '',
                niveauAcquisition: {
                    noteMax: 5,
                    tableau: [],
                },
            },
            discipline: [],
            validation: {
                module: [],
                informationValidation: '',
                informationResultat: '',
                dateResultat: '',
                checkboxResultat: [],
            },
        };
        return newLivret;
    }
    if (action.type === 'ModelDiscipline') {
        let newLivret = livret;
        const editionModule = newLivret.discipline.filter((e) => e.idCritere === action.module.idCritere);
        if (editionModule.length === 0) {
            newLivret.discipline.push(action.module);
        } else {
            newLivret.discipline.splice(action.module.idCritere - 1, 1, action.module);
        }
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }
    if (action.type === 'ModelGeneral') {
        let newLivret = livret;
        if (action.racine !== undefined) {
            newLivret[Object.keys(action.module)[0]] =
                Object.keys(action.module)[0] === 'visible'
                    ? Object.values(action.module)[0] === true
                        ? 1
                        : 0
                    : Object.values(action.module)[0];
            return newLivret;
        }
        newLivret.general[Object.keys(action.module)[0]] = Object.values(action.module)[0];
        return newLivret;
    }
    if (action.type === 'ModuleValidation') {
        let newLivret = livret;
        const editionModule = newLivret.validation.module.filter((e) => e.idModule === action.module.idModule);
        if (editionModule.length === 0) {
            newLivret.validation.module.push(action.module);
        } else {
            newLivret.validation.module.splice(action.module.idCritere - 1, 1, action.module);
        }
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }
    if (action.type === 'ModuleResultat') {
        let newLivret = livret;
        for (let i = 0; i < Object.keys(action.module).length; i++) {
            newLivret.validation[Object.keys(action.module)[i]] = Object.values(action.module)[i];
        }
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }
    if (action.type === 'saveDataGeneral') {
        let newLivret = livret;
        newLivret.general[Object.keys(action.data)] = Object.values(action.data)[0];
        return newLivret;
    }
    if (action.type === 'saveDataDiscipline') {
        let newLivret = livret;
        newLivret.discipline[action.idCritere - 1].donnees.criteres[action.idInput].valeur = Object.values(action.data)[0];
        return newLivret;
    }
    if (action.type === 'saveDataCheckbox') {
        let newLivret = livret;
        if (newLivret.validation.checkboxResultat[action.idCheckbox]) {
            newLivret.validation.checkboxResultat[action.idCheckbox].valeur =
                !newLivret.validation.checkboxResultat[action.idCheckbox].valeur;
        }
        return newLivret;
    }
    if (action.type === 'saveDataValidation') {
        let newLivret = livret;
        if (!action.idModule) {
            newLivret.validation.dateResultat = Object.values(action.data)[0];
            return newLivret;
        }
        if (action.idPiece === undefined) {
            newLivret.validation.module[action.idModule - 1][Object.keys(action.data)] = Object.values(action.data)[0];
            return newLivret;
        }
        newLivret.validation.module[action.idModule - 1].piece[action.idPiece][Object.keys(action.data)] = Object.values(
            action.data,
        )[0];
        return newLivret;
    }
    if (action.type === 'updateNote') {
        const newLivret = livret;
        newLivret.general.niveauAcquisition.tableau[action.index].value = action.note;
        return newLivret;
    }
    if (action.type === 'getData') {
        const newLivret = action.data;
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }
    if (action.type === 'delete') {
        const newLivret = livret;
        if (newLivret.general[action.key] !== undefined && action.module === 'general') {
            delete newLivret.general[action.key];
            newLivret.refreshLivret = !newLivret.refreshLivret;
        }
        if (action.key !== undefined && action.module === 'resultat') {
            newLivret.validation.checkboxResultat.splice(action.key, 1);
            newLivret.refreshLivret = !newLivret.refreshLivret;
        }
        if (action.key !== undefined && action.module === 'validation') {
            newLivret.validation.module[+action.idModule - 1].piece.splice(action.key, 1);
            newLivret.refreshLivret = !newLivret.refreshLivret;
        }
        return newLivret;
    }
    if (action.type === 'deleteDiscpline') {
        const newLivret = livret;
        const newDiscipline = newLivret.discipline.filter((e) => e.idCritere != action.idDiscipline);
        let tableauDonnee = [];
        for (let index = 0; index < newDiscipline.length; index++) {
            const data = {
                idCritere: index + 1,
                titre: newDiscipline[index].titre,
                critere: newDiscipline[index].critere,
                donnees: newDiscipline[index].donnees,
            };
            tableauDonnee.push(data);
        }
        newLivret.discipline = tableauDonnee;
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }
    if (action.type === 'deleteModuleValidation') {
        const newLivret = livret;
        const newModuleValidation = newLivret.validation.module.filter((e) => e.idModule != action.idModule);
        let tableauModuleValidation = [];
        for (let index = 0; index < newModuleValidation.length; index++) {
            const data = {
                idModule: index + 1,
                titre: newModuleValidation[index].titre,
                date: newModuleValidation[index].date,
                informationModule: newModuleValidation[index].informationModule,
                infoComplementaire: newModuleValidation[index].infoComplementaire,
                commentaire: newModuleValidation[index].commentaire,
                piece: newModuleValidation[index].piece,
            };
            tableauModuleValidation.push(data);
        }
        newLivret.validation.module = tableauModuleValidation;
        newLivret.refreshLivret = !newLivret.refreshLivret;
        return newLivret;
    }

    return livret;
}
