/**
 * state des Nom de Page
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function notification(
    notification = {
        message: null,
        status: false,
    },
    action,
) {
    switch (action.type) {
        case 'notification':
            const newNotification = {
                message: action.note.message,
                status: action.note.status ? action.note.status : false,
            };
            return newNotification;
        case 'removeNote':
            return (notification = {
                message: null,
                status: false,
            });
        default:
            break;
    }

    return notification;
}
