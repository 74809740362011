import React from 'react';
import { Link } from 'react-router-dom';

import ListeButton from '../../atoms/ButtonCustom';

const ExportCSV = (props) => {
    if (window.matchMedia('(max-width: 576px)').matches) {
        return <></>;
    }
    const tableauData = [[]];
    const dataKey = [];
    let csvRow = [];
    for (let elem = 0; elem < props.head.length; elem++) {
        tableauData[0].push(props.head[elem].label);
        dataKey.push(props.head[elem].value);
    }
    for (let item = 0; item < props.data.length; item++) {
        const bouh = [];
        for (let i = 0; i < dataKey.length; i++) {
            bouh.push(props.data[item][dataKey[i]]);
        }
        tableauData.push(bouh);
    }
    for (let i = 0; i < tableauData.length; ++i) {
        csvRow.push(tableauData[i].join(','));
    }
    let csvString = csvRow.join('%0D%0A');
    let lienCSV = document.createElement('a');
    lienCSV.href = 'data:text/csv;charset=utf-8,' + csvString;
    lienCSV.target = '_Blank';
    lienCSV.download = 'file.csv';
    document.body.appendChild(lienCSV);

    return (
        <Link>
            <ListeButton
                variant="outlined"
                size="small"
                onClick={() => {
                    lienCSV.click();
                }}
            >
                CSV
            </ListeButton>
        </Link>
    );
};

export default ExportCSV;
