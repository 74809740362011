export const Lien_responsable = 215;
export const Trie_annee_scolaire = 216;
export const Calcul_cotisation = 217;
export const Export_excel_liste_cotisation = 218;
export const Exonération_direction = 240;
export const Trie_site_liste_cotisation = 241;
export const Export_excel_liste_reglement = 220;
export const Trie_site_liste_reglement = 242;
export const Ajouter_un_reglement = 272;
export const Modifier_un_reglement = 273;
export const Supprimer_un_reglement = 274;
export const Resilier_abonnement = 275;
export const paiement_refus = 276;
export const trie_sur_agent = 277;
