/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tabs, Tab } from '@material-ui/core';

import { GET } from '../../../components/Utils/requete';
import TabPanel from '../../../components/organism/Onglet/TabPanel';
import ModuleDisciplineView from './module/ModuleDisciplineView';
import ModuleValidationView from './module/ModuleValidationView';
import ModuleGeneralView from './module/ModuleGeneralView';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import * as fonctionLivret from '../../../fonctionsRoles/livret';

const EleveLivret = (props) => {
    let { id } = useParams();
    let { type } = useParams();
    const css = useStylesMaterielUIGlobal();
    const donneeslivret = useSelector((state) => state.LivretDonnees);
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const inputDisabled = type === 'creation' && !testDroit(user[usurpation].roleFonction, fonctionLivret.Modification_model);
    const [value, setValue] = useState(0);
    const [viewModule, setViewModule] = useState([]);
    const [onglet, setOnglet] = useState('general');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function dataView(data, module) {
        let element = [];
        let moduleView;
        setOnglet(module);
        switch (module) {
            case 'general':
                if (data.general) {
                    moduleView = <ModuleGeneralView data={data.general} disabled={inputDisabled} />;
                    element.push(moduleView);
                }
                break;
            case 'discipline':
                if (data.discipline) {
                    data.discipline.map((row, index) => {
                        moduleView = <ModuleDisciplineView key={index} data={row} disabled={inputDisabled} />;
                        return element.push(moduleView);
                    });
                }
                break;
            case 'validation':
                if (data.validation) {
                    moduleView = <ModuleValidationView data={data.validation} disabled={inputDisabled} />;
                    element.push(moduleView);
                }
                break;
            default:
                break;
        }
        setViewModule(element);
    }
    async function getData() {
        const response = await GET(`/api/livrets/${id}/${type}`, undefined, { redirectOnError: false });
        props.getdata(response.data);
    }

    useEffect(() => {
        props.addNomPage('Livret élève');
        getData();
    }, []);
    useEffect(() => {
        dataView(donneeslivret, onglet);
    }, [refresh]);

    return (
        <div className=" ml7 mt2 mr7 overflowAuto h87" style={{ opacity: '0.9', backgroundColor: '#fff' }}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                <Tab label="Synthèse" onClick={() => dataView(donneeslivret, 'general')} />
                <Tab
                    label="Compétences "
                    onClick={() => {
                        dataView(donneeslivret, 'discipline');
                    }}
                />
                <Tab label="Validation" onClick={() => dataView(donneeslivret, 'validation')} />
            </Tabs>
            <h1 className="textCenter xs_fontSize20px">{donneeslivret.titre}</h1>
            <TabPanel value={value} index={0} className={css.smallContainer}>
                {viewModule}
            </TabPanel>
            <TabPanel value={value} index={1} className={css.smallContainer}>
                {viewModule}
            </TabPanel>
            <TabPanel value={value} index={2} className={css.smallContainer}>
                {viewModule}
            </TabPanel>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        getdata(data) {
            dispatch({ type: 'getData', data });
        },
    };
}

export default connect(null, mapDispatchToProps)(EleveLivret);
