import { GET } from './requete';

export default async function recupCommune(codePostal) {
    const listeVille = [];
    if (5 === codePostal.length) {
        const response = await GET(
            `/api/geoquery?q=${codePostal}&postcode=${codePostal}&limit=100&type=municipality`,
            undefined,
            {
                redirectOnError: false,
            },
        );
        if (200 === response.status) {
            response.features.forEach((feature) => {
                listeVille.push(feature.properties.city);
            });
        }
    }
    listeVille.sort();
    return listeVille;
}
