export default function dateSave(noH) {
    const date = new Date();
    const formatDate =
        date.toLocaleDateString('fr-FR').replace(/\//g, '-').split('-').reverse().join('-') +
        ' ' +
        date.toLocaleTimeString('fr-FR');
    const formatDateNoH = formatDate.split(' ');
    if (noH === true) {
        return formatDateNoH[0];
    } else {
        return formatDate;
    }
}
