/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function editModule(idModule = null, action) {
    if (action.type === 'editModule') {
        const newIdModule = action.idModule;
        return newIdModule;
    }

    return idModule;
}
