/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { TextareaAutosize } from '@material-ui/core';

import useStylesMaterielUIGlobal from '../../../../styles/StyleMaterielUIGlobal';

const ModuleDisciplineView = (props) => {
    const css = useStylesMaterielUIGlobal();
    const refresh = useSelector((state) => state.LivretDonnees.refreshLivret);
    const [dataInput, setDataInput] = useState({});
    function updateState(keys, valueI, idInput) {
        if (idInput !== undefined) {
            props.addSaveData({ [keys]: valueI }, props.data.idCritere, idInput);
        }
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function view() {
        return (
            <div id={props.data.idCritere} className="mb2">
                <hr></hr>
                <div className="flex center">
                    <h3 className="textCenter">{props.data.titre}</h3>
                </div>
                <div>
                    <div className="flex mt1">
                        <h4 className="w40">Domaines d’acquisitions :</h4>
                        <div className="flex directionColumn w50 ml30px">
                            {props.data.critere.split('\n').map((e, index) => {
                                return (
                                    <>
                                        <span key={index}>{`• ${e}`}</span>
                                        <br />
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    {props.data.donnees.criteres.map((i, index) => {
                        return (
                            <div key={index} className="flex mt1">
                                <div className="flex directionColumn w40">
                                    <h4 className="nomargin">{i.titre}:</h4>
                                    <p className="fontSmallGrey">{i.description}</p>
                                </div>
                                <TextareaAutosize
                                    className={css.textareaML30pxW50}
                                    rowsMin={5}
                                    value={dataInput[props.data.donnees.criteres[index].titre]}
                                    disabled={true}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    useEffect(() => {
        for (let index = 0; index < props.data.donnees.criteres.length; index++) {
            updateState(props.data.donnees.criteres[index].titre, props.data.donnees.criteres[index].valeur);
        }
    }, []);
    useEffect(() => {
        view();
    }, [refresh]);
    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        addSaveData(data, idCritere, idInput) {
            dispatch({ type: 'saveDataDiscipline', data, idCritere, idInput });
        },
    };
}
export default connect(null, mapDispatchToProps)(ModuleDisciplineView);
