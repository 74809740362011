/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Select, TextareaAutosize, Switch, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import Input from '../../components/atoms/TextField';
import Container from '../../components/atoms/Container/container';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import { GET, POST } from '../../components/Utils/requete';
import DateInput from '../../components/organism/Input/date';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHead } from './const';

const ListesParametres = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [data, setData] = useState([]);
    const [dataRequete, setDataRequete] = useState([]);
    const [listeCategorie, setListeCategorie] = useState([]);
    const [disabledSave, setDisabledSave] = useState();
    const [categorie, setCategorie] = useState('TOUTE LES CATEGORIES');

    function updateChamps(keys, valueI, index) {
        if (disabledSave === undefined) {
            setDisabledSave(index);
        } else if (disabledSave === index && dataRequete[index].valeur === valueI) {
            setDisabledSave();
        } else if (disabledSave !== index) {
            return props.notification({ message: "Vous devez finir l'operation en cours" });
        }
        const creationNouvelleReference = [];
        for (let elem = 0; elem < data.length; elem++) {
            creationNouvelleReference.push({});
            Object.assign(creationNouvelleReference[elem], data[elem]);
            if (elem === +index) {
                creationNouvelleReference[elem][keys] = valueI;
            }
        }
        setData(creationNouvelleReference);
    }

    async function getCategorie() {
        const response = await GET('/api/parametres/listCategorie');
        setListeCategorie(response.data);
    }

    async function recupParametre() {
        let query = '/api/parametres/list';
        if (categorie !== 'TOUTE LES CATEGORIES') query = query + `?categorie=${categorie}`;
        const response = await GET(query);
        setData(response.data);
        setDataRequete(response.data);
    }
    function CelluleValeur(props, index) {
        switch (props.type) {
            case 'input':
                return (
                    <Input value={props.valeur} className={[css.nomargin, css.w80]} onChange={(event) => updateChamps('valeur', event.target.value, index)} />
                );
            case 'integer':
                return (
                    <Input
                        type="number"
                        value={props.valeur}
                        className={[css.nomargin, css.w40]}
                        onChange={(event) => updateChamps('valeur', event.target.value, index)}
                    />
                );
            case 'date':
                return (
                    <DateInput
                        value={new Date(props.valeur)}
                        format="dd/MM/yyyy"
                        classeDiv="nomargin flex"
                        className={css.w30}
                        onChange={(event) => updateChamps('valeur', DateHeureFormat({ BDD: 'date', date: new Date(event) }), index)}
                    />
                );
            case 'select':
                return (
                    <Select
                        className={css.w80}
                        value={props.valeur}
                        onChange={(event) => {
                            updateChamps('valeur', event.target.value, index);
                        }}
                    >
                        {props.valeursPossibles.map((elem, index) => {
                            return (
                                <option key={index} value={elem.id}>
                                    {elem.libelle}
                                </option>
                            );
                        })}
                    </Select>
                );
            case 'textarea':
                return (
                    <TextareaAutosize
                        className={css.textareaW90M0P0}
                        rowsMin={3}
                        value={props.valeur}
                        onChange={(event) => {
                            updateChamps('valeur', event.target.value, index);
                        }}
                    />
                );
            case 'boolean':
                return <Switch checked={+props.valeur === 0 ? false : true} onChange={() => updateChamps('valeur', +props.valeur === 1 ? 0 : 1, index)} />;
            default:
                return <></>;
        }
    }
    async function updateBDD(index) {
        const dataRequete = {
            cle: data[index].cle,
            valeur: data[index].valeur,
            categorie: data[index].categorie,
        };
        await POST('/api/parametres/save', dataRequete);
        recupParametre();
        setDisabledSave();
    }

    useEffect(() => {
        props.addNomPage('Paramètres');
        recupParametre();
        getCategorie();
    }, [categorie]);

    useEffect(() => {
        getCategorie();
    }, []);

    if (window.matchMedia('(max-width: 576px)').matches) {
        return <p className="textCenter ">Vous ne pouvez pas gérer les paramètres sur mobile</p>;
    }
    if (window.matchMedia('(max-width: 576px)').matches || window.matchMedia('(max-width: 1080px)').matches) {
        return <p className="textCenter ">Vous ne pouvez pas accéder à cette page sur mobile</p>;
    }
    return (
        <div className="listeContainer">
            <Container>
                <div className="flex directionColumn">
                    <label>Catégorie des paramètres</label>
                    <Select
                        className={[css.w40, css.mb2]}
                        value={categorie}
                        onChange={(event) => {
                            setCategorie(event.target.value);
                        }}
                    >
                        <option value={'TOUTE LES CATEGORIES'}>TOUTE LES CATEGORIES</option>
                        <hr></hr>
                        {listeCategorie.map((elem, index) => (
                            <option key={index} value={elem.categorie}>
                                {elem.categorie}
                            </option>
                        ))}
                    </Select>
                </div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {data.map((row, index) => {
                                const indexData = data.findIndex(
                                    // eslint-disable-next-line eqeqeq
                                    (e) => e == row,
                                );
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={[css.td, css.w10]} component="th" scope="row">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                className={[css.mr2]}
                                                disabled={disabledSave !== indexData}
                                                onClick={() => updateBDD(indexData)}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                className={[css.mr2]}
                                                style={disabledSave !== indexData ? { display: 'none' } : null}
                                                onClick={() => {
                                                    setDisabledSave();
                                                    setData(dataRequete);
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={[css.td, css.w10]} component="th" scope="row">
                                            <div>Categorie</div>
                                            {row.categorie}
                                        </TableCell>
                                        <TableCell className={[css.td, css.w10]} component="th" scope="row">
                                            <div>Cle</div>
                                            {row.cle}
                                        </TableCell>
                                        <TableCell className={[css.td, css.fontSize0_9em]} component="th" scope="row">
                                            <div>Valeur</div>
                                            {CelluleValeur(row, index)}
                                        </TableCell>
                                        <TableCell className={[css.td, css.fontSize0_9em, css.w20]} component="th" scope="row">
                                            <div>Description</div>
                                            {row.commentaire}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ListesParametres);
