/**
 * Style des composants HTML
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */
import styled from 'styled-components';

const ContainerLayout = styled.div`
  height: 100%;
`;

const Box = styled.div`
  width: 100%;
  margin: 50px auto 0px auto;
`;

const Center = styled.div`
  width: 100%;
`;
const Main = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const BasicForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const Link = styled.a`
    color: red;
    margin: 15px;
`;

export {
    ContainerLayout,
    Box,
    Center,
    Main,
    BasicForm,
    Link,
};
