/**
 * @author Rajanan Ganesalingam
 * @copyright Horizon-pharma
 * @module Routes
 */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import { Main, BasicForm } from '../style';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/TextField';
import { POST } from '../../../components/Utils/requete';

const MotDePasseOublie = () => {
    const { register, handleSubmit, setError, clearErrors, errors } = useForm();
    const [success, setSuccess] = useState('');
    const onSubmit = async (data) => {
        const response = await POST('/api/askNewPassword', data, { redirectOnError: false });
        response.success && setSuccess(response.message);
        !response.success &&
            setError('onSubmit', {
                types: {
                    message: response.message,
                },
            });
    };

    return (
        <Main>
            <Paper
                style={{
                    padding: '15px',
                    marginTop: '25px',
                    borderRadius: '25px',
                    maxWidth: '400px',
                }}
                elevation={3}
            >
                <h2>Mot de passe oublié</h2>
                <p>Saisissez votre adresse email, votre mot de passe sera ré-initialisé puis vous sera envoyé par email.</p>
                <BasicForm onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        label="Email"
                        name="email"
                        onChange={() => clearErrors(['onSubmit'])}
                        inputRef={register({
                            pattern:
                                // eslint-disable-next-line no-useless-escape
                                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        })}
                    />
                    {errors.onSubmit && errors.onSubmit.types.message}
                    {errors.email && 'Entrez un email valide'}
                    {success && success}
                    <Button>Envoyer le nouveau mot de passe</Button>
                </BasicForm>
            </Paper>
        </Main>
    );
};

export default MotDePasseOublie;
