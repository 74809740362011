/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';

import correct from '../../assets/correct.png';
import error from '../../assets/false.png';

const RetourPaybox = () => {
    const { statut } = useParams();
    function SwitchView() {
        let image;
        let texte;
        switch (statut) {
            case 'success':
                image = correct;
                texte = 'Le paiement est accepté.';
                break;
            case 'error':
            case 'cancelled':
                image = error;
                texte = 'Le paiement est refusé.';
                break;
            case 'waiting':
                image = error;
                texte = 'Le paiement est en attente.';
                break;
            default:
                image = error;
                texte = "Une erreur c'est produit.";
                break;
        }
        return (
            <div className="mt20 w100 flex center">
                <img src={image} alt="" className="w15 " />
                <div className="mt2">
                    <h1 className="">{texte}</h1>
                    <Link to="/accueilResponsable">
                        <Button variant="outlined" size="small">
                            Revenir sur l'accueil
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
    return (
        <div className="flex">
            <SwitchView />
        </div>
    );
};

export default connect(null, null)(RetourPaybox);
