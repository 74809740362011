import React, { useRef, useState } from 'react';

import { Popper, Paper, Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function SplitButton(props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    return (
        <>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button variant="outlined" size="small" onClick={() => setOpen(!open)}>
                    Excel
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current}>
                <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <MenuList>
                            {props.options.map((option, index) => (
                                <MenuItem key={index} onClick={() => setOpen(false)}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}
