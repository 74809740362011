/* eslint-disable react-hooks/exhaustive-deps */
/**
 *   @author Viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Tabs, Tab } from '@material-ui/core';
import CustomTooltips from '../Tooltip/customTooltip';
import { testDroit } from '../../Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const InfoAgent = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    let { id } = useParams();
    let br = <></>;
    if (window.matchMedia('(max-width: 576px)').matches) {
        br = <br></br>;
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let lien;

    const profil = testDroit(user[usurpation].roleFonction, props.idProfil) ? (
        <>
            <CustomTooltips title="Éditer l'Agent">
                <Link to={`/fiche-agent/${props.idUtilisateur}`} className={[css.p0, css.ml10px, css.cVert]}>
                    Profil
                </Link>
            </CustomTooltips>
            {br}
        </>
    ) : (
        <></>
    );
    const service = testDroit(user[usurpation].roleFonction, props.idService) ? (
        <>
            <CustomTooltips title="Éditer les services">
                <Link to={`/service/${props.idUtilisateur}`} className={[css.p0, css.ml10px, css.cVert]}>
                    Services
                </Link>
            </CustomTooltips>
            {br}
        </>
    ) : (
        <></>
    );
    const cours = testDroit(user[usurpation].roleFonction, props.idCours) ? (
        <CustomTooltips title="Voir les cours">
            <Link
                to={`/mes-cours/${props.idUtilisateur}`}
                className={[css.p0, css.ml10px, css.cVert]}
                onClick={() =>
                    props.reponseRecherche({
                        fn: (items) => {
                            return items;
                        },
                    })
                }
            >
                Cours
            </Link>
        </CustomTooltips>
    ) : (
        <></>
    );
    switch (props.type) {
        case 'profil':
            lien = [service, cours];
            break;
        case 'services':
            lien = [profil, cours];
            break;
        case 'cours':
            lien = [profil, service];
            break;
        default:
            lien = [profil, service, cours];
            break;
    }

    if (props.liste) return <span className={props.className}>{lien}</span>;

    return (
        <>
            {(testDroit(user[usurpation].roleFonction, props.idService) ||
                testDroit(user[usurpation].roleFonction, props.idCours) ||
                testDroit(user[usurpation].roleFonction, props.idProfil)) &&
            +id !== user[usurpation].fk_id ? (
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="inherit"
                    textColor="inherit"
                    variant="fullWidth"
                    style={
                        window.matchMedia('(max-width: 576px)').matches
                            ? { backgroundColor: '#91c023' }
                            : { backgroundColor: '#91c023', position: 'fixed', width: 'calc(100% - 210px)', zIndex: '2', top: '64px' }
                    }
                >
                    <Link to={`/fiche-agent/${props.idUtilisateur}`} style={{ width: '33.3%', textAlign: 'center' }}>
                        <Tab label="Profil" />
                    </Link>
                    {testDroit(user[usurpation].roleFonction, props.idService) ? (
                        <Link to={`/service/${props.idUtilisateur}`} style={{ width: '33.3%', textAlign: 'center' }}>
                            <Tab label="Services" />
                        </Link>
                    ) : null}
                    {testDroit(user[usurpation].roleFonction, props.idCours) ? (
                        <Link
                            to={`/mes-cours/${props.idUtilisateur}`}
                            style={{ width: '33.3%', textAlign: 'center' }}
                            onClick={() =>
                                props.reponseRecherche({
                                    fn: (items) => {
                                        return items;
                                    },
                                })
                            }
                        >
                            <Tab label="Cours" />
                        </Link>
                    ) : null}
                </Tabs>
            ) : null}
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
    };
}

export default connect(null, mapDispatchToProps)(InfoAgent);
