import React from 'react';
import { connect } from 'react-redux';

import CurrencyInput from 'react-currency-input-field';

export const InputMonetaire = (props) => {
    return <CurrencyInput className="ml30px w50 pl1" suffix={'€'} value={props.value} onValueChange={props.onChange} />;
};

export default connect(null, null)(InputMonetaire);
