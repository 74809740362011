export default function creationTableauFiltre(tableau, idItem, lib1, lib2) {
    const tableauData = [];
    for (let elem = 0; elem < tableau.length; elem++) {
        let arg = '';
        if (lib2 !== undefined) {
            arg = tableau[elem][lib2];
        }
        const data = {
            id: tableau[elem][idItem],
            libelle: `${tableau[elem][lib1]} ${arg}`,
        };
        tableauData.push(data);
    }
    return tableauData;
}
