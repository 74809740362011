/* eslint-disable no-else-return */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

export default function selectSimple(
    filtre = {
        refreshSimple: false,
        Style: '',
        AnneeScolaire: '',
        AnneeScolaireAbs: '',
        Offre: '',
        Cycle: '',
        Annee: '',
        Statut: '',
        Instrument: '',
        Site: '',
        Niveau: '',
        Etablissement: '',
        AtelierIndividuel: '',
        Roles: '',
        Carburant: '',
        Corps: '',
        Grade: '',
        Filiere: '',
        Diplome: '',
        Emploi: '',
        Contrat: '',
        Actif: '',
        Jour: '',
        Repetition: '',
        Visibilite: '',
        GroupeAtelier: '',
        SousGroupeAtelier: '',
        Salle: '',
        Groupe: '',
        Genre: '',
        RolePMT: '',
        Civilite: '',
        SocialCateg: '',
        StatutFrais: '',
        TypeJour: '',
        StatusAbsence: '',
        Eleve: '',
        ScolariteInterne: '',
        AbsenceObjet: '',
        Enseignant: '',
        StyleOffre: '',
        Reglement: '',
        SiteResidence: '',
    },
    action,
) {
    if (action.type === 'simple') {
        const newFiltre = filtre;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < action.filtre.length; i++) {
            // eslint-disable-next-line prefer-destructuring
            newFiltre[Object.keys(action.filtre[i])] = Object.values(action.filtre[i])[0];
        }
        newFiltre.refreshSimple = !newFiltre.refreshSimple;
        return newFiltre;
    } else if (action.type === 'removeFiltre') {
        const newFiltre = {
            refreshSimple: !filtre.refreshSimple,
            Style: '',
            AnneeScolaire: '',
            Offre: '',
            Cycle: '',
            Annee: '',
            Statut: '',
            Instrument: '',
            Site: '',
            Niveau: '',
            Etablissement: '',
            AtelierIndividuel: '',
            Roles: '',
            Carburant: '',
            Corps: '',
            Grade: '',
            Filiere: '',
            Diplome: '',
            Emploi: '',
            Contrat: '',
            Actif: '',
            Jour: '',
            Repetition: '',
            Visibilite: '',
            GroupeAtelier: '',
            SousGroupeAtelier: '',
            Salle: '',
            Genre: '',
            RolePMT: '',
            Groupe: '',
            Civilite: '',
            SocialCateg: '',
            StatutFrais: '',
            TypeJour: '',
            StatusAbsence: '',
            Eleve: '',
            ScolariteInterne: '',
            AbsenceObjet: '',
            Enseignant: '',
            StyleOffre: '',
            Reglement: '',
            SiteResidence: '',
        };
        return newFiltre;
    }
    return filtre;
}
