/* -------Liste agent----------------- */
export const tableHead = [
    { id: 'nomPrenomUtilisateur', label: 'Agents' },
    { id: 'telephoneFixe', label: 'Fixe' },
    { id: 'telephoneMobile', label: 'Mobile' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Rôle' },
];
export const agentActif = [
    { id: 1, label: 'Agents actif' },
    { id: 0, label: 'Agents inactif' },
    { id: 2, label: 'Tous les agents' },
];
export const acceptedRoles = ['SuperAdmin', 'Administratif', 'Enseignant Resp.'];
export const acceptedRolesDelete = ['SuperAdmin', 'Administratif'];
export const tableHeadExport = [
    { label: 'Nom', value: 'nomPrenomUtilisateur' },
    { label: 'Fixe', value: 'telephoneFixe' },
    { label: 'Mobile', value: 'telephoneMobile' },
    { label: 'Email', value: 'email' },
    { label: 'Rôle', value: 'role' },
    { label: 'ID', value: 'idUtilisateur' },
];
export const roles = [
    { id: 'SuperAdmin', lib: 'SuperAdmin' },
    { id: 'Administratif', lib: 'Administratif' },
    { id: 'Enseignant', lib: 'Enseignant' },
    { id: 'Chargé de Formation', lib: 'Chargé de Formation' },
    { id: 'Opérateur extérieur', lib: 'Opérateur extérieur' },
];
/* -------Fiche agent----------------- */
export const carburant = [
    { id: 'Essence', lib: 'Essence' },
    { id: 'Diesel', lib: 'Diesel' },
    { id: 'Electrique', lib: 'Electrique' },
    { id: 'Hybride', lib: 'Hybride' },
];

/* -------Liste frais agent----------------- */
const Brouillon = 1;
const Soumis = 2;
const EnCours = 3;
const Accepte = 4;
const Refuse = 5;
/* gestion des badges status */
export const statutFraisCouleur = {
    [Brouillon]: '#gray',
    [Soumis]: '#41b5a9',
    [EnCours]: '#2e76b0',
    [Accepte]: '#78cf99',
    [Refuse]: '#f26a44',
};
export const tableHeadListeFrais = [
    { id: 'dateDemande', label: 'Date demande' },
    { id: 'periode', label: 'Période' },
    { id: 'kilometrage', label: 'Kilometrage' },
    { id: 'nombreRepas', label: 'Nombre Repas' },
    { id: 'montantDivers', label: 'Montant Divers' },
    { id: 'libelleFraisStatut', label: 'Statut' },
];
