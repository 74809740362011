/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { TextareaAutosize } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';

import ButtonLivret from '../ButtonLivret';
import Input from '../../../components/atoms/TextField';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { verifKeysResultat, verifKeysValidation } from '../consts';

const ModuleValidationCreate = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [addInputModule, setAddInputModule] = useState([]);
    const [addInputResultat, setAddInputResultat] = useState([]);
    const idModule = useSelector((state) => state.EditModuleLivret);
    const livretDonnees = useSelector((state) => state.LivretDonnees);
    const [afterDelete, setAfterDelete] = useState(false);
    const [dataInput, setDataInput] = useState({
        titre: '',
        critere: '',
        informationModule: '',
        infoComplementaire: '',
        informationValidation: '',
        informationResultat: '',
    });
    function update(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function deleteChamps(idChamps, module, idModule) {
        props.deleteChamp(idChamps, module, idModule);
        setAfterDelete(!afterDelete);
    }
    function addElement(module, data, idModule) {
        let variableInput =
            module === 'validation' ? `TitreModule${addInputModule.length}` : `TitreResultat${addInputResultat.length}`;
        const state = module === 'validation' ? setAddInputModule : setAddInputResultat;
        const titre = module === 'validation' ? 'titre du module' : 'titre du passage';
        let element = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                variableInput = module === 'validation' ? `TitreModule${index}` : `TitreResultat${index}`;
                element.push(
                    <div className="flex mt1">
                        <div className="flex mt1 w100">
                            <div className="flex alignICenter w100">
                                {index > 0 ? (
                                    <ButtonLivret
                                        type={'icone'}
                                        label={<IndeterminateCheckBoxIcon />}
                                        tooltip={'Suppimer un champs'}
                                        className={css.cVert}
                                        onClick={() => deleteChamps(index, module, idModule)}
                                    />
                                ) : null}
                                <label className="mr2">Titre:</label>
                                <span>{data[index].titre}</span>
                            </div>
                        </div>
                    </div>,
                );
                update(variableInput, data[index].titre);
            }
            state(element);
        } else {
            element = (
                <div className="flex mt1">
                    <div className="w100">
                        <label className="mr10">{titre}:</label>
                        <Input
                            className={[css.ml30px, css.w100, css.nomargin, css.mb1]}
                            value={dataInput[variableInput]}
                            onChange={(event) => update(variableInput, event.target.value)}
                        />
                    </div>
                    <br />
                </div>
            );
            state((prevState) => [...prevState, element]);
        }
    }
    function createModule() {
        const piece = () => {
            const element = [];
            for (let elem = 0; elem < addInputModule.length; elem++) {
                const critere = {
                    titre: dataInput[`TitreModule${elem}`],
                    valeur: '',
                };
                element.push(critere);
            }
            return element;
        };
        const data = {
            idModule: idModule ? idModule : props.data.validation.module.length + 1,
            titre: dataInput.titre,
            date: '',
            informationModule: dataInput.informationModule,
            infoComplementaire: dataInput.infoComplementaire,
            commentaire: '',
            piece: piece(),
        };
        props.addModuleValidation(data);
        setAddInputModule([]);
        props.editModule(null);
    }
    function createResultat() {
        const checkbox = () => {
            const element = [];
            for (let elem = 0; elem < addInputResultat.length; elem++) {
                const critere = {
                    titre: dataInput[`TitreResultat${elem}`],
                    valeur: false,
                };
                element.push(critere);
            }
            return element;
        };
        const data = {
            informationValidation: dataInput.informationValidation,
            informationResultat: dataInput.informationResultat,
            dateResultat: '',
            checkboxResultat: checkbox(),
        };
        props.addModuleResultat(data);
    }
    function view() {
        return (
            <>
                <div className="flex">
                    <div className="flex directionColumn w40">
                        <div>
                            <ButtonLivret
                                type={'button'}
                                label={'Ajouter le module'}
                                onClick={() => createModule(props.id)}
                                className={css.mb2}
                            />
                        </div>
                        <h3 className="textCenter">Module de validation</h3>
                        <div>
                            <div className="flex mt1">
                                <div className="w100">
                                    <label className="mr10">Titre :</label>
                                    <Input
                                        value={dataInput.titre}
                                        className={[css.ml30px, css.w100, css.nomargin, css.mb1]}
                                        onChange={(event) => update('titre', event.target.value)}
                                    />
                                </div>
                            </div>
                            <h5>Information sur le (module)</h5>
                            <TextareaAutosize
                                value={dataInput.informationModule}
                                className={css.textareaML30pxW80MB1emMT0}
                                rowsMin={5}
                                onChange={(event) => update('informationModule', event.target.value)}
                            />
                            <h5>Information complémentaire sur le (module)</h5>
                            <TextareaAutosize
                                value={dataInput.infoComplementaire}
                                className={css.textareaML30pxW80MB1emMT0}
                                rowsMin={5}
                                onChange={(event) => update('infoComplementaire', event.target.value)}
                            />
                            <div>
                                <div className="flex mt1">
                                    <h5>Pièce</h5>
                                    <ButtonLivret
                                        type={'icone'}
                                        label={<AddBoxIcon />}
                                        tooltip={'Ajouter une pièce'}
                                        onClick={() => addElement('validation')}
                                        className={css.cVert}
                                    />
                                </div>
                                {addInputModule}
                            </div>
                        </div>
                    </div>
                    <hr className="mr2 ml10"></hr>
                    <div className="flex directionColumn w40">
                        <div>
                            <ButtonLivret
                                type={'button'}
                                label={'Ajouter le module'}
                                onClick={() => createResultat()}
                                className={css.mb2}
                            />
                        </div>
                        <h3 className="textCenter">Informations diverses et validation des résultats</h3>
                        <h5>Information sur les validation</h5>
                        <TextareaAutosize
                            value={dataInput.informationValidation}
                            className={[css.ml30px, css.w100, css.nomargin, css.mb1]}
                            rowsMin={5}
                            onChange={(event) => update('informationValidation', event.target.value)}
                        />
                        <h5>Information sur les résultats</h5>
                        <TextareaAutosize
                            value={dataInput.informationResultat}
                            className={[css.ml30px, css.w100, css.nomargin, css.mb1]}
                            rowsMin={5}
                            onChange={(event) => update('informationResultat', event.target.value)}
                        />
                        <div className="flex mt1">
                            <h5>Passage</h5>
                            <ButtonLivret
                                type={'icone'}
                                label={<AddBoxIcon />}
                                tooltip={'Ajouter une pièce'}
                                onClick={() => addElement('resultat')}
                                className={css.cVert}
                            />
                        </div>
                        {addInputResultat}
                    </div>
                </div>
                <hr></hr>
                <h3 className="textCenter">Visuel final</h3>
            </>
        );
    }
    useEffect(() => {
        addElement('resultat', props.data.validation.checkboxResultat);
        for (let index = 0; index < Object.keys(props.data.validation).length; index++) {
            const indice = Object.keys(props.data.validation)[index];
            if (verifKeysResultat.includes(indice)) {
                update(indice, props.data.validation[indice]);
            }
        }
    }, [afterDelete]);
    useEffect(() => {
        if (livretDonnees.validation.checkboxResultat.length === 0) {
            const data = {
                informationValidation: dataInput.informationValidation,
                informationResultat: dataInput.informationResultat,
                dateResultat: '',
                checkboxResultat: [
                    {
                        titre: 'PASSAGE AU CYCLE SUPERIEUR',
                        valeur: false,
                    },
                ],
            };
            props.addModuleResultat(data);
        }
        view();
    }, []);
    useEffect(() => {
        if (idModule) {
            addElement('validation', props.data.validation.module[+idModule - 1].piece, idModule);
            for (let index = 0; index < Object.keys(props.data.validation.module[+idModule - 1]).length; index++) {
                const indice = Object.keys(props.data.validation.module[+idModule - 1])[index];
                if (verifKeysValidation.includes(indice)) {
                    update(indice, props.data.validation.module[+idModule - 1][indice]);
                }
            }
        }
    }, [idModule]);

    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        addModuleValidation(module) {
            dispatch({ type: 'ModuleValidation', module });
        },
        addModuleResultat(module) {
            dispatch({ type: 'ModuleResultat', module });
        },
        deleteChamp(key, module, idModule) {
            dispatch({ type: 'delete', key, module, idModule });
        },
        editModule(idModule) {
            dispatch({ type: 'editModule', idModule });
        },
    };
}
export default connect(null, mapDispatchToProps)(ModuleValidationCreate);
