import extractModeReglement from '../../Utils/extractModeReglement';
import ExportExcel from '../ExportTableau/exportExcel';

/* --------------Export des cotisationq et reglement ------------------------ */

export function ExportCotisation(props) {
    let headerExport = [
        { label: 'Nom', value: 'nomPrenomUtilisateur' },
        { label: 'Email', value: 'email' },
        { label: 'Date de naissance', value: 'dateNaissance' },
        { label: 'Adresse', value: 'adresse1' },
        { label: 'Adresse suite', value: 'adresse2' },
        { label: 'Code postal', value: 'codePostal' },
        { label: 'Ville', value: 'ville' },
        { label: 'Site', value: 'libelleSite' },
        { label: 'Montant Total', value: 'montantTotal' },
        { label: 'Montant Réglé', value: 'MontantDejaRegle' },
        { label: 'Mode de reglement', value: 'modeReglement' },
        { label: "Numero d'abonnement", value: 'abonnement' },
        { label: 'Solde restant', value: 'soldeRestant' },
    ];
    const tableauExport = [];
    switch (props.label) {
        case 'Toutes les cotisations':
            for (let index = 0; index < props.data.length; index++) {
                tableauExport.push({
                    nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                    dateNaissance: props.data[index].dateNaissance ? props.data[index].dateNaissance.split('-').join('/') : '',
                    adresse1: props.data[index].adresse1,
                    adresse2: props.data[index].adresse2,
                    codePostal: props.data[index].codePostal,
                    ville: props.data[index].ville,
                    email: props.data[index].email,
                    libelleSite: props.data[index].libelleSite,
                    montantTotal: props.data[index].montantTotal,
                    MontantDejaRegle: props.data[index].MontantDejaRegle,
                    modeReglement: extractModeReglement(props.data[index].concatCotisation),
                    abonnement: props.data[index].abonnement,
                    soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                });
            }
            break;
        case 'Règlements complets':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant === 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        dateNaissance: props.data[index].dateNaissance ? props.data[index].dateNaissance.split('-').join('/') : '',
                        adresse1: props.data[index].adresse1,
                        adresse2: props.data[index].adresse2,
                        codePostal: props.data[index].codePostal,
                        ville: props.data[index].ville,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        case 'Règlements partiels':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant > 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        dateNaissance: props.data[index].dateNaissance ? props.data[index].dateNaissance.split('-').join('/') : '',
                        adresse1: props.data[index].adresse1,
                        adresse2: props.data[index].adresse2,
                        codePostal: props.data[index].codePostal,
                        ville: props.data[index].ville,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        case 'Trop-perçus':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant < 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        dateNaissance: props.data[index].dateNaissance ? props.data[index].dateNaissance.split('-').join('/') : '',
                        adresse1: props.data[index].adresse1,
                        adresse2: props.data[index].adresse2,
                        codePostal: props.data[index].codePostal,
                        ville: props.data[index].ville,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        case 'Pas de règlement':
            for (let index = 0; index < props.data.length; index++) {
                if (+props.data[index].MontantDejaRegle === 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        dateNaissance: props.data[index].dateNaissance ? props.data[index].dateNaissance.split('-').join('/') : '',
                        adresse1: props.data[index].adresse1,
                        adresse2: props.data[index].adresse2,
                        codePostal: props.data[index].codePostal,
                        ville: props.data[index].ville,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        default:
            break;
    }
    return <ExportExcel data={tableauExport} head={headerExport} nomFichier={props.nomFichier} label={props.label} />;
}
