import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import startOfWeek from 'date-fns/startOfWeek';
import isSameDay from 'date-fns/isSameDay';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import isValid from 'date-fns/isValid';
import frLocale from 'date-fns/locale/fr';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const DateInput = (props) => {
    const css = useStylesMaterielUIGlobal();

    function formatWeekSelectLabel(date) {
        if (isValid(date)) {
            return `Semaine du ${format(startOfWeek(date, { weekStartsOn: 1 }), 'd MMM ', { locale: frLocale })}`;
        }
    }
    function renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth) {
        let dateClone = date;
        let selectedDateClone = selectedDate;

        const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
        const end = endOfWeek(selectedDateClone);

        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);
        let styleDiv = '';
        let styleCaratere = [css.day];
        if (isFirstDay) {
            styleDiv = 'firstDay';
        }
        if (isLastDay) {
            styleDiv = 'endDay';
        }
        if (dayIsBetween) {
            styleDiv = 'dayIsBetween';
            if (!dayInCurrentMonth) {
                styleCaratere = [css.day, css.highlightNonCurrentMonthDay];
            }
        }
        if (!dayInCurrentMonth) {
            styleCaratere = [css.day, css.nonCurrentMonthDay];
        }

        return (
            <div className={styleDiv}>
                <IconButton className={styleCaratere}>
                    <span> {format(dateClone, 'd')} </span>
                </IconButton>
            </div>
        );
    }
    return (
        <div className={props.classeDiv ? props.classeDiv : 'textRight algnICenter flex mb2'} style={props.styleDiv}>
            {props.label ? (
                <label className={props.classesLabel ? (props.classesLabel === false ? null : props.classesLabel) : 'w25'}>
                    {props.label}
                </label>
            ) : null}
            <KeyboardDatePicker
                disabled={props.disabled}
                format={props.format}
                views={props.views === undefined ? ['year', 'month', 'date'] : props.views}
                value={props.value === '' || props.value === null ? null : new Date(props.value)}
                className={props.className}
                onChange={props.onChange}
                maxDate={props.maxDate}
                minDate={props.minDate}
                label={props.labelChampInput}
                openTo={props.openTo}
                invalidDateMessage={props.invalidDateMessage}
                TextFieldComponent={props.TextFieldComponent}
                renderDay={props.week ? renderWrappedWeekDay : undefined}
                labelFunc={props.week ? formatWeekSelectLabel : undefined}
                disableFuture={props.disableFuture}
                helperText={props.helperText}
            />
            {props.children}
        </div>
    );
};
export default DateInput;

export function ClearCalendrier(props) {
    if (props.date) {
        return (
            <IconButton onClick={props.onClick}>
                <EventBusyIcon />
            </IconButton>
        );
    }
    return <></>;
}
